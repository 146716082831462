<div *ngIf="indicator" class="wrapper" [nbTooltip]="indicator.tooltip || ''" [nbTooltipDisabled]="!indicator.tooltip || indicator.tooltip == ''">
  <div class="indicator-title">{{indicator.title}}</div>

  <div class="flex-space-between">
    <app-icon class="me-1" *ngIf="indicator.icon" [icon]="indicator.icon" fontSize="1.5rem" style="height: 1.5rem"></app-icon>

    <div class="text-center">
      <div class="indicator-subtitle" *ngIf="indicator.subtitle && indicator.subtitle != ''">{{indicator.subtitle}}</div>
      <div *ngIf="indicator.content1 && indicator.content1 != ''">
        <div class="indicator-content1">{{indicator.content1}}</div>
        <div class="indicator-content2" *ngIf="indicator.content2 && indicator.content2 != ''">{{indicator.content2}}</div>
      </div>
      <div *ngIf="!indicator.content1">
        <div class="indicator-percent" *ngIf="percentajeSmart != '' && amountSmart != ''">
          (<span class="{{classPercent}}">{{percentajeSmart}}</span>)
        </div>
        <div class="indicator-percent" *ngIf="percentajeSmart != '' && amountSmart == ''">
          <span class="big-size {{classPercent}}">{{percentajeSmart}}</span>
        </div>
        <div class="indicator-amount {{classAnimation}}">
          <span class="{{classAmount}}" *ngIf="amountSmart != ''">{{amountSmart}}</span>
        </div>
      </div>

    </div>

  </div>


</div>
