import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-background-icon',
  templateUrl: './background-icon.component.html',
  styleUrls: ['./background-icon.component.scss']
})
export class BackgroundIconComponent implements OnInit {
  @Input() icon: string = 'star';
  @Input() fontSize: string = '20rem';
  @Input() top?: string | number;
  @Input() bottom?: string | number;
  @Input() left?: string | number;
  @Input() right?: string | number;
  @Input() margin?: string = '1rem';

  styleObject: any = {};
  center: boolean = true;

  constructor() { }

  ngOnInit(): void {

    if (this.top != undefined) {
      this.styleObject.top = this.top;
      this.center = false;
    }
    if (this.bottom != undefined) {
      this.styleObject.bottom = this.bottom;
      this.center = false;
    }
    if (this.left != undefined) {
      this.styleObject.left = this.left;
      this.center = false;
    }
    if (this.right != undefined) {
      this.styleObject.right = this.right;
      this.center = false;
    }
    if (this.center) {
      this.styleObject = { 'inset': '0px'};
    }
  }

}
