import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {getUUID} from "../../../@models/common";

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputTextareaComponent,
    multi: true
  }]
})
export class InputTextareaComponent implements OnInit, ControlValueAccessor {
  @Input() focus: boolean = false;
  @Input() label?: string;
  @Input() placeholder: string = 'introduzca un valor';
  @Input() help?: string;
  @Input() disabled: boolean = false;
  @Input() fullWidth: boolean = true;
  @Input() maxLength?: number;
  @Input() rows: number = 2;

  set model(val: string) {
    this.onChange(val);
  }
  get model(): string {
    return this.value;
  }

  value: any;  // No se pasa como parámetro, poner un formControl

  private onTouched!: Function;
  private onChanged!: Function;

  id: string = getUUID();



  constructor() { }

  ngOnInit(): void {

  }

  onChange(val: any) {

    this.onTouched();
    this.onChanged(val);
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;

    // Notificamos que hemos cambiado el valor
    this.onChanged(this.value);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }


}
