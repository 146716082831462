import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective implements AfterViewInit {
  @Input() appFocus: boolean = true;
  constructor(private host: ElementRef) {
  }

  ngAfterViewInit(): void {
    if (this.appFocus) {
      setTimeout( (): void => {
        this.host.nativeElement.focus();
      }, 100);
    }


  }
}
