import {Component, Input, OnInit} from '@angular/core';
import {Icon, IconCog} from "../../../@models/icons";
import {Status} from "../../../@models/theme";

@Component({
  selector: 'app-spinner-icon',
  templateUrl: './spinner-icon.component.html',
  styleUrls: ['./spinner-icon.component.scss']
})
export class SpinnerIconComponent implements OnInit {
  @Input() icon: Icon = IconCog;
  @Input() status: Status = 'warning';
  @Input() tooltip: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
