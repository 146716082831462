import {BaseClass} from '../@models/common';
import {BusService} from './bus.service';
import { Subject, Subscription} from 'rxjs';
import {Size} from "../@models/event";
import {BackendRequest, BackendResponse} from "./request.model";


export type EventSubscription = {
  event: string;
  callback: (event: any) => void;
}

export class BusEvents extends BaseClass {
  // @ts-ignore
  private bus: BusService;

  routeChangeEvent: Subject<string> = new Subject<string>();
  busStateEvent: Subject<boolean> = new Subject<boolean>();
  loginEvent: Subject<boolean> = new Subject<boolean>();
  pageVisibleEvent: Subject<boolean> = new Subject<boolean>();
  resizeEvent: Subject<Size> = new Subject<Size>();
  byTagEvent: Map<string,Subject<any>> = new Map();


  eventsSubscribed: EventSubscription[] = [];

  constructor(private window: Window) {
    super();
  }

  init(bus: BusService): void {
    this.bus = bus;

  }

  tagEventNext(tag: string, val: any): void {
    let subject = this.byTagEvent.get(tag);
    if (!subject) {
      subject = new Subject<any>();
      this.byTagEvent.set(tag, subject);
    }
    subject.next(val);
  }

  tagEventSubscribe(tag: string, next: (value: any) => void ): Subscription {
    let subject = this.byTagEvent.get(tag);
    if (!subject) {
      subject = new Subject<any>();
      this.byTagEvent.set(tag, subject);
    }
    return subject.subscribe(next);
  }

  emitResize(): void {
    this.window.dispatchEvent(new Event('resize'));
  }

  subscribeToEvent(key: string, callback: (event: any) => void): void {
    this.bus.ws.on(key, callback);
  }

  sendEvent(event: string, data:any): Promise<BackendResponse> {
    return new Promise<BackendResponse>( (resolve, reject): void => {
      const requestBackend: BackendRequest = new BackendRequest();
      requestBackend.event = event;
      requestBackend.data = data;
      this.bus.sendRemote(requestBackend).then( (response: BackendResponse): void  => {
        resolve(response);
      })
    });
  }

}
