import {AfterViewInit, Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[unfocus]'
})
export class UnfocusDirective implements AfterViewInit {

  constructor(private elRef: ElementRef) {}

  @HostListener('click') onClick() {
    this.elRef.nativeElement.blur();
  }

  ngAfterViewInit() {
    this.elRef.nativeElement.style.boxShadow = 'none';
  }
}
