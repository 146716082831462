import {GrpcRequest, GrpcResponse, Origin, OriginEmpty} from "./grpc";


export class LogRequest extends GrpcRequest {

    type: string = '';
    msg: string = '';


    origin: Origin = OriginEmpty;

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

}


export class LogResponse extends GrpcResponse {
    error: string = '';

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }
}


export function RemoteLog(type: string, msg: string): Promise<LogResponse> {
    const request = new LogRequest();
    request.type = type;
    request.msg = msg;
    return new Promise<LogResponse>( (resolve, reject) => {
        const response = new LogResponse();
        response.sendRequest("log", request).then( () => {
            resolve(response);
        });
    });
}

