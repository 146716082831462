import {Inject, Injectable} from '@angular/core';
import {BusService} from './bus.service';
import {ActivatedRoute, NavigationEnd, Router, UrlTree} from "@angular/router";
import {DOCUMENT} from "@angular/common";
import {RouteStartsWith} from "../@models/strings";

const MaxHistoryLength = 50

@Injectable({
  providedIn: 'root'
})
export class RouterService  {

  private history: string[] = [];

  initialRoute: string = '/';

  // @ts-ignore
  private bus: BusService;

  private window: Window | null;

  constructor(private router: Router,
              private route: ActivatedRoute,
              @Inject(DOCUMENT) private document: Document
              ) {
    this.window = this.document.defaultView;
  }

  init(bus: BusService) {
    this.bus = bus;

    this.router.events.forEach( (event) => {

      if (event instanceof NavigationEnd) {
         this.bus.events.routeChangeEvent.next(event.url);
         this.historyAdd(event.urlAfterRedirects);
      }

    });
  }

  reset() {
    this.history = [];
    this.initialRoute = '/';
  }

  historyAdd(url: string) {
    this.history.push(url);
    if (this.history.length > MaxHistoryLength) {
      this.history.shift();
    }
  }
  historyPrevious(skipCurrent: boolean = true): string | undefined {
    const previous =  this.history.pop();
    if (skipCurrent && previous == this.currentRoute()) {
      return this.historyPrevious(skipCurrent);
    }
    return previous;
  }

  back(): Promise<boolean> {
    const current = this.currentRoute();
    const previous =  this.historyPrevious();
    if (previous && previous != current) {
      return this.goTo(previous);
    } else {
      return Promise.resolve(false);
    }
  }

  private stripFragment(url: string): string {
    const obj = /[^#]*/.exec(url);
    if (obj) {
      return obj[0];
    }
    return '';
  }

  private getUrlTree(url: string): UrlTree {
    const urlPath = this.stripFragment(url) || this.stripFragment(this.router.url);
    const urlFragment = this.router.parseUrl(url).fragment;
    if (urlFragment) {
      return this.router.createUrlTree([urlPath], { relativeTo: this.route, fragment: urlFragment});
    } else {
      return this.router.createUrlTree([urlPath], { relativeTo: this.route});
    }
  }

  getQueryParams() : URLSearchParams {
    if (this.window) {
      return new URLSearchParams(this.window.location.search);
    }
    return new URLSearchParams();
  }
  getQueryParam(param: string) : string | null {
    if (this.window) {
      const urlSearchParams = new URLSearchParams(this.window.location.search);
      return urlSearchParams.get(param);
    }
    return null;
  }

  getHost() : string  {
    if (this.window) {
      return this.window.location.host;
    }
    return 'no-window';
  }

  getUrlBus(forceSecure: boolean = true): string {
    if (this.window) {
      const config = this.bus.configuration;
      let protocol = "https:";
      if (!forceSecure) {
        protocol = this.window.location.protocol;
      }
      return protocol + '//' + config.getServerDNS() + ":" + config.getWebPort();
    } else {
      return 'no-window';
    }
  }

  goTo(url: string, scrollToFragment: boolean = false, scrollOffsetY: number = 0): Promise<boolean> {
    if (this.isExternal(url)) {
      return this.goToExternal(url);
    } else {
      url = this.trimCurrentHost(url) ?? '';

      const hashParts = url.split('#');
      if (hashParts.length > 1) {
        return new Promise<boolean>( (resolve, reject) => {
          this.router.navigateByUrl(url).then( (res) => {
            if (res) {
              this.bus.dom.scrollToFragment(hashParts[1], scrollOffsetY);
            }
            resolve(res);
          });
        });

      } else {
        return this.router.navigateByUrl(url);
      }
    }
  }


  scrollToFragment(offsetY: number = 0): boolean {
    if (this.window) {
      const hash = window.location.hash;
      if (hash) {
        this.bus.dom.scrollToFragment(decodeURI(hash),offsetY);
        return true;
      }
    }

    return false;
  }


  currentRoute(): string {
    return this.router.url;
  }

  currentLocation(): string {
    if (this.window) {
      return  this.window.location.pathname;
    }
    return "";
  }



  currentRouteStartsWith(route: string): boolean {
    const currentUrl = this.bus.router.currentRoute();
    if (RouteStartsWith(currentUrl, route)) {
      return true;
    }
    return false;
  }

  currentLocationStartsWith(route: string): boolean {
    if (this.window) {
      const currentUrl = this.window.location.pathname;
      if (RouteStartsWith(currentUrl, route)) {
        return true;
      }
    }

    return false;
  }

  currentProtocolHost(): string {
    if (this.window) {
      return this.window.location.protocol + '//' + this.window.location.host;
    }
    return '';
  }

  isExternal(url: string): boolean {
    url = this.trimCurrentHost(url) ?? '';
    if (url.startsWith('/')) {
      return false;
    }
    return true;
  }

  trimCurrentHost(url: string | null): string | null {
    if (url == null) {
      return null;
    }
    // quitamos el protocol:host
    const prefix = this.currentProtocolHost();
    if (url.startsWith(prefix)) {
      return url.substring(prefix.length);
    }
    return url;
  }




  goToExternal(url: string, target: string = '_blank'): Promise<boolean> {

    if(this.window) {
      return new Promise<boolean>( (resolve, reject) => {

        try { resolve(!!this.window!.open(url, target)); }
        catch(e) { reject(e); }
      });
    } else {
       return Promise.resolve(false);
    }

  }

  downloadUrl(url: string, target: string = '_self'): Promise<boolean> {

    if(this.window) {
      return new Promise<boolean>( (resolve, reject) => {

        try { resolve(!!this.window!.open(url, target)); }
        catch(e) { reject(e); }
      });
    } else {
      return Promise.resolve(false);
    }

  }

}
