import {
  Component,
  Inject, NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {BusService} from "./@core/@bus/bus.service";
import {
  NbDialogRef,
  NbDialogService,
  NbIconLibraries,
  NbMediaBreakpointsService, NbMenuBag,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbSidebarState
} from "@nebular/theme";
import { Subject } from 'rxjs';
import {getMenuLoggedUser} from "./menu-logged";
import {getMenu, menuDocSetLogged} from "./menu-doc";
import {DestroyerComponent} from "./@core/@angular/abstract/destroyer/destroyer.component";
import {getMenuAdministrator} from "./menu-admin";
import {getMenuAsesor} from "./menu-asesor";
import {Authentication} from "./core/authentication";
import {GetGlobalEventManager} from "./core/bus-configuration";
import {HeaderIsHidden, MenuIsHidden} from "./models/routes";
import {LazyScriptLoaderService} from "./@core/@angular/services/lazy-script-loader.service";
import {DateFormat, NowSeconds} from "./@core/@models/date";
import {StorageService} from "./@core/@bus/storage.service";
import {MultiMenuItem} from "./@core/@form-view/view/multi-menu/multi-menu.component";
import {Title} from "@angular/platform-browser";
import { HttpClient } from '@angular/common/http';
import {DefaultAppTitle} from "./models/config";
import {
  CalendarioCustomDayCellComponent
} from "./features/usuario/calendario/calendario-custom-day-cell/calendario-custom-day-cell.component";
import {Icon, IconClose, MatIcon} from "./@core/@models/icons";
import {Calendario, ListCalendarioRequest, ListCalendarioResponse} from "./models/calendar";
import {GenericRequest, GenericResponse} from "./models/generic";
import {Backend} from "./core/backend";
import {getMenuSuperadmin} from "./menu-superadmin";
import {Pagination} from "./models/pagination";
import {Categorias, ListCategoriasRequest, ListCategoriasResponse} from "./models/categorias";
import {OKTA_AUTH} from "@okta/okta-angular";
import {OktaAuth, UserClaims} from "@okta/okta-auth-js";
import {BackendResponse} from "./@core/@bus/request.model";
import {LoginRequest, LoginResponse} from "./models/login";
import {FormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Status} from "./@core/@models/theme";
import {Deuda, ListDeudaRequest, ListDeudaResponse} from "./models/deuda";
import {Factura, ListFacturaRequest, ListFacturaResponse} from "./models/factura";
import {TIPO_COMPROMISO_PAGO_EUROPAFACTOR} from "./models/commons";
import {DOCUMENT} from "@angular/common";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends DestroyerComponent implements OnInit, OnDestroy {
  allowedLanguages = ['en', 'es', 'it'];

  private destroy$: Subject<void> = new Subject<void>();
  showSideBar: boolean = false;
  documentacionEnabled: boolean = false;
  documentacionEnabledToExternalUser: boolean = false;
  dayCellComponent = CalendarioCustomDayCellComponent;
  isLogged: boolean = false;
  forPrint: boolean = true;
  iconContacta: Icon = MatIcon("email", "");
  iconTelefono: Icon = MatIcon("phone", "");
  iconChat: Icon = MatIcon("chat", "");
  isMaster: boolean = false;
  isAsesor: boolean = false;
  isAdmin: boolean = false;
  fecha: Date = new Date();
  listaCalendario: Calendario[] = [];
  loading: boolean = true;
  sidebarState?: NbSidebarState;
  showDiscounts: boolean = true;
  showConsultas: boolean = true;
  showPhone: boolean = true;
  showStores: boolean = true;
  localStorageEnabled: boolean;
  listaCategorias: Categorias[] = [];
  items: NbMenuItem[] = [];
  filter: Categorias = new Categorias();
  isEuropaFactor: boolean = false;
  labelConsulta: string = $localize`INICIAR CONSULTA`;
  labelMenuSuperAdmin: string = $localize`Menú Superadministrador`;
  labelMenuAdmin: string = $localize`Menú Administrador`;
  labelMenuAsesor: string = $localize`Menú Asesor`;
  labelMenuUsuario: string = $localize`Menú Usuario`;
  labelMenuAyuda: string = $localize`Menú Ayuda`;
  labelEventos: string = $localize`Eventos`;
  hasService: boolean = false;
  isServiceOnline: boolean = false;
  labelClickToCall: string = $localize` TI CHIAMIAMO`;
  labelChat: string = $localize` CHAT`;
  labelPhone: string = $localize`Teléfono`;
  titleModal: string = $localize`Introduzca un número de teléfono`;
  labelLlamar: string = $localize`Llamar`;
  status: Status = 'basic';
  accent: Status = 'info';
  formularioClickToCall: UntypedFormGroup = new UntypedFormGroup({
    phone: new FormControl<string>('',[Validators.required]),
  });

  filterDeuda: Deuda = new Deuda();
  search: string = '';
  listaDeuda: Deuda[] = [];
  listaFactura: Factura[] = [];
  updatingList: boolean = false;
  isLoadingFacturas: boolean = false;
  @ViewChild('dialog') dialog?:TemplateRef<any>;
  dialogRef?: NbDialogRef<any>;

  public menuLogged: NbMenuItem[] = getMenuLoggedUser(this.bus)
  public menuAsesor: NbMenuItem[] = getMenuAsesor();
  public menuAdmin: NbMenuItem[] = getMenuAdministrator(this.bus);
  public menuSuperadmin: NbMenuItem[] = getMenuSuperadmin();
  public currentMenu: 'logged' | 'superadmin' | 'admin' | 'asesor' | 'documentation' = 'documentation';
  public previousMenu: 'logged' | 'superadmin' | 'admin' | 'asesor' | 'documentation' = 'documentation';
  public menuDocumentation: NbMenuItem[] =  getMenu(this.bus);
  isLoading: boolean = true;
  isAuth: any;
  public multiMenu: MultiMenuItem[] = [
    {tag: "logged", items: this.menuLogged},
    {tag: "superadmin", items: this.menuSuperadmin},
    {tag: "admin", items: this.menuAdmin},
    {tag: "asesor", items: this.menuAsesor},
    {tag: "documentation", items: this.menuDocumentation},
  ];

  constructor(@Inject(Window) private window: Window,
              private titleService: Title,
              private bus: BusService,
              private iconLibraries: NbIconLibraries,
              private sidebarService: NbSidebarService,
              private lazyLoad: LazyScriptLoaderService,
              private breakpointService: NbMediaBreakpointsService,
              private storageService: StorageService,
              private http: HttpClient,
              private menuService: NbMenuService,
              private dialogService: NbDialogService,
              private renderer: Renderer2,
              private ngZone: NgZone,
              private router: Router,
              private route: ActivatedRoute,
              @Inject(DOCUMENT) private document: Document,
              @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
              ) {
    super();
    // bus.ws.setDebug(true);
    bus.logger.debugBrowser('Constructor APP');
    this.localStorageEnabled = this.storageService.isLocalStorageEnabled();
    if(this.bus.isColectivoLexer()){
      this.showDiscounts = false;
      this.showConsultas = false;
    }
    if(this.bus.isColectivoEuropaFactor() || this.bus.isColectivoAdvTrade()){
      this.showPhone = false;
      this.showDiscounts = false;
      this.isEuropaFactor = true;
      this.labelConsulta = $localize`SCRIVICI`;
      //this.loadChatScript();
    }

    if(this.bus.isColectivoMapfre()){
      this.showPhone = false;
      this.showDiscounts = false;
    }

    /*if (this.bus.isColectivoSabadell() || this.bus.isColectivoMapfre()){
      if (Authentication.getAuth().me!.external_id) {
        this.showConsultas = true;
      }else{
        this.showConsultas = false;
      }
    }*/
    // Título de la pestaña

    const urlBus: string = this.bus.router.getUrlBus();
    if (this.localStorageEnabled) {
      // Cargamos primero el titulo cacheado
      this.titleService.setTitle(this.bus.storage.globalGet('clubintel-title',DefaultAppTitle));
    }

    this.http.get<any>(urlBus + '/assets?id=app_title&t='+NowSeconds()).subscribe({
      next: data => {
        this.titleService.setTitle(data);
        this.bus.storage.globalSet('clubintel-title',data)
      },
      error: error => {
        // Dejamos el por defecto
      }
    })

    // Cambiamos el favicon
    this.lazyLoad.changeFavIcon('favicon',urlBus + '/assets?id=favicon&t='+NowSeconds());


    if (!this.localStorageEnabled) {
      this.goToEnableStorage();
      return;
    }

    this.bus.router.initialRoute = window.location.pathname + window.location.search;

    this.iconLibraries.registerFontPack('material-icons', {
      ligature: true,
      packClass: 'material-symbols-outlined',
    });

    this.autoUnsubsbriber(bus.events.loginEvent.subscribe( (logged: boolean): void => {
      this.isLogged = logged;
      menuDocSetLogged(this.menuDocumentation, this.isLogged);
      if (logged) {
        this.isMaster = Authentication.getAuth().isMaster();
        this.isAsesor = Authentication.getAuth().isAdvisor();
        this.isAdmin = Authentication.getAuth().isAdmin();

        if (this.bus.isColectivoSabadell() || this.bus.isColectivoMapfre()) {
          this.isServiceOnline = true;
          if (Authentication.getAuth().me!.external_id) {
            this.hasService = true;
          }
        }
        const route: string = this.bus.router.currentRoute();
        this.checkShowSideBar(route);

        // Test
        // setBadgeMenuLogged(MenuLoggedIndex.Comunicados,"2");
      } else {
        this.isMaster = false;
        this.isAsesor = false;
        this.isAdmin = false;
        this.forceMenuUser();
      }
    }));

    this.autoUnsubsbriber(GetGlobalEventManager().mainSidebarStatusEvent.subscribe( (state: NbSidebarState): void => {
      this.sidebarState = state;
      this.showStores = true;
      if (state == "compacted") {
        this.showStores = false;
      }
    }));


    this.autoUnsubsbriber(GetGlobalEventManager().menuDocumentation.subscribe( (activated: boolean): void => {
      this.documentacionEnabled = activated;

      if (!activated) {
        // Activamos al menos algún menu
        this.currentMenu = this.previousMenu;
      } else {
        if (this.currentMenu != 'documentation') {
          this.previousMenu = this.currentMenu;
        }

        this.currentMenu = 'documentation';
      }

    }));

    this.autoUnsubsbriber(bus.events.routeChangeEvent.subscribe( (route: string): void => {

      this.forPrint = HeaderIsHidden(route);

      // this.checkActivatedRoute();
      this.checkShowSideBar(route);
    }));

    this.autoUnsubsbriber(this.menuService.onItemClick().subscribe((menuBag: NbMenuBag): void => {

      // Estado del sidebar
      // collapsed => totalmente oculta
      // compacted => sólo se ven los iconos
      // expanded => icono con título

      if (menuBag.tag == 'asesor' || menuBag.tag == 'logged' || menuBag.tag == 'admin' || menuBag.tag == 'superadmin' || menuBag.tag == 'documentation' ) {

        const { sm, xl } = this.breakpointService.getBreakpointsMap();
        const width: number = this.bus.dom.screenWidth();

        if (width >= xl) {
          this.sidebarService.expand('menu-sidebar');
        } else {
          if (width < sm) {
            this.sidebarService.collapse( 'menu-sidebar');
          } else {
            this.sidebarService.compact( 'menu-sidebar');
          }
        }
      }

    }));

    this.reconnect();
  }

  async ngOnInit(): Promise<void> {
    /*this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const lang = this.route.snapshot.firstChild?.paramMap.get('lang');
        if (lang && !this.allowedLanguages.includes(lang)) {
          this.router.navigate(['/404']);  // Asegúrate de tener una ruta configurada para 404
        }
      }
    });*/
    if (this.bus.isColectivoMapfre()){
      setTimeout((): void => {
        this._oktaAuth.getUser().then(async (userData) => {
          this.isAuth = await this._oktaAuth.isAuthenticated();
          if(this.isAuth) {
            const user: UserClaims = await this._oktaAuth.getUser();
            this.loginSSOMapfre(user.preferred_username)
          }
        });
      }, 700);
    }
    this.getCalendarDates();

    if (!this.bus.isColectivoLexer()){
      this.getMenuOffers();
    }

  }

  private reconnect(): void {
    // Intentamos ver si está la sessión abierta
    Authentication.reconnect().then( (logged: boolean): void => {
      if (logged) {
        this.isLogged = true;
        menuDocSetLogged(this.menuDocumentation, this.isLogged);
        this.isMaster = Authentication.getAuth().isMaster();
        this.isAsesor = Authentication.getAuth().isAdvisor();
        this.isAdmin = Authentication.getAuth().isAdmin();
        return;
      } else {
        this.isLogged = false;
        this.isMaster = false;
        this.isAsesor = false;
        this.isAdmin = false;
      }
    });
  }

  private getCalendarDates(): void{
    if (this.bus.storage.isLocalStorageEnabled()) {
      if(this.isEuropaFactor){
        this.isLoadingFacturas = true;
        setTimeout((): void => {
          //this.updateLista();
        },1000)

      }else{
        const request: ListCalendarioRequest = new ListCalendarioRequest();
        request.pagination = new Pagination({
          page_size: 100,
          order: "DESC",
        });
        Calendario.listCalendario(request).then((response: ListCalendarioResponse): void => {
          this.newResponse(response);
          this.loading = false;
        });
      }
    }
  }


  newResponse(response: ListCalendarioResponse): void {
    this.listaCalendario = [];
    if (response.error == '') {
      if (response.data) {
        this.listaCalendario = response.data;
        this.bus.storage.globalSet('calendar-dates',  this.listaCalendario);
      }
    }
  }

  override ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    super.ngOnDestroy();
  }


  checkMenuActivatedRoute(): void {
    let currentRoute: string = this.bus.router.currentRoute();

    // Quitamos parámetros
    currentRoute = currentRoute.split('?')[0];

    this.window.document.querySelectorAll('nb-menu.selectable .menu-items a').forEach(q => {
      const href: string|null = q.getAttribute("href");
      if (href) {
        if (currentRoute == href) {
          q.classList.add("active");
        } else {
          q.classList.remove("active");
        }
      }
    });
  }

  routeBelongsToMenu(route: string, menu: NbMenuItem[]): boolean {
    for (let i = 0; i < menu.length; i++) {
      const link: string = menu[i].link || '';
      if (link != "") {
        if (route.startsWith(link)) {
          return true;
        }
      }
    }
    return false;
  }


  checkShowSideBar(route: string): void {
    this.showSideBar = !MenuIsHidden(route);

    if (this.routeBelongsToMenu(route, this.menuLogged)) {
      this.currentMenu = 'logged';
      return;
    }

    if (this.routeBelongsToMenu(route, this.menuDocumentation)) {
      this.currentMenu = 'documentation';
      return;
    }

    if (this.isMaster) {
      if (this.routeBelongsToMenu(route, this.menuSuperadmin)) {
        this.currentMenu = 'superadmin';
        return;
      }
    }

    if (this.isAdmin) {
      if (this.routeBelongsToMenu(route, this.menuAdmin)) {
        this.currentMenu = 'admin';
        return;
      }
    }

    if (this.isAsesor) {
      if (this.routeBelongsToMenu(route, this.menuAsesor)) {
        this.currentMenu = 'asesor';
        return;
      }
    }

    // El resto tienen que ser pantallas que no están bajo ningún menu (p.e. menu de avatar)
  }

  sidebarStateChange(state: NbSidebarState): void {
    GetGlobalEventManager().mainSidebarStatusEvent.next(state);
    this.bus.globals.set('main-sidebar-status', state);
  }

  onMenuUser(): void {
    this.previousMenu = 'logged';
    GetGlobalEventManager().menuDocumentation.next(false);
  }

  onMenuSuperadmin(): void {
    this.previousMenu = 'superadmin';
    GetGlobalEventManager().menuDocumentation.next(false);
  }
  onMenuAdmin(): void {
    this.previousMenu = 'admin';
    GetGlobalEventManager().menuDocumentation.next(false);
  }
  onMenuAsesor(): void {
    this.previousMenu = 'asesor';
    GetGlobalEventManager().menuDocumentation.next(false);
  }

  onMenuHelp(): void {
    GetGlobalEventManager().menuDocumentation.next(true);
  }

  forceMenuUser(): void {
    this.previousMenu = 'logged';
    GetGlobalEventManager().menuDocumentation.next(false);
  }

  goToEnableStorage(): void {
    this.bus.router.goTo('/storage-not-enabled');
  }

  onChange($event: any): void{
    const fecha: string = DateFormat.fromJSDate($event).toBackendDate();
    GetGlobalEventManager().calendarioEvent.next(fecha);
    if (this.isEuropaFactor){
      this.bus.router.goTo('/accordi-pagamento');
    }else{
      this.bus.router.goTo('/calendario');
    }
  }

  onContactar(): void{
    if (this.isEuropaFactor){
      this.bus.router.goTo('/contatto');
    }else{
      this.bus.router.goTo('/contacto');
    }
  }

  onPhone(): void{
    this.bus.router.goTo('tel:800007283');
  }

  getMenuOffers(): void{
     this.updateCategories();
  }

  newResponseCategorias(response: ListCategoriasResponse): void {
    this.listaCategorias = [];
    if (response.error == '') {
      if (response.data) {
        this.listaCategorias = response.data;
        this.listaCategorias.sort((a: Categorias, b: Categorias) => a.position - b.position);

        let menuPrincipal: NbMenuItem = new NbMenuItem()
        menuPrincipal.title = "Todas las categorías";
        menuPrincipal.link = '/ventajas-descuentos'
        this.items.push(menuPrincipal)
        this.listaCategorias.filter(item => {
          let menu: NbMenuItem = new NbMenuItem()
          let badge: number = 0;
          if (!item.parent_id){
            menu.title = item.titulo;
            menu.link = '/ventajas-descuentos/categories/' + item.id_externo;
            let children: NbMenuItem[] = [];
            children.push({title: 'Todas', link: '/ventajas-descuentos/categories/' + item.id_externo});
            this.listaCategorias.filter(itemChildren => {
              if (itemChildren.items_count > 0 && itemChildren.parent_id && itemChildren.parent_id == parseInt(item.id_externo)){
                badge = badge + itemChildren.items_count;
                children.push({title: itemChildren.titulo, link: '/ventajas-descuentos/categories/' + itemChildren.id_externo});
                menu.children = children;
                if (badge > 0){
                  let badgeItem = {text: badge.toString(), status: "danger"};
                  // @ts-ignore
                //  menu.badge = badgeItem
                }
              }
            })
            this.items.push(menu)
          }
        })
        this.menuLogged[3].children = this.items
      }
    }
  }

  updateCategories(): void {
    this.loading = true;
    const request: ListCategoriasRequest = new ListCategoriasRequest();
    request.filter = this.filter;
    request.filter.activar = true;
    request.filter.parent_id = -99;
    request.pagination = new Pagination({
      page_size: 300,
    });
    Categorias.listCategorias(request).then((response: ListCategoriasResponse): void => {
      this.newResponseCategorias(response);
      this.loading = false;
    });
  }

  loginSSOMapfre(data: any): Promise<BackendResponse> {
    return new Promise<BackendResponse>( (resolve, reject): void => {
      const request: GenericRequest = new GenericRequest();
      request.command = "sso_mapfre";
      request.data = data;
      Backend.generic(request).then( (response: BackendResponse): void => {
        if (response.data){
          this.isLoading = false;
          const request: LoginRequest = new LoginRequest()
          request.login = data;
          request.password = data;
          Authentication.login(request).then( (logged: LoginResponse): void => {
            if (logged.logged) {
              const routed: boolean = GetGlobalEventManager().routeIfPendingConfigurations();
              if (!routed) {
                const request2: GenericRequest = new GenericRequest();
                request2.command = 'force_update';
                Backend.genericAuth(request2).then( (response: GenericResponse): void => {
                  if (response.error == "") {
                    Authentication.goToLanding();
                  } else {
                    this.bus.dom.toastError(response.error);
                  }
                });
                //this.bus.router.goTo('/inicial');
              }else{
                this.bus.router.goTo('/configuracion');
              }
              this.isLoading = false;

              return;
            }
            this.isLoading = false;
          });
        }
        resolve(response);
      })
    });
  }

  onClickToCall(): void {
    const request: GenericRequest = new GenericRequest();
    request.command = 'click_to_call';
    request.data = Authentication.getAuth().me?.external_id + '/' + this.formularioClickToCall.value.phone;
    Backend.genericAuth(request).then( (response: GenericResponse): void => {
      if (response.error == "" && response.data != "") {
        //this.bus.router.goTo(response.data);
        this.closeModal();
      } else {
        this.bus.dom.toastError('No es posible conectar con otra plataforma');
      }
    });
  }

  openClickToCallModal(): void {
    if (this.dialog) {
      // Asignamos valores al formulario
      this.formularioClickToCall.reset({
        phone:  Authentication.getAuth().config?.me?.phone,
      })

      this.dialogRef = this.dialogService.open(this.dialog, {closeOnBackdropClick: false, autoFocus: false})
    }
  }

  onCancelar(): void {
    this.closeModal();
  }

  closeModal(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
  protected readonly iconClose: Icon = IconClose;

  updateLista():void  {
    const request: ListDeudaRequest = new ListDeudaRequest();
    request.search = this.search;
    if (Authentication.getAuth().me!.external_id) {
      const externalId: string = Authentication.getAuth().me!.external_id;
      if (externalId) {
        request.filter = this.filterDeuda;
        request.filter.id_externo = externalId;
      }
      Deuda.listDeuda(request).then((response: ListDeudaResponse): void => {

        if (response.error == "") {
          if (response.data) {
            response.data.forEach((item: Deuda): void => {
              this.listaDeuda.push(item);
              this.updateListFacturas()
            });
          }
        }
        this.updatingList = false;
        this.isLoadingFacturas = false;

      });
    }
  }

  updateListFacturas(): void{
    this.isLoadingFacturas = true;
    const requestFactura: ListFacturaRequest = new ListFacturaRequest();
    requestFactura.search = this.listaDeuda[0].id
    Factura.listFactura(requestFactura).then((response: ListFacturaResponse): void => {
      this.newResponseFactura(response);
      this.isLoadingFacturas = false;
    });
  }

  newResponseFactura(response: ListFacturaResponse): void {
    this.listaFactura = [];
    if (response.error == '') {
      if (response.data) {
        response.data.forEach((item: Factura): void => {
          if (item.tipo === TIPO_COMPROMISO_PAGO_EUROPAFACTOR){
            this.listaFactura.push(item);
          }
        });
        this.bus.storage.globalSet('calendar-dates',  this.listaFactura);
      }
    }
  }

  loadChatScript(): void {
    this.ngZone.runOutsideAngular(() => {
      const domain = window.location.hostname;
      let scriptUrl = '';
      let ok = false;

      if (domain === 'europafactor.vozitel.com') {
        scriptUrl = 'https://europafactor.vozitel.com/it/assets/chat/dist/vozitelpublicchateuropafactor.js';
        ok = true;
      } else if (domain === 'mapfre.clubintel.com') {
        scriptUrl = 'https://mapfre.clubintel.com/es/assets/chat/dist/vozitelpublicchatmapfre.js';
        ok = true;
      }

      if (ok && scriptUrl) {
        const script = this.renderer.createElement('script');
        script.src = scriptUrl;
        script.type = 'text/javascript';
        this.renderer.appendChild(this.document.head, script);
      }
    });
  }

}

