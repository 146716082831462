<nb-form-field>
  <input class="input-inline" type="text" [(ngModel)]="value" nbInput
         [placeholder]="placeHolder"
         [fieldSize]="size"
         [disabled]="disabled"
         (blur)="onBlur($event)"
         (focus)="onFocus($event)"
         (keyup.enter)="onEnter()"
         (input)="onInput($event)">
</nb-form-field>
