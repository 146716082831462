import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Icon, IconCog} from "../../../@models/icons";
import {Status} from "../../../@models/theme";

@Component({
  selector: 'app-header-action',
  templateUrl: './header-action.component.html',
  styleUrls: ['./header-action.component.scss']
})
export class HeaderActionComponent implements OnChanges {

  @Input() icon: Icon = IconCog;
  @Output() onClick = new EventEmitter<void>();
  @Input() disabled: boolean = false;
  @Input() status: Status = 'primary';
  @Input() tooltip: string = '';
  @Input() withPadding: boolean = false;
  @Input() stopPropagation: boolean = false;

  transformClass = 'animation-heartbeat';
  currentAnimationComponent = '';
  statusCalculated: Status = 'primary';

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.statusCalculated = this.status;
    if (this.disabled) {
      this.statusCalculated = "basic";
    }
  }


  onButtonClicked(event: Event) {
    if (this.stopPropagation) {
      event.stopPropagation();
    }
    this.currentAnimationComponent = this.transformClass;
    setTimeout( () => this.currentAnimationComponent = "", 150);
    this.onClick.emit();

  }

}
