<div class="header-container">
  <div [class]="isEuropaFactor ? 'logo-container-europafactor' : 'logo-container'">
    <a *ngIf="(isLogged || showDocMenu) && !menuIsHidden && !onlyClose && !onlyDocument && !loading" (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" [class]="{ 'logo-logged': isLogged }" href="#" (click)="navigateHome()">
      <img *ngIf="!isVozitel && !isMapfre" class="{{logoAnimation}}" style="height: 2.5rem" src="{{logoIcono}}" alt="Icono"/>
      <img *ngIf="logoHeightLoaded" class="ps-3 hide-md" style="height: {{logoHeight}}" src="{{logoNombre}}" alt="Logo"/>
    </a>
  </div>
</div>
<div *ngIf="!onlyClose && !onlyDocument" class="w-100 flex-space-between">
  <div></div>
  <div class="header-container">
    <app-header-progress-controller *ngIf="isLogged" class="hide-sm"></app-header-progress-controller>
  </div>
  <div class="header-container">
    <app-spinner-icon *ngIf="busError" [icon]="iconConexion" tooltip="{{labelSinConexion}}" status="danger"></app-spinner-icon>

    <nb-actions size="small">
     <!-- <nb-action class="control-item">
        <app-switch class="hide-lg" size="tiny" [checked]="currentTheme == 'dark'" (change)="changeTheme()" >{{currentThemeLabel}}</app-switch>
      </nb-action>-->

      <!--<nb-action *ngIf="isLogged" [ngClass]="{'action-circle-active': publicEnabled, 'action-circle-inactive': !publicEnabled}"
                 class="no-border-lg control-item" nbTooltip="Web Pública"
                 [icon]="iconPublic" (click)="onPublic()" ></nb-action>-->

      <nb-action *ngIf="isLogged && !isMapfre" [ngClass]="{'action-circle-active': documentacionEnabled, 'action-circle-inactive': !documentacionEnabled}"
                 class="no-border-lg control-item" nbTooltip="{{labelAyuda}}"
                 [icon]="iconDocs" (click)="onDocs()" ></nb-action>

      <nb-action *ngIf="isLogged"
                 nbTooltip="{{labelAlertas}}"
                 class="cursor-pointer action-container"
                 [icon]="iconBell"
                 [badgeDot]="alertasPendientes"
                 badgePosition="top end"
                 [badgeStatus]="alertasStatus"
                 [nbPopover]="listaAlertas" nbPopoverPlacement="bottom"
                 (click)="onAlertas()"></nb-action>

      <nb-action *ngIf="isLogged" >
        <app-avatar class="menu-context-align-left" nbContextMenuTag="user-menu-option" [onlyPicture]="userPictureOnly" [nbContextMenu]="userMenu" [user]="user"></app-avatar>
      </nb-action>



      <nb-action *ngIf="!isLogged && !loading" class="login-button">
        <button *ngIf="bpIsGreaterThanSM" nbButton size="tiny" (click)="onLogin()">
          <app-icon [icon]="iconLogin"></app-icon>
          <span class="ps-2">{{labelAcceder}}</span>
        </button>
        <a *ngIf="!bpIsGreaterThanSM" class="cursor-pointer" (click)="onLogin()">
          <app-icon [icon]="iconLogin" fontSize="1.75rem"></app-icon>
        </a>
      </nb-action>

    </nb-actions>
  </div>
</div>
<div *ngIf="onlyClose" class="header-container text-disabled">
  <span>{{labelCerrarPestana}}</span>
  <!--<nb-actions>
    <nb-action class="control-item">
      <app-switch class="hide-lg" size="tiny" [checked]="currentTheme == 'dark'" (change)="changeTheme()" >Modo Oscuro</app-switch>
    </nb-action>
  </nb-actions>-->
</div>

<div *ngIf="onlyDocument" class="header-container">
  <nb-actions>
    <!--<nb-action class="control-item">
      <app-switch class="hide-lg" size="tiny" [checked]="currentTheme == 'dark'" (change)="changeTheme()" >Modo Oscuro</app-switch>
    </nb-action>-->
    <nb-action>
      <button nbButton size="tiny" (click)="onCerrarDocumento()">
        <app-icon [icon]="iconDocumento"></app-icon>
        <span class="ps-2">{{labelCerrar}}</span>
      </button>
    </nb-action>
  </nb-actions>
</div>

<ng-template #listaAlertas>
  <app-header-alertas></app-header-alertas>
</ng-template>

