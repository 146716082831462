import { NbMenuItem } from '@nebular/theme';
import {MatIcon} from "./@core/@models/icons";



export function getMenuSuperadmin(): NbMenuItem[] {
  const menu: NbMenuItem[] = [
    {
      title: $localize`Colectivos`,
      icon: MatIcon('account_balance'),
      link: '/administracion/colectivos',
    },
    {
      title: $localize`Usuarios registrados`,
      icon: MatIcon('group'),
      link: '/administracion/usuarios-registrados',
    },
    /*{
      title: $localize`Usuarios conectados`,
      icon: MatIcon('sensor_occupied'),
      link: '/administracion/usuarios-conectados',
    },*/
    {
      title: $localize`Dashboard ofertas`,
      icon: MatIcon('savings'),
      link: '/administracion/historico-ofertas',
      children: [
        {
          title: $localize`Dashboard`,
          link: '/administracion/dashboard-ofertas',
        },
        {
          title: $localize`Histórico ofertas`,
          link: '/administracion/resumen-ofertas',
        },
        {
          title: $localize`Histórico compras`,
          link: '/administracion/historico-compras',
        },
        {
          title: $localize`Histórico reembolsos`,
          link: '/administracion/historico-reembolsos',
        },
      ]
    },
    {
      title: $localize`Histórico conexiones`,
      icon: MatIcon('history'),
      link: '/administracion/historico-conexiones',
    },
    /* {
       title: $localize`Pruebas`,
       icon: MatIcon('category'),
       link: '/administracion/pruebas',
     },*/



  ];
  return menu;
}
