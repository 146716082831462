<div class="wrapper">
  <div class="flex-space-between wrapper-header">
    <div>
      <div>{{label}}</div>
      <div *ngIf="subtitle != ''" class="label-subtitle">{{subtitle}}</div>
    </div>

    <app-switch [disabled]="disabled" size="tiny" [checked]="checked" (change)="onEnabled($event)"></app-switch>
  </div>
</div>


