import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

const NoImageHorizontal = '@assets/img/no-image-horizontal-es.svg';
const NoImageVertical = '@assets/img/no-image-vertical-es.svg';

@Component({
  selector: 'app-img-loader',
  templateUrl: './img-loader.component.html',
  styleUrls: ['./img-loader.component.scss']
})
export class ImgLoaderComponent implements OnChanges {
  @Input() objectFit: "cover" | "contain" | "fill" | "none" | "scale-down" = "cover";
  @Input() placeHolderVertical: boolean = false;
  @Input() src: string = '';
  @Input() width?: string;
  @Input() timeout: number = 10000;  // 10 segundos para que cargue
  @Input() upgradeToHttps: boolean = true;
  @Input() height?: string;
  @Input() imgClass?: string;
  @Input() borderRadius: string = '0.25rem';
  @Input() isVisible: boolean = true;  // Para controlar que cargue cuando sea visible

  loading: boolean = true;
  oldSrc: string = '';

  constructor() { }

  getNoImage() {
    if (this.placeHolderVertical) {
      return NoImageVertical;
    } else {
      return NoImageHorizontal;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.src && this.oldSrc) {
      return;
    }
    if (!this.src) {
      this.src = this.getNoImage();
    }

    if (this.oldSrc != this.src) {
      this.loading = true;

      if (this.upgradeToHttps) {
        this.src = this.src.replace('http://','https://');
      }
    }

    this.oldSrc = this.src;

    if(this.loading && this.timeout > 0 && this.isVisible) {
      setTimeout((): void => {
        if (this.loading) {
          this.onError();
        }
      }, this.timeout);
    }
  }

  onFinishLoad(): void {
    this.loading = false;
  }

  onError(): void {
    this.loading = false;
    this.src = this.getNoImage();
  }

}
