<nb-card [status]="status" [accent]="accent" class="wrapper">
  <nb-card-header>
    <div class="form-title">{{header}}</div>
  </nb-card-header>
  <nb-card-body>
    <div class="flex-space-between h-100">
      <div class="wizard-image" *ngIf="imageUrl != ''">
        <app-img-loader width="18rem" src="{{imageUrl}}"></app-img-loader>
      </div>
      <div class="wizard-body-wrapper">
        <div *ngIf="title" class="{{titleClass}}">
          {{title}}
        </div>
        <div *ngIf="subtitle" class="{{subtitleClass}}">
          {{subtitle}}
        </div>
        <div *ngIf="body" class="{{bodyClass}}">
          {{body}}
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="flex-space-between">
      <div>
        <app-button [disabled]="!hasPrevious" class="me-2" label="Anterior" size="small" status="basic" (onPress)="pagePrevious()"></app-button>
        <app-button [disabled]="!hasNext"  label="Siguiente" size="small" status="basic" (onPress)="pageNext()"></app-button>
      </div>
      <app-button [disabled]="!canExit" class="ms-2"  label="Salir" size="small" status="danger" (onPress)="onCancelar()"></app-button>
    </div>
  </nb-card-footer>
</nb-card>
