import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input, OnChanges,
  OnDestroy,
  OnInit, SimpleChanges
} from '@angular/core';
import {BusService} from "../../@bus/bus.service";
import {Subscription} from "rxjs";


// Importante: el padre no debe hacer scroll sino irá crediendo indefinidamente

@Directive({
  selector: '[HeightVisible]'
})

export class HeightVisibleDirective implements OnInit,OnChanges, OnDestroy, AfterViewInit, AfterViewChecked {
  @Input() HeightVisible: any;
  @Input() PaddingBottom?: string;
  @Input() MinHeight?: string;
  @Input() Offset?: string;

  subs: Subscription[] = [];
  height: string = '';

  constructor(
      private bus: BusService,
      private elRef: ElementRef,
      private cd: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.subs.push(this.bus.events.resizeEvent.subscribe((size): void => {
      this.calculaHeight(false, true);
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculaHeight();
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription): void => {
      sub.unsubscribe();
    });
  }

  ngAfterViewInit(): void {
    this.calculaHeight();
  }

  ngAfterViewChecked(): void {
    this.calculaHeight(true);
  }

  calculaHeight(fromViewChecked: boolean = false, fromResize: boolean = false): void {
    // Ojo: que ancestros no hagan scroll porque crece indefinidamente
    if (this.HeightVisible === false || this.HeightVisible == "false") {
      return;
    }

    const rect = this.elRef.nativeElement.getBoundingClientRect();

    const lastHeight = this.height;
    const top = rect.y;
    const screenSize = this.bus.dom.screenSize();
    const screenHeight = screenSize.innerHeight;
    let h = screenHeight - top - 1;
    if (this.Offset) {
      const offsetPx: number = this.bus.dom.toPX(this.elRef,this.Offset);
      h = h - offsetPx;
    }

    if (h <= 0) {

      // Hay que volver a calcular más tarde
      setTimeout( (): void => {
        this.calculaHeight(true);
      }, 0);
      return; // No ha terminado de calcular?

    }

    // Le quitamos padding
    this.height = h + "px";

    if (fromViewChecked && this.height == lastHeight) {
      return
    }

    // this.elRef.nativeElement.style.height = this.height;
    this.elRef.nativeElement.style.setProperty('height', this.height, 'important');

    if (this.MinHeight) {
      this.elRef.nativeElement.style.minHeight = this.MinHeight;
    }

    if (this.PaddingBottom) {
      this.elRef.nativeElement.style.paddingBottom = this.PaddingBottom;
    }

    this.elRef.nativeElement.style.marginBottom = 0;

    this.cd.detectChanges();

    // console.log("height end =>", this.height, " posicion => ", top, ' screenHeight =>', screenHeight, rect);
  }

}
