import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

import localeES from '@angular/common/locales/es';
import localeIT from '@angular/common/locales/it';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    NbThemeModule,
    NbLayoutModule,
    NbMenuModule,
    NbSidebarModule,
    NbToastrModule,
    NbIconModule,
    NbTooltipModule,
    NbCardModule,
    NbButtonModule,
    NbTimepickerModule,
    NbDatepickerModule,
    NbCalendarModule,
    NbNativeDateService, NbAlertModule,
} from '@nebular/theme';
import {LayoutService} from "./@core/@angular/services/layout.service";
import {BusService} from "./@core/@bus/bus.service";
import {getTime} from "./@core/@models/common";
import {CoreModule} from "./@core/@bus/core.module";
import { registerLocaleData} from "@angular/common";
import { DestroyerComponent } from './@core/@angular/abstract/destroyer/destroyer.component';
import {LayoutModule} from "./layout/layout.module";
import {NbEvaIconsModule} from "@nebular/eva-icons";
import {LoginModule} from "./features/login/login.module";
import {ChatBotEfModule} from "./features/usuario/europafactor/chat-bot-ef/chat-bot-ef.module";
import {CanDeactivateGuard} from "./@core/@angular/guards/can-deactivate.guard";
import {FormViewModule} from "./@core/@form-view/@form-view.module";
import {ConfigureBus} from "./core/bus-configuration";
import {DialogsModule} from "./@core/@dialogs/@dialogs.module";
import {CalendarModule} from "./@core/@tools/calendar/calendar.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

const oktaAuth: OktaAuth = new OktaAuth({
  issuer: 'https://micuenta.mapfretest.com/oauth2/default',
  clientId: '0oa6x7y0dsEC3mOdZ0x7',
  redirectUri: window.location.origin + '/es/login/callback',
});

export function initializeApp(bus: BusService) {
  return () => new Promise<boolean>((resolve, reject): void => {
    // Esperamos a que el constructor haya acabado
    if (!bus) {
      console.log("initializeApp: no se ha pasado el bus");
      resolve(false);
      return;
    }

    // Configuramos el bus
    ConfigureBus(bus);

    const inicio = getTime();
    const interval = setInterval((): void =>{
      if(bus.wasm.isLoaded()) {
        const elapsed = getTime() - inicio;
        console.log("**** inicialización completa "+ elapsed +" milisegundos ****");
        clearInterval(interval);
        resolve(true);
      }
    }, 50);
  });
}

@NgModule({
    declarations: [
        AppComponent,
        DestroyerComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        LayoutModule,
        BrowserAnimationsModule,
        NbThemeModule.forRoot({name: 'default'}),
        NbLayoutModule,
        NbEvaIconsModule,
        NbMenuModule.forRoot(),
        NbSidebarModule.forRoot(),
        NbToastrModule.forRoot(),
        LoginModule,
        ChatBotEfModule,
        NbIconModule,
        NbTooltipModule,
        NbCardModule,
        NbButtonModule,
        HttpClientModule,
        OktaAuthModule,
        OktaAuthModule.forRoot({oktaAuth}),
        NbDatepickerModule.forRoot(),
        NbTimepickerModule.forRoot({
            localization: {
                hoursText: 'Hor',
                minutesText: 'Min',
                secondsText: 'Seg',
                ampmText: 'Am/Pm',
            }
        }),
        FormViewModule,
        DialogsModule,
        CalendarModule,
        NbCalendarModule,
        FormsModule,
        ReactiveFormsModule,
        NbAlertModule,
    ],
    providers: [
        LayoutService,
        CanDeactivateGuard,
        NbNativeDateService,
        {provide: LOCALE_ID, useValue: navigator.language},
        {provide: Window, useValue: window},
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [BusService],
            multi: true
        },
    ],
    bootstrap: [AppComponent],

})
export class AppModule {


  constructor() {
    registerLocaleData(localeES);
    registerLocaleData(localeIT);
  }

}
