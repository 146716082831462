<nb-form-field>
  <nb-icon *ngIf="icon" nbPrefix [icon]="icon.icon" [pack]="icon.pack" [status]="icon.status"></nb-icon>
  <input [disabled]="disabled" style="text-align: {{align}}" [appFocus]="focus" (focus)="onFocusEvent()" [fullWidth]="fullWidth" [shape]="shape" [placeholder]="placeholder" [type]="type" [(ngModel)]="value" nbInput [fieldSize]="size" (input)="onInput()">
  <button  [tabIndex]="-1" *ngIf="value && !disabled" nbSuffix nbButton ghost size="tiny" (click)="onClear($event)">
    <nb-icon icon="close" pack="eva"></nb-icon>
  </button>
  <div *ngIf="!value || disabled" class="no-button" nbSuffix>

  </div>
</nb-form-field>
