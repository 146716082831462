import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LandingComponent} from "./layout/landing/landing.component";
import {IsLoggedGuard} from "./is-logged.guard";
import {IsMasterGuard} from "./is-master.guard";
import {StorageNotEnabledComponent} from "./layout/storage-not-enabled/storage-not-enabled.component";
import {IsAdminGuard} from "./is-admin.guard";
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import {NotFoundComponent} from "./features/not-found/not-found.component";

const allowedLanguages = ['en', 'es', 'it'];

const routes: Routes = [


  { path: '',   component: LandingComponent, pathMatch: 'full' },

  { path: 'storage-not-enabled',   component: StorageNotEnabledComponent, pathMatch: 'full' },

  { canActivate:[IsLoggedGuard], path: 'configuracion', loadChildren: () => import('./features/configuracion/configuracion.module').then(m => m.ConfiguracionModule) },

  { canActivate:[IsLoggedGuard], path: 'buscador-noticias', loadChildren: () => import('./features/buscador-noticias/buscador-noticias.module').then(m => m.BuscadorNoticiasModule) },

  { path: 'login', loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule) },

  { path: 'not-found', loadChildren: () => import('./features/not-found/not-found.module').then(m => m.NotFoundModule) },

  { path: 'documentacion', loadChildren: () => import('./features/documentacion/documentacion.module').then(m => m.DocumentacionModule) },

  { path: 'chatbot', loadChildren: () => import('./features/usuario/europafactor/chat-bot-ef/chat-bot-ef.module').then(m => m.ChatBotEfModule) },

  { canActivate:[IsLoggedGuard], path: 'legal', loadChildren: () => import('./features/legal/legal.module').then(m => m.LegalModule) },

  { canActivate:[IsMasterGuard], path: 'administracion', loadChildren: () => import('./features/administracion/administracion.module').then(m => m.AdministracionModule) },

  {canActivate:[IsAdminGuard], path: 'administrador', loadChildren: () => import('./features/administrador/administrador.module').then(m => m.AdministradorModule) },

  { canActivate:[IsLoggedGuard], path: 'avatar', loadChildren: () => import('./features/avatar/avatar.module').then(m => m.AvatarModule) },

  { canActivate:[IsLoggedGuard], path: 'inicial', loadChildren: () => import('./features/usuario/inicial/inicial.module').then(m => m.InicialModule) },

  { canActivate:[IsLoggedGuard], path: 'enlaces-interes', loadChildren: () => import('./features/usuario/enlaces-interes/enlaces-interes.module').then(m => m.EnlacesInteresModule) },

  { canActivate:[IsLoggedGuard], path: 'preguntas-frecuentes', loadChildren: () => import('./features/usuario/preguntas-frecuentes/preguntas-frecuentes.module').then(m => m.PreguntasFrecuentesModule) },

  { canActivate:[IsLoggedGuard], path: 'calendario', loadChildren: () => import('./features/usuario/calendario/calendario.module').then(m => m.CalendarioModule) },

  { canActivate:[IsLoggedGuard], path: 'contacto', loadChildren: () => import('./features/usuario/contacto/contacto.module').then(m => m.ContactoModule) },

  { canActivate:[IsLoggedGuard], path: 'empleados', loadChildren: () => import('./features/usuario/empleados/empleados.module').then(m => m.EmpleadosModule) },

  { canActivate:[IsLoggedGuard], path: 'contatto', loadChildren: () => import('./features/usuario/europafactor/contatto/contatto.module').then(m => m.ContattoModule) },

  { canActivate:[IsLoggedGuard], path: 'documenti-scaricabili', loadChildren: () => import('./features/usuario/europafactor/documenti-scaricabili/documenti-scaricabili.module').then(m => m.DocumentiScaricabiliModule) },

  { canActivate:[IsLoggedGuard], path: 'accordi-pagamento', loadChildren: () => import('./features/usuario/europafactor/accordi-pagamento/accordi-pagamento.module').then(m => m.AccordiPagamentoModule) },

  { canActivate:[IsLoggedGuard], path: 'effettua-pagamento', loadChildren: () => import('./features/usuario/europafactor/effettua-pagamento/effettua-pagamento.module').then(m => m.EffettuaPagamentoModule) },

  { canActivate:[IsLoggedGuard], path: 'area-personale', loadChildren: () => import('./features/usuario/europafactor/area-personale/area-personale.module').then(m => m.AreaPersonaleModule) },

  { canActivate:[IsLoggedGuard], path: 'consultas', loadChildren: () => import('./features/usuario/consultas/consultas.module').then(m => m.ConsultasModule) },

  { canActivate:[IsLoggedGuard], path: 'documentos', loadChildren: () => import('./features/usuario/documentos/documentos.module').then(m => m.DocumentosModule) },

  { canActivate:[IsLoggedGuard], path: 'renta', loadChildren: () => import('./features/usuario/renta/renta.module').then(m => m.RentaModule) },

  { canActivate:[IsLoggedGuard], path: 'ventajas-descuentos', loadChildren: () => import('./features/usuario/ventajas-descuentos/ventajas-descuentos.module').then(m => m.VentajasDescuentosModule) },

  { canActivate:[IsLoggedGuard], path: 'consultar-deuda', loadChildren: () => import('./features/usuario/procesos/consultar-deuda/consultar-deuda.module').then(m => m.ConsultarDeudaModule) },

  { canActivate:[IsLoggedGuard], path: 'buzones', loadChildren: () => import('./features/buzones/buzones.module').then(m => m.BuzonesModule) },

  { canActivate:[IsLoggedGuard], path: 'comunicados', loadChildren: () => import('./features/comunicados/comunicados.module').then(m => m.ComunicadosModule) },

  { path: 'registro', loadChildren: () => import('./features/registro/registro.module').then(m => m.RegistroModule) },

  { path: 'public', loadChildren: () => import('./features/public/public.module').then(m => m.PublicModule) },

  { path: 'administrador', loadChildren: () => import('./features/administrador/administrador.module').then(m => m.AdministradorModule) },

  { path: 'asesor', loadChildren: () => import('./features/asesor/asesor.module').then(m => m.AsesorModule) },

  { path: 'accion', loadChildren: () => import('./features/accion/accion.module').then(m => m.AccionModule) },

  {
    path: 'protected',
    loadChildren: () => import('./features/protected/protected.module').then(m => m.ProtectedModule),
    canActivate: [OktaAuthGuard]
  },
  { path: 'login/callback', component: OktaCallbackComponent },
  { path: '**', component: NotFoundComponent },  // Ruta 404

 // { path: '**', pathMatch: 'full', loadChildren: () => import('./features/not-found/not-found.module').then(m => m.NotFoundModule)  },  // Wildcard route for a 404 page

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'disabled', // scrolls to the anchor element when the URL has a fragment
      // scrollOffset: [0, 64],  // scroll offset when scrolling to an element (optional)
      // scrollPositionRestoration: 'enabled', // restores the previous scroll position on backward navigation
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
