import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IconSearch, IconWithProps} from "../../../@models/icons";
import {NbPosition, NbTrigger} from "@nebular/theme";

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit {
  @Input() advancedMode: boolean = false;
  @Input() tooltip: string = "";
  @Input() value: any = '';

  @Output() onInputChange = new EventEmitter<any>();

  iconSearch = IconSearch;
  popoverPosition = NbPosition.BOTTOM;
  trigger: NbTrigger = NbTrigger.FOCUS;
  helpDisabled: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if (this.advancedMode) {
      this.iconSearch = IconWithProps(this.iconSearch, {'status': 'primary'}); // status para indicar que admite +,- y *
    }
  }

  async onSearch(value: string) {
    this.helpDisabled = true;
    this.onInputChange.emit(value);
  }

  onFocus() {
    this.helpDisabled = false;
  }

}
