import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  NbMenuService,
  NbPopoverDirective,
} from "@nebular/theme";
import {Icon} from "../../../@core/@models/icons";
import {BusService} from "../../../@core/@bus/bus.service";
import {Subscription} from "rxjs";
import {ProgressIndicator} from "../../../@core/@bus/download.service";

@Component({
  selector: 'app-header-progress-controller',
  templateUrl: './header-progress-controller.component.html',
  styleUrls: ['./header-progress-controller.component.scss']
})
export class HeaderProgressControllerComponent implements OnInit, OnDestroy {
  progress: number = 0;
  title: string = '';
  badge: string = '';
  elapsed: string = '';

  @ViewChild(NbPopoverDirective) popover?: NbPopoverDirective;

  iconCollapsed: string = 'chevron-down-outline';
  transformClass: string = 'animation-rotate180';
  animationFinish: string = 'animation-shake';
  animationBadge: string = 'animation-zoom-in';
  labelClassOK: string = 'label-inner';

  progressStatus: string = 'basic';
  labelClass: string = this.labelClassOK;

  currentAnimationComponent: string = '';
  currentAnimationBadge: string = '';

  currentIcon: string = this.iconCollapsed;
  currentTransformClass: string = '';

  stateOpened: boolean = false;


  subs: Subscription[] = [];
  descargasActivas: Map<string,ProgressIndicator> = new Map();
  showingDescargaId: string = '';
  cuantasActivas: number = 0;
  currentJobIcon?: Icon;

  constructor(private bus: BusService, private eRef: ElementRef,  private menuService: NbMenuService) {
    // Subscribimos a events de jobs
    this.subs.push(this.bus.download.progressEvents.subscribe((job): void =>{
      this.newEvent(job);
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription): void => {
      sub.unsubscribe();
    });
  }

  switchSubmenu(): void {
    if(this.stateOpened) {
      this.closeSubmenu();
    } else {
      this.openSubmenu();
    }
  }

  openSubmenu(): void {
    if(this.popover) {
      if(!this.stateOpened) {
        this.popover.show();
        this.stateOpened = true;
        this.currentTransformClass = this.transformClass;

      }
    }
  }

  closeSubmenu(): void {
    if(this.popover) {
      if(this.stateOpened) {
        this.popover.hide();
        this.stateOpened = false;
        this.currentTransformClass = '';
      }
    }
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: Event): void {
    // Vemos si ha clicado fuera del compenente
    if(this.eRef.nativeElement.contains(event.target)) {
      this.switchSubmenu();
    } else {
      this.closeSubmenu();
    }
  }

  newEvent(descarga: ProgressIndicator): void {
    if (descarga.uniqId == '') {
      // Todavía no se le ha asigando el ID
      return;
    }

    // Existe
    const existe: boolean = this.descargasActivas.has(descarga.uniqId);
    if (!existe) {
      // Añadir
      this.addNew(descarga);
    } else {
      // Actualizar
      this.processExistingEvent(descarga);
    }

  }

  processExistingEvent(descarga: ProgressIndicator): void {
    // Actualizar
    if (descarga.error && descarga.error != '') {
      this.finishWithError(descarga);
    } else {
      if (descarga.progress >= 100) {
        this.finishOK(descarga);
      } else {
        this.updateDescarga(descarga);
      }
    }
  }



  addNew(descarga: ProgressIndicator): void {
    this.descargasActivas.set(descarga.uniqId, descarga);
    this.cuantasActivas = this.descargasActivas.size;
    if (this.cuantasActivas == 1) {
      // Ponemos esta como activa
      this.activateDescarga(descarga);
    }
    this.updateBadge()

    // Lo volvemos a procesar por si ha acabado
    this.processExistingEvent(descarga);

  }


  updateDescarga(descarga: ProgressIndicator): void {
    this.descargasActivas.set(descarga.uniqId, descarga);
    if (this.showingDescargaId == descarga.uniqId) {
      this.progress = descarga.progress;
      this.title = descarga.title;
      this.labelClass = this.labelClassOK;
      this.updateElapsed(descarga);
      this.updateDescargaIcon(descarga);
    }
  }



  activateDescarga(descarga: ProgressIndicator): void {
    // Ponemos esta como activa
    this.progress = descarga.progress;
    this.title = descarga.title;
    this.labelClass = this.labelClassOK;

    this.showingDescargaId = descarga.uniqId;
    this.progressStatus = 'basic';
    this.updateElapsed(descarga);
    this.updateDescargaIcon(descarga);
    this.updateBadge();

  }

  updateDescargaIcon(descarga: ProgressIndicator): void {
    this.currentJobIcon = descarga.icon;
  }

  updateElapsed(descarga: ProgressIndicator): void {
    const creado: number = descarga.created.getTime();
    const elapsedSecs: number = ((new Date().getTime()) - creado) / 1000;
    this.elapsed = this.bus.wasm.formatSeconds(elapsedSecs);
  }

  updateBadge(): void {
    if (this.cuantasActivas > 1) {
      this.badge = this.cuantasActivas + '';
      this.currentAnimationBadge = this.animationBadge;
      setTimeout((): void => {
        this.currentAnimationBadge = '';
      }, 2000);
    } else {
      this.badge = '';
    }
  }


  finishOK(descarga: ProgressIndicator): void {
    // Si es la activa
    if (this.showingDescargaId == descarga.uniqId) {
      if (this.currentAnimationComponent == '') {
        this.currentAnimationComponent = this.animationFinish;
      }
      this.progress = descarga.progress;
      this.title = descarga.title;
      this.progressStatus = 'success';
      this.labelClass = '';
      setTimeout( (): void => {
        this.removeDescarga(descarga);
      }, 2000);
    } else {
      this.removeDescarga(descarga);
    }
  }

  finishWithError(descarga: ProgressIndicator): void {

    let byUser = true;
    if (descarga.error != 'cancelled_by_user') {
      this.bus.dom.toastError($localize`Se ha producido un error en la descarga`+ ": "+descarga.uniqId);
      byUser = false;
    }

    // Si es la activa
    if (this.showingDescargaId == descarga.uniqId) {
      if (!byUser && this.currentAnimationComponent == '') {
        this.currentAnimationComponent = this.animationFinish;
      }

      this.progressStatus = 'danger';
      this.progress = descarga.progress;
      this.title = descarga.title;
      this.labelClass = '';
      setTimeout( (): void => {
        this.removeDescarga(descarga);
      }, 2000);
    } else {
      this.removeDescarga(descarga);
    }
  }

  removeDescarga(descarga: ProgressIndicator): void {
    this.descargasActivas.delete(descarga.uniqId);
    this.cuantasActivas = this.descargasActivas.size;
    this.currentAnimationComponent = '';

    let menorDescarga: ProgressIndicator;
    let menorTime = 0;

    // Buscamos la próxima
    this.descargasActivas.forEach( (item): void => {
        const t = item.created.getTime();
        if (t < menorTime || menorTime == 0) {
          menorTime = t;
          menorDescarga = item;
        }
    });

    if(menorTime > 0) {
      // @ts-ignore
      this.activateDescarga(menorDescarga);
    }

    this.updateBadge();
  }


}
