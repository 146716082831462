<div class="form-field">
  <label *ngIf="label" [class.disabled]="disabled" [for]="id" class="form-label mb-1">{{label}}</label>
  <div class="country">
    <nb-select [multiple]="multiple" [ngStyle]="{'width': width}" class="select-opacity" [class.form-invalid]="showError" [disabled]="disabled" [fullWidth]="fullWidth" [placeholder]="placeholder" [(selected)]="selectedOption">
      <nb-select-label class="d-flex gap-3 flex-wrap">
        <ng-container *ngFor="let current of currentSelection">
          <div class="wrapper">
            <span class="flag me-1">{{current.data || ''}}</span>
            <span class="name">{{current.label}}</span>
          </div>
        </ng-container>
      </nb-select-label>
      <nb-option *ngFor="let option of options"
                 [nbTooltip]="option.tooltip || ''"
                 [nbTooltipDisabled]="!option.tooltip"
                 [value]="option.value">
        <div class="wrapper">
          <span class="flag me-1">{{option.data || ''}}</span>
          <span class="name">{{option.label}}</span>
        </div>
      </nb-option>
    </nb-select>
  </div>
  <div *ngIf="help" class="form-help">{{help}}</div>
</div>
