import { NbMenuItem } from '@nebular/theme';
import {MatIcon} from "./@core/@models/icons";
import {BusService} from "./@core/@bus/bus.service";


export function getMenuAdministrator(bus: BusService): NbMenuItem[] {
  if (bus.isColectivoLexer()){
    return getMenuAdminLexer();
  }else if (bus.isColectivoMapfre()){
    return getMenuAdminMapfre();
  }else if (bus.isColectivoEuropaFactor()){
    return getMenuAdminEuropaFactor();
  }
  return getMenuAdmin();
}

export function getMenuAdmin(): NbMenuItem[] {
  const menu: NbMenuItem[] = [
    {
      title: $localize`Ventajas / Descuentos`,
      icon: MatIcon('savings'),
      link: '/administrador',
      children: [
        {
          title: $localize`Categorias`,
          link: '/administrador/categorias',
        },
        {
          title: $localize`Ofertas`,
          link: '/administrador/ofertas',
        },

      ]
    },
    {
      title: $localize`Consultar Deuda`,
      icon: MatIcon('credit_score'),
      link: '/administrador/consultar-deuda',
    },
    {
      title: $localize`Configurador Noticias`,
      icon: MatIcon('feed'),
      link: '/administrador/noticias',
      children: [
        {
          title: $localize`Noticias`,
          link: '/administrador/noticias',
        },
        {
          title: $localize`Suscripciones`,
          link: '/administrador/suscripciones',
        },
      ]
    },

    {
      title: $localize`Calendario`,
      icon: MatIcon('event'),
      link: '/administrador/calendario',
    },

    {
      title: $localize`Configurador Enlaces`,
      icon: MatIcon('share'),
      link: '/administrador/enlaces',
    },
    {
      title: $localize`Configurador FAQ`,
      icon: MatIcon('edit_note'),
      link: '/administrador/faq',
    },

    {
      title: $localize`Configurador Páginas`,
      icon: MatIcon('note_add'),
      link: '/administrador/paginas',
    },
    {
      title: $localize`Usuarios conectados`,
      icon: MatIcon('sensor_occupied'),
      link: '/administrador/usuarios-conectados',
    },
  ];
  return menu;
}

export function getMenuAdminLexer(): NbMenuItem[] {
  const menu: NbMenuItem[] = [
    {
      title: $localize`Consultar Deuda`,
      icon: MatIcon('credit_score'),
      link: '/administrador/consultar-deuda',
    },
    {
      title: $localize`Configurador Noticias`,
      icon: MatIcon('feed'),
      link: '/administrador/noticias',
    },

    {
      title: $localize`Calendario`,
      icon: MatIcon('event'),
      link: '/administrador/calendario',
    },

    {
      title: $localize`Configurador Enlaces`,
      icon: MatIcon('share'),
      link: '/administrador/enlaces',
    },
    {
      title: $localize`Configurador FAQ`,
      icon: MatIcon('edit_note'),
      link: '/administrador/faq',
    },

    {
      title: $localize`Configurador Páginas`,
      icon: MatIcon('note_add'),
      link: '/administrador/paginas',
    },
    {
      title: $localize`Usuarios conectados`,
      icon: MatIcon('sensor_occupied'),
      link: '/administrador/usuarios-conectados',
    },
    /*{
      title: $localize`Histórico conexiones`,
      icon: MatIcon('history'),
      link: '/administrador/historico-conexiones',
    },*/
  ];
  return menu;
}

export function getMenuAdminMapfre(): NbMenuItem[] {
  const menu: NbMenuItem[] = [
    {
      title: $localize`Configurador Noticias`,
      icon: MatIcon('feed'),
      link: '/administrador/noticias',
    },

    {
      title: $localize`Calendario`,
      icon: MatIcon('event'),
      link: '/administrador/calendario',
    },

    {
      title: $localize`Configurador Enlaces`,
      icon: MatIcon('share'),
      link: '/administrador/enlaces',
    },
    {
      title: $localize`Configurador FAQ`,
      icon: MatIcon('edit_note'),
      link: '/administrador/faq',
    },

    {
      title: $localize`Configurador Páginas`,
      icon: MatIcon('note_add'),
      link: '/administrador/paginas',
    },
    {
      title: $localize`Usuarios conectados`,
      icon: MatIcon('sensor_occupied'),
      link: '/administrador/usuarios-conectados',
    },
    /*{
      title: $localize`Histórico conexiones`,
      icon: MatIcon('history'),
      link: '/administrador/historico-conexiones',
    },*/
  ];
  return menu;
}

export function getMenuAdminEuropaFactor(): NbMenuItem[] {
  const menu: NbMenuItem[] = [
    {
      title: $localize`Configurador Noticias`,
      icon: MatIcon('feed'),
      link: '/administrador/noticias',
    },
    /*{
      title: $localize`Consultar Deuda`,
      icon: MatIcon('credit_score'),
      link: '/administrador/consultar-deuda',
    },*/
    {
      title: $localize`Configurador FAQ`,
      icon: MatIcon('edit_note'),
      link: '/administrador/faq',
    },
  ];
  return menu;
}
