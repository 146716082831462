import {Inject, Injectable, OnDestroy} from '@angular/core';
import {NbMediaBreakpointsService, NbThemeService} from "@nebular/theme";
import {map, Subject, Subscription, takeUntil} from "rxjs";
import {NbMediaBreakpoint} from "@nebular/theme/services/breakpoints.service";

export type MediaQueryBreakpointsMap = {[breakpoint: string]: number};

export class MediaQueryEvent {
  currentWidth: number;
  currentName: string;
  breakpointsMap: MediaQueryBreakpointsMap;

  constructor(currentBreakpoint: NbMediaBreakpoint, configBreakpoints: MediaQueryBreakpointsMap) {
    this.currentWidth = currentBreakpoint.width;
    this.currentName = currentBreakpoint.name;
    this.breakpointsMap = configBreakpoints;
  }

  static fromWidth(currentWidth: number, configBreakpoints: MediaQueryBreakpointsMap) {
    // Miramos el mímimo que cumple
    const cumplen: string[] = [];
    for (let key in configBreakpoints) {
      if (configBreakpoints[key] < currentWidth) {
        cumplen.push(key);
      }
    }

    // Ahora retornamos el más grande
    let mayor = '';
    let valor = 0;
    cumplen.forEach( (name) => {
      if (mayor == '') {
        mayor = name;
        valor = configBreakpoints[name];
      } else {
        if (configBreakpoints[name] > valor) {
          mayor = name;
          valor = configBreakpoints[name];
        }
      }
    });

    return new MediaQueryEvent({name: mayor, width: valor}, configBreakpoints);

  }

  isLessThan(name: string): boolean {
     if (this.breakpointsMap.hasOwnProperty(name)) {
       const width = this.breakpointsMap[name];
       if (this.currentWidth <= width) {
          return true;
       }
       return false;
     } else {
       throw new Error(`isLessThan ${name} => no existe el breakpoint`)
     }
  }

  isGreaterThan(name: string): boolean {
    if (this.breakpointsMap.hasOwnProperty(name)) {
      const width = this.breakpointsMap[name];
      if (this.currentWidth > width) {
        return true;
      }
      return false;
    } else {
        throw new Error(`isGreaterThan ${name} => no existe el breakpoint`)
    }
  }
}


@Injectable({
  providedIn: 'root'
})
export class MediaQueryService implements OnDestroy {

  private emitter$: Subject<MediaQueryEvent> = new Subject<MediaQueryEvent>();

  private destroy$: Subject<void> = new Subject<void>();

  breakpointsMap: any;

  constructor(@Inject(Window) private window: Window,
              private themeService: NbThemeService,
              private breakpointService: NbMediaBreakpointsService) {

    this.breakpointsMap = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint),
        takeUntil(this.destroy$),
      )
      .subscribe((currentBreakpoint: NbMediaBreakpoint) => {
         this.emitter$.next(new MediaQueryEvent(currentBreakpoint, this.breakpointsMap));
      });

  }

  subscribe(next: (value: MediaQueryEvent) => void): Subscription {
    const sub = this.emitter$.subscribe(next);
    setTimeout( () => {
      // Emitimos el primero
      this.emitter$.next(this.current());
    },10);
    return sub;
  }

  current(): MediaQueryEvent {
    return MediaQueryEvent.fromWidth(window.innerWidth, this.breakpointsMap)
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.emitter$.complete();
  }

}
