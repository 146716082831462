<div>
  <nb-card class="dialog-wrapper"  [status]="status" [accent]="accent">
    <nb-card-header>
      <div class="confirm-title">{{title}}</div>
    </nb-card-header>
    <nb-card-body>
      <div class="confirm-body">
        {{body}}
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="flex-space-between">
        <app-button label="Aceptar" size="small" status="primary" (onPress)="onAceptar()"></app-button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>
