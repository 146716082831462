import { Pipe, PipeTransform } from '@angular/core';
import {ToDurationSmart} from "../../@models/date";

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number): string {
    return ToDurationSmart(value);
  }

}
