<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card *ngIf="!isLoading" class="dialog-wrapper" status="primary" [nbSpinner]="isLoading || executing" nbSpinnerStatus="primary">
    <nb-card-header>
      <div class="flex-space-between">
        <span>{{labelControlAccesso}}</span>
        <app-header-action [icon]="iconClose" status="control" (onClick)="onClose()"></app-header-action>
      </div>
    </nb-card-header>
    <nb-card-body>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit($event)" >
        <app-input [clear]="true" [focus]="true" label="{{labelCorreElectronico}}" placeholder="{{labelPlaceholderEmail}}" type="email" formControlName="user"></app-input>
        <app-sizedbox height="1rem"></app-sizedbox>
        <app-input-password label="{{labelCotrasena}}" placeholder="◦◦◦◦◦◦◦◦" type="password" formControlName="password"></app-input-password>
        <app-sizedbox height="1rem"></app-sizedbox>
        <div class="flex-space-between">
          <app-switch size="tiny" formControlName="remember">
            <span>{{labelGuardarSesion}}</span>
          </app-switch>
          <app-button size="tiny" label="{{labelNuevoUsuario}}" (onPress)="onNuevoUsuario()"></app-button>
        </div>

        <nb-alert class="mt-2 mb-0" *ngIf="error" closable status="danger" (close)="onCloseAlertError()">
          {{error}}
        </nb-alert>

        <nb-alert class="mt-2 mb-0" *ngIf="info" closable status="info" (close)="onCloseAlertInfo()">
          {{info}}
        </nb-alert>

      </form>
    </nb-card-body>
    <nb-card-footer>
      <div class="flex-space-between">
        <app-button [spinner]="true" status="primary" [disabled]="!loginForm.valid" label="{{labelAcceder}}" (onPress)="onSubmit($event)"></app-button>
        <app-sizedbox width="20px"></app-sizedbox>
        <app-link label="{{labelRestaurar}}" [disabled]="!hasUser()" (onClick)="onRememberPassword()"></app-link>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

