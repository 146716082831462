<div [nbSpinner]="loading" class="alertas-container scroll-y">
  <nb-list  *ngIf="cuantasAlertas > 0">
    <nb-list-item *ngFor="let alerta of listaAlertas.alertas; let i = index;">
      <app-header-alerta [alerta]="alerta" (onDelete)="onDeleteIndex(i)"></app-header-alerta>
    </nb-list-item>
  </nb-list>

  <div class="flex-center" *ngIf="cuantasAlertas == 0 && !loading">
    <span class="alertas-no-hay">{{labelNoAlertas}}</span>
  </div>
</div>
