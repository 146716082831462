<app-smart-box [status]="kpi.status || 'basic'">
  <div class="kpi-icon-title">
    <div *ngIf="kpi.icon" class="kpi-icon">
      <app-icon [icon]="kpi.icon"></app-icon>
    </div>
    <div class="kpi-title">
      {{kpi.title}}
    </div>
  </div>
  <div class="kpi-value {{valueClass}}">{{kpi.value}}</div>
  <div class="info-icon" *ngIf="kpi.infoCallback">
    <app-header-action [icon]="iconInfo" [tooltip]="kpi.infoTooltip || ''" (onClick)="kpi.infoCallback()">
    </app-header-action>
  </div>
</app-smart-box>

