import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Icon} from "../../../@models/icons";
import {Status} from "../../../@models/theme";

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit, OnChanges {

  @Input() icon: Icon | undefined;
  @Input() disabled: boolean = false;
  @Input() status?: Status;
  @Input() fontSize?: string;

  iconWithDisabled: Icon | undefined;

  constructor() { }

  ngOnInit(): void {
    this.checkIcon();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkIcon();
  }

  checkIcon() {
    if (this.icon) {
      this.iconWithDisabled = {...this.icon};
      if (this.disabled && this.iconWithDisabled) {
        this.iconWithDisabled.status = 'basic';
      }
    }

  }

}
