<div class="gauge-container text-center">
  <ngx-gauge
    [size]="size"
    [type]="gaugeType"
    [value]="value"
    [label]="label"
    [thick]="gaugeThick"
    [append]="appendText"
    [foregroundColor]="foregroundColor"
    [backgroundColor]="backgroundColor"
  >
  </ngx-gauge>
  <div *ngIf="labelBottom != ''" class="label-bottom">{{labelBottom}}</div>

</div>

