<nb-chat #chatComponent  [ngClass]="maximized ? 'chat-expand' : 'chat-collapse'" size="large" style="position:fixed;bottom: 0; right:0.5rem;z-index: 99999" [scrollBottom]="true" *ngIf="!close">
    <ng-template nbChatTitle>
        <div class="flex-space-between">
            <div><nb-user size="medium"
                          name="Billy"
                          title="{{labelAsistente}}"
            >
            </nb-user></div>
            <div>
                <nb-icon title="{{labelMinimizar}}" *ngIf="!maximized" style="cursor:pointer;" icon="expand-outline" (click)="onClickMax()" [options]="{ animation: { type: 'zoom' } }"></nb-icon>
                <nb-icon title="{{labelMaximizar}}" *ngIf="maximized" style="cursor:pointer;" icon="collapse-outline" (click)="onClickMin()" [options]="{ animation: { type: 'zoom' } }"></nb-icon>
                <nb-icon title="{{labelCerrar}}" style="cursor:pointer;" icon="close-outline" (click)="closeChat()"></nb-icon>
            </div>
        </div>
    </ng-template>

    <a *nbCustomMessage="'link'; let data" [href]="data.href" target="_blank">
        {{ data.text }}
    </a>

    <div *nbCustomMessage="'button'; let buttonText">
        <p (click)="selectOption(buttonText)" class="text-control button-custom-message-text">{{ buttonText }}</p>
    </div>
    <div *nbCustomMessage="'list'; let buttonList">
        <p class="txt-custom-message-list">Scegline uno:</p>
        <p class="button-custom-message-list" (click)="selectOption('1')">1. Hai ricevuto un SMS/Mail o telefonata e vuoi essere ricontattato?</p>
        <p class="button-custom-message-list" (click)="selectOption('2')">2. Hai problemi nell’effettuare il pagamento?</p>
        <p class="button-custom-message-list" (click)="selectOption('3')">3. Altro</p>
    </div>
    <div *ngIf="selectedOption">
        <div *nbCustomMessage="'list2'; let buttonList" style="display:inline-flex;">
            <p class="button-custom2-message-list" style="margin-right: 1rem;" (click)="selectOptionBoolean('si')">SÍ</p>
            <p class="button-custom2-message-list" (click)="selectOptionBoolean('no')">NO</p>
        </div>
    </div>


    <nb-chat-message *ngFor="let msg of messages"
                     [type]="msg.type"
                     [message]="msg.text"
                     [reply]="msg.reply"
                     [sender]="msg.user.name"
                     [date]="msg.date"
                     [customMessageData]="msg.customMessageData">
    </nb-chat-message>


    <nb-chat-form (send)="sendMessage($event)" [dropFiles]="false" messagePlaceholder="{{labelEscribir}}">
    </nb-chat-form>

</nb-chat>
