
import {BaseClass} from "../@core/@models/common";

import {ServerEvent} from "../core/event-manager";
import {GrpcRequest, GrpcResponse, OriginEmpty, Origin} from "../@core/@models/grpc";

export class AlertEvent extends ServerEvent {

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

  getType(): string | undefined {
    return this.getParam('type');
  }
  setType(value: string): void {
    this.setParam('type', value);
  }

  getMessage(): string | undefined {
    return this.getParam('message');
  }
  setMessage(value: string): void {
    this.setParam('message', value);
  }

}


export class Notify  extends BaseClass {
  title: string = '';
  body: string = '';
  type: string = '';
  extra: string = '';

  constructor(asObject?: object) {
    super();
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }
}


export class NotifyEvent extends ServerEvent {

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }


  getNotify(): Notify {
    const data = this.getParam('data');

    if (data) {
      return data as Notify;
    }
    return new Notify();
  }
}


export class Alerta  extends BaseClass{
  db_id: string = '';
  fecha: string = '';
  titulo: string = '';

  // TODO: definirla


  constructor(asObject?: object) {
    super();
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }
}

export class ListAlertasRequest extends GrpcRequest {
  origin: Origin = OriginEmpty;
  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }
}



export class ListAlertasResponse extends GrpcResponse {
  error: string = '';
  alertas: Array<Alerta> = [];

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

}

