import {BaseClass, getTime} from '../@core/@models/common';
import {ToUser, User, UserConfig} from "../models/user";
import {JsonStringify} from "../@core/@models/json";
import {ToMap} from "../@core/@models/types";
import {GetGlobalBus} from "../@core/@models/globals";
import {Session} from "../models/login";
import {BusService} from "../@core/@bus/bus.service";


export const NO_ERROR =  'NO_ERROR';

//  en login si ya conectado, en logout si no está conectado o en refresh si ya ha expirado la session
export const ERROR_LOCKED =  'ERROR_LOCKED';

export interface AuthDataInterface {
  logged: boolean;
  me?: User;
  config?: UserConfig;
  session?: Session;
  error: string;
}


export class AuthData extends BaseClass {
  logged: boolean = false;
  me?: User;
  config?: UserConfig;
  session?: Session;
  error: string = '';
  expiration_date?: Date;

  constructor(asObject?: object) {
    super();

    this.reset();

    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

  reset(): void {
    this.logged = false;
    this.me = undefined;
    this.error = '';
    this.config = undefined;
    this.session = undefined;
    this.expiration_date = undefined;
  }

  getCredentialsId(): string {
    if (this.me) {
      return this.me.credentials_id;
    }
    return '';
  }

  getLogin(): string {
    if (this.me) {
      return this.me.login;
    }
    return '';
  }

  isClausulaLegalAceptada(): boolean {
    if (this.me) {
      return this.me.clausula_legal_aceptada;
    }
    return false;
  }

  isAdvisor(): boolean {
    if (this.me) {
      return this.me.is_advisor;
    }
    return false;
  }

  isAdmin(): boolean {
    if (this.me) {
      return this.me.is_admin;
    }
    return false;
  }

  isMaster(): boolean {
    if (this.me) {
      return this.me.is_master;
    }
    return false;
  }


  getBadge(name: string): string {
    if (this.config) {
      if (this.config.badges) {
        const mapped = ToMap(this.config.badges);
        return mapped.get(name) || "";
      }
    }
    return "";
  }

  toUser(): User | null {
    if (this.isValid()) {
      return ToUser(this.me);
    } else {
      return null;
    }
  }

  hasPendingConfiguracions(): boolean {
    if (this.isValid()) {
      const subestado: number = this.me?.subestado || 0;
      if (subestado) {
        return true;
      }
    }
    return false;
  }


  getStorage(key: string): string | undefined {
    if (this.isValid()) {
      if (this.config?.storage) {
        const m = ToMap(this.config.storage);
        if (m.has(key)) {
          return m.get(key);
        }
      }
    }
    return undefined;
  }

  updateConfig(config: UserConfig): void {
    this.config = config;
    if (config.me) {
      this.me = config.me;
    }
  }


  loadFromLogin(data: AuthDataInterface): void {
    this.reset();
    if (!data.logged) {
      return;
    }

    if (data.session) {
      if(data.session.expires_in > 0) {
        this.expiration_date = new Date();
        this.expiration_date.setSeconds(this.expiration_date.getSeconds() + data.session.expires_in);
      }
    }

    this.me = data.me;
    this.logged = data.logged;
    this.error = data.error;
    this.session = data.session;
    if (data.config) {
      this.updateConfig(data.config);
    }
  }


  hasError(): boolean {
    if (this.error === '' || this.error === NO_ERROR) {
      return false;
    }
    return true;
  }

  destroy(): void {
    this.reset();
  }


  isValid(checkExpired: boolean = true): boolean {
    if (this.hasData() == false) {
      return false;
    }

    return this.isNotExpired(checkExpired);
  }

  isNotExpired(checkExpired: boolean = true): boolean {
    // El userId es negativo si es un usuario externo y positivo si es un agente
    if ( this.expiration_date && this.hasData()) {
      if (checkExpired === false) {
        return true;
      }
      const ahora: number = getTime();
      const expira: number = this.expiration_date.getTime();
      if ( ahora < expira ) {
        return true;
      }
    }
    return false;
  }

  hasData(): boolean {
    if ( this.getCredentialsId() !== '' && this.getSessionIdRaw() !== '') {
      return true;
    }
    return false;
  }


  loadFromCache(): boolean {
    const bus: BusService = GetGlobalBus();
    const authDataStr = bus.storage.sessionGet('authData');
    if (authDataStr) {
      const authData = JSON.parse(authDataStr);
      if (authData) {

        // Config
        const authDataConfigStr = bus.storage.sessionGet('authDataConfig');
        if (authDataConfigStr) {
          const authDataConfig = JSON.parse(authDataConfigStr);
          if (authDataConfig) {
            this.config = new UserConfig(authDataConfig);
          }
        }

        // Convertimos el expirationDate en Date (es un string)
        authData.expirationDate = new Date(authData.expirationDate);
        // console.log('AuthData leido del storage => ', authData);
        if ( authData.expirationDate && authData.userId > 0 && authData.phpSessionId !== '') {
          const ahora: number = getTime();
          const expira = authData.expirationDate.getTime();
          if ( ahora < expira ) {
            this.copyFromObject(authData);

            // Miramo si es el mismo GUID
            const authDataGUID = bus.storage.sessionGet('authDataGUID');
            const GUID: string = bus.guid.getGUID();
            if (GUID !== authDataGUID) {
              // Registramos la nueva sesión con este guid
              return false;
            }
            return true;
          }
        }
      }
    }
    return false;
  }


  getSessionId(): string {
    const res = 'not_logged';
    if (!this.isValid()) {
      return res;
    }

    if (this.session) {
      if (this.session.session_id != '') {
        return this.session.session_id;
      }
    }

    return res;
  }

  getSessionIdRaw(): string {
    if (this.session) {
      return this.session.session_id;
    }

    return '';
  }

  saveToCache(): void {
    const bus: BusService = GetGlobalBus();
    bus.storage.sessionSet('authData', JsonStringify(this));
    bus.storage.sessionSet('authDataConfig', JsonStringify(this.config));
    bus.storage.sessionSet('authDataGUID', bus.guid.getGUID());
  }
  deleteCache(): void {
    const bus: BusService = GetGlobalBus();
    bus.storage.sessionDel('authData');
    bus.storage.sessionDel('authDataConfig');
    bus.storage.sessionDel('authDataGUID');
  }

}
