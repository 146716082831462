import {Injectable} from '@angular/core';
import {BusService} from './bus.service';
import {Observable, Observer, Subject} from "rxjs";
import {WasmResult} from "./wasm.service";
import {BackendRequest, BackendResponse} from "./request.model";
import {Download, DownloadFromBus, Upload, UseStreamAutoMaxSize} from "../@models/download";
import {getUUID} from "../@models/common";
import {Icon, IconDownload, IconTask, IconUpload} from "../@models/icons";
import {JsonStringify} from "../@models/json";



// Servicio para almacenar/leer datos del navegador
// Tiene en cuenta si deben persistir y el tipo de persistencia para que no se solapen datos entre sesiones/pestañas
// Los datos se graban/leen siempre como string (igual que localStorage y sessionStorage)

// Cuando se borra la caché del navegador se borra todos los datos almacenados en el storage
// tab => datos sólo para el tab => no se comparten con otros tabs ni sobreviven al cierre del tab
// session => datos compartidos para la misma session de php => compartidos entre diferentes tabs y sobreviven al cierre del tab
// global => compartidos con todas las sessiones diferentes de php => sobreviven al cierre de los tabs
// user => compartidos con todas las sessiones diferentes del mismo usuario => sobreviven al cierre de los tabs


// const DefaultChunkSize: any = 1024 * 100;  // Dejarlo a undefined para producción
const DefaultChunkSize: any = undefined;  // Dejarlo a undefined para producción

// Ojo: usur sólo uploadChunkedText cuando sepamos seguro que el contenido es texto (
export type UploadChunkCommand = 'uploadChunkedBase64' | 'uploadChunkedText' | 'uploadChunkedDataURL';
export type DownloadChunkCommand = 'downloadChunkedBase64' | 'downloadChunkedText';

export interface ScriptModel {
  name: string;
  src: string;
  loaded: boolean;
}

export type ProgressCancelFun = () => void;

export interface ProgressIndicator {
  uniqId: string,
  title: string,
  progress: number,
  error?: string;
  icon: Icon;
  created: Date,
  cancelFunction: ProgressCancelFun,
}

export type ProgressDownloadCallback = (progress: number, file: Download, error?: string) => void;
export type ProgressUploadCallback = (progress: number, file: Upload, error?: string) => void;
export type ProgressJobCallback = (progress: number, uniqId: string, error?: string) => void;

@Injectable({
  providedIn: 'root'
})
export class DownloadService  {
  private scripts: ScriptModel[] = [];
  // @ts-ignore
  private bus: BusService;

  progressEvents: Subject<ProgressIndicator> = new Subject<ProgressIndicator>();

  constructor() {

  }

  init(bus: BusService): void {
    this.bus = bus;
  }

  createDownloadProgressIndicator(file: Download, callbackProgress?: ProgressDownloadCallback): ProgressDownloadCallback {
    const created: Date = new Date();
    return (progress: number, file: Download, error?: string): void => {
      const uniqId = file.uniqKey;
      if (callbackProgress) {
        callbackProgress(progress,file,error);
      }
      this.progressEvents.next({
        uniqId: uniqId,
        title: file.fileName,
        progress: progress,
        error: error,
        icon: IconDownload,
        created: created,
        cancelFunction: (): void => {
          file.status = 'cancelled_by_user';
          if (uniqId != '') {
            this.bus.download.cancelDownloadingFile(uniqId).then((response: BackendResponse): void => {
              if (response.error != '') {
                this.bus.logger.warningBrowser('Descarga => ', response.error);
                this.bus.dom.toastError("No se ha podido cancelar la descarga: " + uniqId)
              }
            });
          }
        },
      });
    }
  }
  createUploadProgressIndicator(file: Upload, cancelFun: ProgressCancelFun, callbackProgress?: ProgressUploadCallback): ProgressUploadCallback {
    const created: Date = new Date();
    return (progress: number, file:Upload, error?: string): void => {
      const uniqId = file.uniqKey;
      if (callbackProgress) {
        callbackProgress(progress,file,error);
      }
      this.progressEvents.next({
        uniqId: uniqId,
        title: file.title != "" ? file.title : file.fileName,
        progress: progress,
        error: error,
        icon: IconUpload,
        created: created,
        cancelFunction: cancelFun,
      });
    }
  }

  createJobProgressIndicator(title: string, cancelFun: ProgressCancelFun, callbackProgress?: ProgressJobCallback): ProgressJobCallback {
    const created: Date = new Date();
    return (progress: number, uniqId:string, error?: string): void => {
      if (callbackProgress) {
        callbackProgress(progress,uniqId,error);
      }
      this.progressEvents.next({
        uniqId: uniqId,
        title: title,
        progress: progress,
        error: error,
        icon: IconTask,
        created: created,
        cancelFunction: cancelFun,
      });
    }
  }


  // Inspirado en varias soluciones vistas en internet
  // Si el mismo script se intenta cargar desde una URL diferente, se utiliza el previamente cargado
  public loadJsFile(src: string): Observable<ScriptModel> {
    // Convertmios a ScriptModel
    const script = {
      name: src.replace(/^.*[\\\/]/, ''), // solo el nombre del fichero
      src: src,
      loaded: false,
    };

    return new Observable<ScriptModel>((observer: Observer<ScriptModel>): void => {
      const existingScript = this.scripts.find(s => s.name === script.name);

      // Si ya está cargado, terminamos
      if (existingScript && existingScript.loaded) {
        observer.next(existingScript);
        observer.complete();
      }
      else {
        // Añadimos el script
        this.scripts = [...this.scripts, script];

        // Lo cargamos en el DOM
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = script.src;

        scriptElement.onload = (): void => {
          script.loaded = true;
          observer.next(script);
          observer.complete();
        };

        scriptElement.onerror = (error: any): void => {
          observer.error('No se puede cargar el fichero ' + script.src);
        };

        document.getElementsByTagName('body')[0].appendChild(scriptElement);
      }
    });
  }


  // Lee un fichero local de la máquina
  readLocalBinaryFile(file: File): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>( (resolve, reject): void => {
      const reader: FileReader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (): void => {
        resolve(reader.result as ArrayBuffer);
      };
      reader.onerror = (): void => {
        reject(reader.error);
      };
    });

  }


  // Va retornando el porcentaje => cuando llegue a 100% es que ha acabado
  // El uniqId es el ID con el que se hará referencia al fichero posteriormente (por ejemplo en los submit)
  uploadChunkedFile(uploadedFile: Upload, fileChunked: WasmResult, chunkCommand: UploadChunkCommand = 'uploadChunkedBase64'): Observable<number> {
    const uniqId = uploadedFile.uniqKey;
    const fileName = uploadedFile.title;
    return new Observable<number>((observer: Observer<number>): void => {
      if (fileChunked.error !== '') {
        observer.error('uploadChunkedFile: ' + fileChunked.error);
        return;
      }
      const count = fileChunked.result.count;

      let totalSent = 0;

      const progress = (index: number, result: BackendResponse): void => {
        if (result.error === '') {
          totalSent += 1;
          if (totalSent === count) {

            // En result.data viene la estructura de GO FileUpload
            uploadedFile.uniqKey = result.data.uniqId;
            uploadedFile.mime = result.data.mime;
            uploadedFile.fileName = result.data.fileName;
            if (uploadedFile.title == "") {
                uploadedFile.title = result.data.fileName;
            }
            uploadedFile.uploadedData = result.data.uploadedData;

            observer.next(100);
            observer.complete();
          } else {
            const porcentaje = Math.floor((totalSent / count) * 100);
            observer.next(porcentaje);
          }
        } else {
          observer.error('uploadChunkedFile: chunk ' + index + ' => ' + result.error);
        }
      };

      // Para evitar saturar los buffers, no enviamos el siguiente trunk hasta no recibir confirmación del anterior
      const send = (numChunk: number): void => {
        if (numChunk < count) {
          emitter(numChunk, fileChunked.result['chunk_' + numChunk], progress);
        }
      };

      const emitter = (index: any, value: any, callback: any): void => {

        const request: BackendRequest = new BackendRequest();
        request.event = chunkCommand;
        request.data = {
          count: count,
          chunkIndex: index,
          uniqId: uniqId,
          fileName: fileName,
          chunkData: value,
          type: uploadedFile.type,
          refId: uploadedFile.refId,
          context: uploadedFile.context,
        };
        this.bus.sendRemote(request).then( (response: BackendResponse): void => {
          callback(index, response);

          // Comprobamos si se ha cancelado
          if (uploadedFile.cancelledByUser) {
            observer.error('cancelled_by_user');
            return;
          } else {
            send(index + 1);
          }
        })
      };

      // Enviamos el primero
      send(0);
    });
  }

  // Borra un fichero previamente subido
  deleteUploadedFile(uniqId: string): Promise<BackendResponse> {
    return new Promise<BackendResponse>((resolve, reject): void => {
      const request: BackendRequest = new BackendRequest();
      request.event = "deleteUploadedFile";
      request.data = {
        uniqId: uniqId,
      };
      this.bus.sendRemote(request).then( (response): void => {
        resolve(response);
      })
    });
  }

  // Borra un fichero previamente subido
  cancelDownloadingFile(uniqId: string): Promise<BackendResponse> {
    return new Promise<BackendResponse>((resolve, reject): void => {
      const request: BackendRequest = new BackendRequest();
      request.event = "cancelDownloadFile";
      request.data = {
        uniqId: uniqId,
      };
      this.bus.sendRemote(request).then( (response: BackendResponse): void => {
        resolve(response);
      })
    });
  }



  // Descarga un fichero y ejecuta un callback cuando acaba
  // llamar a getStatus para ver el resultado y getBlobContent para recoger el contenido
  downloadBlobFromBus(file: Download, userProgressFunction?: ProgressDownloadCallback, chunkSize: number = 1000 * 20): Promise<Download> {
    file.useStream = 'no';
    file.downloadAsBlob = true;
    return this.downloadFileFromBus(file,userProgressFunction, chunkSize);
  }


  // Descarga un fichero y devuelve el ArrayBuffer o undefined si ha habido error
  downloadFileFromBus(file: Download, userProgressFunction?: ProgressDownloadCallback, chunkSize: number = 1000 * 20, chunkCommand: DownloadChunkCommand = 'downloadChunkedBase64'): Promise<Download> {
    file.startDownloading();
    const progressFunction = this.createDownloadProgressIndicator(file, userProgressFunction);
    return new Promise<Download>((resolve, reject): void => {
      let downloadConfig: DownloadFromBus;

      const request: BackendRequest = new BackendRequest();
      request.event = chunkCommand;
      request.data = {
        file: JsonStringify(file),
        chunkSize: chunkSize,
      };

      if (file.fileName == "") {
        file.fileName = "download.dat";
      }

      this.bus.sendRemoteMultiple(request, (response, deleteWhenFinish?: () => void): void => {
        if (response.error == '') {
          // La primera respuesta es el download config
          if (!downloadConfig) {
            downloadConfig = response.data.response;

            // Copiamos datos al file
            file.fileName = downloadConfig.fileName;
            file.size = downloadConfig.size;
            file.title = downloadConfig.title;
            file.uniqKey = downloadConfig.uniqId;

            // Si useStream = auto, decimos que = false si el fichero es pequeño
            if (file.useStream == 'auto') {
              if (file.size > UseStreamAutoMaxSize) {
                file.useStream = 'yes';
              } else {
                file.useStream = 'no';
              }
            }

          } else {
            const count = response.data.count; // desde 0 hasta total - 1
            const total = response.data.chunks;
            const rawData = response.data.data;
            const asBase64 = response.data.asBase64;
            const cancelled = response.data.cancelled;


            // Vemos si se ha cancelado
            if (cancelled || file.streamIsClosed) {
              file.abort();
              if (deleteWhenFinish) {
                deleteWhenFinish();
              }
              if (file.status != 'cancelled_by_user') {
                file.status = 'cancelled_by_server';
              }

              progressFunction(file.downloadProgress, file, file.status);

              resolve(file);
              return;
            }
            let decoded;
            if (asBase64) {
              decoded = this.bus.wasm.base64Decode(rawData);
            } else {
              decoded = {  // Como wasmResult
                error: '',
                result: rawData,   // El resultado
              }
            }

            if (decoded.error === '') {
              file.addChunk(count, decoded.result); // Uint8Array

              if (file.getCountChunks() >= total) {
                if (file.downloadAsBlob) {
                  file.closeBlob();
                } else {
                  file.close();
                }
                if (deleteWhenFinish) {
                  deleteWhenFinish();
                }

                progressFunction( 100, file);

                file.status = 'ok';
                resolve(file);

              } else {
                file.downloadProgress =  Math.floor((file.getCountChunks() / total) * 100);
                progressFunction( file.downloadProgress, file);

              }
            } else {
              // Ha habido un error
              file.abort();
              this.bus.logger.error('base64Decode => ', decoded.error);
              if (deleteWhenFinish) {
                deleteWhenFinish();
              }
              file.status = 'error';
              file.error = decoded.error;
              progressFunction(file.downloadProgress, file, decoded.error);
              resolve(file);
            }
          }
        } else {
          // Ha habido error
          file.abort();
          if (deleteWhenFinish) {
            deleteWhenFinish();
          }
          file.status = 'error';
          file.error = response.error;
          progressFunction(file.downloadProgress, file, response.error);

          resolve(file);
        }
      })

    });
  }

  downloadCustomFile(data: any, userProgressFunction?: ProgressDownloadCallback): Promise<Download> {
    const fileToDownload: Download = new Download();
    fileToDownload.data = JsonStringify(data);
    fileToDownload.type = 'custom';
    return this.bus.download.downloadFileFromBus(fileToDownload,userProgressFunction);
  }

  downloadFileByKey(key: string, userProgressFunction?: ProgressDownloadCallback): Promise<Download> {
    const fileToDownload: Download = new Download();
    fileToDownload.uniqKey = key;
    fileToDownload.type = 'key';
    return this.bus.download.downloadFileFromBus(fileToDownload,userProgressFunction);
  }

  downloadBlobByKey(key: string, userProgressFunction?: ProgressDownloadCallback): Promise<Download> {
    const fileToDownload: Download = new Download();
    fileToDownload.uniqKey = key;
    fileToDownload.type = 'key';
    return this.bus.download.downloadBlobFromBus(fileToDownload,userProgressFunction);
  }

  uploadFile(file: File, userProgressFunction?: ProgressUploadCallback): Promise<Upload> {
    return new Promise<Upload>((resolve,reject): void => {
      const uploadedFile: Upload = new Upload();
      uploadedFile.uniqKey = getUUID();
      uploadedFile.status = 'running';
      uploadedFile.title = file.name;
      uploadedFile.file = file;

      this.bus.download.readLocalBinaryFile(file).then((binary): void => {
        // Convertimos en base64
        const wasmResult = this.bus.wasm.chunkBase64(binary, DefaultChunkSize);
        if (wasmResult.error !== '') {
          this.bus.logger.error('uploadFile chunk: ERROR', wasmResult.error);
          uploadedFile.status = 'error';
          uploadedFile.error = wasmResult.error;
          if (userProgressFunction) {
            userProgressFunction(uploadedFile.progress,uploadedFile, wasmResult.error);
          }
          resolve(uploadedFile);
        } else {
          // Tengo el resultado, lo subo por el bus
          this.uploadBinary(uploadedFile, wasmResult, userProgressFunction).then( (result): void => {
            resolve(result);
          });
        }
      }).catch((error): void => {
        this.bus.logger.error('uploadFile fichero: ', error);
        uploadedFile.status = 'error';
        uploadedFile.error = error;
        if (userProgressFunction) {
          userProgressFunction(uploadedFile.progress,uploadedFile, error);
        }
        resolve(uploadedFile);
      });
    });
  }

  // Sube un fichero y lo crea directamente en la tabla de Uploads y retorna la url pública de acceso
  // El refId es para indicar el documento (grupo de uploads) al que pertenece
  uploadCustomDocumentToTable(context: string, refId: string, file: File, userProgressFunction?: ProgressUploadCallback): Promise<Upload> {
    return new Promise<Upload>((resolve,reject): void => {
      const uploadedFile: Upload = new Upload();
      uploadedFile.uniqKey = getUUID();
      uploadedFile.status = 'running';
      uploadedFile.title = file.name;
      uploadedFile.file = file;
      uploadedFile.refId = refId;
      uploadedFile.type = 'custom';
      uploadedFile.context = context;

      this.bus.download.readLocalBinaryFile(file).then((binary): void => {
        // Convertimos en base64
        const wasmResult = this.bus.wasm.chunkBase64(binary, DefaultChunkSize);
        if (wasmResult.error !== '') {
          this.bus.logger.error('uploadFile chunk: ERROR', wasmResult.error);
          uploadedFile.status = 'error';
          uploadedFile.error = wasmResult.error;
          if (userProgressFunction) {
            userProgressFunction(uploadedFile.progress,uploadedFile, wasmResult.error);
          }
          resolve(uploadedFile);
        } else {
          // Tengo el resultado, lo subo por el bus
          this.uploadBinary(uploadedFile, wasmResult, userProgressFunction).then( (result): void => {
            resolve(result);
          });
        }
      }).catch((error): void => {
        this.bus.logger.error('uploadFile fichero: ', error);
        uploadedFile.status = 'error';
        uploadedFile.error = error;
        if (userProgressFunction) {
          userProgressFunction(uploadedFile.progress,uploadedFile, error);
        }
        resolve(uploadedFile);
      });
    });
  }

  uploadUpload(uploadedFile: Upload, userProgressFunction?: ProgressUploadCallback): Promise<Upload> {
    return new Promise<Upload>((resolve,reject): void => {
      uploadedFile.uniqKey = getUUID();

      if (!uploadedFile.file) {
        throw new Error('Debe especificar el fichero');
      } else {
        uploadedFile.status = 'running';
        uploadedFile.title = uploadedFile.file.name;
      }

      this.bus.download.readLocalBinaryFile(uploadedFile.file).then((binary): void => {
        // Convertimos en chunks
        const wasmResult = this.bus.wasm.chunkBase64(binary, DefaultChunkSize);
        if (wasmResult.error !== '') {
          this.bus.logger.error('uploadFile chunk: ERROR', wasmResult.error);
          uploadedFile.status = 'error';
          uploadedFile.error = wasmResult.error;
          if (userProgressFunction) {
            userProgressFunction(uploadedFile.progress,uploadedFile, wasmResult.error);
          }
          resolve(uploadedFile);
        } else {
          // Tengo el resultado, lo subo por el bus
          this.uploadBinary(uploadedFile, wasmResult, userProgressFunction).then( (result) => {
            resolve(result);
          });
        }
      }).catch((error) => {
        this.bus.logger.error('uploadFile fichero: ', error);
        uploadedFile.status = 'error';
        uploadedFile.error = error;
        if (userProgressFunction) {
          userProgressFunction(uploadedFile.progress,uploadedFile, error);
        }
        resolve(uploadedFile);
      });
    });
  }

  uploadBlob(buffer: ArrayBuffer, name: string, userProgressFunction?: ProgressUploadCallback): Promise<Upload> {
    return new Promise<Upload>((resolve,reject): void => {
      const uploadedFile: Upload = new Upload();
      uploadedFile.uniqKey = getUUID();
      uploadedFile.status = 'running';
      uploadedFile.isBlob = true;
      uploadedFile.title = name;

      // Convertimos en chunks
      const wasmResult = this.bus.wasm.chunkBase64(buffer, DefaultChunkSize);
      if (wasmResult.error !== '') {
        this.bus.logger.error('uploadBlob chunk: ERROR', wasmResult.error);
        uploadedFile.status = 'error';
        uploadedFile.error = wasmResult.error;
        if (userProgressFunction) {
          userProgressFunction(uploadedFile.progress,uploadedFile, wasmResult.error);
        }
        resolve(uploadedFile);
      } else {
        // Tengo el resultado, lo subo por el bus
        this.uploadBinary(uploadedFile, wasmResult, userProgressFunction).then( (result: Upload): void => {
          resolve(result);
        });
      }
    });
  }

  uploadDataUrl(dataUrl: string, userProgressFunction?: ProgressUploadCallback): Promise<Upload> {

    let uuid = getUUID();

    return new Promise<Upload>((resolve,reject): void => {
      const uploadedFile: Upload = new Upload();
      uploadedFile.uniqKey = uuid;
      uploadedFile.status = 'running';
      uploadedFile.isBlob = true;


      // Convertimos en chunks
      const wasmResult = this.bus.wasm.chunkString(dataUrl, DefaultChunkSize);
      if (wasmResult.error !== '') {
        this.bus.logger.error('uploadDataUrl chunk: ERROR', wasmResult.error);
        uploadedFile.status = 'error';
        uploadedFile.error = wasmResult.error;
        if (userProgressFunction) {
          userProgressFunction(uploadedFile.progress,uploadedFile, wasmResult.error);
        }
        resolve(uploadedFile);
      } else {
        // Tengo el resultado, lo subo por el bus
        this.uploadBinary(uploadedFile, wasmResult, userProgressFunction, 'uploadChunkedDataURL').then( (result): void => {
          resolve(result);
        });
      }
    });
  }

  private uploadBinary(uploadedFile: Upload, fileChunked: WasmResult, userProgressFunction?: ProgressUploadCallback, chunkCommand: UploadChunkCommand = 'uploadChunkedBase64'): Promise<Upload> {
    return new Promise<Upload>((resolve,reject): void => {

      const cancelFun = (): void => {
        uploadedFile.cancelUpload();
      }
      const progressFunction = this.createUploadProgressIndicator(uploadedFile, cancelFun, userProgressFunction);
      this.bus.download.uploadChunkedFile(uploadedFile, fileChunked, chunkCommand).subscribe({ next: (progress): void => {
          uploadedFile.progress = progress;
          progressFunction(progress, uploadedFile);
          if (progress >= 100) {
            uploadedFile.status = 'ok';
            resolve(uploadedFile);
          }
        },error: (error: any): void => {
          uploadedFile.error = error;
          progressFunction(uploadedFile.progress,uploadedFile, error);
        }}
      );
    });
  }

  uploadList(files: File[], maxFileSizeMb: number = 20, maxFileCount: number = 10, allowedMimeTypes: string[] = []): Promise<Upload[]> {
    const resultList: Upload[] = [];
    return new Promise<Upload[]>((resolve, reject): void => {
      let showedMaxFilesReach = false;  // Para que sólo saque el aviso una vez
      for (const item of files) {
        const upload: Upload = new Upload();
        upload.file = item;
        if (item.size > maxFileSizeMb * 1000000){
          upload.error = $localize`El tamaño del archivo` + ' ' + item.name + ' ' + $localize`es superior al permitido`;
          upload.status = 'error';
        } else{
          if (allowedMimeTypes.length === 0 || allowedMimeTypes.includes(item.type)){
            if (files.length >= maxFileCount){
              if (!showedMaxFilesReach) {
                upload.status = 'error';
                upload.error = $localize`Sólo se permite adjuntar`;
                upload.error += ' ' + maxFileCount + ' ';
                if (maxFileCount == 1) {
                  upload.error += $localize`archivo como máximo`;
                } else {
                  upload.error += $localize`archivos como máximo`;
                }
                showedMaxFilesReach = true;
              }
            }
          } else{
            upload.error = $localize`La extensión del archivo` + ' ' + item.name + ' ' + $localize`no está permitida`;
          }
          resultList.push(upload);
        }
      }


      const cuantos = resultList.length;
      let yaSubidos = 0;


      // Subimos los ficheros
      resultList.forEach( (u): void => {
          if(u.status == 'error') {
            yaSubidos++;
            if (cuantos == yaSubidos) {
              resolve(resultList);
            }
          } else {
            this.uploadUpload(u).then( (res): void => {
              yaSubidos++;
              if (cuantos == yaSubidos) {
                resolve(resultList);
              }
            });
          }
      });
    });

  }

}
