import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-sizedbox',
  templateUrl: './sizedbox.component.html',
  styleUrls: ['./sizedbox.component.scss']
})
export class SizedboxComponent implements OnInit {
  @Input() width: string = '';
  @Input() height: string = '';

  boxStyle: string = '';

  constructor() { }

  ngOnInit(): void {
    if (this.width != '') {
      this.boxStyle += "width: "+this.width+";";
    }
    if (this.height != '') {
      this.boxStyle += "height: "+this.height+";";;
    }
  }
}
