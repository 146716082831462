
export const RoutesWithHiddenMenu = [
  '/public',
  '/login',
  '/chatbot',
  '/registro',
  '/configuracion',
  '/legal',
  '/accion',
  '/comunicado',
];


export const RoutesWithHiddenHeader = [
  '/ver-pdf',
];


export function MenuIsHidden(route: string) : boolean {
  // Quitamos ?
  const path = route.split('?')[0];

  // Primer tramo
  const first = path.split('/')[1];

  // Normalizamos
  const clean = '/'+first.toLowerCase();

  for (let i = 0; i < RoutesWithHiddenMenu.length; i++) {
    if (RoutesWithHiddenMenu[i] == clean) {
      return true;
    }
  }
  return false;
}


export function HeaderIsHidden(route: string) : boolean {
  // Quitamos ?
  const path: string = route.split('?')[0];

  // Primer tramo
  const first: string = path.split('/')[1];

  // Normalizamos
  const clean: string = '/'+first.toLowerCase();

  for (let i = 0; i < RoutesWithHiddenHeader.length; i++) {
    if (RoutesWithHiddenHeader[i] == clean) {
      return true;
    }
  }
  return false;
}
