import {BaseClass, countElements} from '../@models/common';
import {JsonParse, JsonStringify} from "../@models/json";




export class BackendRequest extends BaseClass {

  event: string = '';
  data: any = null;
  security_context: string = '';

  constructor(asObject?: object) {
    super();
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

  toPayload(commonPayload: any): object {
    commonPayload.data = JsonStringify(this.data);
    return commonPayload;
  }
}


export class BackendResponse {
  data: any = null;
  error: string = '';

  constructor() {}


  static fromBus(resultado: any): BackendResponse {
    // Convertimos a BackendResponse
    const backend: BackendResponse = new BackendResponse();
    if (resultado.hasOwnProperty('response')) {
      const response = resultado.response;
      const jsonResponse = JsonParse(response);

      // Si sólo tiene una propiedad y es error
      if (jsonResponse.hasOwnProperty('error') && countElements(jsonResponse) == 1) {
          backend.error = jsonResponse.error;
      } else {
        if (response == "null") {
          backend.data = null;
        } else {
          backend.data = jsonResponse;
        }
      }
    }
    if (resultado.hasOwnProperty('error')) {
      backend.error = resultado.error;
    }
    return backend;
  }

}
