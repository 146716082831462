
export const TIPO_COMPROMISO_PAGO_EUROPAFACTOR: number = 1268;
export const FACTURA_IMPAGADA_EUROPAFACTOR: number = 1242;
export const FACTURA_PAGADA_EUROPAFACTOR: number = 1247;

export function  toogleChatbox(show: boolean): void {
  // Seleccionar todos los elementos con la clase 'chatboxhead'
  const chatboxHeads = document.querySelectorAll('.chatboxhead');
  // Iterar sobre los elementos y ocultarlos
  chatboxHeads.forEach((chatboxHead): void => {
    if (chatboxHead instanceof HTMLElement) {
      if(show){
        chatboxHead.style.display = 'block';
      }else{
        chatboxHead.style.display = 'none';
      }
    }
  });
}
