import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Icon, IconUpload} from "../../../@models/icons";
import {NbComponentSize} from "@nebular/theme/components/component-size";
import {Status} from "../../../@models/theme";

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  @Input() multiple: boolean = false;
  @Input() size: NbComponentSize = 'medium';
  @Input() status: Status = 'basic';
  @Input() icon: Icon = IconUpload;
  @Input() accept: string = ''; // Ejemplo accept="image/*"
  @Input() label: string = "Subir fichero";
  @Output() onSelectedFiles: EventEmitter<FileList> = new EventEmitter<FileList>;

  constructor() { }

  ngOnInit(): void {
  }

  handleFiles(event: any): void {
    const files: FileList = event.target.files;
    this.onSelectedFiles.emit(files);
  }

}
