<nb-card [HeightVisible]="true" Offset="1rem">
  <nb-card-header>
    <div class="text-center">
      <div>{{title}}</div>
      <ng-container *ngIf="subheader" [ngTemplateOutlet]="subheader"></ng-container>
    </div>
  </nb-card-header>
  <nb-card-body class="listado-items" >
    <nb-list class="card-list scroll-y">
      <nb-list-item *ngFor="let item of menuOptions; let i = index;"
                    [class.active]="selected == i"
                    [nbTooltip]="item.tooltip" [nbTooltipDisabled]="item.tooltip == ''"
                    class="cursor-pointer" (click)="onMenuClicked(item)">
        <div class="menu-item" [class.active-menu]="selected == i">
          <app-icon [icon]="item.icon"></app-icon>
          <span class="ps-2">{{item.title}}</span>
        </div>
      </nb-list-item>
    </nb-list>
  </nb-card-body>
</nb-card>
