<div class="wrapper">
  <div class="flex-space-between wrapper-header wrapper-background">
    <div>
      <div>{{label}}</div>
      <div class="sublabel" *ngIf="sublabel != ''">{{sublabel}}</div>
    </div>

    <app-spinner-icon *ngIf="spinnerIcon" [icon]="spinnerIcon" [tooltip]="spinnerIconTooltip" [status]="spinnerIconStatus"></app-spinner-icon>
    <app-button [disabled]="disabled"  [label]="buttonLabel" size="tiny" [status]="status" (onPress)="onButtonClicked()"></app-button>
  </div>
</div>


