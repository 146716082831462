import {
  AfterViewInit,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener, Input,
  OnDestroy, OnInit, Renderer2,
  ViewContainerRef, ViewRef
} from '@angular/core';
import {ScrollToTopButtonComponent} from "../../@form-view/view/scroll-to-top-button/scroll-to-top-button.component";
import {StorageService} from "../../@bus/storage.service";

@Directive({
  selector: '[scrollToTop]'
})
export class ScrollToTopDirective implements OnDestroy, OnInit, AfterViewInit {

  @Input() scrollToTopOffset: string = "1rem";

  // ayuda dimensiones de un scroll/elemento => https://javascript.info/size-and-scroll

  readonly distanceToShowButton = 2;  // Número de veces la altura del elemento

  private buttonInstance: ComponentRef<ScrollToTopButtonComponent> | null = null;

  visibility: boolean = false;

  @HostListener('scroll', ['$event']) private onScrollEvent($event:Event):void {
    this.onScroll($event.target);
  };

  constructor(private host: ElementRef,
              private renderer: Renderer2,
              private viewContainerRef: ViewContainerRef,
  ) { }

  ngOnInit() {
    // this.host.nativeElement.style.position = "relative";
  }

  ngAfterViewInit() {

  }

  onScroll(target: EventTarget | null) {
    const hostElement = this.host.nativeElement;

    if (hostElement.scrollTop > this.distanceToShowButton * hostElement.clientHeight) {
      this.visibility = true;
      this.setVisible();
    } else {
      this.visibility = false;
      this.setVisible();
    }

    // console.log(`scroll: h:${hostElement.clientHeight} => ${hostElement.scrollTop}/${hostElement.scrollHeight}`);
  }

  setVisible() {
    if (this.buttonInstance == null) {
      this.createComponent();
    }

    if (this.buttonInstance) {
      this.buttonInstance.instance.visible = this.visibility;
      this.buttonInstance.instance.offset = this.scrollToTopOffset;
    }

  }

  onClickScrollButton() {
    this.host.nativeElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  private createComponent() {
    this.buttonInstance = this.viewContainerRef.createComponent(ScrollToTopButtonComponent);
    this.buttonInstance.instance.visible = false;
    this.buttonInstance.instance.offset = this.scrollToTopOffset;
    this.buttonInstance.instance.onClickCallback = () => {
      this.onClickScrollButton();
    };

    // Si lo queremos insertar como el último hijo
    // this.renderer.appendChild(this.host.nativeElement, this.buttonInstance.location.nativeElement);

  }

  ngOnDestroy(): void {
    if (this.buttonInstance) {
      this.buttonInstance.destroy();
      this.buttonInstance = null;
    }
  }

}
