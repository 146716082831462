<nb-layout [withScroll]="!forPrint">

  <nb-layout-header fixed *ngIf="!forPrint">
    <app-header></app-header>
  </nb-layout-header>

  <nb-sidebar *ngIf="showSideBar && (isLogged || (!isLogged && documentacionEnabledToExternalUser))"
              (stateChange)="sidebarStateChange($event)"
              responsive tag="menu-sidebar">
    <ng-container *ngIf="isLogged" >
      <app-multi-menu [tagActive]="currentMenu" [menus]="multiMenu"></app-multi-menu>
    </ng-container>
    <nb-sidebar-footer *ngIf="isLogged" class="footer-wrapper">
      <div class="footer-buttons" [class.footer-compacted]="sidebarState == 'compacted'">
        <button *ngIf="isMaster" class="cursor-pointer" nbButton ghost  size="small" (click)="onMenuSuperadmin()" nbTooltip="{{labelMenuSuperAdmin}}">
          <nb-icon [status]="currentMenu == 'superadmin' ? 'success' : 'basic'" icon="shield-outline"></nb-icon>
        </button>
        <button *ngIf="isAdmin" class="cursor-pointer" nbButton ghost  size="small" (click)="onMenuAdmin()" nbTooltip="{{labelMenuAdmin}}">
          <nb-icon [status]="currentMenu == 'admin' ? 'success' : 'basic'" icon="settings-outline"></nb-icon>
        </button>
        <button *ngIf="isAsesor" class="cursor-pointer" nbButton ghost  size="small" (click)="onMenuAsesor()" nbTooltip="{{labelMenuAsesor}}">
          <nb-icon [status]="currentMenu == 'asesor' ? 'success' : 'basic'" icon="star-outline"></nb-icon>
        </button>
        <button class="cursor-pointer" nbButton ghost size="small" (click)="onMenuUser()" nbTooltip="{{labelMenuUsuario}}">
          <nb-icon [status]="currentMenu == 'logged' ? 'success' : 'basic'" icon="person-outline"></nb-icon>
        </button>
        <button class="cursor-pointer" nbButton ghost size="small" (click)="onMenuHelp()" nbTooltip="{{labelMenuAyuda}}">
          <nb-icon [status]="currentMenu == 'documentation' ? 'success' : 'basic'" icon="question-mark-circle-outline"></nb-icon>
        </button>
      </div>
    </nb-sidebar-footer>
  </nb-sidebar>

  <nb-layout-column [ngClass]="{'header-none': forPrint}" [ngStyle]="{'padding': !isLogged ? '0' : '1rem'}">
    <router-outlet></router-outlet>
  </nb-layout-column>

  <nb-sidebar *ngIf="showSideBar && currentMenu == 'logged' && (isLogged || (!isLogged && documentacionEnabledToExternalUser))"
              (stateChange)="sidebarStateChange($event)" right
              responsive tag="menu-sidebar" class="right">
    <ng-container *ngIf="isLogged" >
      <label class="title-menu-right my-2">{{labelEventos}}</label>
      <nb-calendar [date]="fecha"  [dayCellComponent]="dayCellComponent" (dateChange)="onChange($event)" style="zoom:0.65"></nb-calendar>
      <!--<label class="title-menu-right my-2 mt-3">Actividad Reciente</label>
      <app-header-alertas></app-header-alertas>-->

    </ng-container>
    <nb-sidebar-footer *ngIf="isLogged" class="my-5" style="margin-bottom: 5rem !important;">
      <ng-container *ngIf="showConsultas">
        <app-button *ngIf="showPhone" class="total flex-center mb-2" [icon]="iconTelefono" label="800 007 283" size="medium" status="basic" (onPress)="onPhone()"></app-button>
        <app-button *ngIf="isEuropaFactor" class="total flex-center mb-2" [icon]="iconTelefono" label="{{labelClickToCall}}" size="small" status="primary" (onPress)="openClickToCallModal()"></app-button>
        <app-button [id]="'buttonCorreo'" [disabled]="isServiceOnline && !hasService" class="total flex-center mb-2" [icon]="iconContacta" label="{{labelConsulta}}" size="small" status="primary" (onPress)="onContactar()"></app-button>
        <app-button *ngIf="isEuropaFactor" class="total flex-center mb-2" [icon]="iconChat" label="{{labelChat}}" size="small" status="primary" (onPress)="loadChatScript()"></app-button>
      </ng-container>
      <!--<app-button *ngIf="showDiscounts" class="total mb-1 flex-center" label="VENTAJAS / DESCUENTOS" size="small" status="primary" (onPress)="onSSO()"></app-button>-->
    </nb-sidebar-footer>
  </nb-sidebar>
</nb-layout>

<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card class="dialog-wrapper" [status]="status" [accent]="accent">
    <nb-card-header>
      <div class="flex-space-between">
        <div class="form-title">{{titleModal}}</div>
        <app-header-action [icon]="iconClose" (onClick)="onCancelar()"></app-header-action>
      </div>

    </nb-card-header>
    <nb-card-body>
      <div class="form-body">
        <form [formGroup]="formularioClickToCall">
          <app-input [focus]="true" label="{{labelPhone}}"  type="text" formControlName="phone"></app-input>
        </form>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="flex-space-between">
        <app-button [id]="'buttonClickToCall'" [disabled]="!formularioClickToCall.valid"  label="{{labelLlamar}}" size="small" status="success" (onPress)="onClickToCall()"></app-button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<!--<ng-template #secondDialog let-data let-ref="dialogRef">
    <nb-card class="dialog-wrapper" [status]="status" [accent]="accent">
        <nb-card-body>
            <div class="form-body">
                <p>En breve recibirá la llamada de uno de nuestros agentes.</p>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <div class="flex-center">
                <button nbButton size="small" status="primary" (click)="closeSecondModal()">Aceptar</button>
            </div>
        </nb-card-footer>
    </nb-card>
</ng-template>-->
