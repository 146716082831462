import { Component, OnInit } from '@angular/core';
import {Authentication} from "../../core/authentication";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor() {
    Authentication.reconnect().then( (logged): void => {
      Authentication.goToLanding();
    });

  }

  ngOnInit(): void {
  }

}
