<app-popover [trigger]="trigger" [placement]="placement" (onChangeVisibility)="onChangeVisibility($event)">
  <div style="position: relative">
    <app-header-action [disabled]="disabled" [icon]="icon" [tooltip]="tooltip" [status]="status"></app-header-action>
    <nb-badge *ngIf="badgeStatus" [dotMode]="true" [status]="badgeStatus" position="top right">
    </nb-badge>
  </div>

  <ng-template *ngIf="!disabled" #hover>
    <nb-card class="wrapper">
      <nb-card-body style="margin:0; padding: 0;">
        <nb-list class="card-list scroll-y" *ngIf="optionsCopy.length > 0">
          <nb-list-item *ngFor="let item of optionsCopy; let i = index;" class="cursor-pointer wrapper-item"
                        [class.selected]="item.selected"
                        [nbTooltip]="item.tooltip || ''"
                        [nbTooltipDisabled]="!item.tooltip"
                        (click)="onOptionCheck(i)">
            <div class="d-flex">
              <app-icon [icon]="item.icon || noIcon"></app-icon>
              <div class="w-100 ps-2 pe-2">
                {{item.label}}
              </div>
            </div>

          </nb-list-item>
        </nb-list>
        <div *ngIf="optionsCopy.length == 0" class="sin-opciones">
          Sin opciones disponibles
        </div>

        <div *ngIf="multiple || allowNew" class="wrapper-botonera flex-space-between">
          <div *ngIf="multiple" class="me-2">
            <app-button size="tiny" label="OK" (onPress)="onOK()"></app-button>
          </div>
          <div *ngIf="allowNew">

            <div class="flex-space-between">
              <div style="width: 7rem;">
                <app-input-text [initialValue]="newValue" size="tiny" [icon]="iconNew" (onInputChange)="onNewValueChange($event)"></app-input-text>
              </div>
              <app-button class="ms-2" [disabled]="!newValueEdited"  label="Añadir" size="tiny" (onPress)="onAdd()"></app-button>
            </div>

          </div>
          <app-header-action class="ms-2" [disabled]="cuantasSelected == 0" status="warning" tooltip="Quitar selecciones"  [icon]="iconDelete" (onClick)="onClear()"></app-header-action>
        </div>
      </nb-card-body>

    </nb-card>

  </ng-template>


</app-popover>

