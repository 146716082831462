import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {Icon} from "../../../@models/icons";

export type PaneMenuOption = {
  icon: Icon,
  title: string,
  id: any,
  tooltip: string,
}

@Component({
  selector: 'app-pane-menu',
  templateUrl: './pane-menu.component.html',
  styleUrls: ['./pane-menu.component.scss']
})
export class PaneMenuComponent implements OnChanges {

  @Input() title: string = 'Menú';
  @Input() menuOptions: PaneMenuOption[] = [];
  @Input() selected : number = 0;

  @ContentChild('subheader') subheader?: TemplateRef<any>;


  @Output() onClick = new EventEmitter<PaneMenuOption>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {

  }

  onMenuClicked(option: PaneMenuOption) {
    this.onClick.emit(option);
  }

}
