import {Component, Input, OnInit} from '@angular/core';
import {Icon} from "../../../@models/icons";


export type CardIndicator = {
  title: string,
  subtitle?: string,
  content1?: string,
  content2?: string,
  amount?: number,
  compactAmount?: boolean,
  currency?: string,
  percentaje?: number,
  tooltip?: string,
  previous?: CardIndicator,
  decimals?: number,
  icon?: Icon,
}



@Component({
  selector: 'app-card-indicators',
  templateUrl: './card-indicators.component.html',
  styleUrls: ['./card-indicators.component.scss']
})
export class CardIndicatorsComponent implements OnInit {

  @Input() indicators: CardIndicator[] = [];


  constructor() { }

  ngOnInit(): void {
  }

}
