import {Component, Input, OnInit} from '@angular/core';

export const IconInvermon = "@assets/img/invermon_icon.svg";
export const IconVegator = "@assets/img/vegator_icon.svg";

@Component({
  selector: 'app-background-image',
  templateUrl: './background-image.component.html',
  styleUrls: ['./background-image.component.scss']
})
export class BackgroundImageComponent implements OnInit {
  @Input() imageUrl: string = IconVegator;
  @Input() width?: string = "20rem";
  @Input() height?: string = "20rem";
  @Input() top?: string | number;
  @Input() bottom?: string | number;
  @Input() left?: string | number;
  @Input() right?: string | number;
  @Input() margin?: string = '1rem';

  styleObject: any = {};
  center: boolean = true;

  constructor() { }

  ngOnInit(): void {

    if (this.top != undefined) {
      this.styleObject.top = this.top;
      this.center = false;
    }
    if (this.bottom != undefined) {
      this.styleObject.bottom = this.bottom;
      this.center = false;
    }
    if (this.left != undefined) {
      this.styleObject.left = this.left;
      this.center = false;
    }
    if (this.right != undefined) {
      this.styleObject.right = this.right;
      this.center = false;
    }
    if (this.center) {
      this.styleObject = { 'inset': '0px'};
    } else {
      if (this.width) {
        this.styleObject.width = this.width;
      }
      if (this.height) {
        this.styleObject.height = this.height;
      }
    }
    this.styleObject.margin = this.margin;
  }

}
