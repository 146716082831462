import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Status} from "../../../@models/theme";
import {Icon} from "../../../@models/icons";

@Component({
  selector: 'app-option-button',
  templateUrl: './option-button.component.html',
  styleUrls: ['./option-button.component.scss']
})
export class OptionButtonComponent implements OnInit {
  @Input() label: string = '';
  @Input() sublabel: string = '';
  @Input() buttonLabel: string = 'Click';
  @Input() disabled: boolean = false;
  @Input() status: Status = 'primary';
  @Input() spinnerIcon?: Icon;
  @Input() spinnerIconTooltip: string = '';
  @Input() spinnerIconStatus: Status = 'warning';

  @Output() onClick = new EventEmitter<void>();


  constructor() { }

  ngOnInit(): void {

  }



  onButtonClicked() {
     this.onClick.emit();
  }

}
