import {getLocaleLong} from "./date";
import {CurrencyUnit} from "../../models/currency";

export const ErrGeneric = $localize`Se ha producido un error`;
export const MsgInfo = $localize`Información`;
export const MsgWarning = $localize`Aviso`;
export const MsgError = $localize`Error`;
export const MsgSucess = $localize`Operación exitosa`;
export const ValidationPatternUrl = '(https://|http://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';


export function Capitalize(input: string): string {
  if (!input) {
    return '';
  }
  let words = input.split(" ");
  let wordsCapitalized: string[] = [];
  words.forEach((element: string): void => {
    let word = element.length > 0 ? element[0].toUpperCase() + element.substring(1).toLowerCase() : "";
    word = word.trim();
    if (word != '') {
      wordsCapitalized.push(word);
    }
  });

  return wordsCapitalized.join(" ");
}

export function StripHtml(value: string): string {
  return value.replace(/<[^>]*>?/gm, '');
}

export function isString(myVar: any): boolean {
  if (typeof myVar === 'string' || myVar instanceof String) {
    return true;
  }
  return false;
}

export function FormatLocalNumber(num: number | string, minimumFractionDigits: number = 2, maximumFractionDigits: number = 2, grouping: boolean = true): string {
  if (isNaN(num as number)) {
    num = 0.0;
  }

  // para 4 digitos en ES no se pone el separador de miles (RAE) => usar de-DE si se desea que los ponga
  const localeLong = getLocaleLong();

  if (isString(num)) {
    // @ts-ignore
    num = parseFloat(num);
  }

  return new Intl.NumberFormat(localeLong, {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
    useGrouping: grouping }).format(num as number);
}


export function NumberSmart(value: number, currency: string = '', withCurrencySpace = true, changeUnits: boolean = true): string {

  let postfix = '';
  currency = CurrencyUnit(currency);

  if (changeUnits && Math.abs(value) >= 1000000) {
    value = value / 1000000;
    postfix = " M" + currency;
  } else {
    if (changeUnits && Math.abs(value) >= 10000) {
      value = value / 1000;
      postfix = " K" + currency;
    } else {
      if (currency != '') {
        postfix = ' ' + currency;
      }
    }
  }
  if (!withCurrencySpace) {
    postfix = postfix.trim();
  }

  const withLanguage: string = FormatLocalNumber(value);

  return withLanguage + postfix;
}

export function ToNumber(x: string): number {
  const parsed: number = Number.parseInt(x, 10);
  if (Number.isNaN(parsed)) {
    return 0;
  }
  return parsed;
}



export function FormatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) {
    return '0 B';
  }
  const k = 1024;
  const dm = decimals <= 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const numResult = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  return FormatLocalNumber(numResult, dm, dm) + ' '  + sizes[i];
}


// Sólo comprueba el primer tramo de la ruta
export function RouteStartsWith(currentRoute: string, routeToCheck: string) : boolean {

  if (routeToCheck[0] != '/') {
    routeToCheck = '/'+routeToCheck;
  }
  routeToCheck = routeToCheck.toLowerCase()

  // Quitamos ?
  const path: string = currentRoute.split('?')[0];

  // Primer tramo
  const first: string = path.split('/')[1];

  // Normalizamos
  const clean: string = '/'+first.toLowerCase();

  if (routeToCheck == clean) {
    return true;
  }
  return false;
}
