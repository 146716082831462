import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
    NbAlertModule, NbBadgeModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule, NbFormFieldModule, NbIconModule,
    NbInputModule, NbListModule, NbMenuModule, NbPopoverModule, NbSelectModule, NbSpinnerModule, NbTagModule,
    NbToggleModule, NbTooltipModule, NbUserModule
} from "@nebular/theme";
import { IconComponent } from './view/icon/icon.component';
import {AvatarComponent} from "./view/avatar/avatar.component";
import {UnfocusDirective} from "../@angular/directives/unfocus.directive";
import { SizedboxComponent } from './view/sizedbox/sizedbox.component';
import {HeightVisibleDirective} from "../@angular/directives/height-visible.directive";
import {AnimationDirective} from "../@angular/directives/animation.directive";
import { CardActionsComponent } from './view/card-actions/card-actions.component';
import { GaugeComponent } from './view/gauge/gauge.component';
import {NgxGaugeModule} from "ngx-gauge";
import {FLipBackComponent, FlipComponent, FlipFrontComponent} from './view/flip/flip.component';
import {RouterModule} from "@angular/router";
import {StripHtmlPipe} from "../@angular/pipes/strip-html.pipe";
import {TimeAgoPipe} from "../@angular/pipes/time-ago.pipe";
import { HeaderActionComponent } from './view/header-action/header-action.component';
import { PaneMenuComponent } from './view/pane-menu/pane-menu.component';
import { HrComponent } from './view/hr/hr.component';
import { CardIndicatorsComponent } from './view/card-indicators/card-indicators.component';
import { CardIndicatorComponent } from './view/card-indicators/card-indicator/card-indicator.component';
import { IconExpandComponent } from './view/icon-expand/icon-expand.component';
import {VisibilityObserverDirective} from "../@angular/directives/visibility-observer.directive";
import { SpinnerIconComponent } from './view/spinner-icon/spinner-icon.component';
import {ResizeDirective} from "../@angular/directives/resize.directive";
import {FocusDirective} from "../@angular/directives/focus.directive";
import { ImgLoaderComponent } from './view/img-loader/img-loader.component';
import { SmartBoxComponent } from './view/smart-box/smart-box.component';
import { UploadFileComponent } from './view/upload-file/upload-file.component';
import {ButtonComponent} from "./form/button/button.component";
import {InputComponent} from "./form/input/input.component";
import {CheckComponent} from "./form/check/check.component";
import {LinkComponent} from "./form/link/link.component";
import {DurationPipe} from "../@angular/pipes/duration.pipe";
import {DateHumanPipe} from "../@angular/pipes/date-human.pipe";
import {SwitchComponent} from "./form/switch/switch.component";
import {InputTextComponent} from "./form/input-text/input-text.component";
import {SliderComponent} from "./form/slider/slider.component";
import {InputTextareaComponent} from "./form/input-textarea/input-textarea.component";
import {SelectComponent} from "./form/select/select.component";
import {OptionStepNumberComponent} from "./form/option-step-number/option-step-number.component";
import {OptionSliderComponent} from "./form/option-slider/option-slider.component";
import {OptionButtonComponent} from "./form/option-button/option-button.component";
import {InputPasswordComponent} from "./form/input-password/input-password.component";
import {OptionSwitchComponent} from "./form/option-switch/option-switch.component";
import {BytesSmartPipe} from "../@angular/pipes/bytes-smart.pipe";
import { InputInlineComponent } from './form/input-inline/input-inline.component';
import { TwoColumnListComponent } from './form/two-column-list/two-column-list.component';
import { HoverActionsComponent } from './view/hover-actions/hover-actions.component';
import { PopoverComponent } from './view/popover/popover.component';
import {StartsWidthPipe} from "../@angular/pipes/starts-width.pipe";
import { KpiComponent } from './view/kpi/kpi.component';
import {EllipsisPipe} from "../@angular/pipes/ellipsis.pipe";
import {NumberSmartPipe} from "../@angular/pipes/number-smart.pipe";
import { BackgroundIconComponent } from './view/background-icon/background-icon.component';
import { BackgroundImageComponent } from './view/background-image/background-image.component';
import { MultiOptionComponent } from './view/multi-option/multi-option.component';
import { TagsComponent } from './form/tags/tags.component';
import { SelectCountryComponent } from './form/select-country/select-country.component';
import {DateHumanCalendarPipe} from "../@angular/pipes/date-human-calendar.pipe";
import { InputSearchComponent } from './form/input-search/input-search.component';
import {SafePipe} from "../@angular/pipes/safe.pipe";
import {ScrollToTopDirective} from "../@angular/directives/scroll-to-top.directive";
import { ScrollToTopButtonComponent } from './view/scroll-to-top-button/scroll-to-top-button.component';
import { BulletComponent } from './view/bullet/bullet.component';
import { MultiMenuComponent } from './view/multi-menu/multi-menu.component';
import { CheckListComponent } from './form/check-list/check-list.component';
import { InputDigitsComponent } from './form/input-digits/input-digits.component';
import {DigitsOnlyDirective} from "../@angular/directives/digits-only.directive";
import { InputNumberComponent } from './form/input-number/input-number.component';
import {MatSliderModule} from "@angular/material/slider";


@NgModule({
  declarations: [
    ButtonComponent,
    InputComponent,
    CheckComponent,
    LinkComponent,
    IconComponent,
    AvatarComponent,
    UnfocusDirective,
    FocusDirective,
    HeightVisibleDirective,
    VisibilityObserverDirective,
    ResizeDirective,
    ScrollToTopDirective,
    DigitsOnlyDirective,
    SizedboxComponent,
    AnimationDirective,
    CardActionsComponent,
    GaugeComponent,
    FlipComponent,
    FlipFrontComponent,
    FLipBackComponent,
    StripHtmlPipe,
    TimeAgoPipe,
    DurationPipe,
    DateHumanPipe,
    SafePipe,
    DateHumanCalendarPipe,
    NumberSmartPipe,
    EllipsisPipe,
    BytesSmartPipe,
    StartsWidthPipe,
    HeaderActionComponent,
    SwitchComponent,
    PaneMenuComponent,
    InputTextComponent,
    HrComponent,
    CardIndicatorsComponent,
    CardIndicatorComponent,
    SliderComponent,
    IconExpandComponent,
    InputTextareaComponent,
    SelectComponent,
    OptionStepNumberComponent,
    OptionSliderComponent,
    OptionButtonComponent,
    InputPasswordComponent,
    SpinnerIconComponent,
    ImgLoaderComponent,
    OptionSwitchComponent,
    SmartBoxComponent,
    UploadFileComponent,
    InputInlineComponent,
    TwoColumnListComponent,
    HoverActionsComponent,
    PopoverComponent,
    KpiComponent,
    BackgroundIconComponent,
    BackgroundImageComponent,
    MultiOptionComponent,
    TagsComponent,
    SelectCountryComponent,
    InputSearchComponent,
    ScrollToTopButtonComponent,
    BulletComponent,
    MultiMenuComponent,
    CheckListComponent,
    InputDigitsComponent,
    InputNumberComponent,
  ],
    exports: [
        AvatarComponent,
        IconComponent,
        HeightVisibleDirective,
        VisibilityObserverDirective,
        ResizeDirective,
        FocusDirective,
        AnimationDirective,
        CardActionsComponent,
        GaugeComponent,
        ButtonComponent,
        SizedboxComponent,
        LinkComponent,
        InputComponent,
        FlipComponent,
        FlipFrontComponent,
        FLipBackComponent,
        StripHtmlPipe,
        TimeAgoPipe,
        HeaderActionComponent,
        SwitchComponent,
        PaneMenuComponent,
        InputTextComponent,
        HrComponent,
        CardIndicatorsComponent,
        SliderComponent,
        IconExpandComponent,
        UnfocusDirective,
        ScrollToTopDirective,
        InputTextareaComponent,
        SelectComponent,
        OptionStepNumberComponent,
        OptionSliderComponent,
        OptionButtonComponent,
        InputPasswordComponent,
        SpinnerIconComponent,
        ImgLoaderComponent,
        DateHumanPipe,
        DateHumanCalendarPipe,
        BytesSmartPipe,
        DurationPipe,
        OptionSwitchComponent,
        SmartBoxComponent,
        UploadFileComponent,
        InputInlineComponent,
        TwoColumnListComponent,
        HoverActionsComponent,
        PopoverComponent,
        StartsWidthPipe,
        KpiComponent,
        EllipsisPipe,
        NumberSmartPipe,
        BackgroundIconComponent,
        BackgroundImageComponent,
        MultiOptionComponent,
        TagsComponent,
        SelectCountryComponent,
        CardIndicatorComponent,
        InputSearchComponent,
        BulletComponent,
        MultiMenuComponent,
        CheckListComponent,
        InputDigitsComponent,
        InputNumberComponent,
        CheckComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NbCardModule,
        NbAlertModule,
        NbButtonModule,
        NbToggleModule,
        NbCheckboxModule,
        NbInputModule,
        NbIconModule,
        NbFormFieldModule,
        NbUserModule,
        NbSpinnerModule,
        NbTooltipModule,
        NgxGaugeModule,
        NbPopoverModule,
        RouterModule,
        NbListModule,
        MatSliderModule,
        NbSelectModule,
        NbBadgeModule,
        NbTagModule,
        NbMenuModule,
    ],
})
export class FormViewModule { }
