
<div class="smart-box" style="border-color: {{fillColor}}">
  <div *ngIf="size=='small'" class="smart-box-corner">

    <svg width="50px"  viewBox="0 0 320 70"  xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g transform="matrix(1,0,0,0.5,20,0)">
        <path d="M300,140L300,24.397C300,10.923 294.539,0 287.801,0C229.267,0 -20,0 -20,0L-1.732,0C4.032,-0 9.559,4.579 13.635,12.73C17.71,20.881 20,31.937 20,43.464L20,43.468C20,53.157 21.924,62.449 25.35,69.3C28.776,76.151 33.422,80 38.266,80C85.471,80 211.875,80 270.002,80C286.569,80 300,106.861 300,139.996L300,140Z" style="fill:{{fillColor}};"/>
    </g>
    </svg>
  </div>
  <div *ngIf="size=='long'" class="smart-box-corner">
    <svg width="100px" viewBox="0 0 640 70" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(2,0,0,0.5,40,1.72662e-12)">
        <path d="M300,140L300,24.397C300,17.927 299.357,11.721 298.214,7.146C297.07,2.57 295.518,0 293.901,-0C249.683,0 -20,0 -20,0C-20,0 -15.561,-0 -10.866,-0C-7.984,-0 -5.22,4.579 -3.183,12.73C-1.145,20.881 0,31.937 0,43.464L0,43.468C-0,53.157 0.962,62.449 2.675,69.3C4.388,76.151 6.711,80 9.133,80C51.795,80 231.621,80 285.001,80C293.285,80 300,106.861 300,139.996L300,140Z" style="fill:{{fillColor}};"/>
    </g>
  </svg>
  </div>

  <div *ngIf="header" class="smart-box-header">
    <ng-container [ngTemplateOutlet]="header"></ng-container>
  </div>
  <app-hr *ngIf="header" [status]="headerLineStatus || ''" class="w-100"></app-hr>
  <div class="smart-box-content">
    <ng-content></ng-content>
  </div>

</div>
