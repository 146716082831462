
import {Status} from "./theme";
import {Icon} from "./icons";


export type Callback = () => void;

export type Option = {
  value: string,
  label: string,
  data?: any,
  icon?: Icon,
  tooltip?: string,
  hidden?: boolean,
  selected?: boolean,
}

export type DialogOptions = {
  status?: Status,
  accent?: Status,
  title?: string,
  body: string,
}

export type TableColumn = {
  title: string;
  align: "center" | "left" | "right";
}

export type TableOptions = {
  status?: Status,
  accent?: Status,
  title?: string,
  columns?: (string |TableColumn)[],
  rows?: string[][],
}

// Convierte un objeto a un Map
export function ToMap(badges: object | undefined): Map<string, any> {
  // Los nuevos
  const newMap: Map<string, any> = new Map();
  if (badges) {
    // Es un array pero viene como objeto
    Object.entries(badges).forEach(([key, value]) => {
      newMap.set(key, value);
    });
  }

  return newMap;

}

// Nos aseguramos que todos los índicas son números para evitar este tipo de datos "[movimientos: '']" que usa el GRPC
export function IsArray(data: any): boolean {
  const isArray = Array.isArray(data);
  if (isArray) {
    let allNumbers = true;
    for (const ele in data) {
      if (isNaN(Number(ele))) {
        allNumbers = false;
        break;
      }
    }
    if (allNumbers) {
      return true;
    }
  }
  return false;
}
