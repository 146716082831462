<div class="app-icon-wrapper">
  <nb-icon *ngIf="iconWithDisabled"
           [status]="status || iconWithDisabled.status"
           [icon]="iconWithDisabled.icon"
           [pack]="iconWithDisabled.pack"
           [options]="iconWithDisabled.options"
           class="flex-center"
           style="height: {{fontSize || iconWithDisabled.fontSize}};font-size: {{fontSize || iconWithDisabled.fontSize}}; width: {{fontSize || iconWithDisabled.fontSize}}; {{iconWithDisabled.extraStyle}}"
  ></nb-icon>
</div>

