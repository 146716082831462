import {Component, Input, OnInit} from '@angular/core';
import {Status} from "../../@models/theme";
import {IconClose} from "../../@models/icons";
import {FormControl, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-otp-form',
  templateUrl: './otp-form.component.html',
  styleUrls: ['./otp-form.component.scss']
})
export class OtpFormComponent implements OnInit {
  @Input() placeholder: string = 'introduza el código recibido';
  @Input() help: string = '';
  @Input() status: Status = 'basic';
  @Input() accent: Status = 'warning';
  @Input() title: string = 'Código OTP';
  @Input() extraInfo: string = '';
  @Input() width: string = "15rem";
  @Input() onResult?: (otp: string) => Promise<string>;

  iconClose = IconClose;

  errorDialog: string = '';

  formulario = new UntypedFormGroup({
    otp: new FormControl<string>('',[Validators.required]),
  });


  constructor() {

  }


  ngOnInit(): void {

  }

  onModify() {
    if (this.onResult) {
      this.onResult(this.formulario.value.otp).then( (result) => {
        this.errorDialog = result;
      });
    }
  }

  onCancelar() {
    if (this.onResult) {
      this.onResult('').then( (result) => {
        this.errorDialog = result;
      });
    }
  }

}
