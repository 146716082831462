import { Injectable } from '@angular/core';

interface Scripts {
  name: string;
  src: string;

}

interface Styles {
  name: string;
  src: string;
  integrity?:string;
  crossorigin?:string;
}


@Injectable({
  providedIn: 'root'
})
export class LazyScriptLoaderService {


  constructor() {}


  load(...scripts: Scripts[]) {
    const promises: any[] = [];
    // push the returned promise of each loadScript call
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    // return promise.all that resolves when all promises are resolved
    return Promise.all(promises);
  }

  changeFavIcon(id: string, faviconUrl: string): Promise<boolean> {

    // Si ya existe, no lo cargamos
    const linkElement: HTMLLinkElement = document.getElementById(id) as HTMLLinkElement;
    if (linkElement) {
      linkElement.type = 'image/x-icon';
      linkElement.rel = 'icon';
      linkElement.href = faviconUrl;
      Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
      // load script
      const linkElement = document.createElement('link');
      linkElement.type = 'image/x-icon';
      linkElement.rel = 'icon';
      linkElement.href = faviconUrl;
      // finally append the script tag in the DOM
      document.getElementsByTagName('head')[0].appendChild(linkElement);
      Promise.resolve(true);
    });
  }

  loadScript(scriptToLoad: Scripts): Promise<any> {

    // Si ya existe, no lo cargamos
    if (document.getElementById(scriptToLoad.name + 'dynamic_script')) {
      Promise.resolve({ script: scriptToLoad.name, loaded: true, status: 'Already Loaded' });
    }

    return new Promise((resolve, reject) => {
      // load script
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = scriptToLoad.name + 'dynamic_script';
      script.src = scriptToLoad.src;

      script.onload = () => {
        resolve({ script: scriptToLoad.name, loaded: true, status: 'Loaded' });
      };
      script.onerror = (error: any) => resolve({ script: scriptToLoad.name, loaded: false, status: 'Loaded' });

      // finally append the script tag in the DOM
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }


  public loadStyles(styles: Styles) {
    return new Promise((resolve, reject) => {
      if (document.getElementById(styles.name + "_style") == null) {
        const styleLink = document.createElement('link');
        styleLink.rel = 'stylesheet';
        styleLink.href = styles.src;
        styleLink.id = styles.name + "_style";
        styleLink.type = 'text/css';
        styleLink.onload = () => {
          resolve({ script: styles.name, loaded: true, status: 'Loaded' });
        };
        if(styles.integrity){
          styleLink.integrity = styles.integrity;
        }
        if(styles.crossorigin){
          styleLink.crossOrigin = styles.crossorigin;
        }
        let bootstrapfer:any = document.getElementsByTagName('link')[0];
        bootstrapfer.parentNode.insertBefore(styleLink, bootstrapfer);
      } else {
        resolve({ script: styles.name, loaded: true, status: 'Already Loaded' });
      }
    });
  }
}
