<div class="bgimg">
  <div class="topleft">
    <a  href="#" (click)="navigateRoot()"><img src="{{logo}}" height="50px" /></a>
  </div>
  <div class="message">
    <div class="message-header">{{labelProteccionNavegador}}</div>
    <div class="message-body">{{labelDesactivarProteccion}}</div>
    <div class="mt-4">
      <app-button label="{{labelProbar}}" size="small" (onPress)="navigateRoot()"></app-button>
    </div>
  </div>
</div>
