import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef, EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit, Output, SimpleChanges
} from '@angular/core';
import {BusService} from "../../@bus/bus.service";
import {Subscription} from "rxjs";

@Directive({
  selector: '[resize]'
})

export class ResizeDirective implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked, OnChanges {
  @Input() resize: any;
  @Input() position: boolean = true;  // Emite el evento también si cambia de posición
  @Output() onResize: EventEmitter<DOMRect> = new EventEmitter<DOMRect>();

  subs: Subscription[] = [];

  last: any = null;

  constructor(private bus: BusService, private elRef: ElementRef, private cd: ChangeDetectorRef) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculaSize();
  }

  ngOnInit(): void {
    this.subs.push(this.bus.events.resizeEvent.subscribe((size): void => {
      this.calculaSize();
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription): void => {
      sub.unsubscribe();
    });
  }

  ngAfterViewInit(): void {
    this.calculaSize();
  }

  ngAfterViewChecked(): void {
    this.calculaSize();
  }

  calculaSize(): void {

    if (this.resize === false || this.resize == "false") {
      return;
    }

    const rect = this.elRef.nativeElement.getBoundingClientRect();

    // Si no ha cambiado, salimos
    if (this.last) {
      let same = true;
      if (this.position) {
        if (rect.x != this.last.x || rect.y != this.last.y) {
          same = false;
        }
      }
      if (rect.height != this.last.height || rect.width != this.last.width) {
        same = false;
      }

      if (same) {
        return;
      }

      this.onResize.emit(rect);

      // console.log("resize", rect);

      this.cd.detectChanges();
    }

    this.last = rect;


  }


}
