<div *ngIf="cuantasActivas > 0"
     class="flex-center {{currentAnimationComponent}}" appAnimation="entrance-down"
     [nbPopover]="listaDownloads" nbPopoverPlacement="bottom" nbPopoverTrigger="noop"
>
  <nb-icon *ngIf="currentJobIcon" [icon]="currentJobIcon.icon" [pack]="currentJobIcon.pack" [status]="currentJobIcon.status"></nb-icon>
  <div class="container" >
    <nb-badge *ngIf="badge != ''" [text]="badge" class="{{currentAnimationBadge}}"  status="info" size="tiny" position="top right"></nb-badge>
    <nb-progress-bar [value]="progress" size="small" [status]="progressStatus">
      <span *ngIf="progress >= 15" class="{{labelClass}}">{{progress}}%</span>
    </nb-progress-bar>
    <div class = "bottom-labels">
      <span *ngIf="title!=''" class="label-outer">{{title}}</span>
      <span class="label-elapsed">{{elapsed}}</span>
    </div>

  </div>
  <nb-icon class="expand-state {{currentTransformClass}}" [icon]="currentIcon"></nb-icon>
  <ng-template #listaDownloads>
    <app-header-progress-popover [mapItems]="descargasActivas"></app-header-progress-popover>
  </ng-template>
</div>



