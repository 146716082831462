import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {

  // Args0 => decimales
  // Ejempo bytesSmart: 2

  transform(value: string, ...args: unknown[]): string {
    if (!value) {
      return '';
    }
    let max = 0;
    if (args.length > 0) {
      max = args[0] as number;
    } else {
      return value;
    }

    if (max) {
      if (value.length <= max) {
        return value;
      }
      return value.substring(0,max - 1) + '...';
    }

    return value;
  }

}
