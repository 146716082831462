import { Pipe, PipeTransform } from '@angular/core';
import {DateFormat} from "../../@models/date";

@Pipe({
  name: 'dateHumanCalendar'
})
export class DateHumanCalendarPipe implements PipeTransform {

  // Args0 => showArticle
  // Ejempo dateHuman: true

  transform(value: string, ...args: unknown[]): string {
    const df = DateFormat.fromBackend(value);
    let showArticle = false;
    if (args.length > 0) {
      showArticle = !!args[0];
    }

    return df.toHumanCalendar(false, false, "auto", showArticle);
  }

}
