

export interface Avatar {
  image_url: string;
  background_color: string;
  ink_color: string;
  initials: string;
}

export let AvatarEmpty: Avatar = {
  image_url: "",
  background_color: "",
  ink_color: "",
  initials: "",
}
