import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmComponent } from './confirm/confirm.component';
import {NbAlertModule, NbCardModule} from "@nebular/theme";
import { ConfirmPromptComponent } from './confirm-prompt/confirm-prompt.component';
import { InfoPromptComponent } from './info-prompt/info-prompt.component';
import { OtpFormComponent } from './otp-form/otp-form.component';
import {ReactiveFormsModule} from "@angular/forms";
import { WizardComponent } from './wizard/wizard.component';
import {FormViewModule} from "../@form-view/@form-view.module";
import { InfoTableComponent } from './info-table/info-table.component';



@NgModule({
  declarations: [
    ConfirmComponent,
    ConfirmPromptComponent,
    InfoPromptComponent,
    OtpFormComponent,
    WizardComponent,
    InfoTableComponent,
  ],
  exports: [
    ConfirmComponent
  ],
  imports: [
    CommonModule,
    NbCardModule,
    ReactiveFormsModule,
    NbAlertModule,
    FormViewModule,
  ]
})
export class DialogsModule { }
