<div class="card-container">
  <ng-template #botones>
    <button *ngIf="refresh" (click)="onClickRefresh()" nbButton ghost><app-icon [icon]="IconRefresh" nbTooltip="Refrescar"></app-icon></button>
    <button *ngIf="left" (click)="onClickLeft()" nbButton ghost><app-icon [icon]="IconRotateLeft" nbTooltip="Rotar Izquierda"></app-icon></button>
    <button *ngIf="right" (click)="onClickRight()" nbButton ghost><app-icon [icon]="IconRotateRight" nbTooltip="Rotar Derecha"></app-icon></button>
    <button *ngIf="plus" (click)="onClickPlus()" nbButton ghost><app-icon [icon]="IconPlus" nbTooltip="Aumentar"></app-icon></button>
    <button *ngIf="minus" (click)="onClickMinus()" nbButton ghost><app-icon [icon]="IconMinus" nbTooltip="Disminuir"></app-icon></button>
    <button *ngIf="external" (click)="onClickExternal()" nbButton ghost><app-icon [icon]="IconExternalLink" nbTooltip="Apertura Externa"></app-icon></button>
    <button *ngIf="expand && !maximized" (click)="onClickMax()" nbButton ghost><app-icon [icon]="IconExpand" nbTooltip="Maximizar"></app-icon></button>
    <button *ngIf="expand && maximized" (click)="onClickMin()" nbButton ghost><app-icon [icon]="IconCollapse" nbTooltip="Minimizar"></app-icon></button>
  </ng-template>
  <ng-template #botonesWrapper>
    <div style="padding: 0.5rem">
      <ng-container *ngTemplateOutlet="botones"></ng-container>
    </div>

  </ng-template>
  <ng-container *ngIf="asMoreVertical">
    <button nbButton ghost [nbPopover]="botonesWrapper" nbPopoverPlacement="bottom-start" nbPopoverTrigger="hover"><app-icon [icon]="IconMore"></app-icon></button>
  </ng-container>
  <ng-container *ngIf="!asMoreVertical">
    <ng-container *ngTemplateOutlet="botones"></ng-container>
  </ng-container>

  <button (click)="onClickClose()" *ngIf="close" nbButton ghost><app-icon [icon]="IconClose" nbTooltip="Cerrar"></app-icon></button>
</div>
