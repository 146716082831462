import {BaseClass} from "../@core/@models/common";
import {GrpcRequest, GrpcResponse, Origin, OriginEmpty} from "../@core/@models/grpc";
import {Pagination} from "./pagination";

export class Deuda extends BaseClass{
  id: string = '';
  id_externo: string = '';
  titulo: string = '';
  nombre: string = '';
  apellido1: string = '';
  apellido2: string = '';
  direccion: string = '';
  provincia: string = '';
  cod_provincia: string = '';
  poblacion: string = '';
  cp: string = '';
  email: string = '';
  telefono: string = '';
  telefono2: string = '';
  nif: string = '';
  referencia_contacto: string = '';
  tipo_contacto: string = '';
  fecha_nacimiento: string = '';
  creado: string = '';
  comentarios: string = '';
  proyecto: number = 0;
  estado: number = 0;
  valoracion: number = 0;
  tipo: string = '';
  referencia: string = '';
  deuda_total: number = 0.0;
  pendiente: number = 0.0;
  pagos_realizados: number = 0.0;
  pagos_comprometidos: number = 0.0;
  cerrado: boolean = false;
  personalizado1: string = '';
  personalizado2: string = '';
  personalizado3: string = '';
  personalizado4: string = '';
  personalizado5: string = '';
  personalizado6: string = '';

  constructor(asObject?: object) {
    super();
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }


  static listDeuda(request: ListDeudaRequest): Promise<ListDeudaResponse> {
    return new Promise<ListDeudaResponse>( (resolve, reject): void => {
      const response: ListDeudaResponse = new ListDeudaResponse();
      response.sendRequest("list_deuda", request).then( (): void => {
        resolve(response);
      });
    });
  }

  static modifyDeuda(request: ModifyDeudaRequest): Promise<ModifyDeudaResponse> {
    return new Promise<ModifyDeudaResponse>( (resolve, reject): void => {
      const response: ModifyDeudaResponse = new ModifyDeudaResponse();
      response.sendRequest("modify_deuda", request).then( (): void => {
        resolve(response);
      });
    });
  }

}


export class ModifyDeudaRequest extends GrpcRequest {

  data?: Deuda;
  operation: string = '';

  origin: Origin = OriginEmpty;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

}


export class ModifyDeudaResponse extends GrpcResponse {

  data?: Deuda;
  error: string = '';

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }
}


export class ListDeudaRequest extends GrpcRequest {
  search?: string = '';
  filter?: Deuda;
  pagination?: Pagination;
  origin: Origin = OriginEmpty;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

}


export class ListDeudaResponse extends GrpcResponse {
  data?: Array<Deuda>;
  error: string = '';
  pagination?: Pagination;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }


}

