import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {getTime} from "../../../@models/common";
import {Status} from "../../../@models/theme";
import {NbComponentSize} from "@nebular/theme/components/component-size";
import {Icon} from "../../../@models/icons";



export enum ButtonClass {
  primary,
  secundary
}


@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() spinner: boolean = false;
  @Input() icon?: Icon;
  @Input() type: string = 'button'; // submit etc
  @Input() label: string = '';
  @Input() status: Status = 'basic';
  @Input() badgeStatus?: Status;
  @Input() size: NbComponentSize = 'medium';
  @Input() spinnerStatus: Status = 'primary';
  @Input() minTimeSpinning: number = 2000; // Milisegundos
  @Output() onPress: EventEmitter<any> = new EventEmitter<any>(); // Se le pasa onComplete

  isRunning: boolean = false;

  transformClass = 'animation-pulse';
  animationClass = '';

  constructor() { }

  ngOnInit(): void {

  }

  pressed(): void {
    // Animación
    this.animationClass = this.transformClass;
    setTimeout( () => {
      this.animationClass = '';
    }, 250);


    if(!this.spinner) {
      this.onPress.emit();
      return;
    }

    this.isRunning = true;
    const currentDisabled = this.disabled;
    this.disabled = true;

    const ahora = getTime();

    this.onPress.emit( () => {
      const elapsed = getTime() - ahora;
      if (elapsed < this.minTimeSpinning) {
        setTimeout( () => {
          this.isRunning = false;
          this.disabled = currentDisabled;
        },this.minTimeSpinning - elapsed);
      } else {
        this.isRunning = false;
        this.disabled = currentDisabled;
      }
    });

  }
}
