import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NbMenuItem} from "@nebular/theme";


export type MultiMenuItem = {
  tag: string,
  items: NbMenuItem[],
}

@Component({
  selector: 'app-multi-menu',
  templateUrl: './multi-menu.component.html',
  styleUrls: ['./multi-menu.component.scss']
})
export class MultiMenuComponent implements OnInit, OnChanges {
  @Input() menus: MultiMenuItem[] = [];
  @Input() tagActive: string = '';

  defaultAnimation: string = 'animation-flip';
  currentAnimation: string = '';

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tagActive']) {
      this.currentAnimation = this.defaultAnimation;
      setTimeout(() => {
        this.currentAnimation = '';
      }, 1000);
    }
  }

}
