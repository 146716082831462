import {Component, EventEmitter, Input, OnInit, Optional, Output, Self} from '@angular/core';
import {Icon, IconSearch} from "../../../@models/icons";
import {NbComponentSize} from "@nebular/theme/components/component-size";
import {debounceTime} from "../../../@models/lodash";
import {NgControl} from "@angular/forms";


@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent implements OnInit {
  @Input() focus: boolean = false;
  @Input() disabled: boolean = false;
  @Input() initialValue: any = '';
  @Input() type: string = 'text';
  @Input() fullWidth: boolean = false;
  @Input() size: NbComponentSize = 'medium';
  @Input() icon?: Icon = IconSearch;
  @Input() align: 'left' | 'right' | 'center' =  'left';
  @Input() shape: 'rectangle' | 'semi-round' | 'round' =  'rectangle';
  @Input() placeholder: string = '';
  @Output() onInputChange = new EventEmitter<any>();
  @Output() onFocus = new EventEmitter<any>();

  debouncer = debounceTime( (value) => {
    this.onInputChange.emit(value);
  }, 500)

  value: any;  // No se pasa como parámetro, poner un formControl

  public onChangeFn = (_: any) => {};
  public onTouchedFn = () => {};

  constructor(@Self() @Optional() public control: NgControl) {
    this.control && (this.control.valueAccessor = this);
  }


  ngOnInit(): void {
    if (this.initialValue) {
      this.value = this.initialValue;
    }
  }

  onInput() {
    this.onChange(this.value);
    this.debouncer(this.value);
    // this.onInputChange.emit(this.value);
  }
  onClear(e:Event) {
    e.stopPropagation()
    this.value = '';
    this.onInputChange.emit(this.value);
  }

  onFocusEvent() {
    this.onFocus.emit();
  }

  // ControlValueAccessor
  onChange(val: any) {
    this.onTouchedFn();
    this.onChangeFn(val);
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
}
