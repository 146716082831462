import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {BusService} from "./@core/@bus/bus.service";
import {Authentication} from "./core/authentication";

@Injectable({
  providedIn: 'root'
})
export class IsLoggedGuard implements CanActivate {

  constructor(private bus: BusService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (Authentication.getAuth().isValid()) {
      return true;
    }

    return new Promise<boolean>( (resolve, reject): void => {
      Authentication.reconnect().then( (logged: boolean): void => {
        if (logged) {
          resolve(true);
        } else {
          this.bus.events.loginEvent.next(false);
          Authentication.goToLanding();
          resolve(false);
        }
      });
    });


  }

}
