import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {IconClose} from "../../../@models/icons";
import {DateFormat} from "../../../@models/date";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, AfterViewInit {
  @Input() title: string = "Seleccionar una fecha";
  @Input() date?: string;
  @Input() max: string = '';
  @Input() min: string = '';
  @Input() step: number = 15;

  @Input() onChangeCallback?: (d: string) => void;
  @Input() onCloseCallback?: () => void;


  dateObject: Date = new Date();
  hasTime: boolean = false;
  stepHours: number = 60;
  singleColumn: boolean = true;

  iconClose = IconClose;

  maxDate?: Date;
  minDate?: Date;

  hourTitle: string = '';

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    let df;
    if (this.date) {
      df = DateFormat.fromBackend(this.date);
      this.dateObject = df.toJSDate();
      this.hasTime = df.hasTime;
    } else {
      df = DateFormat.fromNow();
      df.hasTime = false;
      this.dateObject = df.toJSDate();
      this.hasTime = df.hasTime;
    }

    if (this.hasTime) {
      this.hourTitle = df.toBackendDateTime().split(" ")[1].substring(0,5);
    }

    if (this.max != '') {
      df = DateFormat.fromBackend(this.max);
      this.maxDate = df.toJSDate();
    }

    if (this.min != '') {
      df = DateFormat.fromBackend(this.min);
      this.minDate = df.toJSDate();
    }

  }

  ngAfterViewInit() {
    this.stepHours = this.step;  // Para que actualice la columna
    this.cd.detectChanges();
  }

  dateChange(d: Date | undefined) {
    if (d != undefined) {
      const df = DateFormat.fromJSDate(d);
      if (this.onChangeCallback) {
        if (this.hasTime) {
          this.onChangeCallback(df.toBackendDateTime());
        } else {
          this.onChangeCallback(df.toBackendDate());
        }
      }
    }

  }

  onCloseClick() {
    if (this.onCloseCallback) {
      this.onCloseCallback();
    }
  }

}
