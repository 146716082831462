<div>
  <ng-template #hoverContent>
    <ng-content select="[hover]">
    </ng-content>
  </ng-template>
  <div [nbPopover]="hover || hoverContent"
       [nbPopoverTrigger]="currentTrigger"
       [nbPopoverPlacement]="placement"
       (nbPopoverShowStateChange)="onChangeStatus()"
  >
    <ng-content>
    </ng-content>
  </div>
</div>
