
import {DateTime, DateTimeFormatOptions} from "luxon";
import {BaseClass} from './common';


export const BackendDateTimeFormat = 'yyyy-MM-dd HH:mm:ss';
export const BackendDateFormat = 'yyyy-MM-dd';
export const BackendTimeFormat = 'HH:mm:ss';
export const BackendTimeShortFormat = 'HH:mm';

const n = "numeric",
  s = "short",
  l = "long";

export const DATETIME_FULL: DateTimeFormatOptions = {
  year: n,
  month: l,
  day: n,
  hour: n,
  minute: n,
};

export const TIME_FULL: DateTimeFormatOptions = {
  hour: n,
  minute: n,
  second: n,
};
export const TIME_SHORT: DateTimeFormatOptions = {
  hour: n,
  minute: n,
};

export const DATE_FULL: DateTimeFormatOptions = {
  year: n,
  month: l,
  day: n,
};


export const DATETIME_SHORT: DateTimeFormatOptions = {
  month: l,
  day: n,
  hour: n,
  minute: n,
};

export const DATE_SHORT: DateTimeFormatOptions = {
  month: l,
  day: n,
};

export function getLocale(): string {
  return navigator.language;
  //return 'es';
}
export function getLocaleLong(): string {
  if (navigator.language === 'it'){
    return 'it-IT';
  }
  return 'es-ES';
}

export class DateFormat extends BaseClass{

  public dt: DateTime;
  public hasTime: boolean = false;

  constructor() {
    super();
    this.dt = this.now();
  }

  // Constructores
  static fromNow(addDays: number = 0): DateFormat {
    const res: DateFormat = new DateFormat();

    if (addDays != 0) {
      res.dt =  res.dt.plus({ days: addDays });
    }

    return res;
  }

  static fromUnix(unix: number, addDays: number = 0): DateFormat {
    const res: DateFormat = new DateFormat();

    res.dt = DateTime.fromMillis(unix * 1000).setLocale(getLocale());

    if (addDays != 0) {
      res.dt =  res.dt.plus({ days: addDays });
    }

    return res;
  }

  static fromUnixMillis(millis: number, addDays: number = 0): DateFormat {
    const res: DateFormat = new DateFormat();

    res.dt = DateTime.fromMillis(millis).setLocale(getLocale());

    if (addDays != 0) {
      res.dt =  res.dt.plus({ days: addDays });
    }

    return res;
  }

  static fromBackend(fromMysql: string, addDays: number = 0): DateFormat  {
    if (!fromMysql) {
      return DateFormat.fromNow();
    }

    const res: DateFormat = new DateFormat();
    if (fromMysql.length > 10) {
      res.hasTime = true;
      res.dt = DateTime.fromFormat(fromMysql, BackendDateTimeFormat)
    } else {
      res.hasTime = false;
      res.dt = DateTime.fromFormat(fromMysql, BackendDateFormat)
    }
    if (addDays != 0) {
      res.dt =  res.dt.plus({ days: addDays });
    }

    return res;
  }
  static fromJSDate(date: Date, addDays: number = 0): DateFormat  {
    const res: DateFormat = new DateFormat();
    res.dt = DateTime.fromJSDate(date).setLocale(getLocale());

    const mysql: string = res.toBackendDateTime()
    if (mysql.split(' ')[1] == '00:00:00') {
      res.hasTime = false;
    } else {
      res.hasTime = true;
    }

    if (addDays != 0) {
      res.dt =  res.dt.plus({ days: addDays });
    }
    return res;
  }

  // ISO 8601
  static fromISO(iso8601: string, addDays: number = 0): DateFormat {
    const res: DateFormat = new DateFormat();
    res.dt = DateTime.fromISO(iso8601).setLocale(getLocale());

    const mysql: string = res.toBackendDateTime()
    if (mysql.split(' ')[1] == '00:00:00') {
      res.hasTime = false;
    } else {
      res.hasTime = true;
    }

    if (addDays != 0) {
      res.dt =  res.dt.plus({ days: addDays });
    }
    return res;
  }

  now(): DateTime {
    return DateTime.now().setLocale(getLocale());
  }

  getTimeZone(): string {
    return this.dt.zoneName;
  }

  getDateTime(): string {
    return this.dt.toFormat(BackendDateTimeFormat);
  }

  getDate(addDays: number = 0): string {
    const dt: DateTime = this.dt.plus({ days: addDays })
    return dt.toFormat(BackendDateFormat);
  }

  getTime(): string {
    return this.dt.toFormat(BackendTimeFormat);
  }
  getTimeShort(): string {
    return this.dt.toFormat(BackendTimeShortFormat);
  }

  toJSDate(): Date  {
    return this.dt.toJSDate();
  }

  // ISO 8601
  toISO(): string {
    return this.dt.toISO();
  }

  isEmpty(dateString: string): boolean {
    if (!dateString) {
      return true;
    }
    if (dateString === '0000-00-00' || dateString === '0000-00-00 00:00:00') {
      return true;
    }
    return false;
  }

  isToday(): boolean {
    const now: DateFormat = new DateFormat()
    return this.toBackendDate() == now.toBackendDate();
  }

  isWeekend(): boolean {
    const jsFecha: Date = this.dt.toJSDate();
    if(jsFecha.getDay() == 6 || jsFecha.getDay() == 0) {
      return true;
    }
    return false;
  }


  toBackendDate(): string {
    return this.dt.toFormat(BackendDateFormat);
  }

  toBackendDateTime(): string {
    return this.dt.toFormat(BackendDateTimeFormat);
  }

  toUnix(): number {
    return this.dt.toUnixInteger();
  }

  toUnixMillis(): number {
    return this.dt.toMillis();
  }

  toHuman(weekday: boolean = false, shortMonth: boolean = false): string {
    let format: DateTimeFormatOptions;
    if (this.hasTime) {
      format = DATETIME_FULL;
    } else {
      format = DATE_FULL;
    }

    if (shortMonth) {
      format = {...format};
      format.month = s;
    }

    if (weekday) {
      format = {...format};
      format.weekday = l;
    }

    return this.dt.setLocale(getLocale()).toLocaleString(format);
  }

  toHumanShort(weekday: boolean = false, shortMonth: boolean = false ): string {
    let format: DateTimeFormatOptions;
    if (this.hasTime) {
      format = DATETIME_SHORT;
    } else {
      format = DATE_SHORT;
    }
    if (shortMonth) {
      format = {...format};
      format.month = s;
    }

    if (weekday) {
      format = {...format};
      format.weekday = l;
    }

    return this.dt.setLocale(getLocale()).toLocaleString(format);

  }

  toHumanCalendar(weekday: boolean = false, shortMonth: boolean = false, showYear : 'always' | 'never' | 'auto' = 'auto', showArticle: boolean = false): string {
    const hoy: DateFormat = DateFormat.fromNow();
    if (hoy.toBackendDate() == this.toBackendDate()) {
      return $localize`Hoy`;
    }

    const ayer: DateFormat = DateFormat.fromNow(-1);
    if (ayer.toBackendDate() == this.toBackendDate()) {
      return $localize`Ayer`;
    }

    const manana: DateFormat = DateFormat.fromNow(1);
    if (manana.toBackendDate() == this.toBackendDate()) {
      return $localize`Mañana`;
    }

    let format: DateTimeFormatOptions;

    switch (showYear) {
      case 'always':
        format = DATE_FULL;
        break;
      case 'never':
        format = DATE_SHORT;
        break;
      case 'auto':
        if (this.getYear() == this.getCurrentYear()) {
          format = DATE_SHORT;
        } else {
          format = DATE_FULL;
        }

        break;
    }

    if (shortMonth) {
      format = {...format};
      format.month = s;
    }

    if (weekday) {
      format = {...format};
      format.weekday = l;
    }

    if (showArticle) {
      return "el " + this.dt.toLocaleString(format);
    } else {
      return this.dt.setLocale(getLocale()).toLocaleString(format);
    }
  }

  timeAgo(): string {
    return this.dt.toRelative() || 'N/A';
  }

  // Ayer, mañana, hace 5 días, etc => no muestra la hora
  calendarAgo(withSeconds: boolean = false): string {
    let fecha: string = this.dt.toRelativeCalendar() || '';
    let format: DateTimeFormatOptions;
    if (this.hasTime) {

      if (withSeconds) {
        format = TIME_FULL;
      } else {
        format = TIME_SHORT;
      }
      const hora = this.dt.setLocale(getLocale()).toLocaleString(format);

      fecha = `${fecha}, ${hora}`;
    }

    return fecha;

  }

  getCurrentYear(): string {
    return this.now().toFormat('yyyy');
  }

  getCurrentMonth(): string {
    return this.now().toFormat('MM');
  }
  getCurrentDay(): string {
    return this.now().toFormat('dd');
  }

  getYear(): string {
    return this.dt.toFormat('yyyy');
  }

  getMonth(): string {
    return this.dt.toFormat('MM');
  }
  getDay(): string {
    return this.dt.toFormat('dd');
  }

}

export function NowMillisecs(): number {
  return (new Date()).getTime();
}

export function NowSeconds(): number {
  return (new Date()).getTime()/1000;
}

export function NowMinutes(): number {
  return NowSeconds() / 60;
}

export function NowHours(): number {
  return NowMinutes() / 60;
}

export function IsTodayFromJSDate(fecha: Date): boolean {
  return DateFormat.fromJSDate(fecha).isToday();
}

export function ToDurationSmart(totalSeconds: number): string {
  const hours: number = Math.floor(totalSeconds / 3600);
  const minutes: number = Math.floor((totalSeconds % 3600) / 60);
  const seconds: number = totalSeconds % 60;
  let result: string = `${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  if (!!hours) {
    result = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  return result;
}

// Si la fecha A es anterior a la fecha B, retorna un número negativo
// Si la fecha A es posterior a la fecha B, retorna un número positivo
// Si las fechas son iguales, retorna 0
export function compareDates(a: string, b: string): number {
  const fechaA: Date = new Date(a);
  const fechaB: Date = new Date(b);

  return fechaA.getTime() - fechaB.getTime();
}
