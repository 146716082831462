import {Component, Input, OnInit} from '@angular/core';
import {NgxGaugeType} from "ngx-gauge/gauge/gauge";
import {Status} from "../../../@models/theme";
import {
  ColorStatusBasic,
  ColorStatusDanger,
  ColorStatusInfo,
  ColorStatusPrimary, ColorStatusSuccess,
  ColorStatusWarning
} from "../../../@models/theme";


@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss']
})
export class GaugeComponent implements OnInit {
  @Input() label:string = '';
  @Input() labelBottom:string = '';
  @Input() value: number = 50;
  @Input() prependText:string = "";
  @Input() appendText:string = "%";
  @Input() size:number = 200;
  @Input() status: Status = '';
  @Input() gaugeType: NgxGaugeType = "full";
  @Input() gaugeThick: number = 10;
  foregroundColor: string = '';
  backgroundColor: string = 'rgba(0, 0, 0, 0.15)';

  constructor() { }

  ngOnInit(): void {


    // Redondeo a un decimal
    this.value = Math.round(this.value * 10) / 10;

    switch(this.status) {
      case 'primary':
        this.foregroundColor = ColorStatusPrimary;
        break;
      case 'danger':
        this.foregroundColor = ColorStatusDanger;
        break;
      case 'warning':
        this.foregroundColor = ColorStatusWarning;
        break;
      case 'info':
        this.foregroundColor = ColorStatusInfo;
        break;
      case 'success':
        this.foregroundColor = ColorStatusSuccess;
        break;
      default:
        this.foregroundColor = ColorStatusBasic;
    }
  }

}
