import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Icon, IconMoreVertical} from "../../../@models/icons";
import {Status, Size} from "../../../@models/theme";


export type HoverAction = {
  icon: Icon,
  tooltip: string,
  id: string,
  disabled?: boolean,
  status?: Status,
}

@Component({
  selector: 'app-hover-actions',
  templateUrl: './hover-actions.component.html',
  styleUrls: ['./hover-actions.component.scss']
})
export class HoverActionsComponent implements OnInit{
  @Input() actions: HoverAction[] = [];
  @Input() size: Size = "medium";
  @Output() onAction: EventEmitter<HoverAction> = new EventEmitter<HoverAction>();

  IconMore = IconMoreVertical;

  constructor() { }

  ngOnInit(): void {

  }

  onClickAction(action: HoverAction) {
    this.onAction.emit(action);
  }


}
