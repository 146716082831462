import { Pipe, PipeTransform } from '@angular/core';
import {StripHtml} from "../../@models/strings";

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {

  transform(value: string): string {
    return StripHtml(value);
  }
}
