import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  constructor() { }

  loadScript(domain: string): void {
    let scriptUrl: string | undefined;

    if (domain === 'europafactor.vozitel.com') {
      scriptUrl = 'https://europafactor.vozitel.com/es/assets/chat/dist/vozitelpublicchateuropafactor.js';
    } else if (domain === 'mapfre.clubintel.com') {
      scriptUrl = 'https://mapfre.clubintel.com/es/assets/chat/dist/vozitelpublicchatmapfre.js';
    }

    if (scriptUrl) {
      const script = document.createElement('script');
      script.src = scriptUrl;
      document.head.appendChild(script);
    }
  }
}
