import {Inject, Injectable} from '@angular/core';
import {BusService} from './bus.service';
import {NbDialogService, NbGlobalLogicalPosition, NbLayoutScrollService, NbToastrService} from "@nebular/theme";
import {Icon, IconInfo, IconSuccess, IconWarning, IconError} from "../@models/icons";
import {MsgError, MsgInfo, MsgSucess, MsgWarning} from "../@models/strings";
import {Size} from "../@models/event";
import {fromEvent, Observable, Subscription} from "rxjs";
import {DOCUMENT} from "@angular/common";
import {CSSLenght} from "../@models/toPx";
import {NowMillisecs} from "../@models/date";
import {Callback} from "../@models/types";
import {Platform} from "@angular/cdk/platform";

export type VisibleCallback = {
  func: Callback,
  key: string,
  onlyOnce: boolean,
}

@Injectable({
  providedIn: 'root'
})

// Para mantener aquí todas las variables globales
export class DomService  {

  resizeObservable$?: Observable<Event>;
  resizeSubscription$?: Subscription;
  visibilitySubscription$?: Subscription;

  // @ts-ignore
  bus: BusService;
  cssLenght: CSSLenght;

  whenVisiblePendingTasks: VisibleCallback[] = [];


  constructor(private toastrService: NbToastrService,
              public dialogService: NbDialogService,
              private scroller: NbLayoutScrollService,
              private platform: Platform,
              @Inject(Window) private window: Window,
              @Inject(DOCUMENT) private document: Document) {

    this.cssLenght = new CSSLenght(document);

  }

  getWindow(): Window {
    return this.window;
  }

  launchResize(event: any) {
    this.bus.events.resizeEvent.next({
      innerWidth: event.target.innerWidth,
      innerHeight: event.target.innerHeight,
      outerWidth: event.target.outerWidth,
      outerHeight: event.target.outerHeight,
    });
  }

  init(bus: BusService) {
    this.bus = bus;

    let lastResize = 0;

    this.resizeObservable$ = fromEvent(this.window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( (event: any) => {

      // Para gestionar resizes muy rápidos
      const ahora = NowMillisecs();

      if (lastResize == 0) {
        this.launchResize(event);
        lastResize = ahora;
        setTimeout( () => {
          lastResize = 0;
          this.launchResize(event);
        }, 200)
      }

    });

    // Visibility
    this.visibilitySubscription$ = fromEvent(document, "visibilitychange").subscribe( (evt) => {
      this.bus.events.pageVisibleEvent.next(this.document.visibilityState == 'visible');
      this.processWhenVisible();
    });
  }

  getBrowserName(): string {
    const agent = this.window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'Edge';
      case agent.indexOf('opr') > -1 && !!(<any>this.window).opr:
        return 'Opera';
      case agent.indexOf('chrome') > -1 && !!(<any>this.window).chrome:
        return 'Chrome';
      case agent.indexOf('trident') > -1:
        return 'IE';
      case agent.indexOf('firefox') > -1:
        return 'Firefox';
      case agent.indexOf('safari') > -1:
        return 'Safari';
      default:
        return 'Other';
    }
  }

  getBrowserVersion(){
    let userAgent = navigator.userAgent, tem,
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE '+(tem[1] || '');
    }
    if(matchTest[1]=== 'Chrome'){
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    matchTest= matchTest[2]? [matchTest[1], matchTest[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= userAgent.match(/version\/(\d+)/i))!= null) matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }

  getPlatform(): string {
    if (this.platform.ANDROID) {
      return "ANDROID";
    }
    if (this.platform.IOS) {
      return "IOS";
    }
    if (this.platform.FIREFOX) {
      return "FIREFOX";
    }
    if (this.platform.BLINK) {
      return "BLINK";
    }
    if (this.platform.WEBKIT) {
      return "WEBKIT";
    }
    if (this.platform.TRIDENT) {
      return "TRIDENT";
    }
    if (this.platform.EDGE) {
      return "EDGE";
    }
    if (this.platform.SAFARI) {
      return "SAFARI";
    }
    return "UNKNOWN";
  }

  addWhenVisible(key: string, onlyOnce: boolean, func: Callback) {

    if (onlyOnce) {
      // Comprobamos si ya existe
      let existe = false;
      this.whenVisiblePendingTasks.forEach( (item) => {
        if (item.key == key) {
          existe = true;
        }
      });
      if (existe) {
        return;
      }
    }

    this.whenVisiblePendingTasks.push({
      func: func,
      key: key,
      onlyOnce: onlyOnce,
    });
  }

  processWhenVisible() {
    if (this.pageIsVisible()) {
      this.whenVisiblePendingTasks.forEach( (item) => item.func());
      this.whenVisiblePendingTasks = [];
    }
  }

  toPX(element: any, len: string, prop: string = 'width'): number {
    return this.cssLenght.toPx(element, len, prop );
  }

  screenWidth(): number {
    return this.document.documentElement.clientWidth;
  }


  screenSize(): Size {
    return {
      innerWidth: this.window.innerWidth,
      innerHeight: this.window.innerHeight,
      outerWidth: this.window.outerWidth,
      outerHeight: this.window.outerHeight,
    }
  }

  pageIsVisible(): boolean {
    return this.document.visibilityState == 'visible';
  }

  resizeEmit() {
    this.window.dispatchEvent(new Event('resize'));
  }




  toastSuccess(msg: string, title?: string, icon?: Icon, duration: number = 10000) {
    this.toastrService.success(msg, title || MsgSucess, {
      position: NbGlobalLogicalPosition.BOTTOM_END,
      duration: duration,
      destroyByClick: true,
      icon: icon || IconSuccess,
      hasIcon: true,
    });
  }

  toastInfo(msg: string, title?: string, icon?: Icon, duration: number = 10000) {
    this.toastrService.info(msg, title || MsgInfo , {
      position: NbGlobalLogicalPosition.BOTTOM_END,
      duration: duration,
      destroyByClick: true,
      icon: icon || IconInfo,
      hasIcon: true,
    });
  }
  toastError(msg: string, title?: string, icon?: Icon, duration: number = 10000) {
    this.toastrService.danger(msg, title || MsgError, {
      position: NbGlobalLogicalPosition.BOTTOM_END,
      duration: duration,
      destroyByClick: true,
      icon: icon || IconError ,
      hasIcon: true,
    });
  }
  toastWarning(msg: string, title?: string, icon?: Icon, duration: number = 10000) {
    this.toastrService.warning(msg, title || MsgWarning, {
      position: NbGlobalLogicalPosition.BOTTOM_END,
      duration: duration,
      destroyByClick: true,
      icon: icon || IconWarning ,
      hasIcon: true,
    });
  }
  toastDefault(msg: string, title?: string, icon?: Icon, duration: number = 10000) {
    this.toastrService.default(msg, title, {
      position: NbGlobalLogicalPosition.BOTTOM_END,
      duration: duration,
      destroyByClick: true,
      icon: icon || IconInfo ,
      hasIcon: true,
    });
  }
  toastPrimary(msg: string, title?: string, icon?: Icon, duration: number = 10000) {
    this.toastrService.primary(msg, title, {
      position: NbGlobalLogicalPosition.BOTTOM_END,
      duration: duration,
      destroyByClick: true,
      icon: icon || IconInfo ,
      hasIcon: true,
    });
  }

  scrollToFragment(fragment: string, offsetY: number = 0) {

    if (!fragment.startsWith('#')) {
      fragment = '#' + fragment;
    }
    if (fragment == '#') {
      return;
    }

    setTimeout( () => {
      const element = this.document.querySelector(fragment);
      if (element) {
        const y = (element as HTMLElement).offsetTop  + offsetY;
        this.scroller.scrollTo(0,y);
      }

      /*
      if (element && this.window) {
        const y = element.getBoundingClientRect().top + this.window.scrollY + offsetY;
        this.window.scrollTo({top: y, behavior: 'smooth'});
      }
       */
    }, 0);


  }


}
