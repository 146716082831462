import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Capitalize} from "../../../@models/strings";
import {User} from "../../../../models/user";

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {

  @Input() onlyPicture: boolean = false;
  @Input() name: string = 'sin nombre';
  @Input() title: string = '';
  @Input() imageUrl: string = '';
  @Input() backgroundColor: string = '';
  @Input() inkColor: string = '';
  @Input() initials: string = '';
  @Input() user: User | null = null;
  @Input() size: any = 'medium';
  @Input() showTitle: boolean = true;
  @Input() isDemo: boolean = false;

  classInk = '';

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.user) {
      this.name = this.user.full_name;
      if (this.showTitle) {
        if (this.user.is_master) {
          this.title = $localize`Superadministrador`;
        }else if (this.user.is_admin) {
          this.title = $localize`Administrador`;
        } else {
          if (this.user.is_advisor) {
            this.title = $localize`Asesor`;
          } else {
            if (this.isDemo) {
              this.title = $localize`Usuario DEMO`;
            } else {
              this.title = $localize`Usuario`;
            }
          }
        }

      } else {
        this.title = '';
      }

      this.imageUrl = this.user.avatar.image_url;
      this.backgroundColor = this.user.avatar.background_color;
      this.inkColor = this.user.avatar.ink_color;
      this.initials = this.user.avatar.initials;
    }

    if (this.inkColor == "#ffffff") {
      this.classInk = 'avatar-white';
    } else {
      this.classInk = 'avatar-black';
    }

    // Capitalizamos el nombre
    this.name = Capitalize(this.name);

  }

}
