import {BaseClass} from "../@core/@models/common";
import {GrpcRequest, GrpcResponse, Origin, OriginEmpty} from "../@core/@models/grpc";
import {Pagination} from "./pagination";

export class Factura extends BaseClass{
  id: string = '';
  id_externo: string = '';
  titulo: string = '';
  descripcion: string = '';
  referencia_cliente: string = '';
  tipo: number = 0;
  autor: number = 0;
  vencimiento: string = '';
  fecha: string = '';
  deuda: string = '';
  importe: number = 0.0;
  creado: string = '';
  quita: number = 0.0;
  pendiente: number= 0.0;

  constructor(asObject?: object) {
    super();
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }


  static listFactura(request: ListFacturaRequest): Promise<ListFacturaResponse> {
    return new Promise<ListFacturaResponse>( (resolve, reject): void => {
      const response: ListFacturaResponse = new ListFacturaResponse();
      response.sendRequest("list_factura", request).then( (): void => {
        resolve(response);
      });
    });
  }

  static modifyFactura(request: ModifyFacturaRequest): Promise<ModifyFacturaResponse> {
    return new Promise<ModifyFacturaResponse>( (resolve, reject): void => {
      const response: ModifyFacturaResponse = new ModifyFacturaResponse();
      response.sendRequest("modify_factura", request).then( (): void => {
        resolve(response);
      });
    });
  }

}


export class ModifyFacturaRequest extends GrpcRequest {

  data?: Factura;
  operation: string = '';

  origin: Origin = OriginEmpty;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

}


export class ModifyFacturaResponse extends GrpcResponse {

  data?: Factura;
  error: string = '';

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }
}


export class ListFacturaRequest extends GrpcRequest {
  search?: string = '';
  filter?: Factura;
  // pagination?: Pagination;
  origin: Origin = OriginEmpty;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

}


export class ListFacturaResponse extends GrpcResponse {

  data?: Array<Factura>;
  error: string = '';
  // pagination?: Pagination;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }


}

