import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {getUUID} from "../../../@models/common";
import {ThemePalette} from "@angular/material/core";
import {DestroyerComponent} from "../../../@angular/abstract/destroyer/destroyer.component";
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";


@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SliderComponent,
    multi: true
  }]
})
export class SliderComponent extends DestroyerComponent implements OnInit, ControlValueAccessor {
  @Input() label?: string;
  @Input() help?: string;
  @Input() value: any;
  @Input() disabled: boolean = false;
  @Input() min: number = 0;
  @Input() showThumbLabel: boolean = false;
  @Input() max: number = 100;
  @Input() showTickMarks: boolean = false;
  @Input() fnFormatLabel?: (val: number) => string;
  @Output() onChange = new EventEmitter<number>();

  formatLabel = (value: number): string => {
    if (this.fnFormatLabel) {
      return this.fnFormatLabel(value);
    }
    return value + '';
  }

  debounce = new Subject<number>();

  color: ThemePalette = 'primary';

  // value: any;  // No se pasa como parámetro, poner un formControl

  private onTouched!: Function;
  private onChanged!: Function;

  id: string = getUUID();

  constructor() {
    super();

    this.autoUnsubsbriber(this.debounce.pipe(debounceTime(250)).subscribe( (val) => {
      this.onTouched();
      this.onChanged(val);
      this.onChange.emit(val);
    }));
  }



  ngOnInit(): void {
    if (this.value) {
      this.onChanged(this.value);
    }

    if (this.disabled) {
      this.color = 'accent';  // Dark
    }

  }

  onChangeValue(val: any) {
    this.debounce.next(val);
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

}
