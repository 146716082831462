export type Status = "" | "basic" | "primary" | "info" | "success" | "warning" | "danger" | "control";
export type Size = 'tiny' | 'small' | 'medium' | 'large' | 'giant';
export const HEADER_SCROLL_OFFSET = 64;

export const FontFamilyDefault = 'Open Sans, sans-serif';

// Tienen que estar en concordancia con theme.scss
export const ColorStatusBasic = '#c5cee0';
export const ColorStatusDanger = '#ff3d71';
export const ColorStatusWarning ='#ffaa00';
export const ColorStatusInfo = '#0095ff';
export const ColorStatusSuccess = '#00d68f';
export const ColorStatusPrimary = '#006DFF';
export const ColorStatusHint = '#8f9bb3';
export const ColorBackground = '#222b45';  // Card background
export const ColorPurple = '#A74AC7';

export const ThemeVariablesDefault = {
  "fontMain": "Open Sans, sans-serif",
  "fontSecondary": "Raleway, sans-serif",
  "bg": "#ffffff",
  "bg2": "#f7f9fc",
  "bg3": "#edf1f7",
  "bg4": "#e4e9f2",
  "border": "#ffffff",
  "border2": "#f7f9fc",
  "border3": "#edf1f7",
  "border4": "#e4e9f2",
  "border5": "#c5cee0",
  "fg": "#8f9bb3",
  "fgHeading": "#1a2138",
  "fgText": "#1a2138",
  "fgHighlight": "#006DFF",
  "layoutBg": "#f7f9fc",
  "separator": "#edf1f7",
  "primary": "#006DFF",
  "success": "#00d68f",
  "info": "#0095ff",
  "warning": "#ffaa00",
  "danger": "#ff3d71",
  "primaryLight": "#598bff",
  "successLight": "#2ce69b",
  "infoLight": "#42aaff",
  "warningLight": "#ffc94d",
  "dangerLight": "#ff708d"
};

export const ThemeVariablesDark = {
  "fontMain": "Open Sans, sans-serif",
  "fontSecondary": "Raleway, sans-serif",
  "bg": "#222b45",
  "bg2": "#1a2138",
  "bg3": "#151a30",
  "bg4": "#101426",
  "border": "#222b45",
  "border2": "#1a2138",
  "border3": "#151a30",
  "border4": "#101426",
  "border5": "#101426",
  "fg": "#8f9bb3",
  "fgHeading": "#ffffff",
  "fgText": "#ffffff",
  "fgHighlight": "#3366ff",
  "layoutBg": "#1b1b38",
  "separator": "#1b1b38",
  "primary": "#3366ff",
  "success": "#00d68f",
  "info": "#0095ff",
  "warning": "#ffaa00",
  "danger": "#ff3d71",
  "primaryLight": "#598bff",
  "successLight": "#2ce69b",
  "infoLight": "#42aaff",
  "warningLight": "#ffc94d",
  "dangerLight": "#ff708d"
};

export let ThemeVariablesCurrent = ThemeVariablesDark;

export function changedGlobalTheme(currentTheme: string) {
  if (currentTheme == 'default') {
    ThemeVariablesCurrent = ThemeVariablesDefault;
  } else {
    ThemeVariablesCurrent = ThemeVariablesDark;
  }
}
