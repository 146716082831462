

export function JsonParse(datos: string) {
  // Si es un objeto
  // Quitamos comillas
  try {
    if (datos.startsWith('"')) {
      datos = JSON.parse(datos);
    }
    // datos = trimChar(datos, '"');
    // @ts-ignore
    if (typeof datos === 'string' || datos instanceof String) {
      if (datos.startsWith('{') || datos.startsWith('[')) {
        // datos = datos.replace( /\\+"/g, '\\"');
        return JSON.parse(datos);
      }
      return datos;
    } else {
      // Como string
      return datos;
    }
  } catch(e) {
    console.error("JsonParse => ", datos);
    throw e;
  }

}


// Mantiene el orden de creación de las propieades de los objects
export function JsonStringify(value: any, onlyOwnProperties: boolean = false): string {
  if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
    const order = Object.keys(value);
    if (order.length > 0) {
      const jsonKeys: string[] = [];
      order.forEach( (key) => {
        if (onlyOwnProperties && !value.hasOwnProperty(key)) {
          return;
        } else {
          // Convertimos undefined en nulos para que vayan en el json
          let keyVal = value[key];
          if (keyVal === undefined) {
            keyVal = null;
          }
          jsonKeys.push('"' + key + '":' + JSON.stringify(keyVal));
        }
      });

      return '{' + jsonKeys.join(',') + '}';
    }
  }
  return JSON.stringify(value);
}
