
import {SaveUserConfigRequest, SaveUserConfigResponse, UserConfig} from "../models/user";
import {Authentication} from "./authentication";
import {GetGlobalEventManager} from "./bus-configuration";
import {
  ListColectivosRequest,
  ListColectivosResponse,
  ModifyColectivoRequest,
  ModifyColectivoResponse
} from "../models/colectivo";
import {GenericRequest, GenericResponse} from "../models/generic";
import {
  ListCredentialsRequest,
  ListCredentialsResponse,
  ModifyCredentialsRequest,
  ModifyCredentialsResponse
} from "../models/credentials";
import {ActionRequest, ActionResponse} from "../models/action";

export class Backend {


  static configRequestUpdate(): Promise<UserConfig | undefined> {
    return new Promise<UserConfig | undefined>( (resolve, reject): void => {
      const request: SaveUserConfigRequest = new SaveUserConfigRequest();
      request.what = 'none';
      Backend.saveUserConfigRequest(request).then( (response: SaveUserConfigResponse): void => {
        if (response.error == '') {
          resolve(response.config)
        } else {
          resolve(undefined);
        }
      });
    });

  }

  // User config
  static saveUserConfigRequest(request: SaveUserConfigRequest): Promise<SaveUserConfigResponse> {
    return new Promise<SaveUserConfigResponse>( (resolve, reject): void => {
      const response: SaveUserConfigResponse = new SaveUserConfigResponse();
      response.sendRequest("save_user_config", request).then( (): void => {
        if (response.error == '') {
          // Guardamos la nueva configuración en auth
          if (Authentication.getAuth() && response.config) {
            Authentication.getAuth().updateConfig(response.config);
            GetGlobalEventManager().newConfigEvent.next(response.config);
          }
        }
        resolve(response);
      });
    });
  }

  static resetBadge(badge: string): Promise<SaveUserConfigResponse> {
    const request: SaveUserConfigRequest = new SaveUserConfigRequest();
    request.what = 'updateBadge';
    request.config = new UserConfig();
    const aBadges = new Array<[string, string]>();
    // @ts-ignore
    aBadges[badge] = '';
    request.config.badges = aBadges;
    return this.saveUserConfigRequest(request);
  }

  static updateConfigStorage(key: string, value: string): Promise<SaveUserConfigResponse> {
    const request: SaveUserConfigRequest = new SaveUserConfigRequest();
    request.what = 'updateStorage';
    request.config = new UserConfig();
    const storage = new Array<[string, string]>();
    // @ts-ignore
    storage[key] = value;
    request.config.storage = storage;
    return this.saveUserConfigRequest(request);
  }


  static listColectivos(): Promise<ListColectivosResponse> {
    const request: ListColectivosRequest = new ListColectivosRequest();
    return new Promise<ListColectivosResponse>( (resolve, reject): void => {
      const response: ListColectivosResponse = new ListColectivosResponse();
      response.sendRequest("list_colectivos", request).then( (): void => {
        resolve(response);
      });
    });
  }

  static modifyColectivo(request: ModifyColectivoRequest): Promise<ModifyColectivoResponse> {
    return new Promise<ModifyColectivoResponse>( (resolve, reject): void => {
      const response: ModifyColectivoResponse = new ModifyColectivoResponse();
      response.sendRequest("modify_colectivo", request).then( (): void => {
        resolve(response);
      });
    });
  }

  static generic(request: GenericRequest): Promise<GenericResponse> {
    return new Promise<GenericResponse>( (resolve, reject): void => {
      const response: GenericResponse = new GenericResponse();
      response.sendRequest("generic", request).then( (): void => {
        resolve(response);
      });
    });
  }
  static genericAuth(request: GenericRequest): Promise<GenericResponse> {
    return new Promise<GenericResponse>( (resolve, reject): void => {
      const response: GenericResponse = new GenericResponse();
      response.sendRequest("generic_auth", request).then( (): void => {
        resolve(response);
      });
    });
  }

  static modifyCredentials(request: ModifyCredentialsRequest): Promise<ModifyCredentialsResponse> {
    return new Promise<ModifyCredentialsResponse>( (resolve, reject): void => {
      const response: ModifyCredentialsResponse = new ModifyCredentialsResponse();
      response.sendRequest("modify_credentials", request).then( (): void => {
        resolve(response);
      });
    });
  }

  static listCredentials(request: ListCredentialsRequest): Promise<ListCredentialsResponse> {
    return new Promise<ListCredentialsResponse>( (resolve, reject): void => {
      const response: ListCredentialsResponse = new ListCredentialsResponse();
      response.sendRequest("list_credentials", request).then( (): void => {
        resolve(response);
      });
    });
  }

  static action(request: ActionRequest): Promise<ActionResponse> {
    return new Promise<ActionResponse>( (resolve, reject): void => {
      const response: ActionResponse = new ActionResponse();
      response.sendRequest("action", request).then( (): void => {
        resolve(response);
      });
    });
  }


}
