<div class="pb-1" [ngClass]="{'slider-thumb-up': showThumbLabel}">
  <div>
    <label *ngIf="label" [class.disabled]="disabled" [for]="id" class="form-label mb-1">{{label}}</label>
  </div>
  <app-sizedbox *ngIf="showThumbLabel" height="1rem"></app-sizedbox>
  <mat-slider [id]="id" class="slider"
              [ngClass]="{'cursor-disable-events': disabled}"
              [displayWith]="formatLabel"
              [color]="color"
              [discrete]="showThumbLabel"
              [showTickMarks]="showTickMarks"
              max="{{max}}" min="{{min}}"
              >
    <input matSliderThumb [(ngModel)]="value" (input)="onChangeValue(volume.value)" #volume>
  </mat-slider>
  <div *ngIf="help" class="form-help">{{help}}</div>
</div>
