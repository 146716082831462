import {BaseClass} from "../@core/@models/common";
import {GrpcRequest, GrpcResponse, Origin, OriginEmpty} from "../@core/@models/grpc";
import {Pagination} from "./pagination";
import {Colectivo} from "./colectivo";

export class Calendario extends BaseClass{

  id: string = '';
  titulo: string = '';
  descripcion: string = '';
  publicado: string = '';
  colectivo: string = '';
  colectivo_row?: Colectivo;

  constructor(asObject?: object) {
    super();
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }


  static listCalendario(request: ListCalendarioRequest): Promise<ListCalendarioResponse> {
    return new Promise<ListCalendarioResponse>( (resolve, reject): void => {
      const response: ListCalendarioResponse = new ListCalendarioResponse();
      response.sendRequest("list_calendario", request).then( (): void => {
        resolve(response);
      });
    });
  }


  static modifyCalendario(request: ModifyCalendarioRequest): Promise<ModifyCalendarioResponse> {
    return new Promise<ModifyCalendarioResponse>( (resolve, reject): void => {
      const response: ModifyCalendarioResponse = new ModifyCalendarioResponse();
      response.sendRequest("modify_calendario", request).then( (): void => {
        resolve(response);
      });
    });
  }

}


export class ModifyCalendarioRequest extends GrpcRequest {

  data?: Calendario;
  operation: string = '';

  origin: Origin = OriginEmpty;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

}


export class ModifyCalendarioResponse extends GrpcResponse {
  data?: Calendario;
  error: string = '';

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }
}


export class ListCalendarioRequest extends GrpcRequest {
  search?: string = '';
  filter?: Calendario;
  pagination?: Pagination;
  origin: Origin = OriginEmpty;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

}


export class ListCalendarioResponse extends GrpcResponse {

  data?: Array<Calendario>;
  error: string = '';
  pagination?: Pagination;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

}

