<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card class="dialog-wrapper" [status]="status" [accent]="accent">
    <nb-card-header>
      <div class="confirm-title">{{title}}</div>
    </nb-card-header>
    <nb-card-body>
      <div class="confirm-body">
        <ng-content></ng-content>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="flex-space-between">
        <app-button label="{{labelConfirmar}}" size="small" status="success" (onPress)="onAceptar()"></app-button>
        <app-button label="{{labelCancelar}}" size="small"  status="danger" (onPress)="onCancelar()"></app-button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

