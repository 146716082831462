import {Inject, Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {getTime, getUUID}   from '../@models/common';
import {StorageService} from "./storage.service";



@Injectable({
  providedIn: 'root'
})

export class GuidService {
  storageEnabled: boolean;
  ComputerIdCookieName = "f342d63688130afd2cb676a87ddd";
  constructor(@Inject(Window) private window: Window, private cookieService: CookieService, private storage: StorageService ) {
    this.storageEnabled = storage.isLocalStorageEnabled();
  }

  regenerate() {
    if (!this.storageEnabled) {
      return;
    }
    const guid = this.createUniq();
    sessionStorage.setItem('GUID', guid);
    // @ts-ignore
    this.window.top.name = guid;

    // Ponemos el cookie
    this.setCOOKIE('GUID', guid);
  }

  getComputerId(): string {
    let computerId = this.getCOOKIE(this.ComputerIdCookieName);
    if (!computerId || computerId == "") {
      computerId = getUUID();
      this.setCOOKIE(this.ComputerIdCookieName, computerId);
    }
    return computerId;
  }

  getGUID(): string {
    if (!this.storageEnabled) {
      return "null";
    }

    let guid = sessionStorage.getItem('GUID');
    if (!guid) {
      this.setGUID();
      guid = sessionStorage.getItem('GUID');
    }
    return guid ?? "null";

  }

  createUniq(): string {
    return 'GUID-' + getUUID() + '-' + getTime();
  }

  setGUID() {

    if (!this.storageEnabled) {
      return;
    }

    let guid;

    // @ts-ignore
    if (this.window.top.name.match(/^GUID-/)) {
      // @ts-ignore
      guid = this.window.top.name;
    } else {
      guid = this.createUniq();
    }
    sessionStorage.setItem('GUID', guid);
    // @ts-ignore
    this.window.top.name = guid;

  }

  setCOOKIE(name: string, value: string) {
    // 10 años
    this.cookieService.set( name, value, 3650, '/', undefined , true, 'None');
  }

  getCOOKIE(name: string): string {
    return this.cookieService.get( name );
  }

  delCOOKIE(name: string) {
    this.cookieService.delete( name );
  }

  // Cuando cambia de pestaña (suscripción a evento window.onfocus)
  onFocus(event?: any) {
    this.setGUID();
  }

}
