import {Injectable} from '@angular/core';
import {BusService} from './bus.service';
import {environment} from '../../../environments/environment';
import {JsonStringify} from "../@models/json";
import {RemoteLog} from "../@models/log";


@Injectable({
  providedIn: 'root'
})

export class LoggerService {

  // Para el logger
  private logToRemote = true;
  private debugActivated = true;
  private bus?: BusService;

  constructor() {
    if (this.isProduction()) {
      this.debugActivated = false;
    }
  }

  init( bus: BusService) {
    // @ts-ignore
    this.bus = bus;
  }

  isProduction(): boolean {

    if (environment.production) {
      // for production
      return true;
    } else {
      // for development
      return false;
    }
  }

  private addZero(i: any) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }

  private getCurrentTime(): string {
    const today = new Date();
    let h = today.getHours();
    let m = today.getMinutes();
    let s = today.getSeconds();

    h = this.addZero(h);
    m = this.addZero(m);
    s = this.addZero(s);

    return h + ':' + m + ':' + s;
  }

  setLogToRemote(val: boolean) {
    this.logToRemote = val;
  }

  setDebug(val: boolean) {
    this.debugActivated = val;
  }
  getDebug(): boolean {
    return this.debugActivated;
  }

  getCurrentSession(): string {
    const res = 'not_logged';
    if (!this.bus) {
      return res;
    }
    return this.bus.configuration.getSessionId();
  }

  debug(str: string, ...args: any[]) {
    if (this.debugActivated) {
      console.log(this.getCurrentTime() + ' [' + this.getCurrentSession()  + ']  DEBUG: ' + str, ...args);
      if (this.logToRemote) {
        this.remoteDebug(str, ...args);
      }
    }
  }

  debugBrowser(str: string, ...args: any[]) {
    if (this.debugActivated) {
      console.log(this.getCurrentTime() + ' [' + this.getCurrentSession()  + '] DEBUG: ' + str, ...args);
    }
  }
  noticeBrowser(str: string, ...args: any[]) {
    // tslint:disable-next-line:no-console
    console.info(this.getCurrentTime() + ' [' + this.getCurrentSession()  + '] NOTICE: ' + str, ...args);
  }
  errorBrowser(str: string, ...args: any[]) {
    console.error(this.getCurrentTime() + ' [' + this.getCurrentSession()  + '] DEBUG: ' + str, ...args);
  }

  errorBrowserWithStack(str: string, ...args: any[]) {
    console.error(this.getCurrentTime() + ' [' + this.getCurrentSession()  + '] DEBUG: ' + str, this.stackTrace(), ...args);
  }

  warningBrowser(str: string, ...args: any[]) {
    console.warn(this.getCurrentTime() + ' [' + this.getCurrentSession()  + '] DEBUG: ' + str, ...args);
  }

  warning(str: string, ...args: any[]) {
    console.warn(this.getCurrentTime() + ' [' + this.getCurrentSession()  + '] WARNING: ' + str, ...args);
    if (this.logToRemote) {
      this.remoteWarning(str, ...args);
    }
  }

  error(str: string, ...args: any[]) {
    console.error(this.getCurrentTime() + ' [' + this.getCurrentSession()  + '] ERROR: ' + str, ...args);
    if (this.logToRemote) {
      this.remoteError(str, ...args);
    }
  }

  errorWithStack(str: string, ...args: any[]) {
    console.error(this.getCurrentTime() + ' [' + this.getCurrentSession()  + '] ERROR: ' + str,this.stackTrace(), ...args);
    if (this.logToRemote) {
      this.remoteError(str, ...args);
    }
  }

  stackTrace(): string {
    const err = new Error();
    return err.stack || '';
  }


  private remoteDebug(str: string, ...args: any[]) {
    this.remote('DEBUG', str, ...args);
  }
  private remoteWarning(str: string, ...args: any[]) {
    this.remote('WARNING', str, ...args);
  }
  private remoteError(str: string, ...args: any[]) {
    this.remote('ERROR', str, ...args);
  }


  private remote(tipo: string, str: string, ...args: any[]) {

    let finalString = tipo + ': ' + str;
    args.map( (a) => {
      finalString += '\n\r' + JsonStringify(a);
    });

    RemoteLog(tipo, finalString);

  }
}
