import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Status} from "../../../@models/theme";

@Component({
  selector: 'app-hr',
  templateUrl: './hr.component.html',
  styleUrls: ['./hr.component.scss']
})
export class HrComponent implements OnChanges {
  @Input() status: Status = '';
  @Input() height: string = '';
  @Input() padding: string = '0';
  classLine: string = '';

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.status != '') {
      this.classLine = 'theme-border-' + this.status;
    }
  }

}
