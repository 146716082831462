import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IconSearch} from "../../../@models/icons";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {getUUID} from "../../../@models/common";
import {Option} from "../../../@models/types";
import {listAnimationLeft} from "../../../@angular/animations/list-animation-left";
import {listAnimationRight} from "../../../@angular/animations/list-animation-right";


@Component({
  selector: 'app-two-column-list',
  templateUrl: './two-column-list.component.html',
  styleUrls: ['./two-column-list.component.scss'],
  animations: [listAnimationLeft, listAnimationRight],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TwoColumnListComponent,
    multi: true
  }]
})
export class TwoColumnListComponent implements OnInit,OnChanges, ControlValueAccessor {
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() height?: string;
  @Input() searchable: boolean = true;
  @Input() labelLeft: string = '';
  @Input() labelRight: string = '';
  @Input() options: Option[] = [];
  @Input() value: Option[] = [];
  @Input() HeightVisibleOffset?: string;
  @Output() onChange = new EventEmitter<Option[]>();

  list: Option[] = [];
  listFiltered: Option[] = [];
  currentValue: Option[] = [];

  iconSearch = IconSearch;
  search: string = '';

  itemsLoaded: boolean = false;
  animatingLeft: boolean = false;
  animatingRight: boolean = false;

  private onTouched!: Function;
  private onChanged!: Function;

  id: string = getUUID();

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    // this.itemsLoaded = false;

    if (changes["options"]) {
      this.list = [...this.options];
      this.onSearch(this.search);
    }
    if (changes["value"]) {
      this.mixCurrentValue(this.value);
    }

    setTimeout( () => {
      this.itemsLoaded = true;
    }, 50);

  }

  mixCurrentValue(value: Option[]) {
    // Añadimos los nuevos y quitamos los que no vengan de uno en uno para que no anime la lista completa
    const nuevos: Option[] = [];
    const borrados: number[] = [];

    // Nuevos
    value.forEach( (itemNew) => {
      let existe: boolean = false;
      this.currentValue.forEach((itemOld) => {
        if (itemOld.value == itemNew.value) {
          existe = true;
        }
      })
      if (!existe) {
        nuevos.push(itemNew);
      }
    });

    // Borrados
    this.currentValue.forEach( (itemNew, index) => {
      let existe: boolean = false;
      value.forEach((itemOld) => {
        if (itemOld.value == itemNew.value) {
          existe = true;
        }
      })
      if (!existe) {
        borrados.push(index);
      }
    });

    borrados.forEach( (index) => {
      this.currentValue.splice(index, 1);
    });

    nuevos.forEach( (itemNew) => {
      this.currentValue.push(itemNew);
    });
  }

  onLeftAnimate(val: boolean) {
    this.animatingLeft = val;
  }
  onRightAnimate(val: boolean) {
    this.animatingRight = val;
  }

  onAdd(item: Option, index: number) {
    this.listFiltered.splice(index,1);
    this.currentValue.unshift(item);
    this.onChangeValue(this.currentValue);
    this.onChange.emit(this.currentValue);
  }
  onDel(item: Option, index: number) {
    this.currentValue.splice(index,1);
    this.listFiltered.unshift(item);
    this.onChangeValue(this.currentValue);
    this.onChange.emit(this.currentValue);
  }

  onChangeValue(val: any) {
    if (this.onTouched) {
      this.onTouched();
    }

    if (this.onChanged) {
      this.onChanged(val);
    }
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.mixCurrentValue(obj);
    } else {
      this.mixCurrentValue([]);
    }
  }

  onSearch(value: string) {
    this.search = value;

    // Filtramos
    if (this.search == '') {
      this.listFiltered = [...this.options];
      this.removeSelected();
      return;
    }
    let searchLower = this.search.toLowerCase();
    this.listFiltered = this.options.filter( (item) => {
      if (item.label) {
        if (item.label.toLowerCase().indexOf(searchLower) >= 0) {
          return true;
        }
      }
      return false;
    });
    this.removeSelected();
  }


  removeSelected() {
    // Quitamos los seleccionados
    this.listFiltered = this.listFiltered.filter( (item) => {
      for (let i = 0 ; i < this.currentValue.length; i++) {
        if (this.currentValue[i].value == item.value) {
          return false;
        }
      }
      return true;
    });
  }

}


