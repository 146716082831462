<nb-card>
  <nb-card-header>
    <div class="flex-space-between">
      <span>{{title}}</span>
      <app-header-action [icon]="iconClose" (onClick)="onCloseClick()"></app-header-action>
    </div>
  </nb-card-header>
  <nb-card-body>
    <nb-calendar *ngIf="!hasTime" [visibleDate]="dateObject"
                 [date]="dateObject"
                 [max]="maxDate"
                 [min]="minDate"
                 (dateChange)="dateChange($event)" ></nb-calendar>

    <nb-calendar-with-time *ngIf="hasTime"
                           [visibleDate]="dateObject"
                           [date]="dateObject"
                           [singleColumn]="singleColumn"
                           [step]="stepHours"
                           [title]="hourTitle"
                           [twelveHoursFormat]="false"
                           [showCurrentTimeButton]="true"
                           currentTimeButtonText="Ahora"
                           applyButtonText="Seleccionar"
                           (dateChange)="dateChange($event)">

    </nb-calendar-with-time>
  </nb-card-body>
</nb-card>
