import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Icon, IconDate} from "../../../@models/icons";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {getUUID} from "../../../@models/common";
import {DateFormat} from "../../../@models/date";

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputDateComponent,
    multi: true
  }]
})
export class InputDateComponent implements OnInit, ControlValueAccessor {
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() help?: string;
  @Input() disabled: boolean = false;
  @Input() onlyDate?: boolean;
  @Input() weekDay: boolean = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  value: any;  // No se pasa como parámetro, poner un formControl
  humanDate?: string;
  asDate: boolean = false;
  backendValue: string = DateFormat.fromNow().toBackendDate();

  private onTouched!: Function;
  private onChanged!: Function;

  id: string = getUUID();


  iconDate: Icon = IconDate;

  constructor() { }

  ngOnInit(): void {

  }

  toHuman(val?: any) {
    try {
      if (val == undefined) {
        val = this.value;
      }
      let df;
      if (this.asDate) {
        df = DateFormat.fromJSDate(val);
      } else {
        df = DateFormat.fromBackend(val);
      }
      if (this.onlyDate) {
        this.humanDate = DateFormat.fromBackend(df.toBackendDate()).toHuman(this.weekDay);
      } else {
        this.humanDate = DateFormat.fromBackend(df.toBackendDateTime()).toHuman(this.weekDay);
      }

    } catch (e) {
      this.humanDate = '';
    }

  }
  toBackend() {
    let df;
    if (this.value instanceof Date) {
      df = DateFormat.fromJSDate(this.value);
    } else {
      df = DateFormat.fromBackend(this.value);
    }
    if (this.onlyDate) {
      this.backendValue = df.toBackendDate();
    } else {
      this.backendValue = df.toBackendDateTime();
    }

  }


  onDateChange(val: any) {

    if (this.asDate) {
      val = DateFormat.fromBackend(val).toJSDate();
    }

    this.onTouched();
    this.onChanged(val);
    this.toHuman(val);

    this.onChange.emit(val);
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
    if(this.value) {
      // Notificamos que hemos cambiado el valor
      this.onChanged(this.value);
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (!obj) {
      return;
    }
    if (obj instanceof Date) {
      this.asDate = true;
    }

    if (this.onlyDate == undefined) {
      if (this.asDate) {
        this.onlyDate = false;
      } else {
        const df = DateFormat.fromBackend(obj);
        this.onlyDate = !df.hasTime;
      }
    }

    this.value = obj;

    this.toBackend();

    this.toHuman();
  }


}
