import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {getUUID} from "../../../@models/common";


@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CheckComponent,
    multi: true
  }]
})
export class CheckComponent implements OnInit , ControlValueAccessor {

  @Input() disabled: boolean = false;
  @Input() label?: string;

  value: any;  // No se pasa como parámetro, poner un formControl

  private onTouched!: Function;
  private onChanged!: Function;

  id: string = getUUID();

  constructor() { }

  ngOnInit(): void {
    if (this.value) {
      this.onChanged(this.value);
    }
  }

  onChange(val: any) {
    this.onTouched();
    this.onChanged(val);
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

}
