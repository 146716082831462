import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Icon, IconAdd, IconCheckList, IconDelete, MatIcon} from "../../../@models/icons";
import {NbPosition, NbTrigger} from "@nebular/theme";
import {Option} from "../../../@models/types";
import {Status} from "../../../@models/theme";
import {PopoverComponent} from "../popover/popover.component";
import {clone} from "../../../@models/lodash";

@Component({
  selector: 'app-multi-option',
  templateUrl: './multi-option.component.html',
  styleUrls: ['./multi-option.component.scss']
})
export class MultiOptionComponent implements OnInit, OnChanges {

  @Input() icon: Icon = IconCheckList;
  @Input() noIcon: Icon = MatIcon('person_search');
  @Input() disabled: boolean = false;
  @Input() options: Option[] = [];
  @Input() multiple: boolean = false;
  @Input() allowNew: boolean = false;
  @Input() placement: NbPosition = NbPosition.BOTTOM_END;
  @ViewChild(PopoverComponent) popoverComponent?: PopoverComponent;

  @Output() onChange: EventEmitter<Option[]> = new EventEmitter<Option[]>();
  @Output() onNew: EventEmitter<string> = new EventEmitter<string>();

  tooltip = "";
  trigger = NbTrigger.CLICK;
  status: Status = "basic";
  badgeStatus?: Status = "warning";
  iconNew: Icon = IconAdd;
  iconDelete = MatIcon('delete_sweep');
  newValue = "";
  newValueEdited = "";

  cuantasSelected: number = 0;

  optionsCopy: Option[] = [];

  constructor() { }

  ngOnInit(): void {
    this.init();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  init() {
    this.newValueEdited = '';
    this.optionsCopy = clone(this.options); // Para no modificar el original
    if (this.multiple) {
      this.tooltip = "Seleccione alguna opción";
    } else {
      this.tooltip = "Seleccione una opción";
    }
    this.checkStatus();
  }

  checkStatus() {
    // Si hay opciones, status = primary, sino basic
    if (this.optionsCopy.length > 0) {
      this.status = "primary";
    } else {
      this.status = "basic";
    }
    this.cuentaSelected();
    if (this.cuantasSelected > 0) {
      this.badgeStatus = "warning";
    } else {
      this.badgeStatus = undefined;
    }

    if (this.cuantasSelected > 0) {
      this.tooltip = '';
      const selArray: string[] = [];
      this.optionsCopy.forEach( (opt) => {
        if (opt.selected) {
          selArray.push(opt.label);
        }
      });
      this.tooltip += selArray.join(', ');
    }

  }

  cuentaSelected() {
    this.cuantasSelected = 0;
    this.optionsCopy.forEach( (opt) => {
      if (opt.selected) {
        this.cuantasSelected++;
      }
    });
  }

  onOptionCheck(index: number) {
    this.optionsCopy[index].selected = !this.optionsCopy[index].selected;

    // Si no es multiple, cerramos
    if (!this.multiple) {
      this.checkStatus();
      this.closePopover();
      this.onChange.emit([this.optionsCopy[index]]);
    } else {
      this.cuentaSelected();
    }
  }

  closePopover() {
    if (this.popoverComponent) {
      this.popoverComponent.popover?.hide();
    }
  }

  onOK() {
    const opcionesSeleccionadas = this.optionsCopy.filter( (opt) => {
      return opt.selected;
    } );
    this.checkStatus();
    this.closePopover();
    this.onChange.emit(opcionesSeleccionadas);
  }

  onChangeVisibility(visible: boolean) {
    if (!visible) {
      // Restauramos las opciones originales
      this.init();
    }
  }

  onNewValueChange(value: string) {
    this.newValueEdited = value;
  }

  onAdd() {
    this.checkStatus();
    this.closePopover();
    this.onNew.emit(this.newValueEdited);
    this.newValueEdited = '';
  }

  onClear() {
    this.checkStatus();
    this.closePopover();
    this.onChange.emit([]);
  }

}
