import {Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-flip',
  templateUrl: './flip.component.html',
  styleUrls: ['./flip.component.scss']
})
export class FlipComponent implements OnChanges{

  @Input()
  @HostBinding('class.flipped')
  flipped: boolean = false;

  @Input() toggled: boolean = false;

  toggle() {
    this.flipped = !this.flipped;
  }
  ngOnChanges(changes: SimpleChanges) {
    this.flipped = this.toggled;
  }
}


@Component({
  selector: 'app-flip-front',
  template: '<ng-content></ng-content>',
})
export class FlipFrontComponent { }

/**
 * Component intended to be used within the `<nb-flip-card>` and `<nb-reveal-card>` components.
 *
 * Use it as a container for the back card.
 */
@Component({
  selector: 'app-flip-back',
  template: '<ng-content></ng-content>',
})
export class FLipBackComponent { }
