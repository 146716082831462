import { NbMenuItem } from '@nebular/theme';
import {MatIcon} from "./@core/@models/icons";

export function getMenuAsesor(): NbMenuItem[] {
  const menu: NbMenuItem[] = [
    {
      title: $localize`Cuadro de mandos`,
      icon: MatIcon('dashboard'),
      link: '/asesor/cuadro-mandos',
    },


    {
      title: $localize`Clientes conectados`,
      icon: MatIcon('sensor_occupied'),
      link: '/asesor/usuarios-conectados',
    },



  ];
  return menu;
}
