import {Inject, Injectable} from '@angular/core';
import {BusService} from './bus.service';

import {getTime} from '../@models/common';
import {clone} from '../@models/lodash';
import {JsonStringify} from "../@models/json";


const DEBUG = true;

export type resolver = (value?: any | PromiseLike<any>) => void;
export type backendLoader = (key: string | number, args?: any) => Promise<any>;


@Injectable({
  providedIn: 'root'
})

// Para mantener aquí todas las variables globales
export class GlobalsService  {

  private store: any;
  private cache: any;
  private cacheReferences: any;

  private runningConcurrent: { [key: string]: Map<string, resolver[]> } = {};

  // @ts-ignore
  bus: BusService;

  constructor(@Inject(Window) private window: Window) {
    this.store = {};
    this.cache = {};
    this.cacheReferences = {};
  }

  cloneForCopy(value: any) {
    return clone(value);
  }

  init(bus: BusService) {
    this.bus = bus;

    this.loadFromCacheAll();
  }

  onLogin() {
    // Variables que se necesitan cuando el usuario hace login
    if (DEBUG) {
      this.bus.logger.debugBrowser('**** GlobalsService: onLogin');
    }
  }



  set(name: string, value: any, persistent: boolean = true): void {

    const cloneValue = this.cloneForCopy(value);

    if (DEBUG) {
      this.bus.logger.debugBrowser('**** GlobalsService: asignando variable ' + name, value);
    }

    let toString = "";
    if (persistent) {
      // comprobamos que se puede aplicar JsonStringify
      try {
        toString = JsonStringify(cloneValue);
      } catch (exception) {
        this.bus.logger.warning('globals.set se intenta grabar como persistente un contenido que no permite usar JsonStringify', value);

        // No se puede almacenar en storage del navegador
        persistent = false;
      }
    }

    this.store[name] = { value: cloneValue, persistent: persistent};

    if (persistent) {
      // Guardamos en cache por si actualiza el navegador

      this.saveToCacheAsString(name, toString);
    }
  }

  del(name: string): void {
    if (DEBUG) {
      this.bus.logger.debugBrowser('**** GlobalsService: borrando variable ' + name);
    }

    let persistent = false;
    if (this.exist(name)) {
      persistent = this.store[name].persistent;
    }

    delete this.store[name];

    if (persistent) {
      // Guardamos en cache por si actualiza el navegador
      this.removeFromCache(name);
    }
  }

  get(name: string, defecto?: any): any | undefined {

    // Si pide alguna de las que tenemos en la instancia
    if (name === 'window') {
      return this.window;
    }

    if (this.store.hasOwnProperty(name)) {
      // Clonamos el valor para que la copia que se guarde no se modifique posteriormente (por las referencias)
      const cloneValue = this.cloneForCopy(this.store[name].value);
      return cloneValue;
    }

    if (defecto !== undefined) {
      return defecto;
    }

    this.bus.logger.warning('GlobalsService: Intentando acceder a una variable global inexistente: ' + name);
    return undefined;
  }

  exist(name: string): boolean {
    return this.store.hasOwnProperty(name);
  }

  loadFromCacheAll(): boolean {
    const aKeysStr = this.bus.storage.sessionGet('globals');
    if (aKeysStr) {
      let aKeys = [];
      try {
        aKeys = JSON.parse(aKeysStr);
      } catch (exception) {
        this.bus.logger.warning('globals.loadFromCacheAll: error en JSON.parse para ', aKeysStr);
        return false;
      }
      if (aKeys && typeof aKeys.forEach === 'function') {
        // Añadimos a lo existente
        aKeys.forEach( (key: string) => {
          this.loadFromCache(key);
        });
        return true;
      }
    }
    return false;
  }

  saveToCacheAll() {
    const aKeys = [];
    for (const key in this.store) {
      if (this.store.hasOwnProperty(key)) {
        if (this.store[key].persistent) {
          let toString;
          try {
            toString = JsonStringify(this.store[key].value);
            this.saveToCacheAsString(key, toString);
            aKeys.push(key);
          } catch (exception) {
            this.bus.logger.warning('globals.saveToCacheAll se intenta grabar como persistente un contenido que no permite usar JsonStringify', this.store[key].value);
          }
        }
      }
    }
    try {
      this.bus.storage.sessionSet('globals', JsonStringify(aKeys));
    } catch (exception) {
      this.bus.logger.warning('globals.saveToCacheAll: error en JsonStringify para ', aKeys);
    }
  }

  saveToCacheAsString(name: string, valueAsString: string) {
    this.bus.storage.sessionSet('globals-' + name, valueAsString);
  }

  removeFromCache(name: string) {
    this.bus.storage.sessionDel('globals-' + name);
  }

  loadFromCache(name: string): any | undefined {
    const storeStr = this.bus.storage.sessionGet('globals-' + name);
    if (storeStr) {
      let storeData;
      try {
        storeData = JSON.parse(storeStr);
      } catch (exception) {
        this.bus.logger.warning('globals.loadFromCache: error en JSON.parse para ' + name, storeStr);
        return undefined;
      }
      if (storeData) {
        this.store[name] = { value: storeData, persistent: true};
        return storeData;
      }
    }
    return undefined;
  }

  private getCacheKey(name: string | number, args: any = null) {
     return name + '|' + JsonStringify(args);
  }
  private getCacheKeyPrefix(name: string | number) {
    return name + '|';
  }

  cacheSet(name: string, value: any, expireSeconds: number, args: any = null, reference: string | number = ''): void {

      // Clonamos el valor para que la copia que se guarde no se modifique posteriormente (por las referencias)
      const cloneValue = this.cloneForCopy(value);

      let referenceName = name;
      if (reference + '' !== '') {
        referenceName = reference + '';
      }

      const key = this.getCacheKey(name, args);
      if (!this.cacheReferences.hasOwnProperty(referenceName)) {
        this.cacheReferences[referenceName] = {};
      }
      this.cacheReferences[referenceName][key] = true;
      this.cache[key] = {
        expireTime: getTime() + expireSeconds * 1000,
        args: args,
        value: cloneValue
      };
  }

  cacheGet(name: string, args: any = null): any | undefined {
    const key = this.getCacheKey(name, args);
    if (this.cache.hasOwnProperty(key)) {
      const element = this.cache[key];

      // Ha expirado?
      if (getTime() > element.expireTime) {
        return undefined;
      }

      // Retornamos el valor clonado para que no modiquen el valor cacheado por las referencias
      return this.cloneForCopy(element.value);
    }
    return undefined;
  }





  cacheDel(name: string, args: any = null) {

    this.bus.logger.debugBrowser('cacheDel: ' + name, args);

    const key = this.getCacheKey(name, args);
    delete this.cache[key];

    if (this.cacheReferences.hasOwnProperty(name)) {
      delete this.cacheReferences[name][key];
    }
  }

  cacheDelAllByName(name: string) {
    this.bus.logger.debugBrowser('cacheDelAllByName: ' + name);
    if (this.cacheReferences.hasOwnProperty(name)) {
      const all = this.cacheReferences[name];
      for (const key in all) {
        if (all.hasOwnProperty(key)) {
           delete all[key];
           delete this.cache[key];
        }
      }
      delete this.cacheReferences[name];
    }
  }


}
