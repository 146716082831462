import * as uuid from 'uuid';
import {ɵisObservable as isObservable, ɵisPromise as isPromise} from '@angular/core';
import {from, Observable, of} from 'rxjs';
import {IsArray} from "./types";

export class BaseClass {
  private localTimer = new Date();  // Por defecto es la hora de creación
  constructor() {

  }

  public timerInit(): void {
    this.localTimer = new Date();
  }

  // En milisegundos
  public timerElapsed(): number {
    return getTime() - this.localTimer.getTime();
  }

  public copyFromObject(asObject: object, onlyIfLocalIsEmpty: boolean = false): void {
    for (const p in asObject) {
      if (!this.hasOwnProperty(p)) {
        // continue;  // OJO: que propiedades opcionales con ? el valor de hasOwnProperty es false
      }
      if (onlyIfLocalIsEmpty) {
        let copiar = false;
        // @ts-ignore
        switch (typeof this[p]) {
          case 'string':
            // @ts-ignore
            if (this[p] === '' || this[p] === undefined || this[p] === null) {
              copiar = true;
            }
            break;
          case 'number':
            // @ts-ignore
            if (this[p] === 0 || this[p] === undefined || this[p] === null) {
              copiar = true;
            }
            break;
          case 'boolean':
            // @ts-ignore
            if (this[p] === false || this[p] === undefined || this[p] === null) {
              copiar = true;
            }
            break;
          case 'object':
            // @ts-ignore
            if (this[p] === {} || this[p] === undefined || this[p] === null) {
              copiar = true;
            }
            break;
          case 'function':
            // @ts-ignore
            if (this[p] === undefined || this[p] === null) {
              copiar = true;
            }
            break;
          default:
            // @ts-ignore
            if (this[p] === undefined || this[p] === null) {
              copiar = true;
            }
        }
        if (copiar) {
          // @ts-ignore
          this[p] = asObject[p];
        }

      } else {
        // @ts-ignore
        this[p] = asObject[p];  // Ojo que no se comprueba si los tipos son iguales
      }
    }
  }

  public toBackendPayload(what?: any): object {

    if(!what) {
      what = this;
    }
    const res = {};
    for (const p in what) {
      if (p != "localTimer" && what.hasOwnProperty(p)) {
        const isArray = IsArray(what[p]);
        if (isArray) {
          let tempValue: any[] = [];
          what[p].forEach((element: any) => {

            // Si es un tipo básico
            switch (typeof element) {
              case 'string':
              case 'number':
              case 'boolean':
                // @ts-ignore
                tempValue.push(element);
                break;
              default:
                // @ts-ignore
                tempValue.push(this.toBackendPayload(element));
            }
          });
          // @ts-ignore
          res[p] = tempValue;
        } else {
          switch (typeof what[p]) {
            case 'string':
            case 'number':
            case 'boolean':
              // @ts-ignore
              res[p] = what[p];
              break;
            case 'object':
              if (what[p] === null) {
                // @ts-ignore
                res[p] = null;
              } else {
                // @ts-ignore
                res[p] = this.toBackendPayload(what[p]);
              }
              break;
            default:
            // Ignoramos function y resto
          }
        }
      }
    }
    return res;
  }

}


export function trimChar(str: string, charToRemove: string): string {
  while (str.charAt(0) === charToRemove) {
    str = str.substring(1);
  }

  while (str.charAt(str.length - 1) === charToRemove) {
    str = str.substring(0, str.length - 1);
  }

  return str;
}
export function trimStr(str: string, strToRemove: string): string {
  while (str.startsWith(strToRemove)) {
    str = str.substring(strToRemove.length);
  }

  while (str.endsWith(strToRemove)) {
    str = str.substring(0, str.length - strToRemove.length);
  }

  return str;
}


export function getUUID() {
  return uuid.v4();
}



export function wrapIntoObservable<T>(value: T|Promise<T>|Observable<T>): Observable<T> {
  if (isObservable(value)) {
    return value;
  }

  if (isPromise(value)) {
    // Use `Promise.resolve()` to wrap promise-like instances.
    // Required ie when a Resolver returns a AngularJS `$q` promise to correctly trigger the
    // change detection.
    return from(Promise.resolve(value));
  }

  return of(value);
}


export function gotoIndexHtml(withTimeStamp: boolean = false): void {
  let url = window.location.protocol + '//' + window.location.hostname;

  // Quitamos el / final
  url.replace(/\/$/, '');
  const port = window.location.port;
  if (port) {
    url += ':' + window.location.port;
  }
  if (withTimeStamp) {
    url += '?t=' + getTime();
  }
  window.location.href = url;
}



export function randomString(lengthOfCode: number = 10, possible: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'): string {
  let text = '';
  for (let i = 0; i < lengthOfCode; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}


export function isInteger(value: any): boolean {
  if (isNaN(value)) {
    return false;
  }
  const x = parseFloat(value);
  // tslint:disable-next-line:no-bitwise
  return (x | 0) === x;
}


// Cuenta los elementos del value
// Si es array, los elementos
// Si es objeto, las propieadades
export function countElements(value: any): number {
  if (!value) {
    return 0;
  }

  // es objeto
  if (typeof value === 'object') {
    return Object.keys(value).length;
  }

  // es un array
  if (Array.isArray(value)) {
    return Array.prototype.slice.call(value).length;
  }
  return 0;

}

export function htmlDecode(value: string): string {
  let res = (value + '').replace(/\&#39;/g, '\'');
  res = res.replace(/\&#34;/g, '"');
  return res;
}

export function getTime(): number {
  return (new Date()).getTime();
}

export function getUnixTime(): number {
  return Math.floor(Date.now() / 1000);
}

export function makeError(msg: string, throwError: boolean = true): void {
  console.error(msg);
  if (throwError) {
    throw new Error(msg);
  }
}

export function stringMaxLen(str: string, maxLen: number, $postFix = '...'): string {
  if (str.length > maxLen) {
    return str.substring(0, maxLen) + $postFix;
  }
  return str;
}

export function arraysEqual(a: any, b: any): boolean {
  if (a === b) { return true; }
  if (a == null || b == null) { return false; }
  if (a.length !== b.length) { return false; }

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) { return false; }
  }
  return true;
}


export function getToday(): string {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  return today.getFullYear() + '-' + month + '-' + day;
}

export function getFisrtDayOfMonth(): string {
  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  return today.getFullYear() + '-' + month + '-01';
}

export function isFileImage(file: File): boolean {
  return file && file['type'].split('/')[0] === 'image';
}


export function dataURLtoArrayBuffer(dataURL: string): ArrayBuffer {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  let byteString = atob(dataURL.split(',')[1]);

  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  let ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return ab;
}

export function dataURLtoBlob(dataURL: string): Blob {

  let ab = dataURLtoArrayBuffer(dataURL);

  // separate out the mime component
  let mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0]

  // write the ArrayBuffer to a blob, and you're done
  let blob = new Blob([ab], {type: mimeString});
  return blob;
}

export function dataURLtoArrayBufferWithMime(dataURL: string): { buffer: ArrayBuffer, mime: string, mimeType: string, mimeSubtype: string} {
  let ab = dataURLtoArrayBuffer(dataURL);

  // separate out the mime component
  let mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0]

  let mimeType = '';
  let mimeSubtype = '';

  if (mimeString != '') {
    let mimeTypeParts = mimeString.split('/');
    mimeType = mimeTypeParts[0];
    if (mimeTypeParts.length > 1) {
      mimeSubtype = mimeTypeParts[1];
    }

  }

  return { buffer: ab, mime: mimeString, mimeType: mimeType, mimeSubtype: mimeSubtype};

}

export function copyOwnProperties(asObject: object): object {
  const res = {};
  for (const p in asObject) {
    if (!asObject.hasOwnProperty(p)) {
      continue;
    }
    // @ts-ignore
    res[p] = asObject[p];

  }
  return res;
}


