import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'startsWidth'
})
export class StartsWidthPipe implements PipeTransform {

  // Args0 => decimales
  // Ejempo bytesSmart: 2

  transform(value: string, ...args: unknown[]): boolean {
    let comparar = '';
    if (args.length > 0) {
      comparar = args[0] as string;
    } else {
      return false;
    }
    return value.startsWith(comparar);
  }

}
