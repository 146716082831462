import {Inject, Injectable} from '@angular/core';
import {WebSocketService} from './websocket.service';
import {StorageService} from './storage.service';
import {getTime} from '../@models/common';
import {GuidService} from "./guid.service";
import {GlobalsService} from "./globals.service";
import {BackendRequest, BackendResponse} from "./request.model";
import {LoggerService} from "./logger.service";
import {BusEvents} from "./events.model";
import {WasmService} from "./wasm.service";
import {DownloadService} from "./download.service";
import {RouterService} from "./router.service";
import {DomService} from "./dom.service";
import {BusConfig, BusConfigEmpty} from "../@models/bus-config";
import {SetGlobalBus} from "../@models/globals";



export const DEBUG = true;


@Injectable({
  providedIn: 'root'
})
export class BusService  {

    public events;
    public loaded: boolean = false;

    public configuration: BusConfig = new BusConfigEmpty();

    constructor(@Inject(Window) private window: Window,
                public ws: WebSocketService,
                public guid: GuidService,
                public logger: LoggerService,
                public globals: GlobalsService,
                public storage: StorageService,
                public wasm: WasmService,
                public download: DownloadService,
                public router: RouterService,
                public dom: DomService,

                ) {

      SetGlobalBus(this);
      this.events = new BusEvents(window);

  }

  configure(config: BusConfig): void {
     this.configuration = config;
     this.initialize();
  }

  private initialize(): void {

    // Inicializamos en este orden
    this.storage.init(this);
    this.globals.init(this);  // Necesita storage y auth
    this.router.init(this);
    this.guid.ComputerIdCookieName = this.configuration.getComputerIdCookieName();

    // El resto
    this.ws.init(this);
    this.logger.init(this);
    this.events.init(this);
    this.download.init(this);
    this.wasm.init(this);
    this.dom.init(this);
    this.loaded = true;
    this.logger.debugBrowser(`**** Iniciado Bus con GUID=${this.guid.getGUID()} ****`);
    this.configuration.onInitiated(this);
  }


  getHostForRequests(): string {
    return this.configuration.getServerDNS();
    // return this.window.location.hostname;
  }

  getHostURL(): string {
    const hostURL: string = this.window.location.protocol + '//' + this.getHostForRequests() +
      (this.window.location.port ? ':' + this.window.location.port : '');
    return hostURL;
  }

  public isColectivoEuropaFactor(): boolean {
    // return true;
    return window.location.hostname === 'europafactor.vozitel.com';
  }

  public isColectivoAdvTrade(): boolean {
    // return true;
    return window.location.hostname === 'portaladvtrade.vozitel.com';
  }

  public isColectivoVozitel(): boolean {
    // return true;
    return window.location.hostname === 'vozitel.clubintel.com';
  }

  public isColectivoLexer(): boolean {
    // return true;
    return window.location.hostname === 'lexer.clubintel.com';
  }

  public isColectivoMapfre(): boolean {
    // return true;
    return window.location.hostname === 'mapfre.clubintel.com' || window.location.hostname === 'clubmapfre.cristalware.com';
  }

  public isColectivoEmpleados(): boolean {
    // return true;
    return window.location.hostname === 'empleados.clubintel.com';
  }

  public isColectivoSabadell(): boolean {
    // return true;
    return window.location.hostname === 'bancosabadell.clubintel.com' || window.location.hostname === 'w3.canalnegocios.es' || window.location.hostname === 'canalnegocios.es' || window.location.hostname === 'pre.clubintel.com';
  }

  public isLocalHost(): boolean {
    // return true;
    return window.location.hostname === 'localhost';
  }


  public pingBus(sendPendings: boolean = true): void {
      this.ws.ping(sendPendings);
  }


  private emitRunRemoteCommand(event: string, securityContext: string, data: any, callback: any, multipleResponses: boolean = false): void {
    this.ws.emit(event, data, callback, multipleResponses, securityContext);
  }

  sendRemoteLog(tipo: string, msg: string): void {

    const request: BackendRequest = new BackendRequest();
    request.event = "log";
    request.data = {
      'type': tipo,
      'msg': msg,
    };

    this.sendRemote(request);
  }

  getCommonPayload(): object {
    return  {
      credentialsId: this.configuration.getCredentialsId(),
      sessionId: this.configuration.getSessionId(),
    };
  }

  sendRemote(request: BackendRequest): Promise<BackendResponse> {
    return new Promise<BackendResponse>((resolve, reject): void => {
      const timerInit: Date = new Date();

      // DEBUG: Para comprobar si se recibe respuesta
      let terminada = false;
      let ejecutadoTimeout = false;
      const to = setTimeout( (): void => {
        const elapsed: number = getTime() - timerInit.getTime();
        ejecutadoTimeout = true;
        this.logger.warningBrowser(`sendRemote: demasiado tiempo esperando respuesta [elapsed=${elapsed} ms] ${request.event} => `, request.data);
        this.pingBus();
      },15000);


      this.emitRunRemoteCommand( request.event, request.security_context, request.toPayload(this.getCommonPayload()),
        (resultado: any): void => {

          const backend: BackendResponse = BackendResponse.fromBus(resultado);

          if (DEBUG) {
            const elapsed: number = getTime() - timerInit.getTime();
            this.logger.debugBrowser(`sendRemote:[elapsed=${elapsed} ms] ${request.event} => `, request.data, backend);
          }

          // Resultado
          resolve(backend);

          // DEBUG
          terminada = true;
          if (!ejecutadoTimeout) {
            clearTimeout(to);
          } else {
            const elapsed: number = getTime() - timerInit.getTime();
            this.logger.warningBrowser(`sendRemote: obtenida respuesta después de alertar [elapsed=${elapsed} ms] ${request.event} => `, request.data);
          }
        }, false);
    });

  }


  sendRemoteMultiple(request: BackendRequest, intermediateResultsCallback: (response: BackendResponse, deleteWhenFinish?: () => void) => void): void {
    this.emitRunRemoteCommand( request.event,request.security_context, request.toPayload(this.getCommonPayload()),
      (resultado: any, deleteWhenFinish?: () => void): void => {
        const backend: BackendResponse = BackendResponse.fromBus(resultado);
        // Resultado
        intermediateResultsCallback(backend, deleteWhenFinish);
      }, true);
  }

}
