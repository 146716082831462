<div class="container">

  <div *ngFor="let item of items; let i = index">
    <div class="item {{item.class}}">
      <nb-icon [icon]="item.icon.icon" [pack]="item.icon.pack" [status]="item.icon.status"></nb-icon>
      <div class="item-container">
        <nb-progress-bar status="info" class="progress-item" size="large" [value]="item.progress" [nbTooltip]="item.progress+'%'">
          <span *ngIf="item.progress >= 15" class="label-inner">{{item.progress}}%</span>
        </nb-progress-bar>
        <div class = "bottom-labels">
          <span class="label-item">{{item.title}}</span>
          <span class="label-item-elapsed">{{item.elapsed}}</span>
        </div>
      </div>
      <button (click)="onCancel(item.id)" nbButton ghost><nb-icon [icon]="iconClose.icon"  [pack]="iconClose.pack" nbTooltip="{{labelCancelar}}"></nb-icon></button>
    </div>

  </div>
</div>
