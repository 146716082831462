import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Alerta} from "../../../models/alert";
import {Icon, IconDelete} from "../../../@core/@models/icons";



@Component({
  selector: 'app-header-alerta',
  templateUrl: './header-alerta.component.html',
  styleUrls: ['./header-alerta.component.scss']
})
export class HeaderAlertaComponent implements OnInit {
  @Input() alerta?: Alerta;
  @Output() onDelete: EventEmitter<Alerta> = new EventEmitter<Alerta>();

  labelBorrarAlerta: string = $localize`Borrar Alerta`;
  constructor() { }

  iconDelete: Icon = IconDelete;


  ngOnInit(): void {


  }

  onDeleteAlerta(): void {
    if (this.alerta) {
      // TODO: implementar el borrado
    }

  }

}
