import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CardIndicator} from "../card-indicators.component";
import {FormatLocalNumber, NumberSmart} from "../../../../@models/strings";
import {CurrencyUnit} from "../../../../../models/currency";

@Component({
  selector: 'app-card-indicator',
  templateUrl: './card-indicator.component.html',
  styleUrls: ['./card-indicator.component.scss']
})
export class CardIndicatorComponent implements OnInit, OnChanges {

  @Input() indicator?: CardIndicator;

  defaultAnimation = 'animation-flip';
  classAnimation: string = '';

  amountSmart: string = '';
  percentajeSmart: string = '';

  classPercent: string = '';
  classAmount: string = '';

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    this.doChanges();
  }

  doChanges() {
    if (this.indicator) {
      this.amountSmart = '';
      if (this.indicator.amount != undefined) {
        if (this.indicator.compactAmount != undefined && this.indicator.compactAmount) {
          this.amountSmart = NumberSmart(this.indicator.amount, this.indicator.currency);
        } else {
          this.amountSmart = FormatLocalNumber(this.indicator.amount, this.indicator.decimals || 2, this.indicator.decimals || 2);
          if (this.indicator.currency && this.indicator.currency != '') {
            this.amountSmart += ' ' + CurrencyUnit(this.indicator.currency);
          }
        }
      }

      this.percentajeSmart = NumberSmart(0, '%', false);
      this.classAmount = 'box-neutral';
      this.classPercent = 'text-neutral';
      if (this.indicator.percentaje) {
        this.percentajeSmart = NumberSmart(this.indicator.percentaje * 100, '%', false);


        if (this.indicator.percentaje > 0) {
          this.classPercent = 'text-positive';
          this.classAmount = 'box-positive';
        }
        if (this.indicator.percentaje < 0) {
          this.classPercent = 'text-negative';
          this.classAmount = 'box-negative';
        }
      }


      // Vemos si ha cambiado
      if (this.indicator.previous && this.indicator.previous.amount != this.indicator.amount) {
        // Ha cambiado => filigrana
        this.classAnimation = this.defaultAnimation;
        setTimeout( () => {
          this.classAnimation = '';
        }, 1000);
      }
    }

  }


}
