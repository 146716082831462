import { NbMenuItem } from '@nebular/theme';
import {MatIcon} from "./@core/@models/icons";
import {Status} from "./@core/@models/theme";
import {BusService} from "./@core/@bus/bus.service";


export enum MenuLoggedIndex  {
  Inicial,
  Buzones,
  Comunicados,

}

export function getMenuLoggedUser(bus: BusService): NbMenuItem[] {
  if (bus.isColectivoLexer()){
    return getMenuLoggedLexer();
  }
  if (bus.isColectivoEuropaFactor() || bus.isColectivoAdvTrade()){
    return getMenuLoggedEuropaFactor();
  }
  if (bus.isColectivoVozitel()){
    return getMenuLoggedVozitel();
  }
  if (bus.isColectivoMapfre()){
    return getMenuLoggedMapfre();
  }
  return getMenuLogged();
}
export let MenuLogged: NbMenuItem[] = getMenuLogged();
export let MenuLoggedLexer: NbMenuItem[] = getMenuLoggedLexer();

export function setBadgeMenuLogged(menuIndex: number,text: string, status: Status = "warning"): void {
  MenuLogged[menuIndex].badge = {
    // @ts-ignore
    text: text,
    status: status,
  }
  // MenuLogged = [...MenuLogged];
}
export function delBadgeMenuLogged(menuIndex: number): void {
  MenuLogged[menuIndex].badge = undefined;
  // MenuLogged = [...MenuLogged];
}

export function getBadgeMenuLogged(menuIndex: number) {
  return MenuLogged[menuIndex].badge;
}


export function getMenuLogged(): NbMenuItem[] {
  const menu: NbMenuItem[] = [
    {
      title: $localize`Home`,
      icon: MatIcon('home'),
      link: '/inicial',
    },

    {
      title: $localize`Mis consultas`,
      icon: MatIcon('sms'),
      link: '/consultas',
    },

    {
      title: $localize`Mis documentos`,
      icon: MatIcon('folder'),
      link: '/documentos',
    },

   /* {
      title: $localize`Mis procesos`,
      icon: MatIcon('content_paste_go'),
      link: '/inicial',
      children: [
        {
          title: $localize`Consultar deuda`,
          link: '/consultar-deuda',
        },
        {
          title: $localize`Reclamación deuda`,
          link: '/inicial',
        },
        {
          title: $localize`Reclamación garantías`,
          link: '/inicial',
        },
      ]
    },*/

    {
      title: $localize`Ventajas / Descuentos`,
      icon: MatIcon('savings'),
      link: '/ventajas-descuentos',
    },

    {
      title: $localize`Enlaces de interés`,
      icon: MatIcon('share'),
      link: '/enlaces-interes',
    },

    {
      title: $localize`Preguntas frecuentes`,
      icon: MatIcon('info'),
      link: '/preguntas-frecuentes',
    },

    /*{
      title: $localize`Buzones`,
      icon: MatIcon('mail'),
      link: '/buzones',
    },

    {
      title: $localize`Comunicados`,
      icon: MatIcon('breaking_news_alt_1'),
      link: '/comunicados',
    },*/


  ];
  return menu;
}

export function getMenuLoggedLexer(): NbMenuItem[] {
  const menu: NbMenuItem[] = [
    {
      title: $localize`Home`,
      icon: MatIcon('home'),
      link: '/inicial',
    },
    {
      title: $localize`Mis documentos`,
      icon: MatIcon('folder'),
      link: '/documentos',
    },

    {
      title: $localize`Mi cartera`,
      icon: MatIcon('content_paste_go'),
      link: '/inicial',
      children: [
        {
          title: $localize`Consultar deuda`,
          link: '/consultar-deuda',
        },
      ]
    },

    {
      title: $localize`Enlaces de interés`,
      icon: MatIcon('share'),
      link: '/enlaces-interes',
    },

    {
      title: $localize`Preguntas frecuentes`,
      icon: MatIcon('info'),
      link: '/preguntas-frecuentes',
    }

  ];
  return menu;
}


export function getMenuLoggedVozitel(): NbMenuItem[] {
  const menu: NbMenuItem[] = [
    {
      title: $localize`Home`,
      icon: MatIcon('home'),
      link: '/inicial',
    },
    {
      title: $localize`Mis consultas`,
      icon: MatIcon('sms'),
      link: '/consultas',
    },
    {
      title: $localize`Mis documentos`,
      icon: MatIcon('folder'),
      link: '/documentos',
    },
    {
      title: $localize`Empleados`,
      icon: MatIcon('business'),
      link: '/empleados',
    },
    {
      title: $localize`Mis procesos`,
      icon: MatIcon('content_paste_go'),
      link: '/inicial',
      children: [
        {
          title: $localize`Consultar deuda`,
          link: '/consultar-deuda',
        },
      ]
    },
    {
      title: $localize`Ventajas / Descuentos`,
      icon: MatIcon('savings'),
      link: '/ventajas-descuentos',
    },
    {
      title: $localize`Enlaces de interés`,
      icon: MatIcon('share'),
      link: '/enlaces-interes',
    },

    {
      title: $localize`Preguntas frecuentes`,
      icon: MatIcon('info'),
      link: '/preguntas-frecuentes',
    }

  ];
  return menu;
}


export function getMenuLoggedMapfre(): NbMenuItem[] {
  const menu: NbMenuItem[] = [
    {
      title: $localize`Inicio`,
      icon: MatIcon('home'),
      link: '/inicial',
    },
    {
      title: $localize`Mis consultas`,
      icon: MatIcon('sms'),
      link: '/consultas',
    },
    {
      title: $localize`Mis documentos`,
      icon: MatIcon('folder'),
      link: '/documentos',
    },
    {
      title: $localize`Proceso Renta`,
      icon: MatIcon('euro'),
      link: '/renta',
    }
  ];
  return menu;
}

export function getMenuLoggedEuropaFactor(): NbMenuItem[] {
  const menu: NbMenuItem[] = [
    {
      title: $localize`Home`,
      icon: MatIcon('home'),
      link: '/inicial',
    },

    {
      title: $localize`Area Personale`,
      icon: MatIcon('menu_book'),
      link: '/area-personale',
    },

    {
      title: $localize`Effettua un pagamento`,
      icon: MatIcon('credit_card'),
      link: '/effettua-pagamento',
    },

    {
      title: $localize`Accordi di pagamento`,
      icon: MatIcon('manage_search'),
      link: '/accordi-pagamento',
    },
   /* {
      title: $localize`Documenti scaricabili`,
      icon: MatIcon('pending_actions'),
      link: '/documenti-scaricabili',
    },*/
    {
      title: $localize`F.A.Q`,
      icon: MatIcon('info'),
      link: '/preguntas-frecuentes',
    },

    /*{
      title: $localize`Buzones`,
      icon: MatIcon('mail'),
      link: '/buzones',
    },

    {
      title: $localize`Comunicados`,
      icon: MatIcon('breaking_news_alt_1'),
      link: '/comunicados',
    },*/


  ];
  return menu;
}

export function getMenuSabadellDisabled(): NbMenuItem[] {
  const menu: NbMenuItem[] = [
    {
      title: $localize`Home`,
      icon: MatIcon('home'),
      link: '',
      children: [],
    },

    {
      title: $localize`Area Personale`,
      icon: MatIcon('menu_book'),
      link: '',
      children: [],
    },

    {
      title: $localize`Effettua un pagamento`,
      icon: MatIcon('credit_card'),
      link: '',
      children: [],
    },

    {
      title: $localize`Accordi di pagamento`,
      icon: MatIcon('manage_search'),
      link: '',
      children: [],
    },
    {
      title: $localize`F.A.Q`,
      icon: MatIcon('info'),
      link: '',
      children: [],
    },
  ];
  return menu;
}
