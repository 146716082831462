<div class="form-field">
  <label *ngIf="label" [class.disabled]="disabled" class="form-label mb-1">{{label}}</label>
  <nb-tag-list (tagRemove)="onRemoveKeyword($event)">
    <nb-tag
      [size]="size"
      [appearance]="disabled ? 'outline' : 'filled'"
      [class.disabled]="disabled"
      [class.cursor-pointer]="!disabled"
      [status]="status"
      [removable]="!disabled" *ngFor="let keyword of keywords" [text]="keyword"></nb-tag>
    <input [disabled]="disabled" type="text" nbTagInput (tagAdd)="onKeywordAdd($event)" fullWidth>
  </nb-tag-list>
  <div *ngIf="help" class="form-help">{{help}}</div>
</div>
