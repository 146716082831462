import {Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {Option} from "../../../@models/types";
import {getUUID} from "../../../@models/common";
import {getCountries} from "../../../@models/countries";

@Component({
  selector: 'app-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss']
})
export class SelectCountryComponent implements OnInit,OnChanges, ControlValueAccessor {
  @Input() label?: string;
  @Input() placeholder: string = '';
  @Input() help?: string;
  @Input() disabled: boolean = false;
  @Input() fullWidth: boolean = true;
  @Input() top: boolean = false;
  @Input() width?: string;
  @Input() multiple: boolean = false;

  options: Option[] = [];
  @Input() selected?: string | string[];

  currentSelection: Option[] = [];

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  set selectedOption(val: string | string[]) {
    if (val != this.value) {
      this.setCurrentSelection(val);
      this.onChangeValue(val);
    }

  }
  get selectedOption(): string | string[] {
    return this.value;
  }

  value: any;  // No se pasa como parámetro, poner un formControl

  public onChangeFn = (_: any) => {};
  public onTouchedFn = () => {};

  id: string = getUUID();

  constructor(@Self() @Optional() public control: NgControl) {
    this.control && (this.control.valueAccessor = this);
  }

  setCurrentSelection(code: string | string[]) {
    this.currentSelection = [];

    if (Array.isArray(code)) {
      this.currentSelection = this.options.filter( (opt) => {
        if (code.includes(opt.value)) {
          return true;
        }
        return false;
      });
    } else {
      this.currentSelection = this.options.filter( (opt) => {
        if (code == opt.value) {
          return true;
        }
        return false;
      });
    }
  }


  public get showError(): boolean {
    let dirty = false;
    let invalid = false;
    let touched = false;
    if (!this.control) {
      return false;
    } else {
      dirty = this.control.dirty || false;
      invalid = this.control.invalid || false;
      touched = this.control.touched || false;
    }
    return invalid ? (dirty || touched) : false;
  }

  adaptValue(value: any): string | string[] {
    let result: string | string [];
    if (this.value != undefined) {
      if (this.multiple) {
        if (Array.isArray(this.value)) {
          result = [];
          if (this.value.length > 0) {
            result = this.value.map( s => s.toUpperCase());
          }
        } else {
          // @ts-ignore
          result = [this.value.toUpperCase()];
        }
      } else {
        result = "";
        if (Array.isArray(this.value) && this.value.length > 0) {
          result = this.value[0].toUpperCase();
        } else {
          // @ts-ignore
          result = this.value.toUpperCase();
        }
      }
    } else {
      if (this.multiple) {
        result = [];
      } else {
        result = '';
      }
    }
    return result;

  }

  ngOnInit(): void {
    this.selected = this.adaptValue(this.selected);
    this.setCurrentSelection(this.selected);
    this.value = this.selected;
    this.options = getCountries(this.top);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selected']) {
      this.selected = this.adaptValue(this.selected);
      this.setCurrentSelection(this.selected);
      this.value = this.selected;
    }

    if (changes['top']) {
      this.options = getCountries(this.top);
    }

  }

  onChangeValue(val: any) {
    this.onChangeFn(val);
    this.onTouchedFn();
    this.onChange.emit(val);
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
    if(this.value) {
      // Notificamos que hemos cambiado el valor
      this.onChangeFn(this.value);
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (this.value) {
      this.value = this.adaptValue(this.value);
      this.setCurrentSelection(this.value);
    }

  }


}
