import {Injectable} from '@angular/core';
import {BusService} from './bus.service';
import {getTime} from '../@models/common';

export interface WasmResult {
  error: string; // Si ha habido error
  result: any;   // El resultado
}

@Injectable({
  providedIn: 'root'
})
export class WasmService {

  version: string = '4.3';  // Para que no cachee el loader => cambiar cada vez que se recompile

  // @ts-ignore
  private bus: BusService;

  private loaded = false;

  constructor() {

  }

  loadUncompressed(): Promise<boolean> {
    return new Promise<boolean>( (resolve) => {
      // Cargamos el loader de
      const inicio = getTime();
      this.bus.download.loadJsFile('@assets/webassembly/wasm_exec.js?v=' + this.version).subscribe( (fileExec) => {
        if (fileExec.loaded) {
          // cargamos el loader
          this.bus.download.loadJsFile('@assets/webassembly/loader.js?v=' + this.version).subscribe( (fileLoader) => {
            if (fileLoader.loaded) {
              // En este momento, las funciones ya están exportadas y disponibles
              this.loaded = true;

              const elapsed = getTime() - inicio;
              this.bus.logger.debugBrowser('Tiempo de carga de WASM sin comprimir = ' + elapsed + ' milisegundos');
              resolve(true);
            } else {
              resolve(false);
            }
          });
        } else {
          resolve(false);
        }
      });
    });

  }

  loadCompressed(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // Cargamos el descompresor antes que el loader
      const inicio = getTime();
      this.bus.download.loadJsFile('@assets/webassembly/pako/pako.min.js').subscribe( (filePako) => {
        if (filePako.loaded) {
          this.bus.download.loadJsFile('@assets/webassembly/wasm_exec.js?v=' + this.version).subscribe( (fileExec) => {
            if (fileExec.loaded) {
              // cargamos el loader
              this.bus.download.loadJsFile('@assets/webassembly/loadergz.js?v=' + this.version).subscribe( (fileLoader) => {
                if (fileLoader.loaded) {
                  // En este momento, las funciones ya están exportadas y disponibles
                  this.loaded = true;

                  const elapsed = getTime() - inicio;
                  this.bus.logger.debugBrowser('Tiempo de carga de WASM comprimido = ' + elapsed + ' milisegundos');
                  resolve(true);
                } else {
                  resolve(false);
                }
              });
            } else {
              resolve(false);
            }
          });
        } else {
          resolve(false);
        }
      });
    })

  }

  async init(bus: BusService, compressed: boolean = true) {
    this.bus = bus;
    if (compressed) {
      await this.loadCompressed();  // Porque se ha compilado con compile.sh
    } else {
      await this.loadUncompressed();  // Por si se compila con compile_tiny.sh
    }
    this.bus.logger.debugBrowser('Fin init de WASM');
  }

  isLoaded(): boolean {
    if (this.loaded == false) {
      return false;
    }

    // Comprobamos si una de las funciones existe
    // @ts-ignore
    if (typeof go_seconds_format_duration !== "undefined") {
      return true;
    }

    return false;
  }

  protected checkLoaded() {
    if (!this.isLoaded()) {
      this.bus.logger.errorBrowser('Todavía no ha terminado de cargarse el framework. Inténtelo otra vez en unos segundos.')
    }
  }

  // Poner aquí wrappers para las funciones exportadas de webassembly
  base64(binary: ArrayBuffer): WasmResult {
    this.checkLoaded();
    const byteArray = new Uint8Array(binary);
    // @ts-ignore
    return go_base64(byteArray);
  }

  base64Decode(chunk: string): WasmResult {
    this.checkLoaded();
    // @ts-ignore
    const res = go_base64_decode(chunk);

    // res.result es un Uint8Array

    return res;
  }

  formatSeconds(seconds: number): string {
    // Nos aseguramos que sea un integer
    try {
      seconds = seconds * 1;
    } catch (exception) {
      seconds = 0;
    }

    this.checkLoaded();
    // @ts-ignore
    const res = go_seconds_format_duration(seconds);
    return (res as WasmResult).result;
  }

  nowSecondsTimezone(timeZone: string): number | null {
    this.checkLoaded();
    // @ts-ignore
    const res = go_timezone_now_seconds(timeZone);

    if ((res as WasmResult).error ) {
      return null;
    }
    return (res as WasmResult).result;
  }


  chunkBase64(binary: ArrayBuffer, chunkSize: number = 1024 * 50): WasmResult {
    this.checkLoaded();
    const byteArray = new Uint8Array(binary);
    // @ts-ignore
    return go_chunk_base64(byteArray, chunkSize);
  }

  chunkArrayBuffer(binary: ArrayBuffer, chunkSize: number = 1024 * 50): WasmResult {
    this.checkLoaded();
    const byteArray = new Uint8Array(binary);
    // @ts-ignore
    return go_chunk_array_buffer(byteArray, chunkSize);
  }


  chunkString(data: string, chunkSize: number = 1024 * 50): WasmResult {
    this.checkLoaded();
    // @ts-ignore
    return go_chunk_string(data, chunkSize);
  }

  checkDate(fecha: string): boolean {
    this.checkLoaded();
    // @ts-ignore
    const res = go_check_date(fecha);
    if ((res as WasmResult).result ) {
      return true;
    }
    return false;
  }

  smsCount(sms: string): number {
    this.checkLoaded();
    // @ts-ignore
    const res = go_sms_count(sms);
    return (res as WasmResult).result;
  }

  goDuration(seconds: number, truncateCount: number = 0, showSeconds: boolean = true, withSpace: boolean = false): string {
    this.checkLoaded();
    // @ts-ignore
    const res = go_duration(seconds, truncateCount, showSeconds, withSpace);
    return (res as WasmResult).result;
  }

  utf8RuneCount(sms: string): number {
    this.checkLoaded();
    // @ts-ignore
    const res = go_utf8_rune_count(sms);
    return (res as WasmResult).result;
  }

  checkDateTime(fecha: string): boolean {
    this.checkLoaded();
    // @ts-ignore
    const res = go_check_datetime(fecha);
    if ((res as WasmResult).result ) {
      return true;
    }
    return false;
  }

  checkTime(fecha: string): boolean {
    this.checkLoaded();
    // @ts-ignore
    const res = go_check_time(fecha);
    if ((res as WasmResult).result ) {
      return true;
    }
    return false;
  }

  timeZoneConvertDateTime(mysqlDateTime: string, sourceTimeZone: string, targetTimeZone: string): string | null {
    this.checkLoaded();
    // @ts-ignore
    const res = go_timezone_convert_datetime(mysqlDateTime, sourceTimeZone, targetTimeZone);
    if (res.error) {
      this.bus.logger.warning(`timeZoneConvertDateTime: ${mysqlDateTime} ${sourceTimeZone} => ${targetTimeZone}`, res.error);
      return null;
    }
    return res.result;
  }

  // Ojo que puede de cambiar de día, si lo hace, sólo omite la fecha manteniendo la hora convertida
  timeZoneConvertTime(mysqlTime: string, sourceTimeZone: string, targetTimeZone: string): string | null {
    this.checkLoaded();
    // @ts-ignore
    const res = go_timezone_convert_time(mysqlTime, sourceTimeZone, targetTimeZone);
    if (res.error) {
      this.bus.logger.warning(`timeZoneConvertTime: ${mysqlTime} ${sourceTimeZone} => ${targetTimeZone}`, res.error);
      return null;
    }
    return res.result;
  }


}
