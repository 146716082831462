import {Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {Option} from "../../../@models/types";
import {clone} from "../../../@models/lodash";

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})
export class CheckListComponent implements OnInit,OnChanges, ControlValueAccessor {
  @Input() options: Option[] | undefined;
  @Input() disabled: boolean = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  value: Option[] = [];  // No se pasa como parámetro, poner un formControl

  public onChangeFn = (_: any) => {};
  public onTouchedFn = () => {};

  constructor(@Self() @Optional() public control: NgControl) {
    this.control && (this.control.valueAccessor = this);
  }

  ngOnInit(): void {
    if (this.options) {
      this.value = clone(this.options);
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['options']) {
      this.value = clone(this.options);
    }
  }

  onChangeOption(option: Option) {
    this.onChangeValue(this.value);
  }


  onChangeValue(val: any) {
    this.onChangeFn(val);
    this.onTouchedFn();
    this.onChange.emit(val);
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
    if(this.value) {
      // Notificamos que hemos cambiado el valor
      this.onChangeFn(this.value);
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.value = [];
    } else {
      this.value = obj;
    }

  }

}
