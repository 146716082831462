import {GrpcRequest, GrpcResponse, OriginEmpty, Origin} from "../@core/@models/grpc";

export class GenericRequest extends GrpcRequest {

    command: string = '';
    data: string = '';

    origin: Origin = OriginEmpty;

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

}


export class GenericResponse extends GrpcResponse {

    data: string = '';
    error: string = '';

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }
}
