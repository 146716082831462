import {Component, EventEmitter, Input, OnInit, Optional, Output, Self} from '@angular/core';
import {NbComponentSize} from "@nebular/theme/components/component-size";
import {debounceTime} from "../../../@models/lodash";
import {NgControl} from "@angular/forms";

@Component({
  selector: 'app-input-digits',
  templateUrl: './input-digits.component.html',
  styleUrls: ['./input-digits.component.scss']
})
export class InputDigitsComponent implements OnInit {
  @Input() label?: string;
  @Input() help?: string;
  @Input() focus: boolean = false;
  @Input() initialValue?: any;
  @Input() disabled: boolean = false;
  @Input() type: string = 'text';
  @Input() fullWidth: boolean = false;
  @Input() maxLength: string | number | null = null;
  @Input() size: NbComponentSize = 'medium';
  @Input() placeholder: string = '000000';
  @Input() align: 'left' | 'right' | 'center' =  'left';
  @Output() onInputChange = new EventEmitter<any>();
  @Output() onFocus = new EventEmitter<any>();

  debouncer = debounceTime( (value) => {
    this.onInputChange.emit(value);
  }, 500)

  value: any;  // No se pasa como parámetro, poner un formControl

  public onChangeFn = (_: any) => {};
  public onTouchedFn = () => {};

  constructor(@Self() @Optional() public control: NgControl) {
    this.control && (this.control.valueAccessor = this);
  }

  ngOnInit(): void {
    if (this.initialValue) {
      this.value = this.initialValue;
    }
  }

  onInput() {
    this.onChange(this.value);
    this.debouncer(this.value);
  }

  onFocusEvent() {
    this.onFocus.emit();
  }

  // ControlValueAccessor
  onChange(val: any) {
    this.onTouchedFn();
    this.onChangeFn(val);
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }



}
