import 'web-streams-polyfill';  // Necesario para que funciones en firefox y otros (para Chrome no hace falta)
// @ts-ignore
import streamSaver from '../@assets/streamsaver/StreamSaver';
import {BaseClass, getUUID, makeError} from './common';
import {JsonStringify} from "./json";

/*
**************** Configuración del streamSaver *******************
* Esta configuración es necesaria para que el Chrome no bloquee la descarga por bloqueo de cookies de terceros
*
* 1.- Copiar los ficheros en assets/streamsaver
* 2.- En StreamSaver.js, poner la ruta correcta a mitm.html en el servidor
*
* Ejemplo:
* const streamSaver = {
    createWriteStream,
    WritableStream: global.WritableStream || ponyfill.WritableStream,
    supported: true,
    version: { full: '2.0.5', major: 2, minor: 0, dot: 5 },
    mitm: 'assets/streamsaver/mitm.html?version=2.0.0&asset=.asset'  // JVS: por configuración del apache
  }
*
* 3.- El apache debe estar configurado con la RewriteRule: RewriteRule !\.(js|ico|gif|jpg|png|css|swf|asset)$ /index.html
*
 */

export const UseStreamAutoMaxSize = 10 * 1024 * 1024;  // 10 megas

export function DownloadCustom(data: object): Download {
  const item = new Download();
  item.type = "custom"
  item.data = JsonStringify(data, true);
  return item;
}

export type DownloadFromBus = {
  uniqId: string
  size: number
  chunks: number
  title: string
  cancelled: boolean
  fileName:  string
};

export type Status = 'not_initiated' | 'running' | 'ok' | 'error' | 'cancelled_by_server' | 'cancelled_by_user';


export class UploadedData extends BaseClass{
  id: string = '';
  url: string = '';
  table: string = '';
  name: string = '';

  constructor(asObject?: object) {
    super();
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }
}

export class Upload extends BaseClass{
  file?: File;
  public status: Status = 'not_initiated';
  uniqKey: string = '';
  progress: number = 0;
  isBlob: boolean = false;
  title: string = '';
  fileName: string = '';
  cancelledByUser: boolean = false;
  error: string = '';
  mime: string = '';
  refId: string = '';
  context: string = '';
  type: 'private' | 'custom' = 'private';
  uploadedData: UploadedData = new  UploadedData();  // Si es public, es la URL de download del fichero subido


  constructor(asObject?: object) {
    super();

    if (asObject) {
      this.copyFromObject(asObject);
    }
    if (this.uniqKey == '') {
      this.uniqKey = getUUID();
    }
  }

  getStatus(): Status {
    return this.status;
  }

  getUniqKey(): string {
    return this.uniqKey;
  }
  cancelUpload() {
    this.cancelledByUser = true;
  }
}

export class Download extends BaseClass{
  title: string = '';
  fileName: string = '';
  mimeType: string = '';
  type: string = '';
  filePath: string = '';
  size: number = 0;
  uniqKey: string = '';
  data: string = '';
  deleteAfterUse: boolean = false;
  isDownloading: boolean = false;
  downloadProgress: number = 0;
  createdDate: Date;
  error: string = '';


  // useStream => por defecto usa streams por si se descargan ficheros grandes
  // para ficheros que no sacrifiquen mucha memoria, es más eficiente no usar streams

  // Los eventos de cancel y pause provocados por el usuario desde el browser no se reciben
  // (porque el navegador no los emite hasta que todos los navegadores implementen el API que por el momento es experimental)

  useStream: 'yes' | 'no' | 'auto' = 'auto';  // No funciona si no están permitidas las cookies de terceros
  streamIsClosed: boolean = false;  // Si se usa streams y el usuario cancela la descarga o finaliza la misma

  // Para immplementar las descargas
  private fileStream: streamSaver.WriteStream;
  private fileWriter: any;
  private downloadData: any = {};
  private chunksReceived = 0;
  private totalSize = 0;
  private lastChunkIndex = -1;

  public downloadAsBlob: boolean = false;
  public status: Status = 'not_initiated';
  public blobContent: Uint8Array | undefined;

  // TODO: como mejora, conectarse a window.onunload y abortar todas las descargas vivas

  constructor(asObject?: object) {
    super();
    this.createdDate = new Date();

    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

  getStatus(): Status {
    return this.status;
  }

  getBlobContent(): Uint8Array | undefined {
    return this.blobContent;
  }

  // Primero se descarga del bus, y luego se pasan los datos descargados a esta función
  saveToDisk(fileName: string, binaryData: Uint8Array, onFinish?: () => void) {
    const myBlob = new Blob([binaryData], {type: 'octet/stream'});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(myBlob);
    link.download = fileName;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
    if (onFinish) {
      onFinish();
    }
  }

  streamCreateFile(fileName: string) {
    // Creamos el fichero
    this.fileStream = streamSaver.createWriteStream(fileName, {
      size: this.size,
    });
    this.fileWriter = this.fileStream.getWriter();
    this.fileWriter.closed.then( () => {
      this.streamIsClosed = true;
    } );
  }

  streamSaveChunk(data: Uint8Array) {

    // Escribimos un chunk al stream
    this.fileWriter.write(data);
  }

  streamClose() {
    // Cerramos el fichero
    this.fileWriter.close();
  }

  addChunk(chunkIndex: number, data: Uint8Array) {
    if (this.useStream != 'no') {

      // Miramos si está creado
      if (!this.fileStream) {
        this.streamCreateFile(this.fileName);
      }

      // Comprobamos si vienen ordenados
      if (this.lastChunkIndex === chunkIndex - 1) {
        this.streamSaveChunk(data);
        this.lastChunkIndex = chunkIndex;
      } else {
        // Almacenamos a la espera que vengan los demás
        this.downloadData[chunkIndex] = data;

        // Miramos si tenemos todos los pendientes
        let tenemosTodos = true;
        for (let i = this.lastChunkIndex + 1 ; i <= chunkIndex; i++) {
          if (!this.downloadData.hasOwnProperty(i)) {
            tenemosTodos = false;
          }
        }

        if (tenemosTodos) {
          for (let i = this.lastChunkIndex + 1 ; i <= chunkIndex; i++) {
            this.streamSaveChunk(this.downloadData[chunkIndex]);
            delete this.downloadData[chunkIndex];
          }
          this.lastChunkIndex = chunkIndex;
        }
      }
    } else {
      this.downloadData[chunkIndex] = data;
    }
    this.chunksReceived++;
    if (typeof data === 'string' || data instanceof String) {
      this.totalSize += data.length;
    } else {
      this.totalSize += data.byteLength;
    }
  }

  getCountChunks(): number {
    return this.chunksReceived;
  }

  abort() {
    if (this.fileWriter) {
      this.fileWriter.close();
    }
    this.stopDownloading();
  }

  closeBlob() {
    this.blobContent = this.compactChunks();
    this.stopDownloading();
  }

  close() {
    // grabar a disco o cerrar el stream
    if (this.useStream != 'no') {

      setTimeout( () => {
        this.stopDownloading();
      }, 300);

      // Tienen que haber llegado todos los chunks
      if (this.chunksReceived !== this.lastChunkIndex + 1) {
        makeError('Descarga de fichero con pérdida de chunks');
      }
      this.streamClose();
    } else {
      const compacted = this.compactChunks();
      this.saveToDisk(this.fileName, compacted, () => {
        setTimeout( () => {
          this.stopDownloading();
        }, 300);
      });
    }
  }

  compactChunks(): Uint8Array {
    // Compactamos los chunks recibidos
    const compacted = new Uint8Array(this.totalSize);
    let offset = 0;
    for ( let i = 0; i < this.chunksReceived; i++) {
      compacted.set( this.downloadData[i], offset );
      offset += this.downloadData[i].byteLength;
    }
    return compacted;
  }

  startDownloading() {
    this.isDownloading = true;
    this.status = 'running';
    this.resetTemps();
  }

  stopDownloading() {
    this.isDownloading = false;
    this.resetTemps();
  }

  private resetTemps() {
    this.streamIsClosed = false;
    this.fileStream = null;
    this.fileWriter = null;
    this.downloadData = {};
    this.chunksReceived = 0;
    this.totalSize = 0;
    this.lastChunkIndex = -1;
  }

}
