import { Component, OnInit } from '@angular/core';
import {ListAlertasResponse} from "../../../models/alert";

@Component({
  selector: 'app-header-alertas',
  templateUrl: './header-alertas.component.html',
  styleUrls: ['./header-alertas.component.scss']
})
export class HeaderAlertasComponent implements OnInit {

  listaAlertas: ListAlertasResponse = new ListAlertasResponse();
  cuantasAlertas: number = 0;
  loading: boolean = true;
  labelNoAlertas: string = $localize`No hay alertas`;

  constructor() {

    // TODO: cargar alertas

    this.loading = false;
  }

  ngOnInit(): void {
  }

  onDeleteIndex(index: number): void {
    this.listaAlertas.alertas.splice(index, 1);
    this.cuantasAlertas = this.listaAlertas.alertas ? this.listaAlertas.alertas.length : 0;
  }

}
