
<nb-card class="dialog-wrapper"  [status]="options.status || ''" [accent]="options.accent || ''">
  <nb-card-header *ngIf="options.title">
    <div>{{options.title}}</div>
  </nb-card-header>
  <nb-card-body class="body-table">
    <table class="table-basic">
      <tr *ngIf="columns.length > 0">
        <th *ngFor="let column of columns" class="text-{{column.align}}">
          {{column.title}}
        </th>
      </tr>
      <ng-container *ngIf="options.rows">
        <tr *ngFor="let row of options.rows">
          <td *ngFor="let item of row; let c = index;" class="text-{{columns[c].align || 'left'}}">
            {{item}}
          </td>
        </tr>
      </ng-container>

    </table>
  </nb-card-body>
  <nb-card-footer>
    <div class="flex-space-between">
      <app-button label="Aceptar" size="small" status="primary" (onPress)="onAceptar()"></app-button>
    </div>
  </nb-card-footer>
</nb-card>

