<div class="mb-1">
  <label *ngIf="label" [class.disabled]="disabled" [for]="id" class="form-label mb-1">{{label}}</label>


  <nb-form-field [ngClass]="{'with-weekday': weekDay, 'without-weekday': !weekDay}">
    <input [value]="humanDate" [disabled]="disabled" readonly nbInput fullWidth  [placeholder]="placeholder" [class.disabled]="disabled"/>

    <app-date-picker [disabled]="disabled" nbSuffix [date]="backendValue"  (onChange)="onDateChange($event)">
      <button [disabled]="disabled" type="button" unfocus  nbButton ghost>
        <nb-icon [icon]="iconDate.icon" [pack]="iconDate.pack"></nb-icon>
      </button>
    </app-date-picker>
  </nb-form-field>


  <div *ngIf="help" class="form-help">{{help}}</div>
</div>

