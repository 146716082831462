<div class="form-field">
  <label *ngIf="label" [class.disabled]="disabled" [for]="id" class="form-label mb-1">{{label}}</label>
  <div>
    <nb-select class="select-opacity"
               [size]="sizes"
               [multiple]="multiple"
               [ngStyle]="{'width': width}"
               [class.form-invalid]="showError"
               [disabled]="disabled" [fullWidth]="fullWidth"
               [placeholder]="placeholder"
               (selectedChange)="selectedChange($event)"
               [(selected)]="value">
      <nb-select-label *ngIf="multiple" class="d-flex gap-2 flex-wrap">
        <ng-container *ngFor="let current of currentSelection">
          <div class="tag">
            {{current.label}}
          </div>
        </ng-container>
      </nb-select-label>
      <nb-option *ngFor="let option of options"
                 [nbTooltip]="option.tooltip || ''"
                 [nbTooltipDisabled]="!option.tooltip"
                 [value]="option.value">{{option.label}}</nb-option>
    </nb-select>
  </div>
  <div *ngIf="help" class="form-help">{{help}}</div>
</div>


