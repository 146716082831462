<div class="form-field">
  <label *ngIf="label" [class.disabled]="disabled" [for]="id" class="form-label mb-1">{{label}}</label>
  <div>

    <nb-form-field >
      <button [tabIndex]="-1"  type="button" unfocus nbPrefix nbButton ghost (click)="onEye($event)">
        <nb-icon [icon]="suffixIcon.icon" [pack]="suffixIcon.pack"></nb-icon>
      </button>
      <input #inputElement [appFocus]="focus"
             [disabled]="disabled" nbInput
             (ngModelChange)="onChangeSmart($event)"
             [fullWidth]="fullWidth"
             [type]="type" [(ngModel)]="valueSmart"
             [placeholder]="placeholder" [class.disabled]="disabled"/>
      <button [tabIndex]="-1" type="button" unfocus nbSuffix nbButton ghost (click)="onClear($event);">
        <nb-icon *ngIf="value" [icon]="iconClose.icon" [pack]="iconClose.pack"></nb-icon>
      </button>
    </nb-form-field>

  </div>

  <div *ngIf="help" class="form-help">{{help}}</div>
</div>

