import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  IconClose,
  IconCollapse,
  IconExpand,
  IconExternalLink, IconMoreVertical,
  IconRefresh,
  IconRotateLeft,
  IconRotateRight, IconSearchMinus,
  IconSearchPlus
} from "../../../@models/icons";

@Component({
  selector: 'app-card-actions',
  templateUrl: './card-actions.component.html',
  styleUrls: ['./card-actions.component.scss']
})
export class CardActionsComponent implements OnInit {
  @Input() asMoreVertical: boolean = true;
  @Input() expand: boolean = true;
  @Input() close: boolean = true;
  @Input() external: boolean = false;
  @Input() plus: boolean = false;
  @Input() minus: boolean = false;
  @Input() refresh: boolean = false;
  @Input() left: boolean = false;
  @Input() right: boolean = false;
  @Output() onMaximize: EventEmitter<void> = new EventEmitter<void>();
  @Output() onMinimize: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() onExternal: EventEmitter<void> = new EventEmitter<void>();
  @Output() onPlus: EventEmitter<void> = new EventEmitter<void>();
  @Output() onMinus: EventEmitter<void> = new EventEmitter<void>();
  @Output() onRefresh: EventEmitter<void> = new EventEmitter<void>();
  @Output() onLeft: EventEmitter<void> = new EventEmitter<void>();
  @Output() onRight: EventEmitter<void> = new EventEmitter<void>();

  IconRefresh = IconRefresh;
  IconRotateLeft = IconRotateLeft;
  IconRotateRight = IconRotateRight;
  IconPlus = IconSearchPlus;
  IconMinus = IconSearchMinus;
  IconExternalLink = IconExternalLink;
  IconExpand = IconExpand;
  IconCollapse = IconCollapse;
  IconClose = IconClose;
  IconMore = IconMoreVertical;

  maximized: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  onClickMax() {
    this.maximized = true;
    this.onMaximize.emit();
  }
  onClickMin() {
    this.maximized = false;
    this.onMinimize.emit();
  }
  onClickClose() {
    this.maximized = false;
    this.onClose.emit();
  }

  onClickExternal() {
    this.onExternal.emit();
  }
  onClickPlus() {
    this.onPlus.emit();
  }
  onClickMinus() {
    this.onMinus.emit();
  }
  onClickRefresh() {
    this.onRefresh.emit();
  }
  onClickLeft() {
    this.onLeft.emit();
  }
  onClickRight() {
    this.onRight.emit();
  }

}
