import {Component, OnInit} from '@angular/core';
import {NbCalendarDayCellComponent, NbNativeDateService} from "@nebular/theme";
import {Calendario} from "../../../../models/calendar";
import {BusService} from "../../../../@core/@bus/bus.service";

@Component({
  selector: 'app-calendario-custom-day-cell',
  templateUrl: './calendario-custom-day-cell.component.html',
  styleUrls: ['./calendario-custom-day-cell.component.scss'],
})
export class CalendarioCustomDayCellComponent extends NbCalendarDayCellComponent<Date> implements OnInit{
  listaCalendario: Calendario[] = [];
  loading: boolean = true;
  showDate: boolean = false;
  fecha: number = 0;
  isEuropaFactor: boolean = false;

  constructor(private bus: BusService, public nbDateService: NbNativeDateService) {
    super(nbDateService);
  }

  ngOnInit(): void {
    //this.getCalendario();
    if(this.bus.isColectivoEuropaFactor()){
      this.isEuropaFactor = true;
    }
    const month: string = ("0" + Number(this.visibleDate.getMonth() + 1)).slice(-2)
    this.listaCalendario = this.bus.storage.globalGet('calendar-dates','');
    if(this.listaCalendario){
      this.listaCalendario.forEach(item => {
        if(this.isEuropaFactor){
          // @ts-ignore
          if (month === (item['vencimiento'].slice(5,7)) && this.day === Number(item['vencimiento'].slice(8,10))){
            this.showDate = true;
          }
        }else{
          if (month === (item.publicado.slice(5,7)) && this.day === Number(item.publicado.slice(8,10))){
            this.showDate = true;
          }
        }
      });
    }
  }

  getMonthDaysCount(date: string | Date): number {
    const f: Date = new Date(date);
    f.setMonth(f.getMonth() + 1);
    f.setDate(0);
    return f.getDate();
  };

}
