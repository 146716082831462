import {Component, EventEmitter, Input, OnInit, Optional, Output, Self} from '@angular/core';
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {getUUID} from "../../../@models/common";
import {Icon, IconClose, IconEyeOff, IconEyeOn} from "../../../@models/icons";
import {NbComponentSize} from "@nebular/theme/components/component-size";


@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input() focus: boolean = false;
  @Input() type: string = 'text';
  @Input() label?: string;
  @Input() size: NbComponentSize = 'medium';
  @Input() placeholder: string = $localize`introduzca un valor`;
  @Input() help?: string;
  @Input() disabled: boolean = false;
  @Input() fullWidth: boolean = true;
  @Input() icon: Icon | undefined;
  @Input() align: 'left' | 'right' | 'center' =  'left';
  @Input() eye: boolean = false;  // Para activer el clear icon y el eye icon
  @Input() clear: boolean = false;  // Para activer el clear icon y el eye icon
  @Output() iconClick: EventEmitter<void> = new EventEmitter<void>();


  value: any;  // No se pasa como parámetro, poner un formControl

  public onChangeFn = (_: any): void => {};
  public onTouchedFn = (): void => {};

  id: string = getUUID();

  isEmpty: boolean = true;
  eyeState: boolean = true;

  originalType: string = '';

  suffixIcon: Icon = IconEyeOff;

  constructor(@Self() @Optional() public control: NgControl) {
    this.control && (this.control.valueAccessor = this);
  }

  public get showError(): boolean {
    let dirty = false;
    let invalid = false;
    let touched = false;
    if (!this.control) {
      return false;
    } else {
      dirty = this.control.dirty || false;
      invalid = this.control.invalid || false;
      touched = this.control.touched || false;
    }
    return invalid ? (dirty || touched) : false;
  }

  ngOnInit(): void {
    if (this.clear) {
      this.suffixIcon = IconClose;
    }

    this.originalType = this.type;
    this.checkEmpty();

    if(!this.clear) {
      if(this.originalType == "password") {
        this.suffixIcon = IconEyeOn;
        this.eyeState = true;
      } else {
        this.suffixIcon = IconEyeOff;
      }
    }

  }

  checkEmpty(): void {
    if (this.value) {
      this.isEmpty = this.value.length == 0;
    } else {
      this.isEmpty = true;
    }
  }

  onClear(e:Event ): void {
    e.stopPropagation()
    if(!this.clear && this.originalType == "password") {
      this.eyeState = !this.eyeState;
      if(this.eyeState) {
        this.suffixIcon = IconEyeOn;
        this.type = 'password';
      } else {
        this.suffixIcon = IconEyeOff;
        this.type = 'text';
      }
      return;
    }
    this.value = '';
    this.onChange(this.value);
  }

  iconClicked(e:Event): void {
    e.stopPropagation()
    this.iconClick.emit();
  }
  onChange(val: any): void {
    this.checkEmpty();
    this.onTouchedFn();
    this.onChangeFn(val);
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
    if(this.value) {
      // Notificamos que hemos cambiado el valor
      this.onChangeFn(this.value);
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.checkEmpty();
  }


}
