import {Metadata, User, UserConfig} from "./user";
import {GrpcRequest, GrpcResponse, OriginEmpty, Origin} from "../@core/@models/grpc";
import {BaseClass} from "../@core/@models/common";
import {SharedCredentials} from "../core/authentication";

export class Session extends BaseClass {
    remember: boolean = false;
    session_id: string = '';
    refresh_token: string = '';
    expires_in: number = 0;

    constructor(asObject?: object) {
        super();

        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

}

export class SignUpRequest extends GrpcRequest {

    dni: string = '';
    email: string = '';
    password: string = '';
    name: string = '';
    surnames: string = '';
    phone: string = '';
    token_recaptcha: string = '';
    origin: Origin = OriginEmpty;
    metadatos?: Metadata

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

}

export class SignUpResponse extends GrpcResponse {
    error: string = '';
    logged: boolean = false;
    me?: User;
    config?: UserConfig;
    metadata?: Metadata;

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }
}



export class RecoveryPasswordRequest extends GrpcRequest {

    login: string = '';
    origin: Origin = OriginEmpty;

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

}

export class RecoveryPasswordResponse extends GrpcResponse {
    error: string = '';

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }
}



export class ReconnectRequest extends GrpcRequest {
    origin: Origin = OriginEmpty;

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

}


export class LoginRequest extends GrpcRequest {

    login: string = '';
    password: string = '';
    remember: boolean = false;
    sso: string = '';

    shared?: SharedCredentials;

    origin: Origin = OriginEmpty;

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

}

export class LoginResponse extends GrpcResponse {
    error: string = '';
    logged : boolean = false;
    me?: User;
    config?: UserConfig;
    session?: Session;

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }
}




export class LogoutRequest extends GrpcRequest {

    origin: Origin = OriginEmpty;

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

}

export class LogoutResponse extends GrpcResponse {
    error: string = '';

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }
}

