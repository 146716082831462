import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Icon, IconClose, IconEyeOff, IconEyeOn} from "../../../@models/icons";
import {getUUID} from "../../../@models/common";

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputPasswordComponent,
    multi: true
  }]
})
export class InputPasswordComponent implements OnInit, ControlValueAccessor {
  @Input() focus: boolean = false;
  @Input() label?: string;
  @Input() placeholder: string = '◦◦◦◦◦◦◦◦';
  @Input() help?: string;
  @Input() disabled: boolean = false;
  @Input() fullWidth: boolean = true;
  @Input() password: boolean = true;  // Para activer el clear icon y el eye icon
  @Output() iconClick: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('inputElement') inputElement?: { nativeElement: HTMLInputElement }

  iconClose = IconClose;

  type = 'password';

  value: any = '';  // No se pasa como parámetro, poner un formControl

  valueSmart: string = '';

  private onTouched!: Function;
  private onChanged!: Function;

  id: string = getUUID();

  passwordState: boolean = true;
  suffixIcon: Icon = IconEyeOn;

  constructor(@Inject(Window) private window: Window,) { }

  ngOnInit(): void {
    this.passwordState = this.password;
    this.setEyeIcon();
    this.toSmart();
  }

  setEyeIcon() {
    if (this.passwordState) {
      this.suffixIcon = IconEyeOn;
    } else {
      this.suffixIcon = IconEyeOff;
    }
  }


  onEye(e:Event) {
    e.stopPropagation()
    this.passwordState = !this.passwordState;
    this.setEyeIcon();
    this.toSmart();
  }

  onChange(val: any) {
    this.onTouched();
    this.onChanged(val);
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;

    // Notificamos que hemos cambiado el valor
    this.onChanged(this.value);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.toSmart();
  }

  toSmart() {
    this.valueSmart = this.value;

    if (this.passwordState) {
      this.type = 'password';
    } else {
      this.type = 'text';
    }
  }


  onChangeSmart(val: any) {
    this.value = val;
    this.onChange(val);
  }


  onClear(e:Event) {
    e.stopPropagation()
    this.value = '';
    this.onChange('');
    this.toSmart();
  }

}
