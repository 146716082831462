import {BaseClass} from '../@core/@models/common';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {UserConfig} from "../models/user";
import {NbSidebarState} from "@nebular/theme";
import {GetGlobalBus} from "../@core/@models/globals";
import {Authentication} from "./authentication";
import {BackendResponse} from "../@core/@bus/request.model";
import {ToMap} from "../@core/@models/types";
import {Backend} from "./backend";
import {AuthData} from "./auth-data";


export type CommandParam = {
  name: string,
  value: string,
}

export type Command = {
  command: string,
  params: Array<CommandParam>,
}

export type BadgeChange = {
  name: string,
  value: string,
}

export enum TagEvent {
  Server = "server_event",
  Chat = "chat_event",
  Alert = "alert_event",
  Toastr = "toastr",
  RemoteCommand = "remote_command",
  Notification = "notification_event",
  SessionEject = "session_eject",

  UpdateData = "update_data", // A modo de ejemplo
}

export const delayedEvents = [
  TagEvent.Notification,
  TagEvent.Alert,
];

// Si ya está encolado, no lo encolamos
export const delayedEventsOnlyOnce = [
  TagEvent.UpdateData,
];

export class ServerEvent extends BaseClass {
  event: string = '';
  data: any = {};
  constructor(asObject?: object) {
    super();
    if (asObject) {

      if (asObject instanceof  ServerEvent) {
        this.event = asObject.event;
        this.data = asObject.data;
      } else {
        this.copyFromObject(asObject);
      }
    }
  }
  getEvent(): string {
    return this.event;
  }

  setEvent(event: string): void {
    this.event = event;
  }

  getParam(param: string): any {
    if (this.data && this.data.hasOwnProperty(param)) {
      return this.data[param];
    }
    return undefined;
  }

  setParam(param: string, value: any) {
    this.data[param] = value;
    return undefined;
  }
}


export class RemoteCommandEvent extends ServerEvent {

  command: string = '';
  params: Array<CommandParam> = [];

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }

    this.parse();
  }

  parse(): void {
    const json = this.getParam('command');
    if (json) {
      const cmd = JSON.parse(json);
      this.command = cmd.command;
      this.params = cmd.params;
    }

  }

}


export class EventManager extends BaseClass {

  window: Window;
  lastBadges: Array<[string, string]> | undefined;

  menuDocumentation: BehaviorSubject<boolean>;
  mainSidebarStatusEvent: Subject<NbSidebarState> = new Subject<NbSidebarState>();
  newConfigEvent: Subject<UserConfig | undefined> = new Subject<UserConfig | undefined>();
  newBadgesEvent: Subject<Array<[string, string]> | undefined> = new Subject<Array<[string, string]> | undefined>();
  badgeChangeEvent: Subject<BadgeChange> = new Subject<BadgeChange>();
  animation: Subject<string> = new Subject<string>();
  calendarioEvent: BehaviorSubject<string>;

  private subs: Subscription[] = [];

  constructor() {
    super();

    this.window = GetGlobalBus().dom.getWindow();

    let inDocumentacion = false;
    const currentUrl = this.window.location.pathname;
    if (currentUrl.startsWith('/documentacion')) {
      inDocumentacion =  true;
    }
    this.calendarioEvent = new BehaviorSubject<string>('');
    this.menuDocumentation = new BehaviorSubject<boolean>(inDocumentacion);

    this.init();
  }


  init(): void {

    // Subscribimos a eventos
    this.subscribeToEvents();

    GetGlobalBus().events.tagEventSubscribe(TagEvent.SessionEject, (event: ServerEvent): void => {
      GetGlobalBus().logger.warningBrowser("*** Expulsado desde el servidor ***");
      Authentication.getAuth().destroy();
      GetGlobalBus().events.loginEvent.next(false);
    });

    // Cambios en los badges
    this.newConfigEvent.subscribe( (config): void => {
      if (config) {
        this.processBadgeChangeEvents(config.badges);
      }
    });
    this.newBadgesEvent.subscribe( (badges): void => {
      this.processBadgeChangeEvents(badges);
    });
  }

  processBadgeChangeEvents(current: Array<[string, string]> | undefined): void {
    const currentMap = ToMap(current);
    const lastMap = ToMap(this.lastBadges);


    // Vemos los que han cambiado de los que vienen
    currentMap.forEach( (valueCurrent, indexCurrent): void => {
      if (valueCurrent != lastMap.get(indexCurrent)) {
        this.badgeChangeEvent.next({
          name: indexCurrent,
          value: valueCurrent,
        });
      }
    });

    // Ahora, los que no han venido
    lastMap.forEach( (valueLast, indexLast): void => {
      if (!currentMap.has(indexLast)) {
        this.badgeChangeEvent.next({
          name: indexLast,
          value: "",
        });
      }
    });


    this.lastBadges = current;
  }



  private subscribeToEvents(): void {

    // Desubscribimos si ya se hizo previamente
    this.subs.forEach( (sub: Subscription): void => {
      sub.unsubscribe();
    });

    // Eventos
    const tags = Object.values(TagEvent);
    tags.forEach((value, index): void => {
      GetGlobalBus().events.subscribeToEvent(value, (event: any): void => {

        // Animamos el logo por cada evento que recibamos del servidor
        this.animation.next('logo');

        GetGlobalBus().logger.debugBrowser('evento del servidor',value, event);
        const serverEvent: ServerEvent = new ServerEvent({
          event: value,
          data: event,
        })

        // Eventos que retrasamos a que la página sea visible
        if (delayedEvents.includes(value)) {
          if (!GetGlobalBus().dom.pageIsVisible()) {

            const onlyOnce: boolean = delayedEventsOnlyOnce.includes(value)
            GetGlobalBus().dom.addWhenVisible( value, onlyOnce, (): void => {
              GetGlobalBus().events.tagEventNext(value,serverEvent);
              return;
            });
          }
        }
        GetGlobalBus().events.tagEventNext(value,serverEvent);
      });
    });


    this.subs.push(GetGlobalBus().events.tagEventSubscribe(TagEvent.RemoteCommand, (event: ServerEvent): void => {
      const commandEvent: RemoteCommandEvent = new RemoteCommandEvent(event);
      switch(commandEvent.command) {
        case "update_config":
          Backend.configRequestUpdate().then( (config): void => {
            if (config) {
              // Nueva configuración
              // Guardamos la nueva configuración en auth
              if (Authentication.getAuth()) {
                Authentication.getAuth().updateConfig(config);
                this.newConfigEvent.next(config);

                // Si es necesario alguna validación
                this.routeIfPendingConfigurations();
              }
            }
          });
          break;
        case "update_alerts":
          // TODO: implementar
          break;

      }

    }));

    this.subs.push(GetGlobalBus().events.loginEvent.subscribe( (logged: boolean): void => {
      this.routeIfPendingConfigurations();
    }));


  }

  send(event: ServerEvent): Promise<BackendResponse> {
    return GetGlobalBus().events.sendEvent("browser_event", event.toBackendPayload());
  }

  animate(what: string): void {
    this.animation.next(what);
  }

  routeIfPendingConfigurations(): boolean {
    const auth: AuthData = Authentication.getAuth();
    if (auth.isValid()) {
      if (auth.hasPendingConfiguracions()) {
        GetGlobalBus().router.goTo('/configuracion');
        return true;
      }
    }
    return false;
  }


}
