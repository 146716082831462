import {BaseClass} from "../@core/@models/common";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, Subscription} from "rxjs";

export class Pagination extends BaseClass  {
  page_size: number = 0;
  last_key: string = '';
  has_more: boolean = false;
  order: 'ASC' | 'DESC' = 'ASC';

  constructor(asObject?: object) {
    super();
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }
}

export class PaginatorDataSource<T> extends DataSource<T> {

    private _data: T[] = [];
    private _loadMore?: (start: number, end: number) => Promise<Pagination>;
    private _hasMore: boolean = true;
    private _subscription = new Subscription();
    private _dataStream = new BehaviorSubject<T[]>([]);

    constructor() {
      super();

    };
    connect(collectionViewer: CollectionViewer): Observable<T[]> {
      this._subscription = collectionViewer.viewChange.subscribe(range => {
        this.fetch(range.start, range.end);
      });
      return this._dataStream;
    }
    disconnect(collectionViewer: CollectionViewer) {
      this._subscription.unsubscribe();
    }

    setData( data: T[]) {
      this._data = data;
    }

    setLoadMore(loadMore: (start: number, end: number) => Promise<Pagination>) {
      this._loadMore = loadMore;
    }

    protected fetch(start: number, end: number) {
      // start incluido
      // end no incluido

      if (this._data.length  <= end ) {
        this.loadMore(start, end);
      } else {
        this._dataStream.next(this._data.slice(start, end));
      }
    }

    loadMore(start: number, end: number) {
      if (this._hasMore && this._loadMore) {
        this._loadMore(start, end - 1).then( (res) => {
          this._hasMore = res.has_more;
          this._dataStream.next(this._data.slice(start, end));
        }).catch( () => {

        });
      }
    }
}
