import {Component, OnDestroy} from '@angular/core';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-destroyer',
  templateUrl: './destroyer.component.html',
  styleUrls: ['./destroyer.component.scss']
})
export class DestroyerComponent implements OnDestroy {

  protected subs: Subscription[] = [];

  constructor() { }

  ngOnDestroy(): void {
    this.subs.forEach( (sub) => {
      sub.unsubscribe();
    });
  }

  autoUnsubsbriber(sub: Subscription): Subscription {
    this.subs.push(sub);
    return sub;
  }

}
