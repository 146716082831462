import {Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChanges} from '@angular/core';
import {getUUID} from "../../../@models/common";
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {Option} from "../../../@models/types";
import {NbComponentSize} from "@nebular/theme/components/component-size";

const nadaSeleccionable = 'Nada seleccionable';
const unaOpcion = 'Seleccione una opción';
const algunaOpcion = 'Seleccione alguna opción';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit,OnChanges, ControlValueAccessor {
  @Input() label?: string;
  @Input() placeholder: string = '';
  @Input() help?: string;
  @Input() disabled: boolean = false;
  @Input() fullWidth: boolean = true;
  @Input() multiple: boolean = false;
  @Input() width?: string;
  @Input() sizes: NbComponentSize = 'medium'; //[ 'tiny', 'small', 'medium', 'large', 'giant' ];


  placeholderOriginal: string = '';

  @Input() options: Option[] = [];
  @Input() selected?: string | string[];

  currentSelection: Option[] = [];

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();


  selectedChange(val: string | string[]): void {
    this.value = this.adaptValue(val);
    this.setCurrentSelection(val);
    this.onChangeValue(val);
  }

  value: any;  // No se pasa como parámetro, poner un formControl

  public onChangeFn = (_: any): void => {};
  public onTouchedFn = (): void => {};

  id: string = getUUID();

  constructor(@Self() @Optional() public control: NgControl) {
    this.control && (this.control.valueAccessor = this);
  }

  setCurrentSelection(code: string | string[]): void {
    if (Array.isArray(code)) {
      this.currentSelection = this.options.filter( (opt) => {
        if (code.includes(opt.value)) {
          return true;
        }
        return false;
      });
    } else {
      this.currentSelection = this.options.filter( (opt) => {
        if (code == opt.value) {
          return true;
        }
        return false;
      });
    }
  }

  public get showError(): boolean {
    let dirty = false;
    let invalid = false;
    let touched = false;
    if (!this.control) {
      return false;
    } else {
      dirty = this.control.dirty || false;
      invalid = this.control.invalid || false;
      touched = this.control.touched || false;
    }
    return invalid ? (dirty || touched) : false;
  }

  adaptValue(value: any): string | string[] {
    let result: string | string [];
    if (value != undefined) {
      if (this.multiple) {
        if (Array.isArray(this.value)) {
          result = value;
        } else {
          // @ts-ignore
          result = [value];
        }
      } else {
        result = "";
        if (Array.isArray(value) && value.length > 0) {
          result = value[0];
        } else {
          // @ts-ignore
          result = value;
        }
      }
    } else {
      if (this.multiple) {
        result = [];
      } else {
        result = '';
      }
    }
    return result;

  }

  ngOnInit(): void {
    if (this.selected != undefined) {
      this.selected = this.adaptValue(this.selected);
      this.setCurrentSelection(this.selected);
      this.value = this.selected;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selected != undefined) {
      this.selected = this.adaptValue(this.selected);
      this.setCurrentSelection(this.selected);
      this.value = this.selected;
    }

    this.placeholderOriginal = this.placeholder;
    if (this.options.length == 0) {
      this.placeholder = nadaSeleccionable;
    } else {
      this.placeholder = this.placeholderOriginal;
      if (this.placeholder == '') {
        if (this.multiple) {
          this.placeholder = algunaOpcion;
        } else {
          this.placeholder = unaOpcion;
        }
      }
    }
  }

  onChangeValue(val: any) {
    this.onChangeFn(val);
    this.onTouchedFn();
    this.onChange.emit(val);
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
    if(this.value) {
      // Notificamos que hemos cambiado el valor
      this.onChangeFn(this.value);
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = this.adaptValue(obj);
    this.setCurrentSelection(this.value);
  }


}
