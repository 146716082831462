<div class="form-field">
    <label *ngIf="label" [class.disabled]="disabled" class="form-label mb-1">{{label}}</label>
    <div>

        <nb-form-field >
            <input
                    style="text-align: {{align}}"
                    step="1"
                    (keydown)="onKeyDown($event)"
                    [disabled]="disabled"
                    [appFocus]="focus"
                    (focus)="onFocusEvent()"
                    [fullWidth]="fullWidth"
                    type="number"
                    [(ngModel)]="value"
                    nbInput
                    [maxlength]="maxLength"
                    [placeholder]="placeholder"
                    [fieldSize]="size"
                    (input)="onInput()">
        </nb-form-field>

    </div>

    <div *ngIf="help" class="form-help">{{help}}</div>
</div>
