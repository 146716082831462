import {BaseClass} from "./common";
import {BackendRequest, BackendResponse} from "../@bus/request.model";
import {GetGlobalBus} from "./globals";

export type Origin = {
    'platform': string,
    'platform_version': string,
    'version': string,
    'device_id': string,
    'domain': string,
}

export const OriginEmpty = {
    'platform': '',
    'platform_version': '',
    'version': '',
    'device_id': '',
    'domain': '',
}

export abstract class GrpcRequest extends BaseClass {

    abstract origin: Origin;

    protected constructor(asObject?: object) {
        super();

        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

    public toBackendRequest(what?: any): object {

        const payload = this.toBackendPayload(what);

        // Metemos el origin
        const bus = GetGlobalBus();

        // @ts-ignore
        payload.origin = {
            'platform': 'web',
            'platform_version': bus.dom.getBrowserVersion(),
            'version': bus.router.getHost(),
            'device_id': bus.guid.getComputerId(),
            'domain': bus.router.getHost(),
        }

        return payload;

    }


}


export abstract class GrpcResponse extends BaseClass {
    abstract error: string;

    protected constructor(asObject?: object) {
        super();
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

    loadFromBackend(response: BackendResponse) {
        if (response.error == '') {
            this.error = '';
            this.copyFromObject(response.data);
        } else {
            this.error = response.error;
        }
    }

    sendRequest(event: string, request: GrpcRequest): Promise<void> {
        return new Promise<void>( (resolve, reject) => {
            const requestBackend = new BackendRequest();
            requestBackend.event = event;
            requestBackend.data = request.toBackendRequest();

            GetGlobalBus().sendRemote(requestBackend).then( (response) => {
                this.loadFromBackend(response);
                resolve();
            })
        });
    }
}
