import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Status} from "../../../@models/theme";

@Component({
  selector: 'app-bullet',
  templateUrl: './bullet.component.html',
  styleUrls: ['./bullet.component.scss']
})
export class BulletComponent implements OnInit, OnChanges {
  @Input() content: string = '0';
  @Input() border: Status = 'basic';
  @Input() background: Status = '';

  classBorder: string = '';
  classBackground: string = '';

  constructor() { }

  ngOnInit(): void {
    this.applyTheme();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.applyTheme();
  }
  applyTheme() {
    this.classBorder = '';
    if (this.border != '') {
      this.classBorder = 'theme-border-' + this.border;
    }
    this.classBackground = '';
    if (this.background != '') {
      this.classBackground = 'theme-background-' + this.background;
    }
  }

}
