import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Icon, IconInfo, IconWithProps} from "../../../@models/icons";
import {Status} from "../../../@models/theme";
import {Callback} from "../../../@models/types";


export type KpiBox = {
  title: string;
  subtitle?: string;
  value: string;
  value_status?: Status;
  total?: string;
  status?: Status;
  icon?: Icon;
  infoCallback?: Callback;
  infoTooltip?: string;
  link?: string;
}

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss']
})
export class KpiComponent implements OnInit, OnChanges {
  @Input() kpi: KpiBox = {
    title: "",
    value: "",
  };


  iconInfo: Icon = IconInfo;

  valueClass: string = '';

  constructor() { }

  ngOnInit(): void {
    this.doChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.doChanges();
  }

  doChanges(): void {
    // Asignamos tamaño del icon
    if (this.kpi.icon) {
      this.kpi.icon = IconWithProps(this.kpi.icon,{'fontSize': '2rem'});
    }

    if (this.kpi.value_status) {
      this.valueClass = "theme-" + this.kpi.value_status;
    }

  }

}
