import {
  Component,
  ContentChild,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges, TemplateRef
} from '@angular/core';
import {
  ColorStatusBasic,
  ColorStatusDanger, ColorStatusInfo,
  ColorStatusPrimary, ColorStatusSuccess,
  ColorStatusWarning,
  Status, ThemeVariablesCurrent
} from "../../../@models/theme";

@Component({
  selector: 'app-smart-box',
  templateUrl: './smart-box.component.html',
  styleUrls: ['./smart-box.component.scss']
})
export class SmartBoxComponent implements OnInit, OnChanges {
  @Input() status: Status = "primary";
  @Input() size:  "small" | "long" = "small";
  @Input() headerLineStatus?: Status;

  @ContentChild('corner') corner?: TemplateRef<any>;
  @ContentChild('header') header?: TemplateRef<any>;

  fillColor: string; // ColorBackground;

  constructor() {
    this.fillColor = ThemeVariablesCurrent.separator;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    switch(this.status) {
      case "primary": this.fillColor = ColorStatusPrimary; break;
      case "warning": this.fillColor = ColorStatusWarning; break;
      case "danger": this.fillColor = ColorStatusDanger; break;
      case "info": this.fillColor = ColorStatusInfo; break;
      case "success": this.fillColor = ColorStatusSuccess; break;
      case "basic": this.fillColor = ColorStatusBasic; break;
      default:
        this.fillColor = ThemeVariablesCurrent.separator;
    }
    if (!this.headerLineStatus) {
      this.headerLineStatus = this.status;
    }
  }


}
