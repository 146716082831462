import {Status} from "./theme";
import {clone} from "./lodash";


export interface Icon {
  icon: string;
  pack: string;
  status: string;
  options: any;
  fontSize: string;
  extraStyle: string;
}

export function IconWithProps(iconOriginal: Icon, props: object): Icon {
  const icon = clone(iconOriginal);
  for (const p in props) {
    if (!icon.hasOwnProperty(p)) {
      // continue;  // OJO: que propiedades opcionales con ? el valor de hasOwnProperty es false
    }
    // @ts-ignore
    icon[p] = props[p];  // Ojo que no se comprueba si los tipos son iguales
  }
  return icon;
}

export function EvaIcon(icon: string, status: Status = "basic", fontSize?: string, extraStyle?: string,options?: any): Icon {
  return {
    icon: icon,
    pack: "eva",
    status: status,
    options: options || {},
    fontSize: fontSize || "1.2rem",
    extraStyle: extraStyle || '',
  }
}


export function MatIcon(icon: string, status: Status = "basic",fontSize?: string,  extraStyle?: string,options?: any): Icon {
  return {
    icon: icon,
    pack: "material-icons",
    status: status,
    options: options || {},
    fontSize: fontSize || "1.3rem",
    extraStyle: extraStyle || '',
  }
}

export const IconEyeOn = MatIcon('visibility');
export const IconEyeOff = MatIcon('visibility_off');
export const IconClose = MatIcon('close');
export const IconSuccess = MatIcon('done_all', "success");
export const IconInfo = MatIcon('info', "info");
export const IconWarning = MatIcon('warning', "warning");
export const IconError = MatIcon('error', "danger");
export const IconImage = EvaIcon('image-outline');
export const IconFile = EvaIcon('file-text-outline');
export const IconDelete = EvaIcon('trash-2-outline');
export const IconAvatar = MatIcon('account_circle');
export const IconDownload = EvaIcon('cloud-download-outline');
export const IconUpload = EvaIcon('cloud-upload-outline');
export const IconTask = EvaIcon('checkmark-square-outline');
export function IconFromExtension(fileName: string): Icon {
  return IconFile;
}
export const IconRefresh = EvaIcon('refresh-outline');
export const IconRotateLeft = MatIcon('rotate_left');
export const IconRotateRight = MatIcon('rotate_right');
export const IconSearch = EvaIcon('search-outline');
export const IconSearchPlus = EvaIcon('maximize-outline');
export const IconSearchMinus = EvaIcon('minimize-outline');
export const IconExternalLink = MatIcon('open_in_new');
export const IconExpand = EvaIcon('expand-outline');
export const IconCollapse = EvaIcon('collapse-outline');
export const IconMenuExpanded = EvaIcon('chevron-down-outline');
export const IconMenuCollapsed = EvaIcon('chevron-right-outline');
export const IconMoreVertical = EvaIcon('more-vertical-outline');
export const IconLeft = MatIcon('navigate_before');
export const IconRight = MatIcon('navigate_next');
export const IconAdmin = MatIcon('admin_panel_settings');
export const IconCog = EvaIcon('settings-outline');
export const IconDate = MatIcon('today');
export const IconCheckList = MatIcon('checklist');
export const IconCopyToClipboard = MatIcon('content_paste');

export const IconEdit = MatIcon('edit');
export const IconAdd = MatIcon('add_circle');
export const IconPlus = EvaIcon('plus-circle-outline');
export const IconMinus = EvaIcon('minus-circle-outline');
export const IconFlip = EvaIcon('flip-2-outline');
export const IconChecked = MatIcon('check_circle');
export const IconUnchecked = MatIcon('radio_button_unchecked');
export const IconSigned = MatIcon('verified_user');
export const IconTendingFlat = MatIcon('trending_flat');
