import {BaseClass, countElements} from "../@core/@models/common";
import {Avatar, AvatarEmpty} from "../@core/@models/avatar";
import {GrpcRequest, GrpcResponse, OriginEmpty, Origin} from "../@core/@models/grpc";
import {Credentials} from "./credentials";
import {Authentication} from "../core/authentication";

export interface User  {

  credentials_id: string;
  login: string;
  email: string;
  avatar: Avatar;

  name: string;
  surnames: string;
  full_name: string;
  is_advisor: boolean;
  is_master: boolean;
  is_admin: boolean;
  clausula_legal_aceptada: boolean,
  clausula_legal_version: string,
  phone: string,
  roles: string[],
  estado: number,
  subestado: number,
  sso_token: string;
  external_id: string;
}

export function ToUser(data: any): User | null {
  if (!data) {
    return null;
  }

  // Si no tiene propiedades
  if (countElements(data) == 0) {
    return null;
  }


  let user: User = {
    name: data.name,
    is_advisor: data.is_advisor,
    is_master: data.is_master,
    is_admin: data.is_admin,
    credentials_id: data.credentials_id,
    login: data.login,
    email: data.email,
    full_name: data.full_name,
    surnames: data.surnames,
    clausula_legal_version: data.clausula_legal_version,
    clausula_legal_aceptada: data.clausula_legal_aceptada,
    phone: data.phone,
    roles: data.roles,
    estado: data.estado,
    subestado: data.subestado,
    sso_token: data.sso_token,
    external_id: data.external_id,
    avatar: {
      image_url: data.avatar.image_url ?? '',
      background_color: data.avatar.background_color,
      ink_color: data.avatar.ink_color,
      initials: data.avatar.initials,
    },
  };
  return user;
}


export function ToUserFromCredentials(credential: Credentials): User {

  let user: User = {
    name: credential.name,
    is_advisor: credential.advisor,
    is_master: credential.master,
    is_admin: credential.admin,
    credentials_id: credential.id,
    login: credential.login,
    email: credential.email,
    full_name: credential.fullname,
    surnames: credential.surnames,
    avatar: credential.avatar ? credential.avatar : AvatarEmpty,
    clausula_legal_version: credential.clausula_legal_version,
    clausula_legal_aceptada: credential.clausula_legal_aceptada,
    sso_token: credential.sso_token,
    external_id: credential.external_id,
    phone: credential.phone,
    roles: credential.roles,
    estado: credential.estado,
    subestado: credential.subestado,
  };
  return user;
}



export class UserConfig extends BaseClass {
  credentials_id: string = '';
  badges?: Array<[string, string]>;
  storage?: Array<[string, string]>;
  me?: User;

  data_string: string = '';
  data_bool: boolean = false;

  constructor(asObject?: object) {
    super();
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

}


export class SaveUserConfigRequest extends GrpcRequest {

  config?: UserConfig;
  what: string = '';
  origin: Origin = OriginEmpty;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

}


export class SaveUserConfigResponse extends GrpcResponse{
  error: string = '';
  config?: UserConfig;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }
}


export class Metadata extends BaseClass {
  credentials_id: string = '';
  metadatos?: Array<[string, string]>;
  data_string: string = '';
  data_bool: boolean = false;

  constructor(asObject?: object) {
    super();
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }


  static saveMetadataRequest(request: SaveMetadataRequest): Promise<SaveMetadataResponse> {
    return new Promise<SaveMetadataResponse>( (resolve, reject): void => {
      const response: SaveMetadataResponse = new SaveMetadataResponse();
      response.sendRequest("save_metadata", request).then( (): void => {
        if (response.error == '') {
          // Guardamos la nueva configuración en auth
          if (Authentication.getAuth() && response.metadata) {
            //Authentication.getAuth().updateConfig(response.metadata);
            //GetGlobalEventManager().newConfigEvent.next(response.metadata);
          }
        }
        resolve(response);
      });
    });
  }


  static updateMetadata(key: string, value: string): Promise<SaveMetadataResponse> {
    const request: SaveMetadataRequest = new SaveMetadataRequest();
    request.what = 'updateStorage';
    request.metadata = new Metadata();
    const storage = new Array<[string, string]>();
    // @ts-ignore
    storage[key] = value;
    request.metadata.metadatos = storage;
    return this.saveMetadataRequest(request);
  }
}


export class SaveMetadataRequest extends GrpcRequest {

  metadata?: Metadata;
  what: string = '';
  origin: Origin = OriginEmpty;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

}


export class SaveMetadataResponse extends GrpcResponse{
  error: string = '';
  metadata?: Metadata;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }
}



