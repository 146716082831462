import { Pipe, PipeTransform } from '@angular/core';
import {DateFormat} from "../../@models/date";

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (!value) {
      return '';
    }
    const df = DateFormat.fromBackend(value);
    return df.timeAgo();
  }

}
