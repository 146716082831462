import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[appAnimation]'
})
export class AnimationDirective implements OnInit{
  @Input() appAnimation = 'entrance';
  @Input() appAnimationPrefix = 'animation-';

  constructor(private renderer: Renderer2, private hostElement: ElementRef) {

  }

  ngOnInit(): void {
    if (this.appAnimation != '') {
      this.renderer.addClass(this.hostElement.nativeElement, this.appAnimationPrefix+this.appAnimation);
    }

    // Despues de 5 segundos quitamos la animación
    setTimeout((): void => {
      this.renderer.removeClass(this.hostElement.nativeElement, this.appAnimationPrefix+this.appAnimation);
    }, 5000);
  }


}
