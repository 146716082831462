import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-option-slider',
  templateUrl: './option-slider.component.html',
  styleUrls: ['./option-slider.component.scss']
})
export class OptionSliderComponent implements OnInit {
  @Input() label: string = '';
  @Input() subtitle: string = '';
  @Input() footer: string = '';
  @Input() checked: boolean = true;
  @Input() value: number = 0;
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() disabled: boolean = false;
  @Output() onChange = new EventEmitter<number>();
  @Output() onSwitch = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }


  onEnabled(data: any) {
    this.checked = !this.checked;
    this.onSwitch.emit(this.checked);
  }



  onChangeSlider(value: number) {
    this.onChange.emit(value);
  }

}
