<div class="wrapper">
  <div class="flex-space-between wrapper-header">
    <div>
      <div>{{label}}</div>
      <div *ngIf="subtitle != ''" class="label-subtitle">{{subtitle}}</div>
    </div>
    <app-switch [disabled]="disabled" size="tiny" [checked]="checked" (change)="onEnabled($event)"></app-switch>
  </div>
  <div *ngIf="checked" class="wrapper-background">
    <div *ngIf="checked" class="w-100 pt-4 wrapper-background">
      <app-slider [disabled]="disabled" [showThumbLabel]="true" [min]="min" [max]="max" [(ngModel)]="value" (onChange)="onChangeSlider($event)"></app-slider>
    </div>
    <div *ngIf="checked && footer" class="pb-3 text-center text-disabled">{{footer}}</div>
  </div>
</div>


