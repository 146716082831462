import { Pipe, PipeTransform } from '@angular/core';
import {FormatBytes} from "../../@models/strings";

@Pipe({
  name: 'bytesSmart'
})
export class BytesSmartPipe implements PipeTransform {

  // Args0 => decimales
  // Ejempo bytesSmart: 2

  transform(value: number, ...args: unknown[]): string {
    let decimals = 0;
    if (args.length > 0) {
      decimals = args[0] as number;
    }
    return FormatBytes(value, decimals);
  }

}
