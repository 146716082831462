<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card *ngIf="!isLoading" class="dialog-wrapper" status="primary" [nbSpinner]="isLoading || executing" nbSpinnerStatus="primary">
    <nb-card-header>
      <div class="flex-space-between">
        <span>{{labelControlAccesso}}</span>
        <app-header-action [icon]="iconClose" status="control" (onClick)="onClose()"></app-header-action>
      </div>
    </nb-card-header>
    <nb-card-body>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit($event)" >
        <app-input [clear]="true" [focus]="true" label="{{labelCodiceFiscale}}" placeholder="" type="text" formControlName="codiceFiscale"></app-input>
        <div class="flex-space-between">
          <app-input [clear]="true" [focus]="true" label="{{labelRiferimento}}" placeholder="" type="password" formControlName="riferimento"></app-input>
            <app-button label="" class="mt-4 mx-3" [icon]="iconInfo" size="small" status="control" (onPress)="openInfo()"></app-button>
        </div>
        <app-sizedbox height="1rem"></app-sizedbox>
        <div class="flex-space-between">
          <app-switch size="tiny" formControlName="remember">
            <span>{{labelGuardarSesion}}</span>
          </app-switch>
          <!--<app-button size="tiny" label="{{labelNuevoUsuario}}" (onPress)="onNuevoUsuario()"></app-button>-->
        </div>

        <nb-alert class="mt-2 mb-0" *ngIf="error" closable status="danger" (close)="onCloseAlertError()">
          {{error}}
        </nb-alert>

        <nb-alert class="mt-2 mb-0" *ngIf="info" closable status="info" (close)="onCloseAlertInfo()">
          {{info}}
        </nb-alert>

      </form>
    </nb-card-body>
    <nb-card-footer>
      <div class="flex-space-between">
        <app-button [spinner]="true" status="primary" [disabled]="!loginForm.valid" label="{{labelAcceder}}" (onPress)="onSubmit($event)"></app-button>
        <app-sizedbox width="20px"></app-sizedbox>
        <app-link label="{{labelRestaurar}}" [disabled]="!hasUser()" (onClick)="onRememberPassword()"></app-link>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>


<ng-template #dialogInfo let-data let-ref="dialogRef">
  <nb-card *ngIf="!isLoading" class="dialog-wrapper" status="primary" [nbSpinner]="isLoading || executing" nbSpinnerStatus="primary">
    <nb-card-header>
      <div class="flex-space-between">
        <span>{{labelEnfoque}}</span>
        <app-header-action [icon]="iconClose" status="control" (onClick)="onCloseInfo()"></app-header-action>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="flex-space-between" style="align-items: baseline !important;">
        <div class="bloque-info">
          <span class="title-info">{{labelCarta}}</span>
          <img class="ps-3 hide-md img-bg" src="assets/img/europafactor/carta_new.jpg" alt="EuropaFactor"/>
        </div>
        <div class="bloque-info">
          <span class="title-info">{{labelSMS}}</span>
          <img class="ps-3 hide-md img-bg" src="assets/img/europafactor/sms_new.jpg" alt="EuropaFactor"/>
        </div>
        <div class="bloque-info">
          <span class="title-info">{{labelEmail}}</span>
          <img class="ps-3 hide-md img-bg" src="assets/img/europafactor/mail_new.jpg" alt="EuropaFactor"/>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
