import {NgModule, Optional, SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

import {BusService} from './bus.service';
import {GuidService} from './guid.service';
import { HttpClientModule } from '@angular/common/http';
import {GlobalsService} from './globals.service';
import {WebSocketService} from './websocket.service';
import {makeError} from '../@models/common';
import {WasmService} from "./wasm.service";
import {DownloadService} from "./download.service";
import {LoggerService} from "./logger.service";
import {StorageService} from "./storage.service";

@NgModule({
  declarations: [],
  providers: [
    BusService,
    GlobalsService,
    GuidService,
    WebSocketService,
    CookieService,
    WasmService,
    DownloadService,
    LoggerService,
    StorageService,
    ],
  exports: [
  ],
  imports: [
    CommonModule,
    HttpClientModule,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      makeError('BusModule ya está cargado. Importarlo sólo desde LoggedLayoutModule');
    }
  }
}
