import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbDialogModule,
  NbIconModule,
  NbSpinnerModule
} from "@nebular/theme";
import {ReactiveFormsModule} from "@angular/forms";
import {FormViewModule} from "../../@core/@form-view/@form-view.module";
import { LoginEuropafactorComponent } from './login-europafactor/login-europafactor.component';
import { LoginFormComponent } from './login-form/login-form.component';



@NgModule({
  declarations: [
    LoginComponent,
    LoginEuropafactorComponent,
    LoginFormComponent
  ],
  exports: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    NbCardModule,
    NbAlertModule,
    NbSpinnerModule,
    ReactiveFormsModule,
    NbDialogModule.forChild(),
    NbButtonModule,
    NbIconModule,
    FormViewModule,
  ]
})
export class LoginModule { }
