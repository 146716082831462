import {BaseClass} from "../@core/@models/common";
import {Avatar} from "../@core/@models/avatar";
import {Colectivo} from "./colectivo";
import {GrpcRequest, GrpcResponse, OriginEmpty, Origin} from "../@core/@models/grpc";
import {Pagination} from "./pagination";
import {Option} from "../@core/@models/types";

// Estados
export const CredentialsPendingValidation = 0;
export const CredentialsValidated          = 1;
export const CredentialsRecovering         = 2;
export const CredentialsDeleted            = 3;
export const CredentialsPurged             = 4;

// Subestados
export const CredentialsPendingPhoneValidation = 5;
export const CredentialsChangePassword         = 6;
export const CredentialsTWARequired            = 7;

export let Roles: Option[] = [
    { label: "Editor", value: "edit"},
    { label: "Publicador", value: "publish"},
]

export class Credentials extends BaseClass{

    id: string = '';
    login: string = '';
    created: string = '';
    email: string = '';
    phone: string = '';
    name: string = '';
    surnames: string = '';
    inactivo: boolean = false;
    only_once: string = '';
    only_once_expire: string = '';
    master: boolean = false;
    advisor: boolean = false;
    admin: boolean = false;
    estado: number = 0;
    subestado: number = 0;
    clausula_legal_aceptada: boolean = false;
    clausula_legal_version: string = '';
    locked_until: number = 0;
    access_errors: number = 0;
    colectivo: string = '';
    colectivo_row?: Colectivo;
    avatar_url: string = '';
    no_borrable: boolean = false;
    fecha_borrado: string = '';
    last_connection: string = '';
    roles: string[] = [];
    fullname: string = '';
    avatar?: Avatar;
    sso_token: string = '';
    external_id: string = '';

    constructor(asObject?: object) {
        super();
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }
}


export class ModifyCredentialsRequest extends GrpcRequest {

    data?: Credentials;
    operation: string = '';

    origin: Origin = OriginEmpty;

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

}


export class ModifyCredentialsResponse extends GrpcResponse {

    data?: Credentials;
    error: string = '';

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }
}


export class ListCredentialsRequest extends GrpcRequest {
    search: string = '';
    filter?: Credentials;
    pagination?: Pagination;
    origin: Origin = OriginEmpty;

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

}


export class ListCredentialsResponse extends GrpcResponse {

    data?: Array<Credentials>;
    error: string = '';
    pagination?: Pagination;

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }


}
