import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  NbDialogRef,
  NbDialogService
} from "@nebular/theme";
import {CalendarComponent} from "../calendar/calendar.component";
import {DateFormat} from "../../../@models/date";

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  @Input() title: string = $localize`Selecciona una fecha`;
  @Input() date?: string
  @Input() allowFuture: boolean = false;
  @Input() max: string = '';
  @Input() min: string = '';
  @Output() onChange = new EventEmitter<string>();
  @Output() onClose = new EventEmitter<void>();
  @Input() disabled: boolean = false;


  dialogRef?: NbDialogRef<any>;

  constructor(private dialogService: NbDialogService) { }

  ngOnInit(): void {
      if (!this.max && !this.allowFuture) {
        this.max = DateFormat.fromNow().toBackendDate();
      }
  }

  openDialog() {
    if (this.disabled) {
      return;
    }
    this.dialogRef = this.dialogService.open(CalendarComponent, {
      closeOnBackdropClick: false,
      autoFocus: false,
      context: {
        title: this.title,
        step: 15,
        date: this.date,
        max: this.max,
        min: this.min,
        onChangeCallback: (d: string) => {this.onChangeDate(d); },
        onCloseCallback: () => {this.onCloseClick(); },
      },
    });
  }

  onCloseClick() {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.onClose.emit();
    }
  }

  onChangeDate(d: string) {
    this.onChange.emit(d);
    if (this.dialogRef) {
      this.dialogRef.close();
      this.onClose.emit();
    }
  }

}
