<div class="form-field">
  <label *ngIf="label" [class.disabled]="disabled" [for]="id" class="form-label mb-1">{{label}}</label>
  <div>
    <nb-form-field  *ngIf="icon && !(eye || clear)">
      <button [tabIndex]="-1" type="button" unfocus nbPrefix nbButton ghost (click)="onClear($event);">
        <nb-icon [icon]="icon.icon" [pack]="icon.pack" (click)="iconClicked($event)"></nb-icon>
      </button>
      <input style="text-align: {{align}}" [class.form-invalid]="showError" [fieldSize]="size" [appFocus]="focus" [disabled]="disabled" nbInput [fullWidth]="fullWidth"  [type]="type" [attr.autocomplete]="originalType=='password' ? 'new-password' : null"  [(ngModel)]="value" [placeholder]="placeholder" (ngModelChange)="onChange($event)" [class.disabled]="disabled"/>
    </nb-form-field>

    <nb-form-field *ngIf="icon && (eye || clear)">
      <button [tabIndex]="-1" type="button" unfocus nbPrefix nbButton ghost (click)="onClear($event)">
        <nb-icon [icon]="icon.icon" [pack]="icon.pack" (click)="iconClicked($event)"></nb-icon>
      </button>
      <input style="text-align: {{align}}" [class.form-invalid]="showError" [fieldSize]="size" [appFocus]="focus"  nbInput [fullWidth]="fullWidth"  [type]="type" [attr.autocomplete]="originalType=='password' ? 'new-password' : null"  [(ngModel)]="value" [placeholder]="placeholder" (ngModelChange)="onChange($event)" [class.disabled]="disabled"/>
      <button [disabled]="disabled" [tabIndex]="-1" type="button" unfocus nbSuffix nbButton ghost (click)="onClear($event)">
        <nb-icon *ngIf="!(clear && !value)" [icon]="suffixIcon.icon" [pack]="suffixIcon.pack"></nb-icon>
      </button>
    </nb-form-field>

    <nb-form-field  *ngIf="(eye || clear) && !icon" >
      <input style="text-align: {{align}}" [class.form-invalid]="showError" [fieldSize]="size" [appFocus]="focus"  [disabled]="disabled" *ngIf="!icon" nbInput [fullWidth]="fullWidth"  [type]="type" [attr.autocomplete]="originalType=='password' ? 'new-password' : null"  [(ngModel)]="value" [placeholder]="placeholder" (ngModelChange)="onChange($event)" [class.disabled]="disabled"/>
      <button [tabIndex]="-1"  type="button" unfocus nbSuffix nbButton ghost (click)="onClear($event)">
        <nb-icon *ngIf="!(clear && !value)" [icon]="suffixIcon.icon" [pack]="suffixIcon.pack"></nb-icon>
      </button>
    </nb-form-field>

    <input style="text-align: {{align}}" [class.form-invalid]="showError" [fieldSize]="size" [appFocus]="focus"  [disabled]="disabled" *ngIf="!icon && !(eye || clear)" nbInput [fullWidth]="fullWidth"  [type]="type" [attr.autocomplete]="originalType=='password' ? 'new-password' : null"  [(ngModel)]="value" [placeholder]="placeholder" (ngModelChange)="onChange($event)" [class.disabled]="disabled"/>

  </div>

  <div *ngIf="help" class="form-help">{{help}}</div>
</div>

