import {BusService} from "../@bus/bus.service";

// Se inicializa al arrancar el bus
let _GlobalBus: BusService;

export function GetGlobalBus(): BusService {
    return _GlobalBus;
}
export function SetGlobalBus(bus: BusService) {
    _GlobalBus = bus;
}
