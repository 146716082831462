import { Pipe, PipeTransform } from '@angular/core';
import {DateFormat} from "../../@models/date";
import {FormatLocalNumber, NumberSmart} from "../../@models/strings";
import {CurrencyUnit} from "../../../models/currency";

@Pipe({
  name: 'numberSmart'
})
export class NumberSmartPipe implements PipeTransform {

  // Args0 => removeTime
  // Ejempo dateHuman: true

  transform(value: number, ...args: unknown[]): string {
    let currency;
    if (args.length > 0) {
      currency = args[0] as string;
    }
    return NumberSmart(value, currency);
  }

}
