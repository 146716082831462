import {BaseClass} from "../@core/@models/common";
import {UploadedFile} from "./uploaded-file";
import {GrpcRequest, GrpcResponse, OriginEmpty, Origin} from "../@core/@models/grpc";

export class Colectivo extends BaseClass {

    id: string = '';
    cif: string  = '';
    fecha_alta: string  = '';
    inactivo: boolean  = false;
    activar_ofertas: boolean  = false;
    nombre: string  = '';
    dominio: string  = '';
    logotipo_full?: UploadedFile;
    logotipo_full_png?: UploadedFile;
    logotipo_icono?: UploadedFile;
    logotipo_icono_png?: UploadedFile;
    logotipo_nombre?: UploadedFile;
    logotipo_favico?: UploadedFile;
    home: string = '';
    smtp_server: string = '';
    smtp_user: string = '';
    smtp_password: string = '';
    logotipo_height: number = 0;
    reply_to: string = '';
    preheader_mail: string = '';
    dias_twa: number = 0;
    dias_change_password: number = 0;
    api_url: string = '';
    api_token: string = '';

    constructor(asObject?: object) {
        super();
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

}


export class ModifyColectivoRequest extends GrpcRequest {

    data?: Colectivo;
    operation: string = '';

    origin: Origin = OriginEmpty;

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

}


export class ModifyColectivoResponse extends GrpcResponse {

    data?: Colectivo;
    error: string = '';

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }
}


export class ListColectivosRequest extends GrpcRequest {

    origin: Origin = OriginEmpty;

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

}


export class ListColectivosResponse extends GrpcResponse {

    data?: Array<Colectivo>;
    error: string = '';

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }


}
