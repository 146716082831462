import {Inject, Injectable} from '@angular/core';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {BusService} from './bus.service';
import {countElements, getTime, getUUID, gotoIndexHtml, makeError} from '../@models/common';
import {GuidService} from "./guid.service";
import {fromEvent, map, merge, of, Subscription} from "rxjs";
import {JsonStringify} from "../@models/json";

const PORT = '8000';
const RECONNECT_INTERVAL = 2000;
const PINGER_INTERVAL = 30000; // Milisegundos
const RATE_LIMIT_ERROR = 'rate limit error'; // Tiene que ser el mismo literal que en GO
const enableChunks = true;   // Si partimos las tramas o no (por si hay que echar hacia atrás)
const maxWSChunk = 30 * 1024;  // El límite de AWS es de 32KB pero lo hacemos de 30KB para que quepa el resto de la trama
const prefixLen = 66; // Longitud total del prefijo del chunk
const SIGNATURE = "vgtwebsocket"

@Injectable({
  providedIn: 'root'
})
export class WebSocketService  {
  private pingStarted = 0;
  private lastPing = 0;
  private connectString = '';
  private tabId = '';
  private computerId = '';
  // @ts-ignore
  private socket$: WebSocketSubject<any>;
  public debug = false;
  private closedManually = false;
  private lastReconnectTimeout = 0;
  private reconnectionRetries = 0;
  private lastTimeMessageWasSent = new Date();
  private lastTimeNotOpenSocket: number = 0;

  private busState: boolean = false;

  private connected = false;
  private connecting = false;
  private listeners = {};
  private hasError = false;
  private resendPendingAnswersOnReconnect = false;

  private pendingMessages = [];  // Para ir almacenando los que se envían antes de que termine de conectarse
  private pendingAnswers = {};
  private pendingAnswersSecuencia = 0;

  private socketSubscription: Subscription | null | undefined = null;

  private waitForSessionIdInterval: any = null;

  private tempChunks = {};  // Para almacenar los chunks mientras se reciben

  // @ts-ignore
  private guid: GuidService;
  // @ts-ignore
  private bus: BusService;


  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;

  constructor(@Inject(Window) private window: Window) {



  }
  setDebug(val: boolean) {
    this.debug = val;
  }

  sendBusStateEvent(newState: boolean) {
    if (this.busState != newState) {
      this.busState = newState;
      this.bus.events.busStateEvent.next(newState); // Bus desconectado
    }
  }

  init(bus: BusService) {
    this.bus = bus;
    this.guid = bus.guid;

    this.bus.logger.debugBrowser('**** Iniciado WebSockets ****');

    // No podemos garantizar el orden en el que Angular carga los servicios, por tanto, esperamos a tener la sesión
    this.waitForSessionId();

    // Creamos el pinger cada 15 segundos para mantener los puertos virtuales NAT en los routers abiertos
    setInterval( () => {
      if (this.connecting === true || this.sockConnected() === false) {
        // No enviamos
      } else {
        // Enviamos ping
        this.ping();
      }
    }, PINGER_INTERVAL);

    // Detectar cambios de red
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
        of(null),
        fromEvent(this.window, 'online'),
        fromEvent(this.window, 'offline')
    )
        .pipe(map(() => navigator.onLine))
        .subscribe(status => {
          this.networkStatus = status;
          if(this.networkStatus) {
            this.bus.logger.debugBrowser('Network Status change ONLINE');
            this.closedManually = false;
            this.reconnect();
          } else {
            this.bus.logger.errorBrowser('Network Status change OFFLINE');
            this.closedManually = true;
            this.destroySocket();
            this.sendBusStateEvent(false);
            this.connected = false;
            this.hasError = true;
          }
        });
  }

  private waitForSessionId() {
    this.waitForSessionIdInterval = setInterval( () => {
      clearInterval(this.waitForSessionIdInterval);
      this.waitForSessionIdInterval = null;

      // Ya tenemos la sesión, conectamos
      this.sockConnect();
    }, 500);
  }


  public sockCreate(force: boolean = false): void {
    if (!this.socket$ || force) {
      if (this.socket$) {
        this.socket$.complete();
      }
      this.socket$ = this.sockGetNewWebSocket();
      this.sockSubscribe();
    }
  }

  public sockConnect(): void {

    if (!this.socket$ || this.socket$.closed) {
      try {
        this.sockCreate();

        this._sendPending();

      } catch (err) {
        this.bus.logger.errorBrowser("sockConnect: catch",err);
      }
    } else {
      this._sendPending();
    }
  }

  public sockConnected(): boolean {

    if (!this.socket$ || this.socket$.closed) {
      return false;
    }
    return true;
  }

  private sockGetNewWebSocket() {

    // Calcular el computerId
    if (this.computerId == "") {
      this.computerId = this.guid.getComputerId();
    }

    const puerto = this.bus.configuration.getBusPort() || PORT;

    this.tabId = this.guid.getGUID();
    const server = this.bus.getHostForRequests();
    this.connectString = 'wss://' + server + ':' + puerto + '/bus?tabId=' + this.tabId + '&computerId=' + this.computerId;
    this.bus.logger.debugBrowser('Nuevo Socket: ' + this.connectString, this.tabId, this.computerId );

    return webSocket( {
      url: this.connectString,
      openObserver: {
        next: () => {
          this.onOpen();
        }
      },
      closeObserver: {
        next: () => {
          this.onClose();
        }
      },
      closingObserver: {
        next: () => {
          this.onClosing();
        }
      },
      deserializer: this.wireDeserializer,
      serializer: this.wireSerializer,
    });
  }


  sockClose() {
    this.closedManually = true;
    this.socket$.complete();

    this.destroySocket();
  }

  private destroySocket() {
    if (this.socketSubscription) {
      this.socketSubscription.unsubscribe();
      this.socketSubscription = null;
    }
    // @ts-ignore
    this.socket$ = null;
  }

  isConnected(): boolean {
    return this.connected;
  }

  // Cierre la conexión notificando al servidor el error
  sockCloseWithError(code: number, reason: string) {
    this.closedManually = true;
    this.socket$.error({code: code, reason: reason});

    this.destroySocket();
  }

  sockSubscribe(next?: (value: any) => void, error?: (error: any) => void, complete?: () => void) {

    this.socketSubscription = this.socket$.subscribe({
      next: (msg) => {
        // Se llama cada vez que viene  un mensaje del servidor
        this.onMessage(msg);
        if (next) {
          next(msg);
        }
      },
      error: (err) => {
        // Se llama cada vez que WebSocket API proporciona algún error
        this.debugErr(err);
        this.onError(err);
        if (error) {
          error(err);
        }
      },
      complete: () => {
        // Se llama cada vez que se cierra la conexión, por la razón que fuere
        this.debugComplete();
        this.onClose();
        if (complete) {
          complete();
        }
      }
    });
  }

  private sockReconnect() {
    if (this.connecting === false && this.sockConnected() === false) {
      if (this.closedManually === false) {
        // reconectamos
        this.destroySocket();
        this.connecting = true;

        if (this.reconnectionRetries === 0 || !this.sockConnected()) {
          this.sockConnect();
        }

        this.reconnectionRetries++;

        this.bus.logger.debugBrowser('WSS: reconectando... en ' + this.lastReconnectTimeout + ' ms');
        setTimeout( () => {

          if (!this.sockConnected()) {
            this.sockConnect();

            // En dos segundos reconectamos para ver si se ha conectado o no
            setTimeout( () => {
              if (this.sockConnected()) {
                this.connecting = false;
                this.lastReconnectTimeout = RECONNECT_INTERVAL; // Para que empiece de cero la siguiente vuelva
                this.reconnectionRetries = 0;
                this.sockConnect();  // Para que envíe los pendings
                this.testBusResponse();
              } else {
                this.connecting = false;
                this.sockReconnect();
                if (this.reconnectionRetries >= 3) {
                  // Destruimos la sesión
                  this.bus.configuration.authDestroy();  // Va a index.html
                }
              }
            }, 2000);
          } else {
            this.connecting = false;
            this.lastReconnectTimeout = RECONNECT_INTERVAL; // Para que empiece de cero la siguiente vuelva
            this.reconnectionRetries = 0;
            this.testBusResponse();
          }

        }, this.lastReconnectTimeout);

        this.lastReconnectTimeout += RECONNECT_INTERVAL;
      }
    }
  }

  private debugMsg(msg: any, ...args: any[]) {
    if (this.debug) {
      this.bus.logger.debugBrowser('WSS: mensaje recibido: ', msg, ...args);
    }
  }
  private debugWarn(warn: any, ...args: any[]) {
    this.bus.logger.warningBrowser('WSS: warning: ', warn, ...args);
  }
  private debugErr(err: any, ...args: any[]) {
    this.bus.logger.errorBrowser('WSS: error recibido: ', err, ...args);
  }
  private debugComplete() {
    if (this.debug) {
      this.bus.logger.errorBrowser('WSS: finalizada conexión');
    }
  }

  // ========================================================================
  // implementación de vztwebsocket =========================================
  // ========================================================================

  // Cuando se recibe un mensaje
  private onMessage(msg: any) {
    this.sockReceiveMessage(msg);
  }

  // Cuando hay un error
  private onError(err: any) {
    this.sendBusStateEvent(false);
    this.connected = false;
    this.hasError = true;

    this.destroySocket();

    this.bus.logger.debugBrowser('Bus::Error => ', err);
    this.reconnect();
  }

  // Cuando se cierra la conexión
  private onClose() {
    this.sendBusStateEvent(false);
    this.connected = false;
    this.resendPendingAnswersOnReconnect = true;

    this.destroySocket();

    this.bus.logger.errorBrowser('Bus::Close => desconectado');

    // Esperamos un rato antes de reconectar por si la conexión la ha cerrado el servidor por un eject/logout
    setTimeout( () => {
      this.reconnect();
    }, 2000);
  }

  // Cuando se cierra la conexión
  private onClosing() {

    this.bus.logger.debugBrowser('Bus::Closing => desconectado ...');

  }

  // Cuando se abre la conexión
  private onOpen() {
    this.testBusResponse();
    this.connected = true;
    this.hasError = false;
    this.sendBusStateEvent(true);

    // reenviamos los que ya hemos enviado y no hemos recibido respuesta (con la misma secuencia)
    if (this.resendPendingAnswersOnReconnect) {
      this.resendPendingAnswers();
    }

    this.bus.logger.debugBrowser('Bus::Open => conectado');

    if (this.bus.configuration.authHasData()) {
      // Para que el bus registre la sesión en el servidor
      this.bus.configuration.authReconnect().then( (logged) => {
        if (logged) {
          this.testBusResponse();
          this.bus.logger.debugBrowser('Bus::Reconectada sesión Auth');

          setTimeout(() => {
            // Enviamos los pendientes si los hay
            this._sendPending();
          }, 500);
        }
      });
    }

    setTimeout(() => {
      // Enviamos los pendientes si los hay
      this._sendPending();
    }, 250);

  }

  private reconnect()  {
    this.sockReconnect();
  }

  connect() {
    this.sockConnect();
  }

  disconnect() {
    this.sockClose();
  }

  // encola un mensaje
  private enqueueMessage(msg: any) {
    // Para que no produzca picos en reconexiones de usuarios, sólo almacenamos en la cola un máximo de 3 mensajes
    if (this.pendingMessages.length <= 100) {
      // @ts-ignore
      this.pendingMessages.push(msg);
    } else {
      this.bus.logger.warningBrowser('Websockets: descartando encolado de mensajes por superar longitud máxima: ', msg.msg, JsonStringify(this.pendingMessages));
    }
  }


  // En bruto
  send(msg: any, callback: any, multipleResponses: boolean = false, enviarPendientes: boolean = true) {

    this.lastTimeMessageWasSent = new Date();

    if (this.connecting === true || this.sockConnected() === false) {
      this.enqueueMessage({msg: msg, callback: callback, multipleResponses: multipleResponses});

      if (this.connecting === false && this.sockConnected() === false && this.reconnectionRetries === 0) {
        this.reconnect();
      }
    } else {

      if (enviarPendientes) {
        // Primero, si hay pendientes los enviamos
        this._sendPending();
      }

      this._sendFrame(msg, callback, multipleResponses);
    }

  }


  private resendPendingAnswers() {
    for ( const index in this.pendingAnswers) {
      if (this.pendingAnswers.hasOwnProperty(index)) {
        // @ts-ignore
        const pending = this.pendingAnswers[index];
        this.sockSendMessage(this._encodeFrame(pending.secuencia, pending.msg));
      }
    }
  }

  private resendSecuence(secuence: number) {
    const index = 'i' + secuence;

    if (this.pendingAnswers.hasOwnProperty(index)) {
      // @ts-ignore
      const pending = this.pendingAnswers[index];
      this.sockSendMessage(this._encodeFrame(pending.secuencia, pending.msg));
    }
  }

  private _sendFrame(msg: any, callback: any, multipleResponses: boolean = false) {

    // Añadimos a pending answers
    this.pendingAnswersSecuencia += 1;
    // @ts-ignore
    this.pendingAnswers['i' + this.pendingAnswersSecuencia] = {
      callback: callback,
      msg: msg,
      secuencia: this.pendingAnswersSecuencia,
      multipleResponses: multipleResponses,
    };

    // this.bus.logger.debugBrowser('WebSocket send => ' + this.pendingAnswersSecuencia, msg);

    this.sockSendMessage(this._encodeFrame(this.pendingAnswersSecuencia, msg));
  }

  private _receiveFrame(frame:any) {
    const packedData = this._decodeFrame(frame);

    if (packedData) {
      const sequence = packedData.sequence;
      const data = packedData.data;
      const event =  packedData.event;

      const idAnswer = 'i' + sequence;

      // miramos si es error de rate limit
      if (data.hasOwnProperty('error')) {
        if (data['error'] === RATE_LIMIT_ERROR) {
          // Es un error de rate limit => frenamos y repetimos????
          makeError('Petición al servidor descartada por superar límite de peticiones por segundo. Se vuelve a reintentar.');
          setTimeout(() => this.resendSecuence(sequence), 2000);
          return null;
        }
      }

      // Comprobamos que no es una secuencia que no esperamos => por tanto no hay callback
      if (!this.pendingAnswers.hasOwnProperty(idAnswer)) {
        return packedData;
      }

      // tslint:disable-next-line:no-shadowed-variable
      // @ts-ignore
      const callback = this.pendingAnswers[idAnswer].callback;
      // @ts-ignore
      const multipleResponses = this.pendingAnswers[idAnswer].multipleResponses;

      let deleteWhenFinish;

      // Sólo borramos si no espera respuestas múltiples
      // Si espera respuestas múltiples, es responsabilidad de la función de callback ejecutar la función pasada como segundo
      // argumento para borrar la pendingAnswer
      if (!multipleResponses) {
        // @ts-ignore
        delete this.pendingAnswers[idAnswer];
      } else {
        deleteWhenFinish = () => { // @ts-ignore
          delete this.pendingAnswers[idAnswer]; };
      }

      if (callback) {
        callback(data, deleteWhenFinish);
      }
      return null; // Para indicar que ya se ha procesado
    }
    return packedData;
  }

  private _sendPending() {
    while (this.pendingMessages.length > 0) {
      const msgObject = this.pendingMessages.shift();
      // @ts-ignore
      this._sendFrame(msgObject.msg, msgObject.callback, msgObject.multipleResponses);
    }
  }


  // Emitimos formateado
  emit(event: string, data: any, callback: any, multipleResponses: boolean = false, securityContext: string = '') {

    // Si es ping
    if (event === 'ping') {
      // Si hay mensajes en la cola pendientes de transmitir => no hacemos ping para evitar hacer crecer el buffer
      if (this.pendingMessages.length > 2) {
        if (callback) {
          callback('pong');
        }
        return;
      }

      const elapsed = getTime() - this.lastPing;
      if (elapsed < 10000) {
        // No lo enviamos porque es muy reciente
        if (callback) {
          callback('pong');
        }
        return;
      }
      this.lastPing = getTime();
    }
    this.send(this._encodeMessage(event, securityContext, data), callback, multipleResponses);
  }

  removeAllListeners(msg: any) {
    if (msg) {
      // @ts-ignore
      delete this.listeners[msg];
    } else {
      const key = 'all_messages__';
      // @ts-ignore
      delete this.listeners[key];
    }
  }

  on(msg: any, fun: any) {
    if (!this.listeners.hasOwnProperty(msg)) {
      // @ts-ignore
      this.listeners[msg] = [];
    }
    // @ts-ignore
    this.listeners[msg].push(fun);
  }



  private _callListeners(on: any, data: any) {
    // enviamos a los listener de cada tipo de mensaje
    if (this.listeners.hasOwnProperty(on)) {
      // @ts-ignore
      const listeners = this.listeners[on];

      // Iteramos el array
      const arrayLength = listeners.length;
      for (let i = 0; i < arrayLength; i++) {
        const fun = listeners[i];
        try {
          fun(data);  // Sólo enviamos los datos
        } catch (exception) {
          // @ts-ignore
          this.debugErr(exception);
        }
      }
    } else {
      this.debugWarn("Evento sin callback configurado: " + on, data);
    }
  }



  private _encodeMessage(event: string,securityContext: string,  data: any) {
    const frame = {
      signature: SIGNATURE,
      event: event,
      data: data,
      security_context: securityContext,
    };

    return frame;
  }


  testBusResponse(): void {
    this.emit('ping', { evento: 'ping'}, (resultado: any) => {
      this.bus.logger.debugBrowser("testBusResponse: reconnect bus");
      this.sendBusStateEvent(true);
    });

  }

  ping(sendPendings: boolean = false): void {
    if (this.closedManually) {
      return;
    }

    // Enviammos pendientes?
    if (sendPendings && this.connected) {
      this._sendPending();
    }


    const elapsed = getTime() - this.pingStarted;

    if (this.pingStarted === 0 || elapsed > 5000) {
      this.pingStarted = getTime();
      this.emit('ping', { evento: 'ping'}, (resultado: any) => {
        // resultado = pong
        this.pingStarted = 0;
      });
    }

  }

  protected wireDeserializer(e: MessageEvent) {
    return e.data;
    // return JSON.parse(e.data);
  }

  protected wireSerializer(value: any) {
    return value;
    // return JsonStringify(value);
  }

  private _encodeFrame(sequence: any, data: any) {
    const frame = {
      sequence: sequence,
      data: data
    };

    return JsonStringify(frame);
  }

  private _decodeFrame(frame: any) {
    try {
      const object = JSON.parse(frame);
      const sequence = object.sequence;
      const data = object.data;
      const event = object.event;

      return {
        sequence: sequence,
        data: data,
        event: event,
      };
    } catch (exception) {
      // No es JSON o el formato esperado
      this.debugErr('Frame sin formato adecuado: ', frame);
      return null;
    }
  }

  sockSendMessage(wireData: string) {
    if (this.socket$) {
      // Si ocupa más de 30K, partirla (para que funcione en AWS)
      if (enableChunks && wireData.length >= maxWSChunk) {
        // Enviamos bloques de 30KB
        // Número de chunks, se podría hacer con Math.ceil pero por dejarlo igual que en GO
        let numChunks = Math.floor(wireData.length / maxWSChunk);
        const resto = wireData.length % maxWSChunk;
        if (resto > 0) {
          numChunks++;
        }

        // Creamos un uniqId
        const uniqId = this.getUniqId();

        for (let i = 0; i < numChunks; i++) {
          const chunk = wireData.substring(i * maxWSChunk, (i * maxWSChunk) + maxWSChunk);

          // Añadimos prefijo para identificar el chunk
          const prefix = this.makeChunkPrefix(uniqId, i, numChunks);
          this.socket$.next(prefix + chunk);
        }
      } else {
        this.socket$.next(wireData);
      }

    } else {
      const elapsed = getTime() - this.lastTimeNotOpenSocket;
      // Para evitar loop masivo
      if (elapsed > 1000) {
        this.lastTimeNotOpenSocket = getTime();
        this.bus.logger.warning('sockSendMessage: sin socket abierto enviando el mensaje');
        gotoIndexHtml();
      }
    }
  }

  private sockReceiveMessage(wireData: Blob) {
    // Viene en binario, convertimos a texto
    wireData.text().then( (asText) => {
      this._sockReceiveMessageText(asText)
    });
  }

  private _sockReceiveMessageText(wireData: any) {

    let frame = wireData;

    // Vemos si viene troceado
    if (enableChunks) {
      const currentChunkFrame = this.getChunkPrefix(wireData, '');

      if (currentChunkFrame !== null) {

        // Viene chunked => leemos el resto => lo hacemos así por si vinieran desordenados
        if (!this.tempChunks.hasOwnProperty(currentChunkFrame.uniq)) {
          // @ts-ignore
          this.tempChunks[currentChunkFrame.uniq] = {};
        }
        // @ts-ignore
        this.tempChunks[currentChunkFrame.uniq]['index-' + currentChunkFrame.index] = currentChunkFrame.payload;

        // @ts-ignore
        if (countElements(this.tempChunks[currentChunkFrame.uniq]) >= currentChunkFrame.total) {
          // Construimos frame
          frame = '';
          for (let i = 0; i < currentChunkFrame.total; i++) {
            // @ts-ignore
            frame += this.tempChunks[currentChunkFrame.uniq]['index-' + i];
          }

          // Vaciamos tempChunks
          // @ts-ignore
          delete (this.tempChunks[currentChunkFrame.uniq]);

        } else {
          return;  // No hacemos nada => faltan chunks
        }
      }
    }


    // Primero el frame => retorna NULL si se ha procesado el evento (es una trama que estabamos esperando)
    const packedData = this._receiveFrame(frame);

    this.debugMsg(frame);

    if (packedData) {
      const event = packedData.event;

      // enviamos a los listener de cada tipo de mensaje
      if (event !== undefined && event != "") {
        this._callListeners(event, packedData.data);
      }
    }
  }

  // ********************* PARTICIÓN DE TRAMAS ***********************
  // Misma implementación que en GO
  protected getUniqId(): string {
    return 'vegator-' + getUUID();  // 8+36 bytes
  }

  protected checkUniqId(uid: string): boolean {

    if (uid.length !== 44) { // 8+36 bytes
      return false;
    }

    if (uid.substring(0, 8) === 'vegator-') {
      return true;
    }
    return false;
  }


  protected makeChunkPrefix(uniq: string, index: number, total: number): string {
    // Añadimos prefijo con contadores (0 based)
    return ':&&:' + uniq + '/' + String(index).padStart(6, '0') + '/' + String(total).padStart(6, '0') + ':&&:' ;
  }

  protected getChunkPrefix(data: string, previousUniqId: string = '') {

    if (data.length < prefixLen) {
      return null;
    }

    const prefix = data.substring(0, prefixLen);

    if (prefix.substring(0, 4) !== ':&&:') {
      return null;
    }

    if (prefix.substring(prefix.length - 4) !== ':&&:') {
      return null;
    }

    const contenido = prefix.substring(4, prefixLen - 8 + 4);

    const partes = contenido.split( '/');

    // Si no hay 3 partes ..
    if (partes.length !== 3) {
      return null;
    }

    const uniq = partes[0];

    // Verificamos que es un uniqId válido
    if (!this.checkUniqId(uniq)) {
      return null;
    }

    // Si hay previo, comprobamos
    if (previousUniqId !== '') {
      if (uniq !== previousUniqId) {
        return null;
      }
    }

    // Calculamos el index, está 0 padded
    const index = parseInt(partes[1], 10);
    if (isNaN(index) || index < 0) {
      return null;
    }

    // Calculamos el total de paquetes
    const total = parseInt(partes[2], 10);
    if (isNaN(total) || total <= 0) {
      return null;
    }

    // El index no puede ser superior al total
    if (index >= total) {
      return null;
    }

    const payload = data.substring(prefixLen);

    return {
      'uniq': uniq,
      'index': index,
      'total': total,
      'payload': payload,
    };
  }

}
