import {Option} from "./types";

const countries = [
  { "code": "AD", "country": "Andorra", top: true },
  { "code": "AE", "country": "Emiratos Árabes", top: false },
  { "code": "AF", "country": "Afganistán", top: false },
  { "code": "AG", "country": "Antigua y Barbuda", top: false },
  { "code": "AI", "country": "Anguila", top: false },
  { "code": "AL", "country": "Albania", top: false },
  { "code": "AM", "country": "Armenia", top: false },
  { "code": "AO", "country": "Angola", top: false },
  { "code": "AQ", "country": "Antártida", top: false },
  { "code": "AR", "country": "Argentina", top: true },
  { "code": "AS", "country": "Samoa Americana", top: false },
  { "code": "AT", "country": "Austria", top: true },
  { "code": "AU", "country": "Australia", top: true },
  { "code": "AW", "country": "Aruba", top: false },
  { "code": "AX", "country": "Islas de Åland", top: false },
  { "code": "AZ", "country": "Azerbaiyán", top: false },
  { "code": "BA", "country": "Bosnia y Herzegovina", top: false },
  { "code": "BB", "country": "Barbados", top: false },
  { "code": "BD", "country": "Bangladesh", top: false },
  { "code": "BE", "country": "Bélgica", top: true },
  { "code": "BF", "country": "Burkina Faso", top: false },
  { "code": "BG", "country": "Bulgaria", top: true },
  { "code": "BH", "country": "Bahrein", top: false },
  { "code": "BI", "country": "Burundi", top: false },
  { "code": "BJ", "country": "Benín", top: false },
  { "code": "BL", "country": "San Bartolomé", top: false },
  { "code": "BM", "country": "Islas Bermudas", top: false },
  { "code": "BN", "country": "Brunéi", top: false },
  { "code": "BO", "country": "Bolivia", top: false },
  { "code": "BQ", "country": "Bonaire, San Eustaquio y Saba", top: false },
  { "code": "BR", "country": "Brasil", top: true },
  { "code": "BS", "country": "Bahamas", top: false },
  { "code": "BT", "country": "Bhután", top: false },
  { "code": "BV", "country": "Isla Bouvet", top: false },
  { "code": "BW", "country": "Botsuana", top: false },
  { "code": "BY", "country": "Bielorrusia", top: false },
  { "code": "BZ", "country": "Belice", top: false },
  { "code": "CA", "country": "Canadá", top: true },
  { "code": "CC", "country": "Islas Cocos (Keeling)", top: false },
  { "code": "CD", "country": "República Democrática del Congo", top: false },
  { "code": "CF", "country": "República Centroafricana", top: false },
  { "code": "CG", "country": "República del Congo", top: false },
  { "code": "CH", "country": "Suiza", top: true },
  { "code": "CI", "country": "Costa de Marfil", top: false },
  { "code": "CK", "country": "Islas Cook", top: false },
  { "code": "CL", "country": "Chile", top: true },
  { "code": "CM", "country": "Camerún", top: false },
  { "code": "CN", "country": "China", top: true },
  { "code": "CO", "country": "Colombia", top: true },
  { "code": "CR", "country": "Costa Rica", top: true },
  { "code": "CU", "country": "Cuba", top: false },
  { "code": "CV", "country": "Cabo Verde", top: false },
  { "code": "CW", "country": "Curazao", top: false },
  { "code": "CX", "country": "Isla de Navidad", top: false },
  { "code": "CY", "country": "Chipre", top: false },
  { "code": "CZ", "country": "República Checa", top: false },
  { "code": "DE", "country": "Alemania", top: true },
  { "code": "DJ", "country": "Yibuti", top: false },
  { "code": "DK", "country": "Dinamarca", top: true },
  { "code": "DM", "country": "Dominica", top: false },
  { "code": "DO", "country": "República Dominicana", top: false },
  { "code": "DZ", "country": "Argelia", top: false },
  { "code": "EC", "country": "Ecuador", top: false },
  { "code": "EE", "country": "Estonia", top: false },
  { "code": "EG", "country": "Egipto", top: false },
  { "code": "EH", "country": "Sahara Occidental", top: false },
  { "code": "ER", "country": "Eritrea", top: false },
  { "code": "ES", "country": "España", top: true },
  { "code": "ET", "country": "Etiopía", top: false },
  { "code": "FI", "country": "Finlandia", top: true },
  { "code": "FJ", "country": "Fiyi", top: false },
  { "code": "FK", "country": "Islas Malvinas", top: false },
  { "code": "FM", "country": "Micronesia", top: false },
  { "code": "FO", "country": "Islas Feroe", top: false },
  { "code": "FR", "country": "Francia", top: true },
  { "code": "GA", "country": "Gabón", top: false },
  { "code": "GB", "country": "Gran Bretaña", top: true },
  { "code": "GD", "country": "Granada", top: false },
  { "code": "GE", "country": "Georgia", top: false },
  { "code": "GF", "country": "Guayana Francesa", top: false },
  { "code": "GG", "country": "Guernsey", top: false },
  { "code": "GH", "country": "Ghana", top: false },
  { "code": "GI", "country": "Gibraltar", top: false },
  { "code": "GL", "country": "Groenlandia", top: false },
  { "code": "GM", "country": "Gambia", top: false },
  { "code": "GN", "country": "Guinea", top: false },
  { "code": "GP", "country": "Guadalupe", top: false },
  { "code": "GQ", "country": "Guinea Ecuatorial", top: false },
  { "code": "GR", "country": "Grecia", top: false },
  { "code": "GS", "country": "Islas Georgias del Sur y Sandwich del Sur", top: false },
  { "code": "GT", "country": "Guatemala", top: false },
  { "code": "GU", "country": "Guam", top: false },
  { "code": "GW", "country": "Guinea-Bissau", top: false },
  { "code": "GY", "country": "Guyana", top: false },
  { "code": "HK", "country": "Hong Kong", top: true },
  { "code": "HM", "country": "Islas Heard y McDonald", top: false },
  { "code": "HN", "country": "Honduras", top: false },
  { "code": "HR", "country": "Croacia", top: false },
  { "code": "HT", "country": "Haití", top: false },
  { "code": "HU", "country": "Hungría", top: false },
  { "code": "ID", "country": "Indonesia", top: true },
  { "code": "IE", "country": "Irlanda", top: true },
  { "code": "IL", "country": "Israel", top: true },
  { "code": "IM", "country": "Isla de Man", top: false },
  { "code": "IN", "country": "India", top: true },
  { "code": "IO", "country": "Territorio Británico del Océano Índico", top: false },
  { "code": "IQ", "country": "Irak", top: false },
  { "code": "IR", "country": "Irán", top: false },
  { "code": "IS", "country": "Islandia", top: true },
  { "code": "IT", "country": "Italia", top: true },
  { "code": "JE", "country": "Jersey", top: false },
  { "code": "JM", "country": "Jamaica", top: false },
  { "code": "JO", "country": "Jordania", top: false },
  { "code": "JP", "country": "Japón", top: true },
  { "code": "KE", "country": "Kenia", top: false },
  { "code": "KG", "country": "Kirguistán", top: false },
  { "code": "KH", "country": "Camboya", top: false },
  { "code": "KI", "country": "Kiribati", top: false },
  { "code": "KM", "country": "Comoras", top: false },
  { "code": "KN", "country": "San Cristóbal y Nieves", top: false },
  { "code": "KP", "country": "Corea del Norte", top: false },
  { "code": "KR", "country": "Corea del Sur", top: true },
  { "code": "KW", "country": "Kuwait", top: false },
  { "code": "KY", "country": "Islas Caimán", top: false },
  { "code": "KZ", "country": "Kazajistán", top: false },
  { "code": "LA", "country": "Laos", top: false },
  { "code": "LB", "country": "Líbano", top: false },
  { "code": "LC", "country": "Santa Lucía", top: false },
  { "code": "LI", "country": "Liechtenstein", top: false },
  { "code": "LK", "country": "Sri lanka", top: false },
  { "code": "LR", "country": "Liberia", top: false },
  { "code": "LS", "country": "Lesoto", top: false },
  { "code": "LT", "country": "Lituania", top: false },
  { "code": "LU", "country": "Luxemburgo", top: false },
  { "code": "LV", "country": "Letonia", top: false },
  { "code": "LY", "country": "Libia", top: false },
  { "code": "MA", "country": "Marruecos", top: false },
  { "code": "MC", "country": "Mónaco", top: false },
  { "code": "MD", "country": "Moldavia", top: false },
  { "code": "ME", "country": "Montenegro", top: false },
  { "code": "MF", "country": "San Martín (Francia)", top: false },
  { "code": "MG", "country": "Madagascar", top: false },
  { "code": "MH", "country": "Islas Marshall", top: false },
  { "code": "MK", "country": "Macedônia", top: false },
  { "code": "ML", "country": "Mali", top: false },
  { "code": "MM", "country": "Birmania", top: false },
  { "code": "MN", "country": "Mongolia", top: false },
  { "code": "MO", "country": "Macao", top: false },
  { "code": "MP", "country": "Islas Marianas del Norte", top: false },
  { "code": "MQ", "country": "Martinica", top: false },
  { "code": "MR", "country": "Mauritania", top: false },
  { "code": "MS", "country": "Montserrat", top: false },
  { "code": "MT", "country": "Malta", top: false },
  { "code": "MU", "country": "Mauricio", top: false },
  { "code": "MV", "country": "Islas Maldivas", top: false },
  { "code": "MW", "country": "Malawi", top: false },
  { "code": "MX", "country": "México", top: true },
  { "code": "MY", "country": "Malasia", top: false },
  { "code": "MZ", "country": "Mozambique", top: false },
  { "code": "NA", "country": "Namibia", top: false },
  { "code": "NC", "country": "Nueva Caledonia", top: false },
  { "code": "NE", "country": "Niger", top: false },
  { "code": "NF", "country": "Isla Norfolk", top: false },
  { "code": "NG", "country": "Nigeria", top: false },
  { "code": "NI", "country": "Nicaragua", top: false },
  { "code": "NL", "country": "Países Bajos", top: true },
  { "code": "NO", "country": "Noruega", top: true },
  { "code": "NP", "country": "Nepal", top: false },
  { "code": "NR", "country": "Nauru", top: false },
  { "code": "NU", "country": "Niue", top: false },
  { "code": "NZ", "country": "Nueva Zelanda", top: true },
  { "code": "OM", "country": "Omán", top: false },
  { "code": "PA", "country": "Panamá", top: false },
  { "code": "PE", "country": "Perú", top: false },
  { "code": "PF", "country": "Polinesia Francesa", top: false },
  { "code": "PG", "country": "Papúa Nueva Guinea", top: false },
  { "code": "PH", "country": "Filipinas", top: false },
  { "code": "PK", "country": "Pakistán", top: false },
  { "code": "PL", "country": "Polonia", top: true },
  { "code": "PM", "country": "San Pedro y Miquelón", top: false },
  { "code": "PN", "country": "Islas Pitcairn", top: false },
  { "code": "PR", "country": "Puerto Rico", top: true },
  { "code": "PS", "country": "Palestina", top: false },
  { "code": "PT", "country": "Portugal", top: true },
  { "code": "PW", "country": "Palau", top: false },
  { "code": "PY", "country": "Paraguay", top: false },
  { "code": "QA", "country": "Qatar", top: false },
  { "code": "RE", "country": "Reunión", top: false },
  { "code": "RO", "country": "Rumanía", top: true },
  { "code": "RS", "country": "Serbia", top: false },
  { "code": "RU", "country": "Rusia", top: true },
  { "code": "RW", "country": "Ruanda", top: false },
  { "code": "SA", "country": "Arabia Saudita", top: false },
  { "code": "SB", "country": "Islas Salomón", top: false },
  { "code": "SC", "country": "Seychelles", top: false },
  { "code": "SD", "country": "Sudán", top: false },
  { "code": "SE", "country": "Suecia", top: true },
  { "code": "SG", "country": "Singapur", top: false },
  { "code": "SH", "country": "Santa Elena", top: false },
  { "code": "SI", "country": "Eslovenia", top: true },
  { "code": "SJ", "country": "Svalbard y Jan Mayen", top: false },
  { "code": "SK", "country": "Eslovaquia", top: true },
  { "code": "SL", "country": "Sierra Leona", top: false },
  { "code": "SM", "country": "San Marino", top: false },
  { "code": "SN", "country": "Senegal", top: false },
  { "code": "SO", "country": "Somalia", top: false },
  { "code": "SR", "country": "Surinám", top: false },
  { "code": "SS", "country": "República de Sudán del Sur", top: false },
  { "code": "ST", "country": "Santo Tomé y Príncipe", top: false },
  { "code": "SV", "country": "El Salvador", top: false },
  { "code": "SX", "country": "Sint Maarten", top: false },
  { "code": "SY", "country": "Siria", top: false },
  { "code": "SZ", "country": "Swazilandia", top: false },
  { "code": "TC", "country": "Islas Turcas y Caicos", top: false },
  { "code": "TD", "country": "Chad", top: false },
  { "code": "TF", "country": "Territorios Australes y Antárticas Franceses", top: false },
  { "code": "TG", "country": "Togo", top: false },
  { "code": "TH", "country": "Tailandia", top: true },
  { "code": "TJ", "country": "Tayikistán", top: false },
  { "code": "TK", "country": "Tokelau", top: false },
  { "code": "TL", "country": "Timor Oriental", top: false },
  { "code": "TM", "country": "Turkmenistán", top: false },
  { "code": "TN", "country": "Túnez", top: false },
  { "code": "TO", "country": "Tonga", top: false },
  { "code": "TR", "country": "Turquía", top: true },
  { "code": "TT", "country": "Trinidad y Tobago", top: false },
  { "code": "TV", "country": "Tuvalu", top: false },
  { "code": "TW", "country": "Taiwán", top: true },
  { "code": "TZ", "country": "Tanzania", top: false },
  { "code": "UA", "country": "Ucrania", top: false },
  { "code": "UG", "country": "Uganda", top: false },
  { "code": "UM", "country": "Islas Ultramarinas Menores de Estados Unidos", top: false },
  { "code": "US", "country": "EE.UU.", top: true },
  { "code": "UY", "country": "Uruguay", top: true },
  { "code": "UZ", "country": "Uzbekistán", top: false },
  { "code": "VA", "country": "Ciudad del Vaticano", top: false },
  { "code": "VC", "country": "San Vicente y las Granadinas", top: false },
  { "code": "VE", "country": "Venezuela", top: false },
  { "code": "VG", "country": "Islas Vírgenes Británicas", top: false },
  { "code": "VI", "country": "Islas Vírgenes de los Estados Unidos", top: false },
  { "code": "VN", "country": "Vietnam", top: false },
  { "code": "VU", "country": "Vanuatu", top: false },
  { "code": "WF", "country": "Wallis y Futuna", top: false },
  { "code": "WS", "country": "Samoa", top: false },
  { "code": "YE", "country": "Yemen", top: false },
  { "code": "YT", "country": "Mayotte", top: false },
  { "code": "ZA", "country": "Sudáfrica", top: false },
  { "code": "ZM", "country": "Zambia", top: false },
  { "code": "ZW", "country": "Zimbabue", top: false },
];




export function getCountryFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

export function getCountries(top: boolean = false): Option[] {
   const res: Option[] = [];
   countries.forEach( (country) => {
     if (top && !country.top) {
       return;
     }
     res.push({
       label: country.country,
       value: country.code,
       data: getCountryFlagEmoji(country.code),
     })
   })
   return res;
}

