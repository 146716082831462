
import {BusService} from "../@core/@bus/bus.service";
import {BusConfig} from "../@core/@models/bus-config";
import {Authentication} from "./authentication";
import {EventManager} from "./event-manager";
import {GetGlobalBus} from "../@core/@models/globals";


let GlobalEventManager: EventManager;

export function GetGlobalEventManager(): EventManager {
  return GlobalEventManager;
}

function SetGlobalEventManager(manager: EventManager): void {
  GlobalEventManager = manager;
}

export function ConfigureBus(bus: BusService): void {
  bus.configure(new InvermonBusConfig());
}


export class InvermonBusConfig extends BusConfig {
  authDestroy(): void {
    Authentication.getAuth().destroy();
  }

  authHasData(): boolean {
    return Authentication.getAuth().hasData();
  }

  authIsValid(): boolean {
    return Authentication.getAuth().isValid();
  }

  authReconnect(): Promise<boolean> {
    return Authentication.reconnect();
  }

  getBusPort(): string {
    return "8000";
  }
  getWebPort(): string {
    return "8002";
  }

  getComputerIdCookieName(): string {
    return "3480f7e6024sdfbe0a40e6cb935057f447a7";
  }

  getCredentialsId(): string {
    const credentialsId: string = Authentication.getAuth().getCredentialsId();
    if (!credentialsId) {
      GetGlobalBus().logger.debugBrowser("getCredentialsId vacío => ",Authentication.getAuth())
    }
    return credentialsId;
  }

  getServerDNS(): string {
    const host: string = window.location.host.split(':')[0];
    if (host == "localhost") {
      return "clubintel.com";
    }
    return host;
  }

  getSessionId(): string {
    return Authentication.getAuth().getSessionId()
  }

  onInitiated(bus: BusService): void {
    SetGlobalEventManager(new EventManager());
  }

}
