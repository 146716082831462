import {Component, Input, OnInit} from '@angular/core';
import {Status} from "../../@models/theme";

@Component({
  selector: 'app-confirm-prompt',
  templateUrl: './confirm-prompt.component.html',
  styleUrls: ['./confirm-prompt.component.scss']
})
export class ConfirmPromptComponent implements OnInit {
  @Input() status: Status = 'basic';
  @Input() accent: Status = 'warning';
  @Input() title: string = 'Confirmación';
  @Input() body: string = '';
  @Input() onOK?: () => void;
  @Input() onCANCEL?: () => void;

  constructor() { }

  ngOnInit(): void {
  }

  onResultConfirm(res: boolean) {
    if (res) {
      if (this.onOK) {
        this.onOK();
      }
    } else {
      if (this.onCANCEL) {
        this.onCANCEL();
      }
    }

  }
}
