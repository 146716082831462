import {Component, EventEmitter, Input, OnInit, Optional, Output, Self} from '@angular/core';
import {NbComponentSize} from "@nebular/theme/components/component-size";
import {debounceTime} from "../../../@models/lodash";
import {NgControl} from "@angular/forms";

@Component({
  selector: 'app-input-inline',
  templateUrl: './input-inline.component.html',
  styleUrls: ['./input-inline.component.scss']
})
export class InputInlineComponent implements OnInit {
  @Input() focus: boolean = false;
  @Input() disabled: boolean = false;
  @Input() placeHolder: string = '(editar)';
  @Input() initialValue?: string;
  @Input() size: NbComponentSize = 'medium';
  @Output() onInputChange = new EventEmitter<string>();

  debouncer = debounceTime( (value) => {
    this.onInputChange.emit(value);
  }, 250)


  originalValue: string = '';

  value: any;  // No se pasa como parámetro, poner un formControl

  public onChangeFn = (_: any) => {};
  public onTouchedFn = () => {};

  constructor(@Self() @Optional() public control: NgControl) {
    this.control && (this.control.valueAccessor = this);
  }

  ngOnInit(): void {
    if (this.initialValue) {
      this.value = this.initialValue;
    }
    this.originalValue = this.value;
  }

  onInput(data: any) {
    this.onChange(this.value);
  }

  sendValueToReceptor() {
    if (this.value != this.originalValue) {
      this.originalValue = this.value;

      this.debouncer(this.value);
      //this.onInputChange.emit(this.value);
    }
  }

  onEnter() {
    this.sendValueToReceptor();
  }

  onBlur(event: any) {
    this.sendValueToReceptor();
  }

  onFocus(event: any) {

  }


  // ControlValueAccessor
  onChange(val: any) {
    this.onTouchedFn();
    this.onChangeFn(val);
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

}
