
export const ErrInvalidCredentials = "ErrInvalidCredentials"
export const ErrNotValidated = "ErrNotValidated"
export const ErrAlreadyExist = "ErrAlreadyExist"
export const ErrLocked = "ErrLocked"
export const ErrUnauthorized = "ErrUnauthorized"
export const ErrUnauthorizedRegister = "ErrUnauthorizedRegister"
export const ErrTokenInvalid = "ErrTokenInvalid"
export const ErrNotAllowed = "ErrNotAllowed"
export const ErrNotFound = "ErrNotFound"
export const ErrDataBase = "ErrDataBase"
export const ErrInvalidPhone = "ErrInvalidPhone"
export const ErrInvalidRequest = "ErrInvalidRequest"
