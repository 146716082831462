import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NbTagComponent, NbTagInputAddEvent} from "@nebular/theme";
import {Size, Status} from "../../../@models/theme";

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit, OnChanges {
  @Input() label?: string;
  @Input() help?: string;
  @Input() disabled: boolean = false;
  @Input() value: string[] = [];
  @Input() status: Status = 'basic';
  @Input() size: Size = 'small';
  @Output() onChange: EventEmitter<string[]> = new EventEmitter<string[]>();


  keywords: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      this.init();
    }

  }

  init() {
    this.keywords = [...this.value];
  }

  onRemoveKeyword(tag: NbTagComponent) {
    this.keywords = this.keywords.filter( (key) => {
      if (key == tag.text) {
        return false;
      }
      return true;
    });

    this.onChange.emit(this.keywords);
  }

  onKeywordAdd({ value, input }: NbTagInputAddEvent): void {
    if (value) {
      this.keywords.push(value)
      this.onChange.emit(this.keywords);
    }
    input.nativeElement.value = '';
  }

}
