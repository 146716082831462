<div class="wrapper">
  <div class="flex-space-between wrapper-header">
    <div>
      <div>{{label}}</div>
      <div *ngIf="subtitle != ''" class="label-subtitle">{{subtitle}}</div>
    </div>

    <app-switch [disabled]="disabled" size="tiny" [checked]="checked" (change)="onEnabled($event)"></app-switch>
  </div>
  <div *ngIf="checked" class="flex-center wrapper-background">
    <div class="flex-space-around value-wrapper">
      <app-header-action [disabled]="minusDisabled || disabled" [withPadding]="true" status="danger" [icon]="iconMinus" (onClick)="onClickMinus()"></app-header-action>
      <span class="value-number">{{valueSmart}}</span>
      <app-header-action [disabled]="maxDisabled || disabled" [withPadding]="true" status="success" [icon]="iconPlus" (onClick)="onClickPlus()"></app-header-action>
    </div>
  </div>
</div>


