<div class="hover-container">
  <ng-template #botones>
    <button [size]="size" *ngFor="let item of actions" (click)="onClickAction(item)" nbButton ghost [disabled]="!!item.disabled">
      <app-icon [icon]="item.icon" [nbTooltip]="item.tooltip" [nbTooltipDisabled]="!item.tooltip"></app-icon>
    </button>
  </ng-template>
  <ng-template #botonesWrapper>
    <div style="padding: 0.5rem">
      <ng-container *ngTemplateOutlet="botones"></ng-container>
    </div>
  </ng-template>
  <ng-container>
    <div #ref [nbPopover]="botonesWrapper" nbPopoverPlacement="bottom-start" nbPopoverTrigger="hover"><ng-content></ng-content></div>
    <button [size]="size" *ngIf="!ref.children.length" nbButton ghost [nbPopover]="botonesWrapper" nbPopoverPlacement="bottom-start" nbPopoverTrigger="hover">
      <app-icon [icon]="IconMore"></app-icon>
    </button>
  </ng-container>
</div>
