import {AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {Status} from "../../@models/theme";
import {NbDialogRef} from "@nebular/theme";
import {DialogsService} from "../dialogs.service";

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements AfterViewInit {

  @Input() status: Status = 'basic';
  @Input() accent: Status = 'warning';
  @Input() title: string = $localize`Confirmación`;
  @Output() onResult: EventEmitter<boolean> = new EventEmitter<boolean>();
  labelConfirmar: string = $localize`Confirmar`;
  labelCancelar: string = $localize`Cancelar`;

  dialogRef?: NbDialogRef<any>;
  @ViewChild('dialog') dialog?:TemplateRef<any>;

  constructor(private dialogs: DialogsService) { }

  ngAfterViewInit(): void {
    if (this.dialog) {
      this.dialogRef = this.dialogs.service.open(this.dialog, {closeOnBackdropClick: false, autoFocus: false});
    }
  }

  onAceptar(): void {
    this.onResult.emit(true);
    if (this.dialogRef) {
      this.dialogRef.close();
    }

  }
  onCancelar(): void {
    this.onResult.emit(false);
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
