import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {BusService} from "../../../../@core/@bus/bus.service";
import {isCodiceFiscale, isPhoneFormatIt, isRiferimento} from "../../../../models/validation";
import {GenericRequest, GenericResponse} from "../../../../models/generic";
import {Backend} from "../../../../core/backend";


@Component({
  selector: 'app-public-chat-bot-ef',
  templateUrl: './chat-bot-ef.component.html',
  styleUrls: ['./chat-bot-ef.component.scss']
})
export class ChatBotEFComponent implements OnInit{
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  maximized: boolean = false;
  messages: any[] = [];
  response: boolean = false;
  responseFinal: boolean = false;
  close: boolean = false;
  selectedOption: boolean = false;
  labelAsistente: string = $localize`Asistente Virtual`;
  labelMinimizar: string = $localize`Minimizar`;
  labelMaximizar: string = $localize`Maximizar`;
  labelCerrar: string = $localize`Cerrar`;
  labelEscribir: string = $localize`Escribir un mensaje...`;
  consulta: string = '';
  opcion: string = '';
  opcionBoolean: string = '';
  opcionStep1: number = 0;
  failCount: number = 0;

  constructor(private bus: BusService) {}

  ngOnInit(): void {
    this.loadMessages();
  }

  sendMessage(event: any): void {
    if (this.responseFinal) {
      let ok = this.sendConsulta(event);
      if (ok) {
        this.addMessageReply(event.message, []);
        setTimeout((): void => {
          this.addMessageBilly('Grazie, abbiamo preso in carico la tua richiesta. A breve verrai ricontattato dal tuo consulente');
          this.createTask();
        }, 500);
      } else {
        this.addMessageReply('Le informazioni non sono corrette, inseriscile correttamente', []);
      }
    } else {
      const files = !event.files ? [] : event.files.map((file: { src: string; type: string }) => {
        return {
          url: file.src,
          type: file.type,
          icon: 'file-text-outline',
        };
      });

      let ok = this.sendConsulta(event);
      if (ok) {
        this.addMessageReply(event.message, files);
        this.nextStep();
      } else {
        this.addMessageReply('Le informazioni non sono corrette, inseriscile correttamente', files);
      }
    }
  }

  private sendConsulta(event: any): boolean {
    let ok = true;
    if (this.opcionStep1 == 0) {
      if (this.opcionBoolean == 'si') {
        if (!isRiferimento(event.message)) {
          ok = false;
        }
      }
    }

    if (this.opcionStep1 == 1 && this.opcionBoolean == 'si') {
      if (!isCodiceFiscale(event.message)) {
        ok = false;
      }
    }

    if (this.opcionStep1 == 2 && this.opcionBoolean == 'si') {
      if (!isPhoneFormatIt(event.message)) {
        ok = false;
      }
    }

    if (this.opcionStep1 == 1 && this.opcionBoolean == 'no') {
      if (!isPhoneFormatIt(event.message)) {
        ok = false;
      }
    }

    if (ok) {
      if (this.consulta) {
        this.consulta = this.consulta + '-' + event.message;
      } else {
        this.consulta = event.message;
      }
    } else {
      this.failCount++;
      if (this.failCount >= 3) {
        setTimeout(() => {
          this.addMessageBilly('Lei ha fallito 3 volte, riprovi più tardi.');
          setTimeout(() => {
            this.closeChat();
          }, 2000);
        }, 600);
      }
    }
    return ok;
  }

  private loadMessages(): void {
    let company: string = 'Europa Factor';
    if(this.bus.isColectivoAdvTrade()){
      company = 'Advancing Trade';
    }
    this.addMessageBilly('Ciao sono Billy, assistente virtuale di ' + company +', come posso esserti utile?');
    setTimeout((): void => {
      this.messages.push({
        type: 'list',
        reply: false,
        date: new Date(),
        user: {
          name: 'Billy',
          avatar: '',
        },
      });
    }, 500);

  }

  selectOption(option: any): void {
    let text = '';
    if (this.opcion == '') {
      if (option == '1') {
        text = 'Hai il numero di pratica riportato nella comunicazione?';
      } else if (option == '2') {
        text = 'Inserire il numero di pratica';
      } else if (option == '3') {
        text = 'È in possesso del suo numero di pratica?';
      }
      this.opcion = option;
      this.response = true;
      this.addMessageBilly(text);
      this.selectedOption = true;
      if (this.opcion != '2') {
        setTimeout((): void => {
          this.messages.push({
            type: 'list2',
            reply: false,
            date: new Date(),
            user: {
              name: 'Billy',
              avatar: '',
            },
          });
        }, 500);
      } else {
        this.opcionBoolean = 'si';
      }
    }
  }


  selectOptionBoolean(option: any): void{
    if (this.opcionBoolean == ''){
      let text = '';
      if (option == 'si'){
        text = 'Inserire il numero di pratica';
      }else if (option == 'no'){
        text = 'Inserire il nome e cognome';
      }

      this.opcionBoolean = option;
      this.addMessageBilly(text);
    }


    // this.responseFinal = true;
  }

  closeChat(): void{
    this.close = true;
    this.onClose.emit(true);
  }
  onClickMax(): void {
    this.maximized = true;
  }
  onClickMin(): void {
    this.maximized = false;
  }

  createTask(): void {
    const request: GenericRequest = new GenericRequest();
    request.command = 'consulta_chat_europafactor';
    request.data = this.opcion + '/' + this.consulta;
    Backend.generic(request).then( (response: GenericResponse): void => {
      if (response.error == "") {
        this.bus.dom.toastSuccess('Consultazione creata con successo');
      } else {
        this.bus.dom.toastError('Impossibile creare la consultazione');
      }
    });
  }

  nextStep(): void {
    let text = '';
    if (this.opcionStep1 == 0) {
      if (this.opcionBoolean == 'si') {
        text = 'Inserire il codice fiscale';
      } else {
        text = 'Inserire il numero di cellulare';
        this.responseFinal = true;
      }
    } else if (this.opcionStep1 == 1) {
      if (this.opcionBoolean == 'si') {
        text = 'Inserire il numero di cellulare';
      }
      this.responseFinal = true;
    }
    this.opcionStep1++;
    this.addMessageBilly(text);
  }


  addMessageBilly(text: string): void {
    this.messages.push({
      text: text,
      date: new Date(),
      reply: false,
      user: {
        name: 'Billy',
        avatar: 'https://i.gifer.com/no.gif',
      },
    });
  }

  addMessageReply(text: string, files: any): void {
    this.messages.push({
      text: text,
      date: new Date(),
      files: files,
      type: files.length ? 'file' : 'text',
      reply: true,
      user: {
        name: '',
        avatar: 'https://i.gifer.com/no.gif',
      },
    });
  }
}
