import {Injectable} from '@angular/core';
import {BusService} from './bus.service';
import {getTime, makeError} from '../@models/common';
import {JsonStringify} from "../@models/json";
import {FormatBytes} from "../@models/strings";

const GLOBAL_PREFIX = 'clubintel';
const SEPARADOR = '|';
const STORAGE_ERROR = "No está habilitado el uso del localStorage o sessionStorage";

// Servicio para almacenar/leer datos del navegador
// Tiene en cuenta si deben persistir y el tipo de persistencia para que no se solapen datos entre sesiones/pestañas
// Los datos se graban/leen siempre como string (igual que localStorage y sessionStorage)

// Cuando se borra la caché del navegador se borra todos los datos almacenados en el storage
// tab => datos sólo para el tab => no se comparten con otros tabs ni sobreviven al cierre del tab
// session => datos compartidos para la misma session de php => compartidos entre diferentes tabs y sobreviven al cierre del tab
// global => compartidos con todas las sessiones y usuarios  => sobreviven al cierre de los tabs
// user => compartidos con todas las sessiones diferentes del mismo usuario => sobreviven al cierre de los tabs



type MemoryEntry = {
  ValidUntil: number,
  Value: any,
}


@Injectable({
  providedIn: 'root'
})
export class StorageService  {

  // @ts-ignore
  private bus: BusService;
  storageEnabled: boolean;

  memoryMap: Map<string, MemoryEntry> = new Map();

  constructor() {
    this.storageEnabled = this.isLocalStorageEnabled();
  }

  isLocalStorageEnabled(): boolean {
    try {
      // localStorage
      localStorage.setItem("bus-localstorage-test", "OK");
      const storedLocal = localStorage.getItem("bus-localstorage-test");
      if (storedLocal != "OK") {
        return false;
      }
      // localStorage
      sessionStorage.setItem("bus-sessionstorage-test", "OK");
      const storedSession = sessionStorage.getItem("bus-sessionstorage-test");
      if (storedSession != "OK") {
        return false;
      }

      return true; // Se pueden usar

    } catch (err) {
      return false;
    }

    return false;
  }

  init(bus: BusService) {
    this.bus = bus;
  }

  private getTabUniqKey(key: string): string {
    const prefix = this.bus.guid.getGUID();
    if (!prefix) {
      if (this.bus.logger.getDebug()) {
        this.bus.logger.warningBrowser('StorageService: getTabUniqKey ha retornado vacío');
      }
    }
    return prefix + SEPARADOR + key;
  }

  private getSessionUniqKey(key: string): string {
    const prefix = this.bus.configuration.getSessionId();
    if (!prefix) {
      if (this.bus.logger.getDebug()) {
        this.bus.logger.warningBrowser('StorageService: getSessionUniqKey ha retornado vacío');
      }
    }
    return prefix + SEPARADOR + key;
  }

  private getUserUniqKey(key: string): string {
    const prefix = this.bus.configuration.getCredentialsId();
    if (!prefix) {
      if (this.bus.logger.getDebug()) {
        this.bus.logger.warningBrowser('StorageService: getUserUniqKey ha retornado vacío');
      }
    }
    return prefix + SEPARADOR + key;
  }

  private getGlobalUniqKey(key: string): string {
    return GLOBAL_PREFIX + SEPARADOR + key;
  }

  private serialize(value: any, expireSeconds: number = 0): string {
    let expire = 0;
    if (expireSeconds) {
      expire = getTime() + (expireSeconds * 1000);
    }
    const toStore = {
      value: value,
      expire: expire,
    };
    const serilized = JsonStringify(toStore);
    if (serilized.length > 1024 * 20) {
      const size = FormatBytes(serilized.length,2);
      this.bus.logger.warningBrowser('Storage: demasiado tamaño de la variable: '+size, toStore);
    }
    return serilized;
  }

  private unserialize(serialized: string): any | undefined {
    try {
      const fromStore = JSON.parse(serialized);
      const expire = fromStore['expire'];
      if (expire && expire < getTime()) {
        return undefined;
      }
      return fromStore['value'];
    } catch (e) {
      makeError('JSON parse: ' + serialized, false);
      return undefined;
    }
  }

  tabSet(key: string, value: any, expireSeconds: number = 0) {
    if (!this.storageEnabled) {
      console.error(STORAGE_ERROR);
      return;
    }
    const storeKey = this.getTabUniqKey(key);
    const serialized = this.serialize(value, expireSeconds);
    sessionStorage.setItem(storeKey, serialized);
  }

  tabGet(key: string): any | undefined {
    if (!this.storageEnabled) {
      console.error(STORAGE_ERROR);
      return undefined;
    }
    const storeKey = this.getTabUniqKey(key);
    const stored =  sessionStorage.getItem(storeKey);
    if (stored === null) {
      return undefined;
    }
    return this.unserialize(stored);
  }

  tabDel(key: string) {
    if (!this.storageEnabled) {
      console.error(STORAGE_ERROR);
      return;
    }
    const storeKey = this.getTabUniqKey(key);
    sessionStorage.removeItem(storeKey);
  }

  sessionSet(key: string, value: any, expireSeconds: number = 0) {
    if (!this.storageEnabled) {
      console.error(STORAGE_ERROR);
      return;
    }
    const storeKey = this.getSessionUniqKey(key);
    const serialized = this.serialize(value, expireSeconds);
    localStorage.setItem(storeKey, serialized);
  }

  sessionGet(key: string, defecto: any = undefined): any | undefined {
    if (!this.storageEnabled) {
      console.error(STORAGE_ERROR);
      return undefined;
    }
    const storeKey = this.getSessionUniqKey(key);
    const stored =  localStorage.getItem(storeKey);
    if (stored === null) {
      return defecto;
    }
    return this.unserialize(stored);
  }

  sessionDel(key: string) {
    if (!this.storageEnabled) {
      console.error(STORAGE_ERROR);
      return;
    }
    const storeKey = this.getSessionUniqKey(key);
    localStorage.removeItem(storeKey);
  }


  globalSet(key: string, value: any, expireSeconds: number = 0) {
    if (!this.storageEnabled) {
      console.error(STORAGE_ERROR);
      return;
    }
    const storeKey = this.getGlobalUniqKey(key);
    const serialized = this.serialize(value, expireSeconds);
    localStorage.setItem(storeKey, serialized);
  }

  globalGet(key: string, defecto: any = undefined): any | undefined {
    if (!this.storageEnabled) {
      console.error(STORAGE_ERROR);
      return undefined;
    }
    const storeKey = this.getGlobalUniqKey(key);
    const stored =  localStorage.getItem(storeKey);
    if (stored === null) {
      return defecto;
    }
    return this.unserialize(stored);
  }

  globalDel(key: string) {
    if (!this.storageEnabled) {
      console.error(STORAGE_ERROR);
      return;
    }
    const storeKey = this.getGlobalUniqKey(key);
    localStorage.removeItem(storeKey);
  }


  userSet(key: string, value: any, expireSeconds: number = 0) {
    if (!this.storageEnabled) {
      console.error(STORAGE_ERROR);
      return;
    }
    const storeKey = this.getUserUniqKey(key);
    const serialized = this.serialize(value, expireSeconds);
    localStorage.setItem(storeKey, serialized);
  }

  userGet(key: string, defecto: any = undefined): any | undefined {
    if (!this.storageEnabled) {
      console.error(STORAGE_ERROR);
      return undefined;
    }
    const storeKey = this.getUserUniqKey(key);
    const stored =  localStorage.getItem(storeKey);
    if (stored === null) {
      return defecto;
    }
    return this.unserialize(stored);
  }

  userDel(key: string) {
    if (!this.storageEnabled) {
      console.error(STORAGE_ERROR);
      return;
    }
    const storeKey = this.getUserUniqKey(key);
    localStorage.removeItem(storeKey);
  }


  memorySet(key: string, value: any, durationSeconds?: number) {
    this.memoryMap.set(key,{
      ValidUntil: durationSeconds == undefined ? 0 : ((new Date()).getTime()/1000) + durationSeconds,
      Value: value,
    })
  }

  memoryGet(key: string, defecto: any = undefined): any | undefined {
    if (this.memoryMap.has(key)) {
      const entry: MemoryEntry | undefined = this.memoryMap.get(key);
      if (entry == undefined) {
        return defecto;
      }
      if (entry.ValidUntil == 0) {
        return entry.Value;
      }

      // Miramos si ha expirado
      if ((new Date()).getTime()/1000 > entry.ValidUntil) {
        // La borramos
        this.memoryMap.delete(key);
        return defecto;
      }

      return entry.Value;
    }
    return defecto;
  }

  memoryDel(key: string): any | undefined {
    this.memoryMap.delete(key);
  }

}
