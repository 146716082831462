<nb-card class="dialog-wrapper"  [status]="status" [accent]="accent">
  <nb-card-header>
    <div class="flex-space-between">
      <div class="form-title">{{title}}</div>
      <app-header-action [icon]="iconClose" (onClick)="onCancelar()"></app-header-action>
    </div>

  </nb-card-header>
  <nb-card-body>
    <div class="form-body" style="width: {{width}}">
      <nb-alert class="mb-3" status="info">
        <div *ngIf="help" class="info-help">
          <span>{{help}}</span>
        </div>
      </nb-alert>
      <div class="extra-info" *ngIf="extraInfo != ''">
        {{extraInfo}}
      </div>
      <form [formGroup]="formulario">
        <app-input [focus]="true" [placeholder]="placeholder" type="text" formControlName="otp"></app-input>
      </form>
      <nb-alert class="mt-3 mb-0" *ngIf="errorDialog" closable status="danger" (close)="errorDialog = ''">
        {{errorDialog}}
      </nb-alert>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="flex-space-between">
      <app-button [disabled]="!formulario.valid || formulario.untouched"  label="Enviar" size="small" status="success" (onPress)="onModify()"></app-button>
      <app-button label="Cancelar" size="small" status="danger" (onPress)="onCancelar()"></app-button>
    </div>
  </nb-card-footer>
</nb-card>
