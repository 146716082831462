import {Component, Input, OnInit} from '@angular/core';
import {Icon, MatIcon} from "../../../@models/icons";
import {Status} from "../../../@models/theme";

@Component({
  selector: 'app-scroll-to-top-button',
  templateUrl: './scroll-to-top-button.component.html',
  styleUrls: ['./scroll-to-top-button.component.scss']
})
export class ScrollToTopButtonComponent implements OnInit {

  @Input() offset: string = "1rem";

  transformClass = 'animation-heartbeat';
  currentAnimationComponent = '';
  statusCalculated: Status = 'primary';

  icon: Icon = MatIcon("download_for_offline");

  visible: boolean = false;
  onClickCallback: () => void = () => {};

  constructor() { }

  ngOnInit(): void {
  }

  onPressed() {
    this.currentAnimationComponent = this.transformClass;
    setTimeout( () => this.currentAnimationComponent = "", 150);
    this.onClickCallback();
  }

}
