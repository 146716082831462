import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Icon, IconMenuCollapsed, IconMenuExpanded} from "../../../@models/icons";
import {Status} from "../../../@models/theme";

@Component({
  selector: 'app-icon-expand',
  templateUrl: './icon-expand.component.html',
  styleUrls: ['./icon-expand.component.scss']
})
export class IconExpandComponent implements OnInit {
  @Input() expanded: boolean = false;
  @Input() status: Status = 'basic';
  @Input() fontSize?: string;
  @Output() onClick = new EventEmitter<boolean>();

  iconExpanded: Icon = IconMenuExpanded;
  iconCollapsed: Icon = IconMenuCollapsed;

  icon: Icon = IconMenuCollapsed;
  currentAnimationComponent: string = '';

  tooltipExpanded: string = 'Contraer';
  tooltipCollapsed: string = 'Expandir';

  tooltip: string = '';


  constructor() { }

  ngOnInit(): void {
    if (this.expanded) {
      this.icon = this.iconExpanded;
      this.tooltip = this.tooltipExpanded;
    } else {
      this.icon = this.iconCollapsed;
      this.tooltip = this.tooltipCollapsed;
    }
  }

  onButtonClicked() {

    if (!this.expanded) {
      this.currentAnimationComponent = 'animation-rotate90';
      setTimeout( () => {
        this.currentAnimationComponent = '';
        this.icon = this.iconExpanded;
        this.tooltip = this.tooltipExpanded;
      }, 150);
    } else {
      this.currentAnimationComponent = 'animation-rotate-90';
      setTimeout( () => {
        this.currentAnimationComponent = '';
        this.icon = this.iconCollapsed;
        this.tooltip = this.tooltipCollapsed;
      }, 150);
    }

    this.expanded = !this.expanded;
    this.onClick.emit(this.expanded);
  }

}
