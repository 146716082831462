import {BaseClass} from "../@core/@models/common";
import {GrpcRequest, GrpcResponse, Origin, OriginEmpty} from "../@core/@models/grpc";
import {Pagination} from "./pagination";
import {Colectivo} from "./colectivo";

export class Categorias extends BaseClass{

  id: string = '';
  id_externo: string = '';
  titulo: string = '';
  slug: string = '';
  image_url: string = '';
  subcategorias: string = '';
  activar: boolean = false;
  parent_id : number = 0;
  position: number = 0;
  items_count: number = 0;
  created: string = '';
  fecha_actualizacion: string = '';
  colectivo: string = '';
  colectivo_row?: Colectivo;

  constructor(asObject?: object) {
    super();
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }


  static listCategorias(request: ListCategoriasRequest): Promise<ListCategoriasResponse> {
    return new Promise<ListCategoriasResponse>( (resolve, reject): void => {
      const response: ListCategoriasResponse = new ListCategoriasResponse();
      response.sendRequest("list_categorias", request).then( (): void => {
        resolve(response);
      });
    });
  }



  static modifyCategorias(request: ModifyCategoriasRequest): Promise<ModifyCategoriasResponse> {
    return new Promise<ModifyCategoriasResponse>( (resolve, reject): void => {
      const response: ModifyCategoriasResponse = new ModifyCategoriasResponse();
      response.sendRequest("modify_categorias", request).then( (): void => {
        resolve(response);
      });
    });
  }

}


export class ModifyCategoriasRequest extends GrpcRequest {

  data?: Categorias;
  operation: string = '';

  origin: Origin = OriginEmpty;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

}


export class ModifyCategoriasResponse extends GrpcResponse {

  data?: Categorias;
  error: string = '';

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }
}


export class ListCategoriasRequest extends GrpcRequest {
  search?: string = '';
  filter?: Categorias;
  pagination?: Pagination;
  origin: Origin = OriginEmpty;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

}


export class ListCategoriasResponse extends GrpcResponse {

  data?: Array<Categorias>;
  error: string = '';
  pagination?: Pagination;

  constructor(asObject?: object) {
    super();

    // Llamamos después de super para que no se sobreescriban los valores por defecto
    if (asObject) {
      this.copyFromObject(asObject);
    }
  }

}

