import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {NbPopoverDirective, NbPosition, NbTrigger} from "@nebular/theme";

/*
- Si se usa el atributo [hover] el contenido es cargado siempre antes de pintar
- Si se usa el template #hover el contenido sólo se carga cuando se hace el hover
    => puede que se salga de la pantalla si no se especifican las dimensiones


 */

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit, OnChanges {
  @Input() disabled: boolean = false;
  @Input() trigger: NbTrigger = NbTrigger.HOVER;
  @Input()  placement: NbPosition = NbPosition.END;
  @ContentChild('hover') hover?: TemplateRef<any>;
  @ViewChild(NbPopoverDirective) popover?: NbPopoverDirective;
  @Output() onChangeVisibility: EventEmitter<boolean> = new EventEmitter<boolean>();


  currentTrigger: NbTrigger = NbTrigger.HOVER;

  constructor() { }

  ngOnInit(): void {
    this.currentTrigger = this.trigger;
    if (this.disabled) {
      this.currentTrigger = NbTrigger.NOOP;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.disabled) {
      this.close();
      this.currentTrigger = NbTrigger.NOOP;
    } else {
      this.currentTrigger = this.trigger;
    }
  }

  close() {
    if (this.popover) {
      this.popover.hide();
    }
  }

  onChangeStatus() {
    if (this.popover) {
      this.onChangeVisibility.emit(this.popover.isShown);
    }
  }
}
