import {Inject, Injectable} from '@angular/core';
import {NbDialogService} from "@nebular/theme";
import {DOCUMENT} from "@angular/common";
import {DialogOptions, TableOptions} from "../@models/types";
import {ConfirmPromptComponent} from "./confirm-prompt/confirm-prompt.component";
import {InfoPromptComponent} from "./info-prompt/info-prompt.component";
import {OtpFormComponent} from "./otp-form/otp-form.component";
import {WizardComponent, WizardPage} from "./wizard/wizard.component";
import {InfoTableComponent} from "./info-table/info-table.component";

@Injectable({
  providedIn: 'root'
})

// Para mantener aquí todas las variables globales
export class DialogsService  {

  constructor(public service: NbDialogService,

              @Inject(Window) private window: Window,
              @Inject(DOCUMENT) private document: Document) {


  }

  dialogConfirm(options: DialogOptions | string): Promise<boolean> {
    return new Promise<boolean>( (resolve, reject) => {
      if (typeof options === 'string') {
        options = {
          body: options,
        }
      }
      const dialogRef = this.service.open(ConfirmPromptComponent, {
        closeOnBackdropClick: false,
        autoFocus: false,
        context: {
          ...options,
          onOK: () => {
            dialogRef.close();
            resolve(true);

            },
          onCANCEL: () => {
            dialogRef.close();
            resolve(false);

            },
        },
      });
    });
  }

  dialogInfo(options: DialogOptions | string): Promise<void> {
    return new Promise<void>( (resolve, reject) => {
      if (typeof options === 'string') {
        options = {
          body: options,
        }
      }
      const dialogRef = this.service.open(InfoPromptComponent, {
        closeOnBackdropClick: false,
        autoFocus: false,
        context: {
          ...options,
          onOK: () => {
            dialogRef.close();
            resolve(); },
        },
      });
    });
  }

  dialogInfoTable(options: TableOptions): Promise<void> {
    return new Promise<void>( (resolve, reject) => {
      const dialogRef = this.service.open(InfoTableComponent, {
        closeOnBackdropClick: false,
        autoFocus: false,
        context: {
          options: options,
          onOK: () => {
            dialogRef.close();
            resolve(); },
        },
      });
    });
  }

  dialogOTP(onResult: (otp: string) => Promise<string>, title: string = 'Código OTP', help: string = '', extraInfo: string = '') {
    const dialogRef = this.service.open(OtpFormComponent, {
      closeOnBackdropClick: false,
      autoFocus: false,
      context: {
        title: title,
        help: help,
        extraInfo: extraInfo,
        onResult: (otp: string) => {
          return new Promise<string>( (resolve) => {
            onResult(otp).then( (res) => {
              if (res == '') {
                setTimeout( () => {
                  dialogRef.close();
                }, 50);
              }
              resolve(res);
            });
          });


        },
      },
    });
  }

  dialogWizard(header: string, pages: WizardPage[]): Promise<void> {
    return new Promise<void>( (resolve, reject) => {
      const dialogRef = this.service.open(WizardComponent, {
        closeOnBackdropClick: false,
        autoFocus: false,
        context: {
          header: header,
          pages: pages,
          onExit: () => {
            dialogRef.close();
            resolve();
          },
        },
      });
    });
  }

}
