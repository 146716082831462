

export function isMobilePhone(phone: string): boolean {
    // Longitud mínima 9 y máxima 15
    // Puede empezar por +
    // Todos números

    const re = /^[+]?[0-9]{9,15}$/;
    return re.test(phone);
}

export function isNIF(value: string): boolean{
  let isNIF = false;
  if (/^[0-9]{8}[A-Z]$/.test(value)) {
    const letter: string = value.charAt(value.length - 1);
    const numberPart: string = value.substr(0, value.length - 1);
    const remainder: number = parseInt(numberPart, 10) % 23;
    const letters = 'TRWAGMYFPDXBNJZSQVHLCKE';

    if (letters.charAt(remainder) === letter) {
      isNIF= true; // NIF is valid
    }
  }

  return isNIF;
}

export function promptForPassword(password: string ): boolean {
  return (!/(?=.*\d)(?=.*[a-zA-Z])(?=.*\W)/.test(password)) ? false : true;
}

export function isCodiceFiscale(codice: string ): boolean {
  return (/^[a-zA-Z]{6}[a-zA-Z0-9]{10}$|^\d{11}$/.test(codice)) ? true : false;
}

export function isRiferimento(riferimento: string ): boolean {
  return (/^[a-zA-Z0-9]{8}$/.test(riferimento)) ? true : false;
}

export function isPhoneFormatIt(phone: string): boolean {
  return (/^(?:\+39|39|0039)\d{10}$/.test(phone));
}
