import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-option-switch',
  templateUrl: './option-switch.component.html',
  styleUrls: ['./option-switch.component.scss']
})
export class OptionSwitchComponent implements OnInit {
  @Input() label: string = '';
  @Input() subtitle: string = '';
  @Input() checked: boolean = true;
  @Input() disabled: boolean = false;
  @Output() onSwitch = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {

  }


  onEnabled(data: any) {
    this.checked = !this.checked;
    this.onSwitch.emit(this.checked);
  }


}
