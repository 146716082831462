import {GrpcRequest, GrpcResponse, OriginEmpty, Origin} from "../@core/@models/grpc";

export class ActionRequest extends GrpcRequest {

    cmd: string = '';
    tid: string = '';
    tok: string = '';

    origin: Origin = OriginEmpty;


    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }

}


export class ActionResponse extends GrpcResponse {
    error: string = '';
    message: string = '';

    constructor(asObject?: object) {
        super();

        // Llamamos después de super para que no se sobreescriban los valores por defecto
        if (asObject) {
            this.copyFromObject(asObject);
        }
    }
}

