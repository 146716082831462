import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Icon, IconClose} from "../../../@core/@models/icons";
import {FormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {NbDialogRef, NbDialogService} from "@nebular/theme";
import {BusService} from "../../../@core/@bus/bus.service";
import {Authentication} from "../../../core/authentication";
import {LoginRequest, LoginResponse} from "../../../models/login";
import {GetGlobalEventManager} from "../../../core/bus-configuration";
import {RouteStartsWith} from "../../../@core/@models/strings";
import {ErrNotValidated} from "../../../models/error";
import {GenericRequest, GenericResponse} from "../../../models/generic";
import {Backend} from "../../../core/backend";
import {Deuda, ModifyDeudaRequest, ModifyDeudaResponse} from "../../../models/deuda";

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, AfterViewInit {

  @ViewChild('dialog') dialog?:TemplateRef<any>;

  isLoading: boolean = true;
  executing: boolean = false;
  labelControlAccesso: string = $localize`Control de Accesso`;
  labelCorreElectronico: string = $localize`Correo Electrónico`;
  labelPlaceholderEmail: string = $localize`email@dominio.com`;
  labelCotrasena: string = $localize`Contraseña`;
  labelGuardarSesion: string = $localize`Guardar sesión`;
  labelNuevoUsuario: string = $localize`Nuevo usuario`;
  labelAcceder: string = $localize`Acceder`;
  labelRestaurar: string = $localize`Restaurar contraseña`;
  iconClose: Icon = IconClose;
  error: string = '';
  info: string = '';

  loginForm: UntypedFormGroup = new UntypedFormGroup({
    user: new FormControl<string>('', Validators.email),
    password: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(4),
    ]),
    remember: new FormControl<boolean>(true),
  });

  whereToGoAfterLogin: string;
  dialogRef?: NbDialogRef<any>;

  constructor(private bus: BusService,private dialogService: NbDialogService) {
    this.whereToGoAfterLogin = this.bus.router.initialRoute;
    if (this.whereToGoAfterLogin.startsWith( '/login') || this.whereToGoAfterLogin.startsWith( '/accion')) {
      this.whereToGoAfterLogin = '/'; // Para que vaya a la route por defecto
    }

    if (Authentication.getAuth().isValid()) {
      this.isLoading = false;
      if (this.dialogRef) {
        this.dialogRef.close();
      }
      this.bus.router.goTo(this.whereToGoAfterLogin);
      return;
    }

    const sso: string = Authentication.getSso();

    if (sso != "") {
      // Intentamos ver si está la sessión abierta
      const request: LoginRequest = new LoginRequest()
      request.sso = sso;
      Authentication.login(request).then( (logged: LoginResponse): void => {
        if (logged) {
          this.isLoading = false;
          if (this.dialogRef) {
            this.dialogRef.close();
          }
          this.bus.router.goTo(this.whereToGoAfterLogin);
          return;
        }
        this.isLoading = false;
      });
    } else {
      // Intentamos ver si está la sessión abierta
      Authentication.reconnect().then( (logged: boolean): void => {
        if (logged) {
          this.isLoading = false;
          if (this.dialogRef) {
            this.dialogRef.close();
          }
          this.bus.router.goTo(this.whereToGoAfterLogin);
          return;
        }
        this.isLoading = false;
      });
    }
  }


  hasUser(): boolean {
    const control = this.loginForm.get('user');
    if (control) {
      if (!control.value) {
        return false;
      }
      return control.valid;
    }
    return false;
  }

  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    if (this.dialog) {
      // Ponemos el last user
      let remember = this.bus.storage.globalGet("clubintel-remember");
      if (remember == undefined) {
        remember = true;
      }
      this.loginForm.reset({
        user: this.bus.storage.globalGet("clubintel-last-user"),
        password: '',
        remember: remember,
      });

      this.dialogRef = this.dialogService.open(this.dialog, {
        closeOnBackdropClick: false,
        autoFocus: false,
      });
    }
  }

  async onSubmit(callback: VoidFunction): Promise<void> {
    this.info = "";
    this.error = "";
    const request: LoginRequest = new LoginRequest();
    request.login = this.loginForm.value.user;
    request.password = this.loginForm.value.password;
    request.remember = this.loginForm.value.remember;

    await Authentication.login(request).then( (response: LoginResponse): void => {
      if (response.error == "") {
        if (Authentication.getAuth().isValid()) {

          // Asignamos cookie last user
          this.bus.storage.globalSet("clubintel-last-user",Authentication.getAuth().getLogin());
          this.bus.storage.globalSet("clubintel-remember",this.loginForm.value.remember);
          GetGlobalEventManager().menuDocumentation.next(false);

          if (this.dialogRef) {
            this.dialogRef.close();
          }
          if (callback) {
            callback();
          }
          const routed: boolean = GetGlobalEventManager().routeIfPendingConfigurations();
          if (!routed) {
            if (this.whereToGoAfterLogin != '/' + navigator.language.substring(0, 2) && this.whereToGoAfterLogin != '/' + navigator.language.substring(0, 2) + '/'  && this.whereToGoAfterLogin != '/' + navigator.language.substring(0, 2) + '/accion' && this.whereToGoAfterLogin != '/' + navigator.language.substring(0, 2) +'/public' && this.whereToGoAfterLogin != '/' + navigator.language.substring(0, 2) +'/registro'&& this.whereToGoAfterLogin != '/registro' && !RouteStartsWith(this.whereToGoAfterLogin,'/accion') && this.whereToGoAfterLogin != '/public') {
              this.bus.router.goTo(this.whereToGoAfterLogin);
            } else {
              this.updateData();
            }
          }
          return;
        }
      }
      if (response.error == ErrNotValidated) {
        this.error = $localize`Cuenta no validada todavía`;
      } else {
        this.error = $localize`Credenciales inválidas`;
      }
      if (callback) {
        callback();
      }
    });

  }

  updateData(): void {
    const request: GenericRequest = new GenericRequest();
    request.command = 'force_update_lexer';
    if (!this.bus.isColectivoLexer()){
      if (this.bus.isColectivoVozitel()){ // DEMO: Quitar cuando termine
        request.command = 'force_update_vozitel';
      }else{
        request.command = 'force_update';
      }
    }
    Backend.genericAuth(request).then( (response: GenericResponse): void => {
      if (response.error == "") {
        Authentication.goToLanding();
      } else {
        this.bus.dom.toastError(response.error);
      }
    });
  }

  sendToBackend(operation: string): Promise<ModifyDeudaResponse> {
    return new Promise<ModifyDeudaResponse>( (resolve, reject): void => {
      const request: ModifyDeudaRequest = new ModifyDeudaRequest();
      request.operation = operation;
      Deuda.modifyDeuda(request).then( (response: ModifyDeudaResponse): void => {
        resolve(response);
      });
    });
  }

  onRememberPassword(): void {
    this.info = "";
    this.error = "";
    this.executing = true;

    const request: GenericRequest = new GenericRequest();
    request.command = 'forget_password';
    request.data = this.loginForm.value.user;

    Backend.generic(request).then( (response: GenericResponse): void => {
      if (response.error == "") {
        this.info = $localize`siga las instrucciones del correo recibido`;
      } else {
        this.error = $localize`no se ha podido realizar la operación`;
      }
      this.executing = false;
    });
  }

  onClose(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    Authentication.goToLanding();
  }

  onCloseAlertError(): void {
    this.error = '';
  }
  onCloseAlertInfo(): void {
    this.info = '';
  }

  onNuevoUsuario(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.bus.router.goTo('/registro');
  }



}
