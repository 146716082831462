import {AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[visibilityObserver]',
})
export class VisibilityObserverDirective implements OnDestroy, OnInit, AfterViewInit {
  @Output() onVisible = new EventEmitter<number>(); // Emite el ratio de interseccion de 0 a 1
  @Output() onHidden = new EventEmitter();

  intersectionObserver?: IntersectionObserver;

  constructor(private element: ElementRef) {}


  ngOnInit() {

  }

  ngAfterViewInit() {
    this.intersectionObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if(entry.intersectionRatio > 0) {
          this.onVisible.emit(entry.intersectionRatio);
        } else {
          this.onHidden.emit();
        }
      });
    });
    this.intersectionObserver.observe(this.element.nativeElement);
  }

  ngOnDestroy() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }
}
