import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ProgressIndicator} from "../../../@core/@bus/download.service";
import {BusService} from "../../../@core/@bus/bus.service";
import {Icon, IconClose} from "../../../@core/@models/icons";


type itemDescarga = {
  id: string,
  icon: Icon,
  title: string,
  elapsed: string,
  progress: number,
  class: string,
}

@Component({
  selector: 'app-header-progress-popover',
  templateUrl: './header-progress-popover.component.html',
  styleUrls: ['./header-progress-popover.component.scss']
})
export class HeaderProgressPopoverComponent implements OnInit, OnDestroy {
  @Input() mapItems: Map<string,ProgressIndicator> = new Map();
  labelCancelar: string = $localize`Cancelar`;

  items: itemDescarga[] = []
  interval: any;

  iconClose: Icon = IconClose;

  constructor(private bus: BusService) { }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  ngOnInit(): void {
    this.updateAll();
    this.interval = setInterval( (): void => {
      this.updateAll();
    }, 1000);
  }

  updateAll(): void {
    this.mapItems.forEach( (item): void => {
      const creado: number = item.created.getTime();
      const elapsedSecs: number  = ((new Date().getTime()) - creado) / 1000;
      const elapsed: string = this.bus.wasm.formatSeconds(elapsedSecs);

      // Vemos si existe
      const indexFound: number =  this.items.findIndex(e => e.id === item.uniqId);
      if (indexFound >= 0) {
         this.items[indexFound].icon =  item.icon;
         this.items[indexFound].title =  item.title;
         this.items[indexFound].elapsed =  elapsed;
         this.items[indexFound].progress =  item.progress;
         this.items[indexFound].class = 'bottom-line';
      } else {
        this.items.push({
          id: item.uniqId,
          icon: item.icon,
          title: item.title,
          elapsed: elapsed,
          progress: item.progress,
          class: 'bottom-line',
        });
      }
    });

    // Al ultimo le quitamos el bottom-line
    if (this.items.length > 0 ) {
      this.items[this.items.length - 1].class = '';
    }
  }

  onCancel(uniqId: string): void {
    const descarga = this.mapItems.get(uniqId);
    if (descarga) {
      if (descarga.cancelFunction != null) {
        descarga.cancelFunction();
      }
    }
  }

}
