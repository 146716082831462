import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IconMinus, IconPlus} from "../../../@models/icons";
import {FormatLocalNumber} from "../../../@models/strings";

@Component({
  selector: 'app-option-step-number',
  templateUrl: './option-step-number.component.html',
  styleUrls: ['./option-step-number.component.scss']
})
export class OptionStepNumberComponent implements OnInit, OnChanges {
  @Input() label: string = '';
  @Input() subtitle: string = '';
  @Input() checked: boolean = true;
  @Input() step: number = 1000;
  @Input() value: number = 0;
  @Input() disabled: boolean = false;
  @Input() thousandsSeparator: boolean = false;
  @Output() onChange = new EventEmitter<number>();
  @Output() onSwitch = new EventEmitter<boolean>();

  @Input() max?: number;
  @Input() min?: number;

  valueSmart: string = '';

  minusDisabled: boolean = false;
  maxDisabled: boolean = false;

  iconPlus = IconPlus;
  iconMinus = IconMinus;

  constructor() { }

  ngOnInit(): void {
    this.toSmart();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.toSmart();
  }

  toSmart() {
    if (this.thousandsSeparator) {
      this.valueSmart = FormatLocalNumber(this.value,0,0);
    } else {
      this.valueSmart = this.value + '';
    }

    if (this.max != undefined && this.value >= this.max) {
      this.maxDisabled = true;
    } else {
      this.maxDisabled = false;
    }
    if (this.min != undefined && this.value <= this.min) {
      this.minusDisabled = true;
    } else {
      this.minusDisabled = false;
    }
  }


  onEnabled(data: any) {
    this.checked = !this.checked;
    this.onSwitch.emit(this.checked);
  }

  onClickPlus() {
    const old = this.value;
    this.value += this.step;

    if (this.max != undefined && this.value > this.max) {
      this.value = this.max;
    }
    if (this.value != old) {
      this.onChange.emit(this.value);
    }
    this.toSmart();
  }

  onClickMinus() {
    const old = this.value;
    this.value -= this.step;

    if (this.min != undefined && this.value < this.min) {
      this.value = this.min;
    }
    if (this.value != old) {
      this.onChange.emit(this.value);
    }
    this.toSmart();
  }

}
