import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './date-picker/date-picker.component';
import {
  NbButtonModule,
  NbCalendarModule,
  NbCardModule,
  NbDatepickerModule, NbFormFieldModule, NbIconModule, NbInputModule,
  NbTimepickerModule
} from "@nebular/theme";
import { CalendarComponent } from './calendar/calendar.component';
import { InputDateComponent } from './input-date/input-date.component';
import {FormViewModule} from "../../@form-view/@form-view.module";




@NgModule({
  declarations: [
    DatePickerComponent,
    CalendarComponent,
    InputDateComponent
  ],
    exports: [
        DatePickerComponent,
        InputDateComponent,
        CalendarComponent
    ],
  imports: [
    CommonModule,
    NbCardModule,
    NbCalendarModule,
    NbDatepickerModule,
    NbTimepickerModule,
    NbInputModule,
    NbFormFieldModule,
    NbIconModule,
    NbButtonModule,
    FormViewModule,
  ],


})
export class CalendarModule { }
