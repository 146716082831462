import {Component, Inject, OnInit} from '@angular/core';
import {filter, map, Subject, takeUntil} from "rxjs";
import {
  NbMediaBreakpointsService, NbMenuBag,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService
} from "@nebular/theme";
import {LayoutService} from "../../@core/@angular/services/layout.service";
import {BusService} from "../../@core/@bus/bus.service";
import {Icon, IconAvatar, IconSearch, MatIcon} from "../../@core/@models/icons";
import {User, UserConfig} from "../../models/user";
import {changedGlobalTheme, Status} from "../../@core/@models/theme";
import {AlertEvent, Notify, NotifyEvent} from "../../models/alert";
import {MediaQueryEvent, MediaQueryService} from "../../@core/@angular/services/media-query.service";
import {ErrGeneric} from "../../@core/@models/strings";
import {delBadgeMenuLogged, MenuLoggedIndex, setBadgeMenuLogged} from "../../menu-logged";
import {DestroyerComponent} from "../../@core/@angular/abstract/destroyer/destroyer.component";
import { NowHours} from "../../@core/@models/date";
import {ToMap} from "../../@core/@models/types";
import {Backend} from "../../core/backend";
import {Authentication} from "../../core/authentication";
import {GetGlobalEventManager} from "../../core/bus-configuration";
import {ServerEvent, TagEvent} from "../../core/event-manager";
import {MenuIsHidden} from "../../models/routes";
import {GenericRequest, GenericResponse} from "../../models/generic";
import {AuthData} from "../../core/auth-data";
import {OKTA_AUTH} from "@okta/okta-angular";
import {OktaAuth} from "@okta/okta-auth-js";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends DestroyerComponent implements OnInit {

  labelSinConexion: string = $localize`Sin conexión con el servidor`;
  labelAlertas: string = $localize`Alertas`;
  labelCerrarPestana: string = $localize`Cierre esta pestaña cuando acabe de leer el documento`;
  labelCerrar: string = $localize`Cerrar`;
  labelAyuda: string = $localize`Ayuda`;

  logoIcono: string = "assets/clubintel_logo_icono.svg?v=3";
  logoNombre: string = "assets/clubintel_logo_nombre.svg?v=5";
  logoHeight: string = "1.5rem";
  logoHeightLoaded: boolean = false;

  loading: boolean = true;
  isMapfre: boolean = false;
  logoAnimationDefault: string = 'animation-rotate360-long';
  logoAnimation: string = '';

  isLogged: boolean = false;
  busError: boolean = false;
  showDocMenu: boolean = false;

  onlyClose: boolean = false;
  onlyDocument: boolean = false;
  labelAcceder: string = $localize`Acceder`;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;

  user: User | null = null;

  currentTheme: string = 'default';
  currentThemeLabel: string = 'Modo Oscuro';

  iconBell: Icon = MatIcon('notifications');
  iconDocs: Icon = MatIcon('help');
  iconPublic: Icon = MatIcon('public');
  isVozitel: boolean = false;
  isEuropaFactor: boolean = false;

  iconSearch: Icon = IconSearch;
  iconConexion: Icon =  MatIcon('bolt', "warning");
  iconLogin: Icon = MatIcon('login');
  iconAvatar: Icon = IconAvatar;
  iconDocumento: Icon = MatIcon('article');
  iconConfig: Icon = MatIcon('settings');

  menuIsHidden: boolean = false;

  alertasPendientes: boolean = false;
  alertasStatus: Status = 'warning';

  documentacionEnabled: boolean = false;
  publicEnabled: boolean = false;

  userMenu: NbMenuItem[] = [
    { title: $localize`Configuración`, data: 'configuracion', icon: this.iconConfig },
    { title: $localize`Avatar`, data: 'avatar', icon: this.iconAvatar },
    { title: $localize`Salir`, data: 'salir', icon: MatIcon('logout') } ,
  ];

  bpIsGreaterThanSM: boolean = true;

  currentBadges: Map<string, string> = new Map();

  constructor(private bus: BusService,
              private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private mediaQuery: MediaQueryService,
              private breakpointService: NbMediaBreakpointsService,
              @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) {

    super();

    // Logotipos
    const heightRequest: GenericRequest = new GenericRequest();
    heightRequest.command = 'logo_height';
    Backend.generic(heightRequest).then( (response: GenericResponse): void => {
      if (response.error == '') {
        this.logoHeight = response.data;
      }
      this.logoHeightLoaded = true;

    });
    const urlBus: string = this.bus.router.getUrlBus();
    this.logoIcono = urlBus + '/assets?id=logo_icono&t='+NowHours()
    this.logoNombre = urlBus + '/assets?id=logo_nombre&t='+NowHours()
    if(this.bus.isColectivoSabadell()){
      this.userMenu = [
        { title: $localize`Configuración`, data: 'configuracion', icon: this.iconConfig },
        { title: $localize`Buscador`, data: 'buscador-noticias', icon: this.iconSearch },
        { title: $localize`Avatar`, data: 'avatar', icon: this.iconAvatar },
        { title: $localize`Salir`, data: 'salir', icon: MatIcon('logout') } ,
      ];
    }

    if (this.bus.isColectivoMapfre()){
      this.isMapfre = true;
      this.userMenu  = [
        { title: $localize`Avatar`, data: 'avatar', icon: this.iconAvatar },
        { title: $localize`Salir`, data: 'salir', icon: MatIcon('logout') } ,
      ];
    }

    if(this.bus.isColectivoEuropaFactor()){
      this.isEuropaFactor = true;
      this.userMenu = [
        { title: $localize`Configuración`, data: 'configuracion', icon: this.iconConfig },
        { title: $localize`Salir`, data: 'salir', icon: MatIcon('logout') } ,
      ];
    }

    Authentication.waitForAuthValid().then( (auth: AuthData): void => {
      this.isLogged = auth.isValid();
      this.loading = false;

      if (this.isLogged) {
        this.checkUnread();
        this.user = Authentication.getAuth().toUser();
      }
    });


    this.autoUnsubsbriber(mediaQuery.subscribe( (change: MediaQueryEvent): void => {
      this.bpIsGreaterThanSM = change.isGreaterThan('sm');
    }));

    // Estado del bus
    this.autoUnsubsbriber(bus.events.busStateEvent.subscribe( (busState: boolean): void => {
      this.busError = !busState;
    }));

    // Cambio de ruta
    this.autoUnsubsbriber(bus.events.routeChangeEvent.subscribe( (url: string): void => {

      this.menuIsHidden = MenuIsHidden(url);

      if (url.startsWith('/legal')) {
        this.onlyClose = true;
      } else {
        this.onlyClose = false;
      }
      if (url.startsWith('/documento')) {
        this.onlyDocument = true;
      } else {
        this.onlyDocument = false;
      }

      if (url.startsWith('/public')) {
        this.publicEnabled = true;
        this.documentacionEnabled = false;
      } else {
        this.publicEnabled = false;
      }

    }));

    // Icono documentación
    this.autoUnsubsbriber(GetGlobalEventManager().menuDocumentation.subscribe( (activado: boolean): void => {
      this.documentacionEnabled = activado;
    }));

    // Badges
    this.autoUnsubsbriber(GetGlobalEventManager().newBadgesEvent.subscribe( (badges): void => {
      this.newBadges(badges);
    }));


    this.autoUnsubsbriber(bus.events.loginEvent.subscribe( (logged: boolean): void => {
      this.isLogged = logged;
      this.user = Authentication.getAuth().toUser();


      if (logged) {
        const cookieTheme = this.bus.storage.userGet("clubintel-theme");
        if (cookieTheme && cookieTheme != this.currentTheme && cookieTheme != '') {
          this.themeService.changeTheme(cookieTheme);
        }
        this.checkUnread();
      }

    }));

    this.autoUnsubsbriber(GetGlobalEventManager().animation.subscribe( (what: string): void => {
      if (what == 'logo') {
        this.animateLogo();
      }
    }));

  }

  checkThemeLabel(): void {
    if (this.currentTheme == 'dark') {
      this.currentThemeLabel = 'Modo Claro';
    } else {
      this.currentThemeLabel = 'Modo Oscuro';
    }
  }



  ngOnInit(): void {
    this.menuIsHidden = MenuIsHidden(this.bus.router.currentRoute());
    if (this.bus.isColectivoVozitel()){
      this.isVozitel = true;
    }
    this.currentTheme = this.themeService.currentTheme;
    this.checkThemeLabel();

    // Cogemos tema de la cookie
    this.readThemeStorage('default');


    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]): boolean => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean): void => {
        this.userPictureOnly = isLessThanXl;
      });

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName =>  {
        this.currentTheme = themeName;
        this.checkThemeLabel();
        changedGlobalTheme(themeName);
      });

    // Menu avatar
    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }): boolean => tag === 'user-menu-option'),
        map((menu: NbMenuBag) => menu.item.data),
      )
      .subscribe(data => {
        switch (data) {
          case 'salir':
            this.isLogged = false;
            let redirect: boolean = this.isMapfre ? false : true;
            Authentication.logout(redirect).then( (ok: boolean): void => {
              if (ok) {
                if (this.isMapfre){
                  this.signOut();
                  Authentication.goToLanding();
                }
                this.isLogged = false;
              } else {
                this.bus.dom.toastError(ErrGeneric);
              }
            });
            break;
          case 'configuracion':
            this.bus.router.goTo('/configuracion');
            break;
          case 'buscador-noticias':
            this.bus.router.goTo('/buscador-noticias');
            break;
          case 'avatar':
            this.bus.router.goTo('/avatar');
            break;
        }
      });


    // Subscripción a alertas
    this.autoUnsubsbriber(this.bus.events.tagEventSubscribe(TagEvent.Alert, (event: ServerEvent): void => {
        const alertEvent = new AlertEvent(event);
        this.alertasPendientes = true;

        // No las almacenamos porque están en la BBDD
        const msg = alertEvent.getMessage()
        if (msg) {
          this.bus.dom.toastInfo(msg);
        }

    }));


    // Subscripción a notificaciones
    this.autoUnsubsbriber(this.bus.events.tagEventSubscribe(TagEvent.Notification, (event: ServerEvent): void => {
      const notifyEvent: NotifyEvent = new NotifyEvent(event);
      this.alertasPendientes = true;

      this.bus.logger.debugBrowser("TagEvent.Notification: ", notifyEvent);


      // Convertimos en alerta
      const notif: Notify = notifyEvent.getNotify();
      if (notif) {

        // Si sin nuevos movimientos, borramos cache
        switch(notif.type) {
          case 'otp':
            // Se requiere OTP
            // TODO: implementar
            break;
          default:
            this.bus.dom.toastInfo(notif.title);
        }
      }

    }));

    this.autoUnsubsbriber(GetGlobalEventManager().newConfigEvent.subscribe( (config): void => {
      this.configUpdated(config);
    }));


    this.documentacionEnabled = this.bus.router.currentRouteStartsWith('/documentacion');
    this.publicEnabled = this.bus.router.currentRouteStartsWith('/public');

  }

  override ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    super.ngOnDestroy();
  }

  readThemeStorage(setDefault?: string): void {
    if (this.isLogged) {
      const cookieTheme = this.bus.storage.userGet("clubintel-theme");
      if (cookieTheme && cookieTheme != this.currentTheme && cookieTheme != '') {
        this.themeService.changeTheme(cookieTheme);
      } else {
        if (setDefault) {
          this.setDefaultTheme(setDefault)
        }
      }
    } else {
      const cookieTheme = this.bus.storage.globalGet("clubintel-theme");
      if (cookieTheme && cookieTheme != this.currentTheme && cookieTheme != '') {
        this.themeService.changeTheme(cookieTheme);
      } else {
        if (setDefault) {
          this.setDefaultTheme(setDefault)
        }
      }
    }
  }

  setDefaultTheme(setDefault: string): void{
    if(this.bus.isColectivoLexer()){
      this.themeService.changeTheme('lexer');
      this.saveThemeStorage('lexer');
    }else if(this.bus.isColectivoSabadell()) {
      this.themeService.changeTheme('sabadell');
      this.saveThemeStorage('sabadell');
    }else if(this.bus.isColectivoEuropaFactor()){
      this.themeService.changeTheme('europafactor');
      this.saveThemeStorage('europafactor');
    }else if(this.bus.isColectivoAdvTrade()){
      this.themeService.changeTheme('advtrade');
      this.saveThemeStorage('advtrade');
    }else if(this.bus.isColectivoMapfre()){
      this.themeService.changeTheme('mapfre');
      this.saveThemeStorage('mapfre');
    }else if(this.bus.isColectivoVozitel()){
      this.themeService.changeTheme('vozitel');
      this.saveThemeStorage('vozitel');
    }else{
      this.themeService.changeTheme(setDefault);
    }
  }

  saveThemeStorage(theme: string): void {
    if(this.isLogged) {
      this.bus.storage.userSet("clubintel-theme",theme);
    }
    this.bus.storage.globalSet("clubintel-theme",theme);
  }

  changeTheme(): void {
    if(!this.bus.isColectivoLexer() && !this.bus.isColectivoSabadell() && !this.bus.isColectivoEuropaFactor() && !this.bus.isColectivoAdvTrade() && !this.bus.isColectivoVozitel() && !this.bus.isColectivoMapfre()) {
      if (this.currentTheme == 'default') {
        this.themeService.changeTheme('dark');
        this.saveThemeStorage('dark');
      } else {
        this.themeService.changeTheme('default');
        this.saveThemeStorage('default');
      }
    }

    /*if(this.bus.getHostForRequests() === "lexer.clubintel.com"){
      this.themeService.changeTheme('lexer');
      this.saveThemeStorage('lexer');
    }else if(this.bus.getHostForRequests() === "lexer.clubintel.com"){
      this.themeService.changeTheme('sabadell');
      this.saveThemeStorage('sabadell');
    }else{
      if (this.currentTheme == 'default') {
        this.themeService.changeTheme('dark');
        this.saveThemeStorage('dark');
      } else {
        this.themeService.changeTheme('default');
        this.saveThemeStorage('default');
      }
    }*/

    this.checkThemeLabel();
  }

  toggleSidebar(): boolean {

    // Estado del sidebar
    // collapsed => totalmente oculta
    // compacted => sólo se ven los iconos
    // expanded => icono con título

    // Estado actual
    const currentStatus = this.bus.globals.get('main-sidebar-status', 'expanded');
    switch(currentStatus) {
      case 'compacted':
        this.sidebarService.expand('menu-sidebar');
        break;
      case 'collapsed':
        this.sidebarService.expand('menu-sidebar');
        break;
      default: // expanded
        this.sidebarService.collapse( 'menu-sidebar');
    }
    this.layoutService.changeLayoutSize();

    return false;
  }

  animateLogo(): void {
    // Si está animando, no hacemos nada
    if (this.logoAnimation != '') {
      return;
    }

    this.logoAnimation = this.logoAnimationDefault;
    setTimeout((): void=>{
      this.logoAnimation = '';
    }, 1000);
  }

  navigateHome(): boolean {
    this.animateLogo();
    Authentication.goToLanding();
    return false;
  }

  onDocs(): void {
    GetGlobalEventManager().menuDocumentation.next(!this.documentacionEnabled);
    if (!this.bus.router.currentRouteStartsWith('/documentacion')) {
      if(this.isEuropaFactor){
        this.bus.router.goTo('/documentacion/legal-privacidad');
      }else{
        this.bus.router.goTo('/documentacion');
      }

    } else {
      Authentication.goToLanding();
    }
  }

  onPublic(): void {
    if (!this.bus.router.currentRouteStartsWith('/public')) {
      this.bus.router.goTo('/public');
    } else {
      Authentication.goToLanding();
    }
  }

  onAlertas(): void {
    this.alertasPendientes = false;
  }


  configUpdated(config: UserConfig | undefined): void {
    if (config) {
      Authentication.getAuth().updateConfig(config);
      this.user = Authentication.getAuth().toUser();

      this.newBadges(config.badges);

    }
  }




  checkUnread(): void {
    // TODO: mirar si hay notificaciones pendientes

  }


  onLogin(): void {
    this.bus.router.goTo('/login');
  }

  onCerrarDocumento(): void {
    this.onlyDocument = false;
    Authentication.goToLanding();
  }

  newBadges(badges: object | undefined): void {
    // Los nuevos
    const newBadgeMap = ToMap(badges);

    // Los que no han venido
    this.currentBadges.forEach((value, key): void => {
        if (!newBadgeMap.has(key)) {
          newBadgeMap.set(key, "");
        }
    });


    // Ya los tenemos todos, procesamos
    newBadgeMap.forEach((value, key): void => {
      switch(key) {
        case "comunicados":
          if (value && value != "" && value != "0") {
            setBadgeMenuLogged(MenuLoggedIndex.Comunicados,value);
          } else {
            delBadgeMenuLogged(MenuLoggedIndex.Comunicados)
          }

          break;
        default:
          this.bus.logger.warning("badge no reconocido", key);
      }

    });


    // Los guardamos
    this.currentBadges = newBadgeMap;


  }

  public async signOut(): Promise<void> {
    await this._oktaAuth.signOut();
  }

}
