import { Pipe, PipeTransform } from '@angular/core';
import {DateFormat} from "../../@models/date";

@Pipe({
  name: 'dateHuman'
})
export class DateHumanPipe implements PipeTransform {

  // Args0 => removeTime
  // Ejempo dateHuman: true

  transform(value: string, ...args: unknown[]): string {
    const df = DateFormat.fromBackend(value);
    if (args.length > 0) {
      if (args[0]) {
        df.hasTime = false;
      }
    }

    return df.toHuman();
  }

}
