<div class="row">
  <div class="column" style="width: 50%">
    <label *ngIf="labelLeft"  [class.disabled]="disabled" class="form-label">{{labelLeft}}</label>
    <nb-card class="list-wrapper-card" style="height: {{height}}" [HeightVisible]="!!HeightVisibleOffset" [Offset]="HeightVisibleOffset">
      <nb-card-header *ngIf="searchable && !disabled" class="list-wrapper-header text-center">
        <app-input-text class="search-input" size="tiny" [fullWidth]="true" [icon]="iconSearch" (onInputChange)="onSearch($event)"> </app-input-text>
      </nb-card-header>
      <nb-card-body class="list-wrapper-body" [nbSpinner]="loading">
        <nb-list *ngIf="listFiltered.length > 0"
                 class="card-list-tall cursor-pointer scroll-y">
          <nb-list-item *ngFor="let optionSelectable of listFiltered; let i = index;"
                        [@listAnimationLeft]="itemsLoaded"
                        (@listAnimationLeft.start)="onLeftAnimate(true)"
                        (@listAnimationLeft.done)="onLeftAnimate(false)"
                        [ngClass]="{'cursor-disable-events': disabled, 'text-hint': disabled}"
                        (click)="onAdd(optionSelectable, i)"
          >
            <div class="flex-space-between">
              <app-icon *ngIf="optionSelectable.icon" [icon]="optionSelectable.icon" class="me-2"></app-icon>
              <span class="list-label">{{optionSelectable.label}}</span>
            </div>
          </nb-list-item>
        </nb-list>
        <div *ngIf="listFiltered.length == 0 && animatingLeft== false" class="flex-center h-100">
          <span>Lista vacía</span>
        </div>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="column" style="width: 50%">
    <label *ngIf="labelRight"  [class.disabled]="disabled" class="form-label">{{labelRight}}</label>
    <nb-card class="list-wrapper-card" style="height: {{height}}" [HeightVisible]="!!HeightVisibleOffset" [Offset]="HeightVisibleOffset">
      <nb-card-body class="list-wrapper-body">
        <nb-list *ngIf="currentValue.length > 0"
                 class="card-list-tall cursor-pointer scroll-y">
          <nb-list-item  *ngFor="let option of currentValue; let i = index;"
                         [@listAnimationRight]="itemsLoaded"
                         (@listAnimationRight.start)="onRightAnimate(true)"
                         (@listAnimationRight.done)="onRightAnimate(false)"
                         [ngClass]="{'cursor-disable-events': disabled, 'text-hint': disabled}"
                         (click)="onDel(option, i)"
          >
            <div class="flex-space-between">
              <app-icon *ngIf="option.icon" [icon]="option.icon" class="me-2"></app-icon>
              <span class="list-label">{{option.label}}</span>
            </div>

          </nb-list-item>
        </nb-list>
        <div *ngIf="currentValue.length == 0  && animatingRight== false" class="flex-center h-100">
          <span>Lista vacía</span>
        </div>
      </nb-card-body>
    </nb-card>
  </div>


</div>

