import {Component, Input, OnInit} from '@angular/core';
import {TableColumn, TableOptions} from "../../@models/types";

@Component({
  selector: 'app-info-table',
  templateUrl: './info-table.component.html',
  styleUrls: ['./info-table.component.scss']
})
export class InfoTableComponent implements OnInit {

  @Input() options: TableOptions = {};
  @Input() onOK?: () => void;


  columns: TableColumn[] = [];

  constructor() { }

  ngOnInit(): void {

    // Generamos las columnas
    if (this.options.columns) {
      this.options.columns.forEach( (col) => {
        if (typeof col  == 'string') {
            this.columns.push({
              title: col,
              align: 'left',
            })
        } else {
          this.columns.push(col);
        }
      });

    }

  }

  onAceptar() {
    if (this.onOK) {
      this.onOK();
    }
  }

}
