import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {getUUID} from "../../../@models/common";
import {Status} from "../../../@models/theme";


@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SwitchComponent,
    multi: true
  }]
})
export class SwitchComponent implements OnInit, ControlValueAccessor {
  @Input() disabled: boolean = false;
  @Input() checked: boolean = false;
  @Input() status: Status = 'basic';
  @Input() size: "tiny" | "small" | "normal" = "small";
  @Output() onChange = new EventEmitter<boolean>();

  value: any;  // No se pasa como parámetro, poner un formControl


  private onTouched!: Function;
  private onChanged!: Function;

  id: string = getUUID();


  constructor() { }

  ngOnInit(): void {
    if (this.checked) {
      this.value = this.checked;
    }
  }

  onChangeClick(val: any) {
    this.value = !this.value;
    this.onChangeValue(this.value);
    this.onChange.emit(this.value);
  }

  onChangeValue(val: any) {
    if (this.onTouched) {
      this.onTouched();
    }

    if (this.onChanged) {
      this.onChanged(val);
    }
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.checked = obj;
  }
}
