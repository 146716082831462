import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from "./header/header.component";
import {FooterComponent} from "./footer/footer.component";
import {
  HeaderProgressControllerComponent
} from "./header/header-progress-controller/header-progress-controller.component";
import {
    NbActionsModule, NbBadgeModule, NbButtonModule,
    NbContextMenuModule,
    NbIconModule, NbListModule, NbMenuModule, NbPopoverModule, NbProgressBarModule,
    NbSpinnerModule,
    NbToggleModule,
    NbTooltipModule
} from "@nebular/theme";
import { HeaderProgressPopoverComponent } from './header/header-progress-popover/header-progress-popover.component';
import { HeaderAlertasComponent } from './header/header-alertas/header-alertas.component';
import { LandingComponent } from './landing/landing.component';
import { HeaderAlertaComponent } from './header/header-alerta/header-alerta.component';
import {FormViewModule} from "../@core/@form-view/@form-view.module";
import { StorageNotEnabledComponent } from './storage-not-enabled/storage-not-enabled.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    HeaderProgressControllerComponent,
    HeaderProgressPopoverComponent,
    HeaderAlertasComponent,
    LandingComponent,
    HeaderAlertaComponent,
    StorageNotEnabledComponent,
  ],
    exports: [
        HeaderComponent,
        HeaderAlertasComponent,
    ],
  imports: [
    CommonModule,
    NbIconModule,
    NbActionsModule,
    NbToggleModule,
    NbTooltipModule,
    NbContextMenuModule,
    NbSpinnerModule,
    NbProgressBarModule,
    NbBadgeModule,
    NbPopoverModule,
    NbButtonModule,
    NbMenuModule,
    NbListModule,
    FormViewModule,
  ],
})
export class LayoutModule { }
