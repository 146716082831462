import {Option} from "../@core/@models/types";

export const CurrencyList: Option[] = [
  { value: 'EUR', label: "Euro"},
  { value: 'USD', label: "Dólar EE.UU."},
  { value: 'GBP', label: "Libra"},
];

export function CurrencyUnit(currency?: string): string {
  if (!currency) {
    return '';
  }
  switch(currency.toUpperCase()) {
    case 'EUR': return '€';
    case 'USD': return '$';
    case 'GBP': return '£';
  }
  return currency;
}
