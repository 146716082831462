

// En la implementación de BusConfig, utilizar funciones de flecha para no tener problemas con el this

import {BusService} from "../@bus/bus.service";

export abstract class BusConfig {
  abstract getComputerIdCookieName(): string;
  abstract getBusPort(): string;
  abstract getWebPort(): string;
  abstract getServerDNS(): string;
  abstract authHasData(): boolean; // Retorna true si la sesión actual tiene datos (podrían estar expirados)
  abstract authDestroy(): void;    // Destruye la sessión actual
  abstract authReconnect(): Promise<boolean>; // Intenta reconexión y devuelve el resultado (true/false)
  abstract authIsValid(): boolean; // Retorna true si la sesión actual es válida (usuario autenticado sin haber expirado)
  abstract getSessionId(): string;  // Retorna el ID de la sessión actual
  abstract getCredentialsId(): string; // Retorna el ID único del usuario conectado
  abstract onInitiated(bus: BusService): void;     // Se lanza cuando a terminado la inicialización del bus (después de aplicar la configuración)
}

export class BusConfigEmpty extends BusConfig {
  authDestroy(): void {
  }

  authHasData(): boolean {
    return false;
  }

  authIsValid(): boolean {
    return false;
  }

  authReconnect(): Promise<boolean> {
    return Promise.resolve(false);
  }

  getBusPort(): string {
    return "8000";
  }

  getWebPort(): string {
    return "8002";
  }

  getComputerIdCookieName(): string {
    return "f63688130afd2cb676a87d"; // "VEGATOR" MD5 hash
  }

  getCredentialsId(): string {
    return "";
  }

  getServerDNS(): string {
    return "vegator.es";
  }

  getSessionId(): string {
    return "";
  }

  onInitiated(bus: BusService): void {
  }

}
