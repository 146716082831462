<ng-container *ngIf="!advancedMode">
  <app-input-text [nbTooltip]="tooltip" [nbTooltipDisabled]="tooltip == ''" [initialValue]="value" class="search-input" size="tiny"  [icon]="iconSearch" (onInputChange)="onSearch($event)"> </app-input-text>
</ng-container>
<ng-container *ngIf="advancedMode">
  <app-popover #popover  [placement]="popoverPosition" [trigger]="trigger" [disabled]="helpDisabled">
    <app-input-text [initialValue]="value" (onFocus)="onFocus()" class="search-input" size="tiny"  [icon]="iconSearch" (onInputChange)="onSearch($event)"> </app-input-text>
    <ng-template #hover>
      <nb-card style="margin:2px;">
        <nb-card-body class="body-advanced">
          <div>Búsquedas más precisas:</div>
          <br/>
          <table class="table-basic table-no-hover">
            <tr>
              <th>Operador</th><th>Descripción</th>
            </tr>
            <tr>
              <td>(ninguno)</td><td>Palabra opcional</td>
            </tr>
            <tr>
              <td>+</td><td>Palabra obligatoria</td>
            </tr>
            <tr>
              <td>-</td><td>Excluir palabra</td>
            </tr>
            <tr>
              <td>*</td><td>Cualquier terminación</td>
            </tr>
            <tr>
              <td>"..."</td><td>Texto literal</td>
            </tr>
          </table>
          <br/>
          <div>
            Ejemplo: +Continental -oferta* "neumáticos"
          </div>
        </nb-card-body>
      </nb-card>

    </ng-template>
  </app-popover>
</ng-container>

