import { NbMenuItem } from '@nebular/theme';
import {MatIcon} from "./@core/@models/icons";
import {BusService} from "./@core/@bus/bus.service";

const LinkLicencia = '/documentacion/legal-licencia';

export function getMenu(bus: BusService): NbMenuItem[] {
  if (bus.isColectivoLexer()){
      return getMenuDocLexer();
  }else if (bus.isColectivoMapfre()){
    return getMenuDocMapfre();
  }else if (bus.isColectivoSabadell()){
    return getMenuDocBS();
  }else if (bus.isColectivoEuropaFactor()){
    return getMenuDocEuropaFactor();
  }
  return getMenuDoc();
}

export function getMenuDoc(): NbMenuItem[] {
  const menu: NbMenuItem[] =  [
    {
      title: $localize`Acerca de CLUBINTEL`,
      icon: MatIcon('grade'),
      link: '/documentacion',
    },

    {
      title: $localize`Preguntas Frecuentes`,
      icon: MatIcon('quiz'),
      link: '/documentacion/faq',
    },

    {
      title: $localize`Contacto y Soporte`,
      icon: MatIcon('support'),
      link: '/documentacion/soporte',
    },

    {
      title: $localize`Política de Privacidad`,
      icon: MatIcon('privacy_tip'),
      link: '/documentacion/legal-privacidad',
    },
    /*{
      title: $localize`Contrato de Licencia`,
      icon: MatIcon('copyright'),
      link: '/documentacion/legal-contrato',
    },*/
  ];

  return menu;

}

export function getMenuDocMapfre(): NbMenuItem[] {
  const menu: NbMenuItem[] =  [
    {
      title: $localize`Acerca CLUB MAPFRE`,
      icon: MatIcon('grade'),
      link: '/documentacion',
    },

    {
      title: $localize`Preguntas Frecuentes`,
      icon: MatIcon('quiz'),
      link: '/documentacion/faq',
    },

    {
      title: $localize`Contacto y Soporte`,
      icon: MatIcon('support'),
      link: '/documentacion/soporte',
    },

    {
      title: $localize`Política de Privacidad`,
      icon: MatIcon('privacy_tip'),
      link: '/documentacion/legal-privacidad',
    },
    /*{
      title: $localize`Contrato de Licencia`,
      icon: MatIcon('copyright'),
      link: '/documentacion/legal-contrato',
    },*/
  ];

  return menu;

}

export function getMenuDocBS(): NbMenuItem[] {
  const menu: NbMenuItem[] =  [
    {
      title: $localize`Acerca CANAL NEGOCIOS`,
      icon: MatIcon('grade'),
      link: '/documentacion',
    },

   /* {
      title: $localize`Preguntas Frecuentes`,
      icon: MatIcon('quiz'),
      link: '/documentacion/faq',
    },*/

    {
      title: $localize`Contacto y Soporte`,
      icon: MatIcon('support'),
      link: '/documentacion/soporte',
    },

    {
      title: $localize`Política de Privacidad`,
      icon: MatIcon('privacy_tip'),
      link: '/documentacion/legal-privacidad',
    },
    /*{
      title: $localize`Contrato de Licencia`,
      icon: MatIcon('copyright'),
      link: '/documentacion/legal-contrato',
    },*/
  ];

  return menu;

}

export function getMenuDocEuropaFactor(): NbMenuItem[] {
  const menu: NbMenuItem[] =  [
    /* {
     title: $localize`Acerca EuropaFactor`,
     icon: MatIcon('grade'),
     link: '/documentacion',
   },*/

   /* {
      title: $localize`Preguntas Frecuentes`,
      icon: MatIcon('quiz'),
      link: '/documentacion/faq',
    },*/

   /* {
      title: $localize`Contacto y Soporte`,
      icon: MatIcon('support'),
      link: '/documentacion/soporte',
    },*/
    {
      title: $localize`Política de Privacidad`,
      icon: MatIcon('privacy_tip'),
      link: '/documentacion/legal-privacidad',
    },
    /*{
      title: $localize`Contrato de Licencia`,
      icon: MatIcon('copyright'),
      link: '/documentacion/legal-contrato',
    },*/
  ];

  return menu;

}

export function getMenuDocLexer(): NbMenuItem[] {
  const menu: NbMenuItem[] =  [
    {
      title: $localize`Acerca de LEXER`,
      icon: MatIcon('grade'),
      link: '/documentacion',
    },

   /* {
      title: $localize`Preguntas Frecuentes`,
      icon: MatIcon('quiz'),
      link: '/documentacion/faq',
    },*/
    {
      title: $localize`Contacto y Soporte`,
      icon: MatIcon('support'),
      link: '/documentacion/soporte',
    },
   /* {
      title: $localize`Política de Privacidad`,
      icon: MatIcon('privacy_tip'),
      link: '/documentacion/legal-privacidad',
    },*/
    {
      title: $localize`Contrato de Licencia`,
      icon: MatIcon('copyright'),
      link: '/documentacion/legal-contrato',
    },
  ];

  return menu;

}



export function menuDocSetLogged(items: NbMenuItem[], isLogged: boolean): void  {
  if (isLogged) {
    // Miramos si el link está
    let esta = false;
    items.forEach((item): void => {
      if (item.link == LinkLicencia) {
        esta = true;
      }
    })

    /*if (!esta) {
      items.push(
        {
          title: $localize`Contrato de Licencia`,
          icon: MatIcon('copyright'),
          link: LinkLicencia,
        },
      )
    }*/
  } else {
    // Lo quitamos
    // Miramos si el link está
    let estaIndex = -1;
    items.forEach((item, index): void => {
      if (item.link == LinkLicencia) {
        estaIndex = index;
      }
    })
    if (estaIndex >= 0) {
      items.splice(estaIndex,1);
    }
  }
}
