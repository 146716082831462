import {Component, Input, OnInit} from '@angular/core';
import {Status} from "../../@models/theme";

@Component({
  selector: 'app-info-prompt',
  templateUrl: './info-prompt.component.html',
  styleUrls: ['./info-prompt.component.scss']
})
export class InfoPromptComponent implements OnInit {

  @Input() status: Status = 'basic';
  @Input() accent: Status = 'warning';
  @Input() title: string = 'Información';
  @Input() body: string = '';
  @Input() onOK?: () => void;

  constructor() { }

  ngOnInit(): void {
  }

  onAceptar() {
    if (this.onOK) {
      this.onOK();
    }
  }

}
