import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    NbAccordionModule, NbButtonModule,
    NbCardModule, NbChatModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbSpinnerModule, NbTabsetModule,
    NbTagModule, NbUserModule
} from "@nebular/theme";
import {FormViewModule} from "../../../../@core/@form-view/@form-view.module";
import {ReactiveFormsModule} from "@angular/forms";
import {ChatBotEfRoutingModule} from "./chat-bot-ef-routing.module";
import {ChatBotEFComponent} from "./chat-bot-ef.component";
import {CalendarModule} from "../../../../@core/@tools/calendar/calendar.module";

@NgModule({
  declarations: [
    ChatBotEFComponent,
  ],
    imports: [
        CommonModule,
        ChatBotEfRoutingModule,
        NbCardModule,
        FormViewModule,
        NbAccordionModule,
        NbSpinnerModule,
        ReactiveFormsModule,
        NbTagModule,
        NbDatepickerModule,
        NbInputModule,
        CalendarModule,
        NbButtonModule,
        NbIconModule,
        NbTabsetModule,
        NbChatModule,
        NbUserModule
    ]
})
export class ChatBotEfModule { }
