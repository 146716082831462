import {Component, Input, OnInit} from '@angular/core';
import {Status} from "../../@models/theme";


export type WizardPage = {
  title: string,
  titleClass?: string,
  subtitle?: string,
  subtitleClass?: string,
  body: string,
  bodyClass?: string,
  canExit?: boolean,
  imageUrl?: string,
}



@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {
  @Input() pages: WizardPage[] = [];
  @Input() header: string = 'Wizard';
  @Input() status: Status = 'basic';
  @Input() accent: Status = 'warning';
  @Input() onExit?: () => void;


  currentPage: number = 0;
  hasNext: boolean = false;
  hasPrevious: boolean = false;
  canExit: boolean = false;

  defaultTitleClass = 'text-main-header text-gradient-warm';
  defaultSubtitleClass = 'text-main-subheader text-gradient-blue';
  defaultBodyClass = 'wizard-body-content';

  title: string = '';
  titleClass: string = '';
  subtitle: string = '';
  subtitleClass: string = '';
  body: string = '';
  bodyClass: string = '';
  imageUrl: string = '';

  constructor() {

  }

  ngOnInit() {
    this.hasNext = false;
    this.hasPrevious = false;
    if (this.currentPage >= this.pages.length) {
      this.currentPage = 0;
    }
    this.doChecks();

  }

  doChecks() {
    this.hasPrevious = this.currentPage > 0;
    this.hasNext = this.currentPage < this.pages.length - 1;
    this.canExit = this.pages[this.currentPage].canExit || false;
    if (this.currentPage >= this.pages.length-1) {
      this.canExit = true;
    }
    this.title = this.pages[this.currentPage].title;
    this.titleClass = this.pages[this.currentPage].titleClass || '';
    this.subtitle = this.pages[this.currentPage].subtitle || '';
    this.subtitleClass = this.pages[this.currentPage].subtitleClass || '';
    this.body = this.pages[this.currentPage].body;
    this.bodyClass = this.pages[this.currentPage].bodyClass || '';
    this.imageUrl = this.pages[this.currentPage].imageUrl || '';

    // Defaults
    if (this.titleClass == '') {
      this.titleClass = this.defaultTitleClass;
    }
    if (this.subtitleClass == '') {
      this.subtitleClass = this.defaultSubtitleClass;
    }
    if (this.bodyClass == '') {
      this.bodyClass = this.defaultBodyClass;
    }
  }



  pageNext() {
    if (this.currentPage < this.pages.length - 1) {
      this.currentPage += 1;
      this.doChecks();
    }
  }

  pagePrevious() {
    if (this.currentPage > 0) {
      this.currentPage -= 1;
      this.doChecks();
    }
  }

  onCancelar() {
    if (this.onExit) {
      this.onExit();
    }
  }

}
