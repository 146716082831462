import {Component, Inject, OnInit} from '@angular/core';
import {NowHours} from "../../@core/@models/date";
import {BusService} from "../../@core/@bus/bus.service";

@Component({
  selector: 'app-storage-not-enabled',
  templateUrl: './storage-not-enabled.component.html',
  styleUrls: ['./storage-not-enabled.component.scss']
})
export class StorageNotEnabledComponent implements OnInit {

  logo: string = "assets/clubintel_logo.svg?v=3";
  labelProteccionNavegador: string = $localize`Su navegador tiene protección de escritura`;
  labelDesactivarProteccion: string = $localize`Por favor, desactive las protecciones de su navegador`;
  labelProbar: string = $localize`Probar de nuevo`;

  constructor(@Inject(Window) private window: Window,
              private bus: BusService) {
    const urlBus: string = this.bus.router.getUrlBus();
    this.logo = urlBus + '/assets?id=logo_full&t='+NowHours()
  }

  ngOnInit(): void {

  }

  navigateRoot(): void {
    this.window.location.href = '/';
  }

}
