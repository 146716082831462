

import {BackendRequest, BackendResponse} from "../@core/@bus/request.model";
import {AuthData, AuthDataInterface} from "./auth-data";
import {GetGlobalEventManager} from "./bus-configuration";
import {GetGlobalBus} from "../@core/@models/globals";
import {
  LoginRequest,
  LoginResponse,
  LogoutRequest,
  LogoutResponse, ReconnectRequest,
  SignUpRequest,
  SignUpResponse
} from "../models/login";
import {getTime} from "../@core/@models/common";
import {Subscription} from "rxjs";

let GlobalAuth: AuthData = new AuthData();

export type resolver = (value?: any | PromiseLike<any>) => void;

export type SharedCredentials  = {
  credentials: string,
  session: string,
  device: string,
}

// Para mantener aquí todas las variables globales
export class Authentication  {

  static authenticating: boolean = false;
  static timerExpireSession: any;

  static waitAuthenticating(): Promise<boolean> {
    return new Promise<boolean>( (resolve, reject): void => {
      const interval = setInterval( (): void => {
        if (!Authentication.authenticating) {
          clearInterval(interval);
          resolve(Authentication.getAuth().isValid());
        }
      }, 500);
    });
  }

  static applyTimerExpireSesion(): void  {
    if (Authentication.timerExpireSession) {
      clearTimeout(Authentication.timerExpireSession);
      Authentication.timerExpireSession = null;
    }

    const auth: AuthData = Authentication.getAuth();
    if (auth.isValid()) {

      const ahora: number = getTime();
      const expira: number = auth.expiration_date?.getTime() ?? 0;

      const when: number = expira - ahora - 10000; // 10 segundos antes
      if (when > 0 ) {
        Authentication.timerExpireSession = setTimeout( (): void => {
          Authentication.reconnect();
        }, when);
      } else {
        Authentication.reconnect();
      }
    }
  }

  static setAuth(value: AuthData): void {
    GlobalAuth = value;
  }

  static getAuth(): AuthData {
    return GlobalAuth;
  }

  static getSso(): string {
    const queryParams: URLSearchParams = GetGlobalBus().router.getQueryParams();
    return queryParams.get('sso') ?? '';
  }

  private static getSharedKey(shared: SharedCredentials): string {
    return 'sharedInvalidated-'+shared.credentials+'-'+shared.session;
  }

  static setSharedInvalidated(shared: SharedCredentials, val: boolean): void {
    GetGlobalBus().globals.set(Authentication.getSharedKey(shared), val);
  }
  static getSharedInvalidated(shared: SharedCredentials): boolean {
    const res = GetGlobalBus().globals.get(Authentication.getSharedKey(shared), false);
    if (res) {
      return true;
    }
    return false;
  }


  static reconnect(): Promise<boolean> {
    if (Authentication.authenticating) {
      return Authentication.waitAuthenticating();
    }
    return new Promise<boolean>( (resolve, reject): void => {
      // Miramos si ha pasado shared credentials
      const shared = Authentication.getSharedCredentials();
      if (shared && !this.getSharedInvalidated(shared)) {
        // Intentamos ver si está la sessión abierta
        Authentication.loginShared(shared).then( (response): void => {
          const logged: boolean = Authentication.getAuth().isValid();
          if (!logged) {
            this.setSharedInvalidated(shared,true);
            GetGlobalBus().events.loginEvent.next(false);
          }
          resolve(logged);
          return;
        });
      } else {
        // Intentamos ver si está la sessión abierta
        Authentication.reconnectDirect().then( (logged: boolean): void => {
          resolve(logged);
        });
      }
    });
  }

  private static reconnectDirect(): Promise<boolean> {
    return new Promise<boolean>( (resolve, reject): void => {
      Authentication.authenticating = true;
      const request: ReconnectRequest = new ReconnectRequest();
      const response: LoginResponse = new LoginResponse();
      response.sendRequest("reconnect", request).then( (): void => {
        if (response.error == "") {
          Authentication.newAuth(response);
          if (Authentication.getAuth().isValid()) {
            GetGlobalBus().events.loginEvent.next(true);
          }
        }
        Authentication.authenticating = false;
        resolve(Authentication.getAuth().isValid());
      });
    });
  }

  static getSharedCredentials(): SharedCredentials | null {
    // Pasamos los query args
    const queryParams = GetGlobalBus().router.getQueryParams();
    const res = {
      credentials: queryParams.get('u') ?? '',
      session: queryParams.get('s') ?? '',
      device: queryParams.get('d') ?? '',
    };

    if (res.credentials != '' && res.session != '' && res.device != '') {
      return res;
    }
    return null;

  }

  static loginShared(shared: SharedCredentials): Promise<LoginResponse> {
    const request: LoginRequest = new LoginRequest();
    request.shared = shared;
    return Authentication.login(request);
  }



  static login(request: LoginRequest): Promise<LoginResponse> {
    return new Promise<LoginResponse>( (resolve, reject): void => {
      Authentication.authenticating = true;
      const response: LoginResponse = new LoginResponse();
      response.sendRequest("login", request).then( (): void => {
        if (response.error == "") {
          Authentication.newAuth(response);
          if (Authentication.getAuth().isValid()) {
            GetGlobalBus().events.loginEvent.next(true);
          }
        }
        Authentication.authenticating = false;
        resolve(response);
      });
    });
  }


  static logout(redirect: boolean = true): Promise<boolean> {
    return new Promise<boolean>( (resolve, reject): void => {
      Authentication.authenticating = true;
      const response: LogoutResponse = new LogoutResponse();
      const request: LogoutRequest = new LogoutRequest();
      response.sendRequest("logout", request).then( (): void => {
        Authentication.authenticating = false;
        if (response.error == "") {
          Authentication.resetAuth();
          GetGlobalBus().events.loginEvent.next(false);
          GetGlobalBus().router.reset();
          if (redirect){
            Authentication.goToLanding();
          }
          resolve(true);
        } else {
          GetGlobalBus().logger.errorBrowser('logout => ', response.error);
          resolve(false);
        }
      });
    });
  }


  static signUp(request: SignUpRequest): Promise<SignUpResponse> {
    return new Promise<SignUpResponse>( (resolve, reject): void => {
      const response: SignUpResponse = new SignUpResponse();
      response.sendRequest("signup", request).then( (): void => {
        resolve(response);
      });
    });
  }

  static forgetPassword(data: any): Promise<BackendResponse> {
    return new Promise<BackendResponse>( (resolve, reject): void => {
      const request: BackendRequest = new BackendRequest();
      request.event = "forget_password";
      request.data = data;
      GetGlobalBus().sendRemote(request).then( (response: BackendResponse): void => {
        resolve(response);
      })
    });
  }

  static waitForAuthValid(): Promise<AuthData> {
    if (Authentication.getAuth().isValid()) {
      return Promise.resolve(Authentication.getAuth());
    }
    return new Promise<AuthData>( (resolve, reject): void => {
      Authentication.reconnect().then( (logged: boolean): void => {
        resolve(Authentication.getAuth());
      });
    });
  }

  static waitForLogged(): Promise<AuthData> {
    if (Authentication.getAuth().isValid()) {
      return Promise.resolve(Authentication.getAuth());
    }
    return new Promise<AuthData>( (resolve, reject): void => {

      // Si tarda más de 2 segundos en recibir el evento, reconectamos
      let timeout: any = setTimeout( (): void => {
        Authentication.reconnect();
      }, 2000);

      const sub: Subscription = GetGlobalBus().events.loginEvent.subscribe( (logged: boolean): void => {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        if(logged) {
          resolve(Authentication.getAuth());
          sub.unsubscribe();
        }
      });
    });
  }

  static newAuth(data: AuthDataInterface): void {
    Authentication.getAuth().loadFromLogin(data);
    Authentication.applyTimerExpireSesion();
    GetGlobalEventManager().newConfigEvent.next(Authentication.getAuth().config);
  }

  static resetAuth(): void {
    Authentication.getAuth().reset();
    Authentication.applyTimerExpireSesion();
    GetGlobalEventManager().newConfigEvent.next(Authentication.getAuth().config);
  }


  static goToLanding(): Promise<boolean> {
    if (Authentication.getAuth().isValid()) {
      return GetGlobalBus().router.goTo('/inicial');
    } else {
      return GetGlobalBus().router.goTo('/public');
    }
  }



}
