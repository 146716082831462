import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {BusService} from "../../@core/@bus/bus.service";
import {NbDialogRef, NbDialogService} from "@nebular/theme";
import {Icon, IconClose} from "../../@core/@models/icons";
import {Authentication} from "../../core/authentication";
import {GetGlobalEventManager} from "../../core/bus-configuration";
import {ErrNotValidated} from "../../models/error";
import {LoginRequest, LoginResponse} from "../../models/login";
import {GenericRequest, GenericResponse} from "../../models/generic";
import {Backend} from "../../core/backend";
import {RouteStartsWith} from "../../@core/@models/strings";
import {Deuda, ModifyDeudaRequest, ModifyDeudaResponse} from "../../models/deuda";
import {GetGlobalBus} from "../../@core/@models/globals";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isEuropaFactor: boolean = false;


  constructor(private bus: BusService) {

  }

  ngOnInit(): void {
    if(this.bus.isColectivoEuropaFactor()) {
      this.isEuropaFactor = true;
    }
  }

}
